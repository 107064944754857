<template>
    <inv-object-tab-item :tab="tab" :tabIsLoading="tabIsLoading">
        <template slot="body">
            <v-card flat v-if="coordinatesNotFountMessage && !hasCoordinates" :class="{ 'tab-body-disabled' : tabIsLoading }">
                <v-card-text>
                    {{coordinatesNotFountMessage}}
                </v-card-text>
            </v-card>
            <div v-else-if="hasCoordinates" style="height: 100%" :class="{ 'tab-body-disabled' : tabIsLoading }">
                <inv-object-tab-map-pointer :latitude="latitudeDecimal" :longitude="longitudeDecimal" :resizeNeeded="expanded"></inv-object-tab-map-pointer>
            </div>
        </template>
    </inv-object-tab-item>
</template>

<script>
    import backend from 'obj-fe/app/backend';
    import notify from 'obj-fe/services/notifications';

    import tabBehaviour from './inv-object-tab-behaviour';
    import InvObjectTabItem from './inv-object-tab-item.vue';
    import InvObjectTabMapPointer from './inv-object-tab-map-pointer.vue';

    export default {
        mixins:[ tabBehaviour ],
        components:{
            InvObjectTabItem,
            InvObjectTabMapPointer
        },
        data(){
            return {
                latitudeDecimal: null,
                longitudeDecimal: null,
                coordinatesNotFountMessage: ''
            };
        },
        computed:{
            hasCoordinates(){
                return !!(this.latitudeDecimal && this.longitudeDecimal);
            }
        },
        methods:{
            onFirstTimeExpandLoad(successCb, errorCb){
                backend.objects.mapInfo(
                    this.objectId, 
                    data => {
                        this.latitudeDecimal = data.latitude;
                        this.longitudeDecimal = data.longitude;
                        this.coordinatesNotFountMessage = data.altTxt;
                        successCb();
                    }, 
                    errorCb
                );
            }
        }
    };
</script>