<template>
    <v-form v-model="isValid">
        <template v-for="(col, colIndex) in columns">
            <spreadsheet-row-form-field :key="colIndex" v-if="!col.hideInForm" :row-index="rowIndex" :col-index="colIndex" :spreadsheet="spreadsheetRef"></spreadsheet-row-form-field>
        </template>
    </v-form>
</template>

<script>
    import SpreadsheetRowFormField from './spreadsheet-row-form-field.vue';

    export default {
        components:{
            SpreadsheetRowFormField
        },
        props:{
            rowIndex: Number,
            spreadsheet: Object // spreadsheet reference
        },
        data(){
            return {
                isValid: false
            };
        },
        computed:{
            spreadsheetRef(){
                return this.spreadsheet || this.$parent.$parent.$parent;
            },
            columns(){
                let row = this.spreadsheetRef.getRow(this.rowIndex);
                return this.spreadsheetRef.columns[row.rowType];
            }
        }
    };
</script>