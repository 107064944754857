import store from 'obj-fe/app/store';
import backend from 'obj-fe/app/backend';
import router from 'obj-fe/app/router';
import object from 'obj-fe/utils/object';
import locals from 'obj-fe/services/localisation';
import Vue from 'vue';

function InitState(){
    this.layers = null;
    this.layerData = null;
}

store.registerModule('INVENTORY_MAP', {
    namespaced: true,
    state: new InitState(),
    actions:{
        ROUTE_CHANGE_START:{
            root: true,
            handler(ctx, route){
                if(route.name === 'inventory-map') {
                    ctx.dispatch('CLEAR');
                    ctx.dispatch('LOAD_LAYERS_LIST');
                }
            }
        },
        CLEAR(ctx){
            ctx.commit('CLEAR');
        },
        LOAD_LAYERS_LIST(ctx){
            backend.maps.layers(data => {
                data = data.map(layer => {
                    layer.id = layer.name;
                    layer.selected = false;
                    return layer;
                });
                ctx.commit('SET_LAYERS_LIST', data);
            });
        },
        LOAD_LAYER_DATA(ctx){
            let selectedLayerIds = ctx.state.layers.filter(layer => layer.selected).map(layer => layer.id);

            if(selectedLayerIds.length > 0) backend.maps.layerData(null, selectedLayerIds, layerData => {
                ctx.commit('SET_LAYER_DATA', layerData);
            });
            else ctx.commit('SET_LAYER_DATA', null);
        },
        TOGGLE_LAYER(ctx, layer){
            ctx.commit('TOGGLE_LAYER', layer);
            ctx.dispatch('LOAD_LAYER_DATA');
        }
    },
    mutations:{
        CLEAR(state){
            let initState = new InitState();
            for(let key in initState) Vue.set(state, key, initState[key]);
        },
        SET_LAYERS_LIST(state, layers){
            state.layers = layers;
        },
        SET_LAYER_DATA(state, layerData){
            state.layerData = layerData;
        },
        TOGGLE_LAYER(state, layer){
            layer.selected = !layer.selected;
            state.layers = state.layers.slice();
        }
    }
});