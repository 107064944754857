<template>
    <v-card 
        class="mini-object-card"
        flat
        v-if="object"
    >
        <v-card-title>
            <h2>
                <inv-object-link :object="object"/>
            </h2>
            <!-- more, save and discard changes buttons -->
            <div class="object-main-buttons">
                <!-- close button -->
                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-btn
                            @click="$emit('close')"
                            v-on="on"
                            icon
                            outlined
                            color="error"  
                            class="object-close-button" 
                        >
                            <v-icon>fal fa-times</v-icon>
                        </v-btn>
                    </template>
                    <span>
                        {{'close' | translate}}
                    </span>
                </v-tooltip>

            </div>

        </v-card-title>
        <div class="content-wrapper">
            <v-card-text v-if="object.fieldsGrouped">
                <v-container style="padding-top: 0px">
                    <v-row>
                        <v-col 
                            cols="12"
                            v-for="(formGroup, i) in object.fieldsGrouped" 
                            :key="i" 
                            class="tabs-group"
                        >
                            <v-subheader 
                                v-if="formGroup.displayString" 
                                class="tabs-header"
                            >
                                {{ formGroup.displayString }}
                            </v-subheader>
                            <v-row 
                                no-gutters 
                                v-for="(field, i) in formGroup.fields.filter(e => !!e.value || e.value === '' || e.value === 0)"
                                :key="`${i}_${formGroup.displayString}`"
                            >
                                <v-col 
                                    v-if="field.displayName" 
                                    cols="5" 
                                    class="form-field-label"
                                >
                                    {{ field.displayName }}
                                </v-col>
                                
                                <v-col 
                                    :cols="field.displayName ? 7 : 12" 
                                    class="form-field-value"
                                >
                                    <obj-generic-field 
                                        :field="field" 
                                        :value="field.value" 
                                        :edit-mode="false" 
                                        :meta="{ objectId }" 
                                        @input="(v) => $emit('field-input', field, v)" 
                                    />
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>

            <inv-mini-object-panels
                :object="object"
            />
        </div>
    </v-card>
    <v-card v-else>
        <v-card-title>
            <!-- more, save and discard changes buttons -->
            <div class="object-main-buttons">
                <!-- close button -->
                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-btn
                            @click="$emit('close')"
                            v-on="on"
                            icon
                            outlined
                            color="error"  
                            class="object-close-button" 
                        >
                            <v-icon>fal fa-times</v-icon>
                        </v-btn>
                    </template>
                    <span>
                        {{'close' | translate}}
                    </span>
                </v-tooltip>
            </div>
        </v-card-title>
    </v-card>
</template>

<style scoped>
.mini-object-card{
    max-height: 100%;
    overflow: hidden;
}
.mini-object-card .content-wrapper{
    flex-grow: 1;
    overflow: auto;
}
.mini-object-card .v-card__title{
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: nowrap;
    padding-bottom: 16px;
    flex-shrink: 1;
}
.mini-object-card.v-card .v-card__text{
    padding: 0 16px !important;
    height: unset;
    flex: unset;
}
.mini-object-card .tabs-group{
    padding: 0 0 12px 0;
} 
.mini-object-card.v-card .mini-object-actions{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}
.mini-object-actions .v-btn--active::before{
    opacity: 0 !important;
}
</style>
<script>
    import InvObjectForm from '../inv-object-form.vue';
    import InvObjectLink from '../inv-object-link.vue';
    import InvObjectTabs from '../inv-object-tabs.vue';
    import emdslInitiator from '../../dsl/emdsl/emdsl-initiator/emdsl-initiator.vue';
    import InvMiniObjectPanels from "./inv-mini-object-panels.vue";

    export default {
        components:{
            InvMiniObjectPanels,
            InvObjectForm,
            InvObjectLink,
            InvObjectTabs,
            emdslInitiator
        },
        props:{
            id: String,
            enableDelete: Boolean,
            customSave: Boolean
        },
        computed:{
            pageObjectId(){
                return this.$store.state.INVENTORY.pageObjectId;
            },
            objectId(){
                if(this.id) return this.id;
                else return this.pageObjectId;
            },
            object(){
                return this.$store.state.INVENTORY.objectPreviews[ this.objectId ];
            },
        },
    };
</script>
