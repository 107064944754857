import { render, staticRenderFns } from "./inv-object-form-field-placement.vue?vue&type=template&id=b70526dc&scoped=true&"
import script from "./inv-object-form-field-placement.vue?vue&type=script&lang=js&"
export * from "./inv-object-form-field-placement.vue?vue&type=script&lang=js&"
import style0 from "./inv-object-form-field-placement.vue?vue&type=style&index=0&id=b70526dc&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b70526dc",
  null
  
)

export default component.exports