<template>
    <inv-object-tab-item :tab="tab" :tabIsLoading="tabIsLoading">
        <template slot="body">
            <div 
                style="height: 100%; width: 100%" 
                :class="{ 'tab-body-disabled' : tabIsLoading }"
                v-if="mapReady"
            >
                <InvGisMap
                    :layers="layers"
                    :latitudeDecimal="latitudeDecimal"
                    :longitudeDecimal="longitudeDecimal"
                    computeCenter
                />
            </div>
        </template>
    </inv-object-tab-item>
</template>

<script>
    import backend from 'obj-fe/app/backend';
    import notify from 'obj-fe/services/notifications';

    import tabBehaviour from './inv-object-tab-behaviour';
    import InvObjectTabItem from './inv-object-tab-item.vue';
    import InvGisMap from '../inventory-map/inv-gis-map.vue';

    export default {
        mixins:[ tabBehaviour ],
        components:{
            InvObjectTabItem,
            InvGisMap
        },
        data(){
            return {
                latitudeDecimal: null,
                longitudeDecimal: null,
                mapReady: false
            };
        },
        computed:{
            hasCoordinates(){
                return !!(this.latitudeDecimal && this.longitudeDecimal);
            },
            layers(){
                return this.$store.state.INVENTORY_GIS_MAP.layers;
            },
        },
        methods:{
            onFirstTimeExpandLoad(successCb, errorCb){
                this.$store.dispatch('INVENTORY_GIS_MAP/CLEAR');
                this.$store.dispatch('INVENTORY_GIS_MAP/LOAD_LAYERS_LIST');
                
                backend.objects.mapInfo(
                    this.objectId, 
                    data => {
                        this.latitudeDecimal = data.latitude;
                        this.longitudeDecimal = data.longitude;
                        this.mapReady = true;
                        successCb();
                    }, 
                    errorCb
                );
            }
        }
    };
</script>