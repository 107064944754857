/**
 * MouseEvent path property polyfill
 */
(function MouseEventPathPolyfill() {
    if (!('path' in Event.prototype)) {
        Object.defineProperty(Event.prototype, 'path', {
            get: function () {
                const path = [];
                let currentElem = this.target;
                while (currentElem) {
                    path.push(currentElem);
                    currentElem = currentElem.parentElement;
                }
                if (path.indexOf(window) === -1 && path.indexOf(document) === -1)
                    path.push(document);
                if (path.indexOf(window) === -1)
                    path.push(window);
                return path;
            }
        });
    }
})();

export default {
    methods:{
        unfocus(event){
            if(this.selectedCells.count === 0) return; // selection already cleared
            
            let path = event.path || (event.composedPath && event.composedPath());
            if(!path) return; // path missing
            
            // traverse event path to ensure this event is not from this component
            for(let i=0; i < path.length; i++) {
                let targetElm = path[i];
                if(targetElm === this.$refs.table) return; // target is from this component
                else if(!targetElm || targetElm === window) break; // target element is not from this component
            }

            this.clearCellsSelection();
        },

        keypressHandlers(event){
            // if(this.selectedCells.firstRowIndex === -1) return;
            // // traverse event path to ensure this event is from this component
            // let path = event.path || (event.composedPath && event.composedPath());
            // for(let i=0; i < path.length; i++) {
            //     let targetElm = path[i];
            //     if(targetElm === this.$refs.table) break; // target is from this component
            //     else if(!targetElm || targetElm === window) return; // target element is not from this component
            // }

            let notFocused = this.selectedCells.firstRowIndex === -1;
            let metaKeyName = '';
            if(event.ctrlKey) metaKeyName = 'ctrl_';
            else if(event.altKey) metaKeyName = 'alt_';
            else if(event.metaKey) metaKeyName = 'cmd_';
            else if(event.shiftKey) metaKeyName = 'shift_';

            let keyHandlerMethodName = (notFocused ? 'unfocused_' : '') + 'key_'+metaKeyName+event.code;
            if(typeof this[keyHandlerMethodName] === 'function') {
                this[keyHandlerMethodName](event);
            }
            else if(!notFocused && (!metaKeyName || event.shiftKey) && event.key.length === 1) this.key_default(event);
        },
        
        key_default(event){
            if(this.editingCell.active) return;
            event.preventDefault();

            let rowIndex = this.selectedCells.firstRowIndex;
            let colIndex = this.selectedCells.firstColIndex;
            
            this.toggleCellEdit(rowIndex, colIndex, event.key);
        },
        key_ctrl_KeyZ(event){
            if(this.editingCell.active) return;
            event.preventDefault();

            if(event.shiftKey) this.redo();
            else this.undo();
        },
        key_cmd_KeyZ(event){
            if(this.editingCell.active) return;
            event.preventDefault();

            if(event.shiftKey) this.redo();
            else this.undo();
        },
        unfocused_key_ctrl_KeyZ(event){
            if(this.editingCell.active) return;
            event.preventDefault();

            if(event.shiftKey) this.redo();
            else this.undo();
        },
        unfocused_key_cmd_KeyZ(event){
            if(this.editingCell.active) return;
            event.preventDefault();

            if(event.shiftKey) this.redo();
            else this.undo();
        },
        key_ctrl_KeyC(){
            if(this.editingCell.active) return;
            this.copySelectedCells();
        },
        key_cmd_KeyC(){
            if(this.editingCell.active) return;
            this.copySelectedCells();
        },
        key_ctrl_KeyV(){
            if(this.editingCell.active) return;
            this.pasteCopiedCells();
        },
        key_cmd_KeyV(){
            if(this.editingCell.active) return;
            this.pasteCopiedCells();
        },
        key_Backspace(){
            if(this.editingCell.active) return;
            this.clearSelectedCells();
        },
        key_Delete(){
            if(this.editingCell.active) return;
            this.clearSelectedCells();
        },
        key_shift_ArrowUp(event){
            if(this.editingCell.active) return;
            event.preventDefault();
            let sel = this.selectedCells;
            let fromInc = sel.fromRowIndex < sel.firstRowIndex ? 1 : 0;
            let toInc = (!fromInc && sel.toRowIndex >= sel.firstRowIndex) ? 1 : 0;
            this.selectCells(sel.fromRowIndex - fromInc, sel.fromColIndex, sel.toRowIndex - toInc, sel.toColIndex, sel.firstRowIndex, sel.firstColIndex);
        },
        key_shift_ArrowDown(event){
            if(this.editingCell.active) return;
            event.preventDefault();
            let sel = this.selectedCells;
            let fromInc = sel.fromRowIndex < sel.firstRowIndex ? 1 : 0;
            let toInc = (!fromInc && sel.toRowIndex >= sel.firstRowIndex) ? 1 : 0;
            this.selectCells(sel.fromRowIndex + fromInc, sel.fromColIndex, sel.toRowIndex + toInc, sel.toColIndex, sel.firstRowIndex, sel.firstColIndex);
        },
        key_shift_ArrowLeft(event){
            if(this.editingCell.active) return;
            event.preventDefault();
            let sel = this.selectedCells;
            let fromInc = sel.fromColIndex < sel.firstColIndex ? 1 : 0;
            let toInc = (!fromInc && sel.toColIndex >= sel.firstColIndex) ? 1 : 0;
            this.selectCells(sel.fromRowIndex, sel.fromColIndex - fromInc, sel.toRowIndex, sel.toColIndex - toInc, sel.firstRowIndex, sel.firstColIndex);
        },
        key_shift_ArrowRight(event){
            if(this.editingCell.active) return;
            event.preventDefault();
            let sel = this.selectedCells;
            let fromInc = sel.fromColIndex < sel.firstColIndex ? 1 : 0;
            let toInc = (!fromInc && sel.toColIndex >= sel.firstColIndex) ? 1 : 0;
            this.selectCells(sel.fromRowIndex, sel.fromColIndex + fromInc, sel.toRowIndex, sel.toColIndex + toInc, sel.firstRowIndex, sel.firstColIndex);
        },

        key_Tab(event){
            event.preventDefault();
            this.closeEditingMode();

            let colIndex = this.selectedCells.firstColIndex;

            // request new column on tab if this is end line
            if(colIndex === this.columns.length-1) this.$emit('add-column', colIndex);
            
            this.key_ArrowRight(event);
        },
        key_Enter(event){ // select next row
            let wasEditing = this.closeEditingMode();
            let rowIndex = this.selectedCells.firstRowIndex;
            if(wasEditing) return; // do not create new row if editing last row cell

            // add new row on enter if this is end lines
            if(rowIndex === this.rows.length-1) this.addRow();

            this.$nextTick(() => this.key_ArrowDown(event));
        },
        key_NumpadEnter(event){
            this.key_Enter(event);
        },
        key_ArrowUp(event){
            if(this.editingCell.active) return;
            event.preventDefault();
            this.selectCells(this.selectedCells.firstRowIndex-1, this.selectedCells.firstColIndex);
        },
        key_ArrowDown(event){
            if(this.editingCell.active) return;
            event.preventDefault();
            this.selectCells(this.selectedCells.firstRowIndex+1, this.selectedCells.firstColIndex);
        },
        key_ArrowLeft(event){
            if(this.editingCell.active) return;
            event.preventDefault();
            this.selectCells(this.selectedCells.firstRowIndex, this.selectedCells.firstColIndex-1);
        },
        key_ArrowRight(event){
            if(this.editingCell.active) return;
            event.preventDefault();
            this.selectCells(this.selectedCells.firstRowIndex, this.selectedCells.firstColIndex+1);
        },
    },
    mounted(){
        window.addEventListener('keydown', this.keypressHandlers);
        window.addEventListener('mousedown', this.unfocus);
    },
    destroyed(){
        window.removeEventListener('keydown', this.keypressHandlers);
        window.removeEventListener('mousedown', this.unfocus);
    }
};