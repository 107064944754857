function deepCloneObject(obj){
    let copy;
    if(Array.isArray(obj)) copy = [];
    else if(Object.prototype.toString.call(obj) === '[object Object]') copy = {};
    else return obj;

    for(let key in obj){
        if(key !== '_state' && key !== 'toJSON' && obj.hasOwnProperty(key)) copy[key] = deepCloneObject(obj[key]);
    }
    return copy;
}

function stringifyReplacer(ignoreProps = {}){
    return function(key, value){
        if(key === '_state' || ignoreProps[key]) return undefined;
        else if(Object.prototype.toString.call(value) === '[object Object]') return Object.keys(value).sort().reduce((s,k) => {s[k] = value[k]; return s}, {});
        else return value;
    }
}

function arrayToObject(array){
    let obj = {};
    array.forEach(v => obj[v] = true);
    return obj;
}

function Row(rowData = {}, dontTrackProps = []){
    let rowCopy = deepCloneObject(rowData);

    // make deep copy of data object to ensure original data would not be changed
    for(let key in rowCopy){
        if(key !== 'rowType') this[key] = rowCopy[key];
    }

    rowCopy.rowType = rowData.rowType || 'default';

    Object.defineProperty(this, 'rowType', {
        enumerable: false,
        writable: true,
        value: rowData.rowType || 'default'
    });

    this._state = rowData._state || {
        id: new Date().getTime() + '-' + (Math.floor(Math.random() * 100000000) + 1) + '-' + (Math.floor(Math.random() * 100000000) + 1),
        height: 24,
        active: false,
        editingColIndex: -1,
        editingCellOrigValue: undefined,
        cellErrors: null,
        rowErrors: null,
        cellInfos: {},
        requiredCells: {},
        readonlyCells: {},
        editableCells: {},
        disabledCells: {},
        cellViewValues: {},
        cellEditors:{},
        cellRenderers:{},
        cellLabels:{},
        cellDataWatchers:{}, // { [rowDataKey]:{ colIndex1, colIndex2, ...} }
        cellHrefs:{},
        cellStyles:{}
        // changed: false,
    };

    Object.defineProperty(this, '_ignoreProps', {
        enumerable: false,
        value: arrayToObject(dontTrackProps)
    });

    Object.defineProperty(this, '_contentHash', {
        enumerable: false,
        value: rowData._contentHash || JSON.stringify(this, stringifyReplacer(this._ignoreProps))
    });

    Object.defineProperty(this, '_initValue', {
        enumerable: false,
        value: rowData._initValue || rowCopy
    });

    this.toJSON = function(){
        return { ...this, _state:undefined };
    };

    return this;
}

Row.prototype.isEmpty = function(){
    for(let key in this){
        if(key !== '_state' && key !== 'toJSON' && !this._ignoreProps[key] && this.hasOwnProperty(key) && this[key] !== undefined && this[key] !== '') return false;
    }
    return true;
};

Row.prototype.hasChanged = function(debug){
    let hasChanged = (this._initValue.rowType !== this.rowType) || (this._contentHash !== JSON.stringify(this, stringifyReplacer(this._ignoreProps)));
    // if(this._state.changed !== hasChanged) this._state.changed = hasChanged;
    
    if(debug && hasChanged) {
        console.warn('Row changed', 'row type change: ' + (this._initValue.rowType !== this.rowType));
        let comparedProps = compareJSONs(this._contentHash, JSON.stringify(this, stringifyReplacer(this._ignoreProps)));
        for(let key in comparedProps){
            console.warn('changed on prop "'+key+'": ', comparedProps[key][0], comparedProps[key][1]);
        }
    }
    
    return hasChanged;
};

Row.prototype.getCellChanges = function(){
    return compareJSONs(this._contentHash, JSON.stringify(this, stringifyReplacer(this._ignoreProps)));
};

function compareJSONs(jsonA, jsonB){
    let objA = JSON.parse(jsonA);
    let objB = JSON.parse(jsonB);

    let keys = Array.from(new Set(Object.keys(objA).concat(Object.keys(objB))));
    let diff = {};
    
    keys.forEach(key => {
        let keyA = JSON.stringify(objA[key]) + '';
        let keyB = JSON.stringify(objB[key]) + '';

        if(keyA !== keyB) diff[key] = [ keyA, keyB ];
    });

    return diff;
}

export default Row;