import store from 'obj-fe/app/store';
import backend from 'obj-fe/app/backend';
import router from 'obj-fe/app/router';
import object from 'obj-fe/utils/object';
import locals from 'obj-fe/services/localisation';
import notify from 'obj-fe/services/notifications';
import Vue from 'vue';

store.registerModule('BATCH_JOBS', {
    namespaced: true,
    state: {
        services: []
    },
    actions:{
        ROUTE_CHANGE_START:{
            root: true,
            handler(ctx, route){
                if(route.name === 'batch-jobs') {
                    ctx.dispatch('LOAD_SERVICES');
                }
            }
        },
        LOAD_SERVICES(ctx){
            backend.batchJobs.all(data => {
                ctx.commit('SET_SERVICES', data);
            });
        },
        RUN(ctx, serviceId){
            notify.info('batch_job_requested');
            ctx.commit('SET_SERVICE_LOGS', { serviceId, logs:null });
            ctx.commit('SET_SERVICE_RUNNING', { serviceId, running:true });

            backend.batchJobs.runJob(serviceId, logs => {
                ctx.commit('SET_SERVICE_LOGS', { serviceId, logs });
                ctx.commit('SET_SERVICE_RUNNING', { serviceId, running:false });
            }, err => {
                ctx.commit('SET_SERVICE_LOGS', { serviceId, logs:err+'' });
                ctx.commit('SET_SERVICE_RUNNING', { serviceId, running:false });
            });
        }
    },
    mutations:{
        SET_SERVICES(state, data){
            state.services = data.map(service => {
                service.id = service.id || service.url;
                service.name = service.displayString;
                return service;
            });
        },
        SET_SERVICE_LOGS(state, { serviceId, logs }){
            let service = state.services.filter(service => service.id === serviceId)[0];
            Vue.set(service, 'logs', logs);
        },
        SET_SERVICE_RUNNING(state, { serviceId, running }){
            let service = state.services.filter(service => service.id === serviceId)[0];
            Vue.set(service, 'running', running);
        }
    }
});