import Vue from 'vue';
import router from 'obj-fe/app/router';

import '../components/spreadsheet';

import InvCustomReportPage from './inv-custom-report-page.vue';
import InvReportActions from './inv-report-actions.vue';
import InvCustomReportButtons from './inv-report-actions.vue';

// rewrites default routes from REPORTS module and assigns them with custom report components 
router.addRoutes([{ name:'report', path: '/reports/:reportId', component: InvCustomReportPage }]);

// to be used across the project k
Vue.component('inv-report-actions', InvReportActions);
Vue.component('inv-custom-report-buttons', InvCustomReportButtons);


// custom reports in menu:

// /maintenance/org-structure
router.addRoutes([{ name: 'org-structure', path: '/reports/OrgEntitiesReport', alias: '/maintenance/org-structure', component: InvCustomReportPage }]);

// /maintenance/list-values
router.addRoutes([{ name: 'list-values', path: '/reports/listValues', alias: '/maintenance/list-values', component: InvCustomReportPage }]);

// /collections/:reportId
router.addRoutes([{ name:'collection', path: '/collections/:reportId', alias:'/collections/:reportId', component: InvCustomReportPage }]);