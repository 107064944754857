<template>
    <div v-if="buttons">
        <v-tooltip
            top
            v-for="(button, i) in buttons"
            :key="i"
        >
            <template v-slot:activator="{on}">
                <v-btn
                    icon
                    v-on="on"
                    class="expand-actions-button obj-report-button"
                    color="accent"
                    @click="$emit('clicked', button)"
                    :x-small="iconSizes.iconSizeXSmall"
                    :small="iconSizes.iconSizeSmall"
                    :large="iconSizes.iconSizeLarge"
                    :x-large="iconSizes.iconSizeXLarge"
                >
                    <v-icon>{{ button.icon ?  'fal ' + button.icon : 'fal fa-bullhorn' }}</v-icon>
                </v-btn>
            </template>

            <span>
                {{ button.displayString }}
            </span>
        </v-tooltip>
    </div>
</template>

<script>
export default {
    props: {
        buttons: Array,
        iconSizes: {
            iconSizeXSmall: Boolean,
            iconSizeSmall: Boolean,
            iconSizeLarge: Boolean,
            iconSizeXLarge: Boolean
        }
    }
}
</script>

<style scoped>
.inv-object-tab-item-action-buttons button{
    margin-left: 8px;
}
</style>
