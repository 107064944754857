<template>
    <div>
        <div class="left-offset" :style="{ 'width': (10*(row.level || 0))+'px' }"></div>
        <v-btn x-small text :disabled="!row.hasChildren" @click="toggleExpand()" @mousedown="$event.stopPropagation()" :style="{ 'margin-left': (10*(row.level || 0))+'px' }">
            <v-icon v-if="isExpanded">fal fa-minus-square</v-icon>
            <v-icon v-else>fal fa-plus-square</v-icon>
        </v-btn>
    </div>
</template>

<script>
    import rendererMixin from '../../components/spreadsheet/spreadsheet-renderer-mixin';

    export default {
        mixins:[ rendererMixin ],
        computed:{
            reportState(){
                return this.$store.state['REPORTS'].reportsById[ this.column.reportId ] || {};
            },
            expandedRows(){
                return this.reportState.expandedRows || {};
            },
            isExpanded(){
                return this.expandedRows[ this.row.id ];
            }
        },
        methods:{
            toggleExpand(){
                this.$store.dispatch('REPORTS/TOGGLE_REPORT_EXPAND_ROW', { reportId: this.column.reportId, rowId: this.row.id });
            }
        }
    }
</script>

<style scoped>
    div {
        margin:-2px 0px -4px 0px;
    }

    .v-btn {
        padding:0 !important;
    }

    .left-offset {
        position:absolute;
        top:0px;
        bottom:0px;
        left:0px;
        width:10px;
        background-color:#f5f5f5;
        border-right: 1px solid #ccc;
    }
</style>