import Vue from 'vue';
import store from 'obj-fe/app/store';
import backend from 'obj-fe/app/backend';

store.registerModule('COLLECTIONS', {
    namespaced: true,
    state:{
        collectionsList: [],
    },
    actions:{
        ROUTE_CHANGE_START:{
            root: true,
            handler(ctx, route){
                if(route.name === 'collections-list') {
                    ctx.dispatch('LOAD_COLLECTIONS_LIST');
                }
                else if(route.name === 'collection'){
                }
            }
        },
        LOAD_COLLECTIONS_LIST(ctx){
            backend.collections.collections(data => {
                ctx.commit('SET_COLLECTIONS_LIST', data);
            });
        }
    },
    mutations:{
        SET_COLLECTIONS_LIST(state, data){
            let folders, folderIds = {}, list = [];

            function createFolders(folders){
                if(folders.length && !folderIds[ folders.join('/') ]){
                    list.push({
                        isFolder: true,
                        name: folders[ folders.length-1 ],
                        folders: folders.slice(),
                        orderIndex: folders.join('/'),
                    });
                    folderIds[ folders.join('/') ] = true;
                }
                
                folders.pop();
                if(folders.length) createFolders(folders);
            }
            
            data.forEach(item => {
                folders = item.foldersDisplayString || [];
                createFolders(folders.slice());
                
                list.push({
                    id: item.name,
                    name: item.displayString,
                    description: item.description,
                    folders: folders,
                    orderIndex: folders.join('/') + '/' + item.name,
                    icon:'fal fa-chart-bar',
                    href:'/collections/' + item.name
                });
            });

            sortArray('orderIndex', 'asc', list);
            Vue.set(state, 'collectionsList', list);

        },
    }
});

function sortArray(keyName, dir, array){
    if(arguments.length===2){
        array = arguments[1];
        dir = 1;
    }

    if(dir==='asc') dir=1;
    if(dir==='desc') dir=-1;

    array.sort(function(a, b) {
        if (a[keyName] > b[keyName])
            return dir;
        if (a[keyName] < b[keyName])
            return -dir;
        // a must be equal to b
        return 0;
    });

    return array;
}