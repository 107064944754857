import store from 'obj-fe/app/store';
import backend from 'obj-fe/app/backend';
import Vue from 'vue';

function InitState(){
    this.serviceId = null;
    this.resourceId = null;
    this.impactData = null;
    this.diagramData = null;

}

store.registerModule('IMPACT_DIAGRAMS', {
    namespaced: true,
    state: new InitState(),
    actions:{
        ROUTE_CHANGE_START:{
            root: true,
            handler(ctx, route){
                if(route.name === 'impact-diagrams') {
                    ctx.commit('CLEAR');
                    ctx.dispatch('LOAD_IMPACT_DATA', { resourceId: route.params.resourceId, serviceId: route.params.serviceId });
                }
            }
        },
        CLEAR(ctx){
            ctx.commit('CLEAR');
        },
        LOAD_IMPACT_DATA(ctx, impactIds){
            backend.impactDiagrams.impactChains(impactIds, data =>{
                ctx.commit('SET_IMPACT_DATA', data);

                if(
                    !!data.diagramId &&     
                    !!data.highlighted && 
                    data.highlighted.length > 0
                ){
                    let highlighted = data.highlighted.toString(); 
                    let customRestUrl = `impact/svg/highlighted?diagramId=${data.diagramId}&highlighted=${highlighted}`;
                    backend.objects.impactSvgDiagram({ 
                        customRestUrl: customRestUrl
                    }, 
                    diagramData => {
                        ctx.commit('SET_DIAGRAM_DATA', diagramData);
                    });
                }
            });
        }
    },
    mutations:{
        CLEAR(state){
            let initState = new InitState();
            for(let key in initState) Vue.set(state, key, initState[key]);
        },
        SET_IMPACT_DATA(state, impactData){
            state.impactData = impactData;
        },
        SET_DIAGRAM_DATA(state, diagramData){
            state.diagramData = diagramData;
        }
    }
});