<template>
    <v-tab-item 
        class="inv-object-tab-item"
        :data-test="'object-tabs_tab-content' + tab.parameters.dataSource"    
    >
        <v-card flat>
            <v-card-title>
                <div class="d-flex align-center justify-end inv-object-header" style="width: 100%;">
                    <div
                        class="inv-object-tab-item-header-space"
                        @click="$event.stopPropagation()"
                    >
                        <slot name="header-space"/>
                    </div>
                    <div 
                        class="inv-object-tab-item-action-buttons" 
                        @click="$event.stopPropagation()"
                    >
                        <!-- slot's name should be header-controls -->
                        <slot name="header"/> 
                    </div>
                </div>
            </v-card-title>
            <v-card-text data-test="object-content">
                <div class="loading-indicator" :class="{ 'loading-indicator--active': tabIsLoading }">
                    <v-progress-linear indeterminate color="var(--v-accent-base)"></v-progress-linear>
                </div>
                <div v-if="height" :style="{ height }">
                    <slot name="body"></slot>
                </div>
                <slot v-else name="body"></slot>
            </v-card-text>
        </v-card>
    </v-tab-item>
</template>


<script>
    export default {
        props:{
            tab: Object,
            tabIsLoading: Boolean,
            height:{}
        }
    };
</script>

<style>
.inv-object-tab-item{
    height: 100% ;
}
.inv-object-tab-item .v-card{
    height: 100%;
    display: flex;
    flex-direction: column;
}
.inv-object-tab-item-action-buttons{
    display: flex; 
    flex-direction: row;
    justify-content: flex-end;
    padding: 8px 16px;
    z-index: 3;
    flex-grow: 0;
}
.inv-object-tab-item-action-buttons > button{
    margin-left: 8px;
}
.inv-object-tab-item-header-space{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    padding: 8px 16px;
    z-index: 3;
    flex-grow: 4;
}
.inv-object-tab-item .v-card .v-card__title{
    padding: 0 0 0 16px;
    flex: 0 1 auto;
}
.inv-object-tab-item .v-card .v-card__text{
    flex: 1 1 auto;
    height: 300px;
    overflow-y: hidden;
    padding: 0px;
}
.inv-object-tab-item .v-card .v-card__text form{
    height: 100%;
    max-height: 100%;
    overflow-y: auto;
}
.loading-indicator {
    opacity: 0;
    transition: all 0.25s ease-out;
}

.loading-indicator--active {
    opacity: 1;
}
.tab-body-disabled::before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    cursor: not-allowed !important;
    width: 100%;
    height: 100%;
    z-index: 6;
}
</style>