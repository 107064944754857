<template>
  <v-container fluid>
    <v-data-iterator
      :items="items"
      :items-per-page="-1"
      item-key="globalId"
      hide-default-footer
      no-data-text=""
      no-results-text=""
    >
      <template v-slot:item="{ item }">
        <div class="search-details-root">
          <v-subheader class="search-details-title">
            <inv-object-link :object="item" />
          </v-subheader>

          <div class="search-details-items">
            <template v-if="isLoading">
              <div class="search-details-item" v-for="n in 3" :key="n">
                <v-skeleton-loader type="text" style="width: 160px; margin-top: 12px; margin-bottom: 8px" />
                <dl>
                  <div class="field__root">
                    <dt class="field__name"><v-skeleton-loader type="text" style="width: 60px" /></dt>
                    <dd class="field__value"><v-skeleton-loader type="text" style="width: 120px" /></dd>
                  </div>
                  <div class="field__root">
                    <dt class="field__name"><v-skeleton-loader type="text" style="width: 80px" /></dt>
                    <dd class="field__value"><v-skeleton-loader type="text" style="width: 100px" /></dd>
                  </div>
                  <div class="field__root">
                    <dt class="field__name"><v-skeleton-loader type="text" style="width: 70px" /></dt>
                    <dd class="field__value"><v-skeleton-loader type="text" style="width: 120px" /></dd>
                  </div>
                </dl>
              </div>
            </template>
            <template v-else>
              <div
                class="search-details-item"
                v-for="(group, gid) in searchDetails[item.globalId]"
                :key="group.displayString + gid"
                v-show="hasNonEmptyFields(group)"
              >
                <v-subheader class="search-details-item-title">{{ group.displayString }}</v-subheader>
                <dl>
                  <div
                    class="field__root"
                    v-for="(field, fid) in group.fields"
                    :key="field.displayName + fid"
                    v-show="field.displayString"
                  >
                    <dt class="field__name" :title="field.displayName">{{ field.displayName }}</dt>
                    <dd class="field__value" :title="field.displayString">{{ field.displayString }}</dd>
                  </div>
                </dl>
              </div>
            </template>
          </div>
        </div>
      </template>
    </v-data-iterator>
  </v-container>
</template>

<script>
import Vue from "vue";
import backend from "obj-fe/app/backend";
import object from "obj-fe/utils/object";

import InvObjectLink from "./inv-object-link.vue";

export default {
  props: ["items"],
  components: {
    InvObjectLink,
  },
  data() {
    return {
      isLoading: true,
      searchDetails: {},
    };
  },
  filters: {},
  computed: {
    query() {
      return this.$store.state.INVENTORY.query;
    },
  },
  watch: {
    items: {
      immediate: true,
      handler() {
        this.handleSearch();
      },
    },
    query: {
      deep: true,
      handler() {
        this.handleSearch();
      },
    },
  },
  methods: {
    handleSearch() {
      const items = this.items?.map(({ globalId, objectTypeId }) => ({ globalId, objectTypeId }));
      this.isLoading = true;
      this.fetchSearchDetailsDebounced(items);
    },
    fetchSearchDetailsDebounced: object.debounce(function (items) {
      this.fetchSearchDetails(items);
    }, 250),
    fetchSearchDetails(items) {
      const filteredItems = items.filter((item) => !this.searchDetails[item.globalId]);

      if (filteredItems.length <= 0) {
        this.isLoading = false;
        return;
      }

      this.isLoading = true;
      backend.objects.searchDetails(
        { items: filteredItems },
        (response) => {
          response.forEach((item) => {
            Vue.set(this.searchDetails, item.globalId, item.fieldsGrouped);
          });
          this.isLoading = false;
        },
        () => {
          this.isLoading = false;
        }
      );
    },
    hasNonEmptyFields(group) {
      return group?.fields?.some((field) => field.displayString && String(field.displayString).trim().length > 0);
    },
  },
};
</script>

<style scoped>
.search-details-root:not(:last-child) {
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 10px;
  margin-bottom: 6px;
}
.search-details-title {
  font-size: 1.25em;
  height: 24px;
}
.search-details-items {
  position: relative;
  width: 100%;
  display: flex;
  gap: 28px;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
}
.search-details-item {
  scroll-snap-align: start;
  scroll-margin-left: 1.5rem;
  scroll-margin-right: 1.5rem;
  flex-shrink: 0;
  width: 100%;
  max-width: 320px;
}
.search-details-tile {
  padding-top: 8px;
  padding-bottom: 4px;
}
.search-details-item-title {
  height: 24px;
}
.field__root {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 16px;
  padding: 2px 0;
}
.field__name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #212121;
}
.field__value {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  grid-column: span 2 / span 2;
}
</style>
