import {
    Lexer,
    CstParser
} from 'chevrotain';
import {
    createInterpreter
} from './ihidsl-interpreter.js';
import {
    createTokenList,
    createToken,
    tokens
} from './ihidsl-tokens.js';


class IhiDslParser extends CstParser { //} EmbeddedActionsParser { //CstParser {
    constructor() {
        super(createTokenList(), {
            // by default the error recovery flag is **false**
            // use recoveryEnabled flag in the IParserConfig object to enable enable it.
            recoveryEnabled: true,
            dynamicTokensEnabled: true
        });

        const $ = this;

        $.RULE('rootExpression', () => {
            $.MANY(() => {
                $.OR([{
                    ALT: () => $.SUBRULE($.entityDefinition, {
                        LABEL: 'entitiesDefinition'
                    })
                },
                {
                    ALT: () => $.SUBRULE($.expressionsDefinition, {
                        LABEL: 'expressionsDefinition'
                    }) 
                }
                ]);
            });
        });

        $.RULE('entityDefinition', () => {
            $.CONSUME(tokens.entity, {
                LABEL: 'type'
            });
            $.CONSUME1(tokens.Identifier, {
                LABEL: 'value'
            });
            $.OPTION1(() => {
                $.SUBRULE($.attributes, {
                    LABEL: 'attributes'
                });
            });
            $.OPTION2(() => {
                $.CONSUME(tokens.CurlyBracketLeft, {
                    LABEL: 'bracketLeft'
                });
                $.MANY1(() => {
                    $.SUBRULE($.entityColumn, {
                        LABEL: 'column'
                    });
                });
                $.OPTION(()=>{
                    $.SUBRULE($.expressionsDefinition, {
                        LABEL: 'expressionsDefinition'
                    });
                });
                $.CONSUME(tokens.CurlyBracketRight, {
                    LABEL: 'bracketRight'
                });
            });
        });

        $.RULE('entityColumn', () => {
            $.CONSUME(tokens.column, {
                LABEL: 'type'
            });
            $.CONSUME1(tokens.Identifier, {
                LABEL: 'value'
            });
            $.OPTION(() => {
                $.SUBRULE($.attributes, {
                    LABEL: 'attributes'
                });
            });
        });
        
        $.RULE('expressionsDefinition', ()=>{ 
            $.OR1([{
                ALT: () => $.CONSUME(tokens.join, {LABEL: 'type'})
            },{
                ALT: () => $.CONSUME(tokens.where, {LABEL: 'type'})
            },{
                ALT: () => $.CONSUME(tokens.objectAssignment, {LABEL: 'type'})
            }]);
            $.OPTION(()=>{
                $.CONSUME1(tokens.Identifier, {
                    LABEL: 'value'
                });
            });
            $.OPTION2(() => {
                $.SUBRULE($.attributes);
            });
            $.CONSUME(tokens.CurlyBracketLeft);
            $.SUBRULE($.andOrExp, { LABEL: 'andOrExp' });
            $.CONSUME(tokens.CurlyBracketRight);
        });

        $.RULE('composedExpression', () => {
            $.OR([{
                ALT: () => {
                    $.SUBRULE($.compareRule);
                }
            },
            {
                ALT: () => {
                    $.SUBRULE($.parentAndOrExp);
                }
            }]);
        });

        $.RULE('andOrExp', () => {
            $.SUBRULE($.composedExpression, {
                LABEL: 'lhs'
            });
            $.MANY(() => {
                $.OR([{
                    ALT: () => {
                        $.CONSUME(tokens.andOperator, {LABEL: 'comparator'});
                    }
                },
                {
                    ALT: () => {
                        $.CONSUME(tokens.orOperator, {LABEL: 'comparator'});
                    }
                }
                ]);
                $.SUBRULE2($.composedExpression, {
                    LABEL: 'rhs'
                });
            });
        });

        $.RULE('parentAndOrExp', () => {
            $.CONSUME(tokens.LeftParenthesis, {LABEL: 'LeftParenthesis'});
            $.SUBRULE($.andOrExp);
            $.CONSUME(tokens.RightParenthesis, {LABEL: 'RightParenthesis'});
        });

        $.RULE('compareRule',()=>{
            $.OR1([
                { ALT:() => $.SUBRULE1($.reference, {LABEL: 'leftValue'})},
                { ALT:() => $.SUBRULE1($.constant, {LABEL: 'leftValue'})},
            ]);
            $.OR2([
                { ALT:() => { $.CONSUME(tokens.Equal, {LABEL: 'comparator'}); }},
                { ALT:() => { $.CONSUME(tokens.lessThan, {LABEL: 'comparator'}); }},
                { ALT:() => { $.CONSUME(tokens.greaterThan, {LABEL: 'comparator'}); }}
            ]);
            $.OR3([
                { ALT:() => $.SUBRULE2($.reference, {LABEL: 'rightValue'})},
                { ALT:() => $.SUBRULE2($.constant, {LABEL: 'rightValue'})},
            ]);
        });
        $.RULE('reference', ()=>{
            $.CONSUME1(tokens.Identifier, {
                LABEL: 'entity'
            });
            $.CONSUME1(tokens.Dot, {
                LABEL: 'separator'
            });
            $.CONSUME2(tokens.Identifier, {
                LABEL: 'attribute'
            });
        });
        $.RULE('constant', ()=>{
            $.OR([
                { ALT: ()=> $.CONSUME(tokens.StringQuoted, {LABEL: 'value'})},
                { ALT: ()=> $.CONSUME(tokens.StringQuotedSingle, {LABEL: 'value'})},
            ]);
        });

        // [ attr1 = val1, attr2 = val2 ]
        $.RULE('attributes', () => {
            $.CONSUME(tokens.SquareBracketLeft, {
                LABEL: 'bracketLeft'
            });
            $.MANY_SEP({
                SEP: tokens.Comma,
                DEF: () => {
                    $.SUBRULE($.attribute, {
                        LABEL: 'attribute'
                    });
                }
            });
            $.CONSUME(tokens.SquareBracketRight, {
                LABEL: 'bracketRight'
            });
        });

        $.RULE('attribute', () => {
            $.CONSUME(tokens.Identifier, { LABEL: 'name' });
            $.CONSUME(tokens.Equal, { LABEL: 'equal' });
            $.OR([{
                ALT: () => $.SUBRULE($.stringValue, {
                    LABEL: 'value'
                })
            }
            ,{
                ALT: () => $.SUBRULE($.stringValueSet, {
                    LABEL: 'valueSet'
                })
            }
            ]);
        });
        $.RULE('stringValue', () => {
            $.OR([{ 
                ALT: () => $.CONSUME(tokens.Identifier, { LABEL:'value' }) 
            },
            {
                ALT: () => $.CONSUME(tokens.String, {
                    LABEL: 'value'
                })
            },
            {
                ALT: () => $.CONSUME(tokens.StringQuoted, {
                    LABEL: 'value'
                })
            },{
                ALT: () => $.CONSUME(tokens.StringQuotedSingle, {
                    LABEL: 'value'
                })
            }    
            ]);
        });
        
        $.RULE('stringValueSet', () => {
            $.CONSUME(tokens.CurlyBracketLeft);
            $.MANY_SEP({
                SEP: tokens.Comma,
                DEF: () => {
                    $.SUBRULE($.stringValue, {
                        LABEL: 'value'
                    });
                }
            });
            $.CONSUME(tokens.CurlyBracketRight);
        });


        this.performSelfAnalysis();
    }
}

// wrapping it all together
// reuse the same parser instance.
const ihiDslParser = new IhiDslParser();

// Obtains the default CstVisitor constructor to extend.
const BaseCstVisitor = ihiDslParser.getBaseCstVisitorConstructor();
const ihiDslInterpreter = createInterpreter(BaseCstVisitor);

function createLexerInstance() {
    this.queryLexer = new Lexer(createTokenList());
}


function repairTokenColumnsPositions(lexResult, text){

    let lineStartOffsets = { '0':0 };
    let lines = text.split('\n');
    lines.forEach((line, index) => {
        let prevLine = lines[ index-1 ];
        if(index > 0) lineStartOffsets[ index ] = lineStartOffsets[ index - 1 ] + prevLine.length + 1;
    });

    lexResult.tokens = lexResult.tokens.map(token => {
        token.startColumn = token.startOffset - lineStartOffsets[ token.startLine - 1 ] + 1;
        token.endColumn = token.endOffset - lineStartOffsets[ token.endLine - 1 ] + 1;
        return token;
    });

    return lexResult;
}

function parse(text) {
    // 1. Tokenize the input.
    // need to repairTokenPosColumns, because in fault tolerant mode, columns are moved and are not in sync with source text
    const lexResult = repairTokenColumnsPositions(this.queryLexer.tokenize(text), text);

    // 2. Parse the Tokens vector.
    ihiDslParser.input = lexResult.tokens;
    const cst = ihiDslParser.rootExpression();

    // 3. Perform semantics using a CstVisitor.
    // Note that separation of concerns between the syntactic analysis (parsing) and the semantics.
    const value = ihiDslInterpreter.visit(cst);

    this.text = text;

    this.result = {

        tokens: lexResult.tokens,
        lexErrors: lexResult.errors,
        parseErrors: ihiDslParser.errors.concat(value.errors || []), // append interpreter errors
        value: value,
        isValid: lexResult.errors.length === 0 && ihiDslParser.errors.length === 0,
        validated: false,
        validate: validateResources.bind(this),

        validQueue: [],
        onValidated(cb) {
            if (this.validated) cb();
            else this.validQueue.push(cb);
        },
        validationFinished() {
            this.isValid = this.lexErrors.length === 0 && this.parseErrors.filter(e => !e.isWarning).length === 0;
            this.validated = true;
            this.validQueue.forEach(cb => cb());
            this.validQueue = [];
        }
    };

    // trigger aditional async validations
    this.result.validate();

    return this.result;
}

function validateResources(cb) {
    let resourcesToValidate = [];

    let parser = this;

    function done() {
        parser.result.validationFinished();
        if (cb) cb();
    }

    if (!parser.validateResources) return done();

    parser.validateResources(resourcesToValidate.map(e => e.data), result => {

        result.forEach((err, index) => {
            if (err) {
                let data = resourcesToValidate[index];
                err.token = data.data.token;
                this.result.parseErrors.push(err);
            }
        });

        done();
    });
}

function checkQuoteText(text) {
    if (typeof text !== 'string') return text;
    else if (!text.match(/^[a-zA-Z0-9\-_:]*$/)) return '"' + text.replace(/"/g, '\\"') + '"';
    else return text;
}

function checkUnquoteText(str = ''){
    let quoteChar = str[0];
    let lastChar = str[ str.length-1 ];
    if(quoteChar === '"' || quoteChar === '\'') return str.slice(1, lastChar === quoteChar ? str.length-1 : undefined);
    else return str;
}

function checkQuoteSugg(sugg){
    sugg.text = checkQuoteText(sugg.text);
    return sugg;
}

function findNearestPrevTokenByTypeId(tokens, typeId, maxLookahead = 0, currTokenIndex = tokens.length-1){
    let lookAheadCount = 0;
    for(let i=currTokenIndex;i>=0;i--){
        let token = tokens[i];
        if(token.tokenType.typeId === typeId) return token;
        if(maxLookahead && (lookAheadCount >= maxLookahead)) return;
        lookAheadCount++;
    }
}
function getAliases(type){
    let aliases = parser.result.value.aliases;

    // this here is a satan's making. 
    // so redundancies should be suggested whenever nodes are 
    // and this unholy approach has been selected so that this requirement is met
    
    Object.keys(aliases).forEach(alias=>{
        aliases[alias][0].customType = aliases[alias][0].type;
        if(aliases[alias][0].type === 'REDUNDANCY') aliases[alias][0].customType = 'NODE'; 
    });

    return Object.keys(aliases)
        .filter(key => type ? aliases[key][0].customType === type : true)
        .map(key => {
            let suffix = '';
            if(aliases[key][0].type === 'LINK') suffix = ' ' + ConnectionToken.text;
            let text = checkQuoteText(key) + suffix;
            return { 
                text, 
                displayText: text, 
                className: aliases[key][0].type + '-suggestion custom-suggestion',
                type: 'ALIAS' 
            };
        });
}

function getNextTokenSuggestions(line = 0, column = 0, cb) {
    let parser = this;
    let lines = this.text.split('\n');
    let lineText = lines[line] || '';
    let text = lineText.slice(0, column);
    let lineStartOffset = lines.slice(0, line).reduce((sum, lineStr) => sum + lineStr.length + 1, 0);
    let lineEndOffset = lineStartOffset + lineText.length;
    let offset = lineStartOffset + text.length;

    // find token which contains desired column
    let tokens = this.result.tokens.slice();
    let targetToken = this.result.tokens.filter(token => token && token.startOffset <= offset && offset <= token.endOffset).pop();
    if(targetToken) tokens = tokens.slice(0, tokens.indexOf(targetToken));
    else {
        tokens = this.queryLexer.tokenize(this.text.slice(0, offset)).tokens;
        targetToken = tokens.pop();
    }
    
    let startOffset = targetToken ? targetToken.startOffset : lineStartOffset;
    let endOffset = targetToken ? targetToken.endOffset : lineEndOffset;
    let isAfterToken = offset > endOffset+1;
    // let isAtEOL = offset === this.text.length;
    let replacingCurrentToken = !isAfterToken; // && !isAtEOL 


    let currTokenText = targetToken ? targetToken.image.slice(0, offset - startOffset) : '';
    if(isAfterToken) {
        tokens.push(targetToken);
        targetToken = null;
        startOffset = endOffset = offset;
    }

    function loadAttributesIfNeeded(cb){
        // collect all the data about the nearest entity. this data in then used for building the  
        // to do that, we need to consider looking for every type of entity definition
        let entityDefinition = findNearestPrevTokenByTypeId(tokens, 'ENTITY_DEFINITION');
        let columnDefinition = findNearestPrevTokenByTypeId(tokens, 'COLUMN_DEFINITION');
        let joinDefinition = findNearestPrevTokenByTypeId(tokens, 'JOIN_DEFINITION');
        let whereDefinition = findNearestPrevTokenByTypeId(tokens, 'WHERE_DEFINITION');
        let objectAssignmentDefinition = findNearestPrevTokenByTypeId(tokens, 'OBJECT_ASSIGNMENT_DEFINITION');

        // now we get the most recent entity
        let foundEntities = [
            entityDefinition, 
            columnDefinition, 
            joinDefinition, 
            objectAssignmentDefinition, 
            whereDefinition, 
        ].sort((a,b)=>{
            if(!a && !b) return 0;
            if(!a && b) return 1;
            if(a && !b) return -1;
            
            return a.startOffset >= b.startOffset ? -1 : 1; 
        });

        
        // process important parts of the entity
        let entity = foundEntities[0];
        let entityIndex = tokens.indexOf(entity);
        let attributesTokens = tokens.slice(entityIndex);
        let attributesBeginningIndex = attributesTokens.map(e=>e.tokenType.typeId).indexOf('SQUARE_BRACKET_LEFT');
        

        let entityIdentifierToken = tokens[entityIndex + 1];
        let entityIdentifierValue = '';
        if(entityIdentifierToken.tokenType.typeId === 'VALUE' || entityIdentifierToken.tokenType.typeId === 'IDENTIFIER') entityIdentifierValue = entityIdentifierToken.image;
        
        // process attributes - prepare them for backend
        attributesTokens = attributesTokens.slice(attributesBeginningIndex + 1);
        let processedAttributes = [];

        let remainingAttribute = '';

        if(attributesTokens.length > 2){
            while(attributesTokens.length > 2){
                // read tokens as attributes
                let attributeNameToken = attributesTokens.shift();  
                // read the equal token
                attributesTokens.shift();
                let attributeValueToken = attributesTokens.shift();
                let processedAttribute = {};
                
                if(attributeValueToken && attributeValueToken.tokenType.typeId === 'CURLY_BRACKET_LEFT'){
                    processedAttribute.value = [];
                    processedAttribute.type = 'LIST';
                    
                    while(attributeValueToken.tokenType.typeId !== 'CURLY_BRACKET_RIGHT'){
                        attributeValueToken = attributesTokens.shift();
                        if(!attributeValueToken) break;
                        if(attributeValueToken.tokenType.typeId === 'IDENTIFIER') processedAttribute.value.push(attributeValueToken.image);
                    }
                }
                else{
                    processedAttribute.type = 'SIMPLE';
                    processedAttribute.value = attributeValueToken.image;
                }

                processedAttribute.attribute = attributeNameToken.image;

                // the last attributeValueToken will be defined => either a real value or a curly bracket right. 
                // if the last attribute value token is undefined, it means that the suggest has been invoked from within the set brackets { }
                if(attributeValueToken) processedAttributes.push(processedAttribute); 
                else remainingAttribute = attributeNameToken.image;
                // skip the comma separator
                if(attributesTokens.length > 0 && attributesTokens[0].image === ',') attributesTokens.shift();
            }
        }

        // prepare remaining data about the remaining attribute name or value

        let valueToken = (targetToken && ['VALUE', 'IDENTIFIER'].indexOf(targetToken.tokenType.typeId) > -1) ? targetToken : null;
        let text = checkUnquoteText((valueToken || {}).image);
        let prefix = checkUnquoteText(valueToken ? currTokenText : '');

        if(attributesTokens.length > 0){
            let identifierToken = attributesTokens.shift();
            let equalToken = attributesTokens.shift();
            
            if( 
                identifierToken && identifierToken.tokenType.typeId === 'IDENTIFIER' && 
                equalToken && equalToken.tokenType.typeId === 'EQ'){

                remainingAttribute = identifierToken.image;
            } 
        }
        
        // perform attribute suggestions call
        if(entity){
            let suggestRequestData = {
                'attribute': remainingAttribute,
                'context': {
                    'alias': entityIdentifierValue,
                    'attributes': processedAttributes,
                    'type': entity.tokenType.name.toUpperCase()
                },
                // 'name' : entityIdentifierValue,
                'searchToken': text !== prefix ? prefix : text
            };
            // attributes and attribute values suggesting
            // value has "stringValue" in rulestack
            let suggestAttributeValue = syntacticSuggestions
                .filter(e => e.ruleStack.indexOf('stringValue') > -1)
                .length > 0;

            if(suggestRequestData.context.type === 'COLUMN'){
                suggestRequestData.context.type = 'ENTITY';

                if(suggestAttributeValue){
                    if(parser.suggestColumnAttributeValues){
                        // custom requirement for 'targetEntity' column attribute
                        if(remainingAttribute === 'targetEntity'){
                            let aliases = Object.keys(parser.result.value.aliases)
                                .filter(e => e !== '$CONTEXT_OBJECT')
                                .map(e => ({
                                    displayText: e, 
                                    text: e,
                                    className: e.type + ' REFERENCE-suggestion custom-suggestion ',
                                    type: 'REFERENCE'
                                }));

                            cb(aliases, startOffset, endOffset);
                        }
                        parser.suggestColumnAttributeValues(suggestRequestData, suggestions => {
                            cb(suggestions, startOffset, endOffset);
                        });
                    }
                }
                else{
                    if(parser.suggestColumnAttributes){
                        parser.suggestColumnAttributes(suggestRequestData, suggestions => {
                            cb(suggestions, startOffset, endOffset);
                        });
                    }
                }
            }
            else{
                if(suggestAttributeValue){
                    if(parser.suggestAttributeValues){
                        parser.suggestAttributeValues(suggestRequestData, suggestions => {
                            cb(suggestions, startOffset, endOffset);
                        });
                    }
                }
                else{
                    if(parser.suggestAttributes){
                        parser.suggestAttributes(suggestRequestData, suggestions => {
                            cb(suggestions, startOffset, endOffset);
                        });
                    }
                }
            }    
        }
    }
    function loadEntityColumnValues(cb){
        
        let entityDefinition = findNearestPrevTokenByTypeId(tokens, 'ENTITY_DEFINITION');
        let entityDefinitionIndex = tokens.indexOf(entityDefinition);
        let entityIdentifier = tokens[entityDefinitionIndex + 1];

        let valueToken = (targetToken && ['VALUE', 'IDENTIFIER'].indexOf(targetToken.tokenType.typeId) > -1) ? targetToken : null;
        let text = checkUnquoteText((valueToken || {}).image);
        let prefix = checkUnquoteText(valueToken ? currTokenText : '');

        if(entityIdentifier){

            let fullAlias = Object.entries(parser.result.value.aliases)
                .filter(e => e[0] === entityIdentifier.image)
                .map(e => ({ name: e[0], attributes: e[1][0].attributes ? e[1][0].attributes : [] })); 
                
            if(fullAlias.length > 0) fullAlias = fullAlias[0];
            
            let suggestRequestData = {
                'attribute': '',
                'context': {
                    'alias': entityIdentifier.image,
                    'attributes': fullAlias.attributes,
                    'type': 'ENTITY'
                },
                'searchToken': text !== prefix ? prefix : text
            };


            if(parser.suggestColumnValues){
                parser.suggestColumnValues(suggestRequestData, suggestions => {
                    cb(suggestions, startOffset, endOffset);
                });
            }
        }
    }
    function getAliasSuggestions(shouldSuggestConstant, cb){
        
        let aliases = [];
        let onlyColumns = targetToken && targetToken.image === '.'; // used to differentiate whether to suggest entities with columns OR only columns
        let prevAliases = Object.entries(parser.result.value.aliases);
        
        prevAliases.forEach(alias => {
            let aliasName = alias[0];
            let aliasColumns = alias[1][0].columns;
            
            // push entities
            // if(!onlyColumns) aliases.push({type: 'ENTITY-SHORT', text: aliasName});
            
            if(aliasColumns){
                aliasColumns.forEach(column => {
                    // push columns or entities + columns
                    if(!onlyColumns) aliases.push({type: 'ENTITY-LONG', text: aliasName + '.' + column});
                    else if(aliases.map(e => e.text).indexOf(column) === -1) aliases.push({type: 'COLUMN', text: column});
                });
            }
        });

        aliases = aliases
            .map(e => {
                return { 
                    text: (e.type === 'COLUMN' ? '.' : '') + e.text, 
                    displayText: e.text, 
                    className: e.type + ' REFERENCE-suggestion custom-suggestion ',
                    type: 'REFERENCE' 
                };
            });

        if(shouldSuggestConstant && parser.suggestExpressionConstant){
            let attributeToken = findNearestPrevTokenByTypeId(tokens, 'IDENTIFIER', 5);
            let entityToken = findNearestPrevTokenByTypeId(tokens, 'IDENTIFIER', 5, tokens.indexOf(attributeToken) - 1 );

            
            let entityAttributes = parser.result.value.aliases[entityToken.image][0].attributes || [];

            let suggestRequestData = {
                column: attributeToken.image || '',
                entityAttributes,
            };

            parser.suggestExpressionConstant(suggestRequestData, suggestions => {
                cb([].concat(aliases).concat(suggestions), startOffset, endOffset);
            });
        }
        else {
            cb(
                aliases,
                startOffset, 
                endOffset,
            );
        }
    }

    let syntacticSuggestions = ihiDslParser.computeContentAssist('rootExpression', tokens);

    let stringSuggestingOverride = syntacticSuggestions
        .filter(e=> 
            e.ruleStack.indexOf('stringValue') > -1 || 
            e.ruleStack.indexOf('reference') > -1 ||
            e.nextTokenType.typeId === 'IDENTIFIER'
        )
        .length > 0;

    // console.log('ss', syntacticSuggestions);

    if(stringSuggestingOverride){
        let shouldSuggestAttribute = syntacticSuggestions
            .filter(e=>e.ruleStack.indexOf('attribute') > -1)
            .length > 0;

        let shouldSuggestEntityColumn = syntacticSuggestions
            .filter(e=>e.ruleStack.indexOf('entityColumn') > -1)
            .length > 0;

        let shouldSuggestReferenceAliases = syntacticSuggestions
            .filter(e=>e.ruleStack.indexOf('reference') > -1)
            .length > 0;
        
        let shouldSuggestConstant = syntacticSuggestions
            .filter(e=>e.ruleStack.indexOf('constant') > -1)
            .length > 0;
        
        let parentAndOrExpOpener = syntacticSuggestions
            .filter(e=>e.ruleStack.indexOf('parentAndOrExp') > -1)
            .length > 0; 
        
        
        if(shouldSuggestAttribute){
            loadAttributesIfNeeded((valueTokens, startOffset, endOffset) => {
                let attributesSuggestions = [].concat(valueTokens).concat(prepareSyntacticSuggestions());
                attributesSuggestions.map(e=>{
                    let elementText = e;
                    if(/\s/g.test(elementText.text)) elementText = checkQuoteSugg(elementText);
                    return elementText;
                });
    
                
                return cb(
                    filterAccordingToPrefix(attributesSuggestions), 
                    getCursorPosFromOffset(startOffset), 
                    getCursorPosFromOffset(endOffset || startOffset),
                    replacingCurrentToken
                );
            });
        }
        else if(shouldSuggestEntityColumn){
            loadEntityColumnValues((valueTokens, startOffset, endOffset) => {
                let columnValuesSuggestions = [].concat(valueTokens).concat(prepareSyntacticSuggestions());
                columnValuesSuggestions.map(e=>{
                    let elementText = e;
                    if(/\s/g.test(elementText.text)) elementText = checkQuoteSugg(elementText);
                    return elementText;
                });

                return cb(
                    filterAccordingToPrefix(columnValuesSuggestions), 
                    getCursorPosFromOffset(startOffset), 
                    getCursorPosFromOffset(endOffset || startOffset),
                    replacingCurrentToken
                );
            });  
        }
        else if(shouldSuggestReferenceAliases){
            getAliasSuggestions(shouldSuggestConstant && !parentAndOrExpOpener, (valueTokens, startOffset, endOffset, overridingReplacingCurrentToken) => {
                let aliasSuggestions = [].concat(valueTokens).concat(prepareSyntacticSuggestions());
                aliasSuggestions.map(e=>{
                    let elementText = e;
                    if(/\s/g.test(elementText.text)) elementText = checkQuoteSugg(elementText);
                    return elementText;
                });
                return cb(
                    filterAccordingToPrefix(aliasSuggestions), 
                    getCursorPosFromOffset(startOffset), 
                    getCursorPosFromOffset(endOffset || startOffset),
                    replacingCurrentToken || overridingReplacingCurrentToken
                );
            });
        }            
    }

    else showSyntacticSuggestions((suggestions, startOffset, endOffset) => {
        let disallowedSuggestions = ['.'];

        if (!lineText.replace(/\s/g, '').length) {
            suggestions = suggestions.filter(e => disallowedSuggestions.indexOf(e.text) === -1);
        }

        cb(
            filterAccordingToPrefix(
                suggestions.map(item => {
                    return {
                        id: item.id,
                        displayText: item.displayText || item.text,
                        text: item.text,
                        className: 'SYNTAX-suggestion custom-suggestion',
                        type: 'SYNTAX'
                    };})
            ),
            getCursorPosFromOffset(startOffset),
            getCursorPosFromOffset(endOffset || startOffset),
            replacingCurrentToken
        );
    });


    function getCursorPosFromOffset(offset){
        let lines = parser.text.slice(0, offset).split('\n');
        let line = lines.length - 1;
        let column = lines.pop().length;
        return { line, column };
    }

    function showSyntacticSuggestions(cb) {
        cb(
            prepareSyntacticSuggestions(syntacticSuggestions),
            startOffset,
            endOffset
        );
    }

    function prepareSyntacticSuggestions() {
        let disallowedSuggestions = ['.'];

        return ihiDslParser.computeContentAssist('rootExpression', tokens)
            .map(item => item.nextTokenType)
            .filter(item => item && (item.text !== undefined))
            .filter(e => disallowedSuggestions.indexOf(e.text) === -1)
            .map(item => {
                return {
                    text: item.text,
                    displayText: item.displayText || item.text,
                    className: 'SYNTAX-suggestion custom-suggestion',
                    type: 'SYNTAX'
                };
            });
    }

    function filterAccordingToPrefix(arr){
        let splitText = text.split(' ');
        let finalText = splitText[splitText.length - 1].replace(/\s/g, '');
        if(finalText !== ''){
            let filteredSuggestions = arr
                .filter(item => {
                    if(!item.text) return false;
                    if(finalText !== '') return  item.text.toLowerCase().includes(finalText.toLowerCase()) || checkUnquoteText(item.text.toLowerCase()).includes(finalText.toLowerCase());  

                    return true;
                });
            if(filteredSuggestions.length !== 0){
                arr = filteredSuggestions;
            }
        }

        return arr;
    }
}

export default function Parser(opts = {}) {
    this.parse = parse;
    this.getNextTokenSuggestions = getNextTokenSuggestions;

    this.validateResources = opts.validateResources;
    this.suggestAttributes = opts.suggestAttributes;
    this.suggestAttributeValues = opts.suggestAttributeValues;
    this.suggestColumnValues = opts.suggestColumnValues;
    this.suggestColumnAttributes = opts.suggestColumnAttributes;
    this.suggestColumnAttributeValues = opts.suggestColumnAttributeValues;
    this.suggestExpressionConstant = opts.suggestExpressionConstant;
    
    this.createLexerInstance = opts.createLexerInstance || createLexerInstance;
    this.createLexerInstance();
}