<template>
    <v-expansion-panels
        v-if="expansionPanelTabs.length > 0"
        :value="expansionPanelTabs"
        multiple
        flat
    >
        <v-expansion-panel
            v-for="tab in expansionPanelTabs"
            :key="tab.expansionPanelId"
            @click="toggleExpandTab(tab)"
        >
            <v-expansion-panel-header>
                <template v-slot:actions>
                    <v-btn
                        :to="computeReferenceToTab(tab)"
                        exact
                        icon
                        class="px-2"
                        target="_blank"
                    >
                        <v-icon @click="$event.stopPropagation();" class="tab-link-icon">fal fa-external-link</v-icon>
                    </v-btn>
                    <v-icon>$expand</v-icon>
                </template>
                <span :class="getTabLabelClass(tab)">
                    <v-icon style="margin-right: 10px">{{ getTabIcon(tab) }}</v-icon>
                    {{tab.name}}
                </span>
            </v-expansion-panel-header>

            <v-expansion-panel-content>
                <inv-mini-object-panel-report
                    :object-id="objectId"
                    :tab="tab"
                    expanded
                />
            </v-expansion-panel-content>
        </v-expansion-panel>
    </v-expansion-panels>
</template>

<script>
    import InvMiniObjectPanelReport from "./inv-mini-object-panel-report.vue";

    export default {
        components: {
            InvMiniObjectPanelReport,
        },
        props: {
            object: Object,
        },
        data() {
            return {
                expansionPanelIds: [],
            };
        },
        watch:{
            object: {
                handler() {
                    this.expansionPanelIds = []
                }
            }
        },
        computed: {
            objectId() {
                return this.object.id || this.object.globalId;
            },
            tabsWithExpansionPanelId() {
                return this.object.panels.map(panel => ({ ...panel, expansionPanelId: this.createExpansionPanelId(panel) }));
            },
            expansionPanelTabs(){
                return this.tabsWithExpansionPanelId
                    .filter(tab => tab.type === 'GENERIC_PANEL')
                    .filter(tab => tab.importance === 'IMPORTANT');
            },
        },
        methods: {
            createExpansionPanelId(tab) {
                return `${this.objectId}_${tab.parameters.dataSource}`;
            },
            toggleExpandTab(tab) {
                if (this.expansionPanelIds.includes(tab.expansionPanelId)) {
                    this.expansionPanelIds = this.expansionPanelIds.filter(expandedTab => expandedTab !== tab.expansionPanelId);
                } else {
                    this.expansionPanelIds.push(tab.expansionPanelId);
                }
            },
            getTabIcon(tab) {
                return (this.expansionPanelIds.includes(tab.expansionPanelId) ? 'fas ' : 'fal ') + tab.icon;
            },
            getTabLabelClass(tab) {
                return this.expansionPanelIds.includes(tab.expansionPanelId) ? 'font-weight-bold' : '';
            },
            computeReferenceToTab(tab){
                let retval = {
                    name: 'inventory-object',
                    params:{
                        id: this.objectId
                    },
                    query: {}
                };

                let panels = {};
                panels[tab.parameters.dataSource?.toString()] = {};
                retval.query.panels = JSON.stringify(panels);
                return retval;
            },
        }
    }
</script>

<style scoped>
.v-expansion-panel-header {
    padding: 0 16px;
    font-size: 14px;
}
.v-expansion-panel-content {
    padding: 0 16px;
}
>>> .v-expansion-panel-content__wrap {
    padding: 0;
}
.tab-link-icon {
    margin-right: 10px;
}
/* prevent link icon rotation */
.v-expansion-panel--active > .v-expansion-panel-header--active .v-expansion-panel-header__icon:not(.v-expansion-panel-header__icon--disable-rotate) .v-icon.tab-link-icon {
    transform: rotate(0deg);
}
.tab-link-icon:hover {
    color: var(--v-accent-base) !important;
}
</style>
