<template>
    <div class="field-value-readonly">
        <div v-for="(resourcesGroup, key) in value" :key="key">
            <span v-for="(object,i) in resourcesGroup.nodeResources" :key="i">
                <v-icon x-small v-if="i>0">fal fa-chevron-right</v-icon>
                <inv-object-link :object="object"></inv-object-link>
            </span>
        </div>
    </div>
</template>

<script>
    import InvObjectLink from './inv-object-link.vue';

    export default {
        components:{
            InvObjectLink
        },
        props:{
            field: Object,
            value: {},
            required: Boolean,
            rules: Array,
            readonly: Boolean
        }
    }
</script>

<style scoped>
.v-icon{
        color: #000000de;
}
</style>