import Vue from 'vue';

function DELETE(){}

export default function createObjectAsArray(values = []){
    if(!Array.isArray(values)) throw new Error('wrong arguments');
            
    let obj = {};
    values.forEach((row, index) => obj[index] = row);
            
    obj.length = values.length;
    Object.defineProperty(obj, 'length', {
        value: values.length,
        writable: true,
        enumerable: false
    });

    // obj[Symbol.iterator] = function(){
    //     const ordered = Object.values(this).sort((a, b) => a - b);
    //     let i = 0;
    //     return {
    //         next(){
    //             return {
    //                 done: i >= this.length,
    //                 value: ordered[i++]
    //             };
    //         }
    //     }
    // }

    Object.defineProperty(obj, 'map', {
        value: function(cb){
            let array = [];
            for(let key in this){
                array.push(cb(this[key], key));
            }
            return array;
        },
        writable: false,
        enumerable: false
    });

    Object.defineProperty(obj, 'filter', {
        value: function(cb){
            let array = [];
            for(let key in this){
                if(cb(this[key], key)) array.push(this[key]);
            }
            return array;
        },
        writable: false,
        enumerable: false
    });

    // reduce((previousValue, currentValue, currentIndex, array) => { ... }, initialValue)
    Object.defineProperty(obj, 'reduce', {
        value: function(cb, initialValue){
            let value = initialValue;
            for(let key in this){
                value = cb(value, this[key], key, this);
            }
            return value;
        },
        writable: false,
        enumerable: false
    });

    Object.defineProperty(obj, 'forEach', {
        value: function(cb){
            for(let key in this){
                cb(this[key], parseInt(key, 10));
            }
            return this;
        },
        writable: false,
        enumerable: false
    });

    Object.defineProperty(obj, 'sort', {
        value: function(cb){
            let array = [];
            for(let key in this) array.push(this[key]);
            array.sort(cb);
            for(let key in this) this[key] = array[key];
            return this;
        },
        writable: false,
        enumerable: false
    });

    Object.defineProperty(obj, 'push', {
        value: function(){
            let lastIndex = this.length;
            for(let key in arguments){
                Vue.set(this, parseInt(key, 10) + lastIndex, arguments[key]);
                this.length++;
            }
            
            return this;
        },
        writable: false,
        enumerable: false
    });

    Object.defineProperty(obj, 'reindex', {
        value: function(modifyItems = {}){
            let oldLength = this.length;
            let newKeys = {};
            let newIndex = 0;
            let oldIndex = 0;
            let modifiersLeft = Object.keys(modifyItems).length;

            while(modifiersLeft || (oldIndex < oldLength)){
                if(modifyItems[oldIndex] === DELETE) {
                    delete modifyItems[oldIndex];
                    modifiersLeft--;
                    oldIndex++;
                }
                else if(modifyItems[oldIndex]) {
                    modifyItems[oldIndex].forEach(newValue => {
                        newKeys[newIndex] = newValue;
                        newIndex++;
                    });
                    delete modifyItems[oldIndex];
                    modifiersLeft--;
                }
                else {
                    newKeys[newIndex] = this[oldIndex]; // just copy
                    newIndex++;
                    oldIndex++;
                }
            }

            // copy keys into current object
            let newLength = -1;
            for(let key in newKeys) {
                Vue.set(this, key, newKeys[key]);
                newLength = key;
            }
            newLength = parseInt(newLength, 10) + 1;

            if(oldLength > newLength) for(let i=newLength;i<oldLength;i++) {
                Vue.delete(this, i);
            }

            this.length = newLength;
            
            return this;
        },
        writable: false,
        enumerable: false
    });

    Object.defineProperty(obj, 'slice', {
        value: function(from = 0, to){
            if(from < 0) {
                from = this.length + from;
                if(from < 0) from = 0;
            }

            if(to < 0) {
                to = this.length + to;
                if(to < 0) to = 0;
            }

            let array = [];
            let record = false;
            for(let key in this){
                if(key == from) record = true;
                if(key == to) break;

                if(record) array.push(this[key]);
            }
            return array;
        },
        writable: false,
        enumerable: false
    });

    // splice(start, deleteCount, item1, item2, itemN)
    Object.defineProperty(obj, 'splice', {
        value: function(start, deleteCount){
            let itemsToAdd = Array.prototype.slice.call(arguments, 2);

            if(start < 0) {
                start = this.length + start;
                if(start < 0) start = 0;
            }
            else if(start > this.length) start = this.length;

            // if deleteCount is omitted, or if its value is equal to or larger than array.length - start
            if(isNaN(deleteCount) || deleteCount >= this.length - start) {
                deleteCount = this.length - start;
            }
            if(deleteCount < 0) deleteCount = 0;

            let deletedItems = deleteCount > 0 ? this.slice(start, start + deleteCount) : [];
            let lengthChanged = deleteCount !== itemsToAdd.length;

            let modifyItems = {};
            for(let i=0;i<deleteCount;i++){
                modifyItems[ start + i ] = DELETE;
            }

            for(let i=0;i<itemsToAdd.length;i++){
                let replace = i < deleteCount;
                if(replace) {
                    this[ start + i ] = itemsToAdd[i];
                    delete modifyItems[ start + i ];
                }
                else {
                    modifyItems[ start + i ] = itemsToAdd.slice(i);
                    break;
                }
            }

            // perform reindex
            if(lengthChanged) this.reindex(modifyItems);

            return deletedItems;
        },
        writable: false,
        enumerable: false
    });

    Object.defineProperty(obj, 'indexOf', {
        value: function(item){
            for(let key in obj){
                if(this[key] === item) return parseInt(key, 10);
            }
            return -1;
        },
        writable: false,
        enumerable: false
    });

    Object.defineProperty(obj, 'includes', {
        value: function(item){
            return this.indexOf(item) > -1;
        },
        writable: false,
        enumerable: false
    });

    return obj;
}

// // TESTS:
// function arraysAreEqual(a, b){
//     if(a.length !== b.length) return false;
//     for(let i=0;i<a.length;i++){
//         if(a[i] !== b[i]) return false;
//     }
//     return true;
// }

// function expectEqual(a, b, msg){
//     if(!arraysAreEqual(a,b)) {
//         throw new Error('Expect equality, but different ' + JSON.stringify(a) + ' !== ' + JSON.stringify(b) + ' ' + msg);
//     }
// }

// // slice
// [ [], [0], [0,0], [0,1], [0,6], [0,100], [0,-1], [0,-6], [0,-100], [1,0], [1], [6], [100], [-1], [-6], [-100] ].forEach(args => {
//     let a = [1,2,3,4,5,6];
//     let b = createObjectAsArray(a);
//     expectEqual(a.slice.apply(a, args), b.slice.apply(b, args), ', slice arguments: ' + args);
// });

// // splice
// let args = [ 
//     [], [0], [1], [5], [100], [-3], [-100], [0,0],
//     [0,1], [0,5], [0,100], [0,-3], [0,-100],
//     [1,1], [1,5], [1,100], [1,-3], [1,-100],
//     [5,1], [5,5], [5,100], [5,-3], [5,-100],
//     [6,1], [6,5], [6,100], [6,-3], [6,-100],
//     [-3,1], [-100,5], [100,100], [-3,-3], [6,-100], [-100, -100],
//     [0,0,'a','b','c'],
//     [0,1,'a','b'], [0,5,'a','b'], [0,100,'a','b'], [0,-3,'a','b'], [0,-100,'a','b'],
//     [1,1,'a','b'], [1,5,'a','b'], [1,100,'a','b'], [1,-3,'a','b'], [1,-100,'a','b'],
//     [5,1,'a','b'], [5,5,'a','b'], [5,100,'a','b'], [5,-3,'a','b'], [5,-100,'a','b'],
//     [6,1,'a','b'], [6,5,'a','b'], [6,100,'a','b'], [6,-3,'a','b'], [6,-100,'a','b'],
//     [-3,1,'a','b'], [-100,5,'a','b'], [100,100,'a','b'], [-3,-3,'a','b'], [6,-100,'a','b'], [-100, -100,'a','b'],
// ];
// args.forEach(args => {
//     let a = [1,2,3,4,5,6];
//     let b = createObjectAsArray(a);
//     let resA = a.splice.apply(a, args);
//     let resB = b.splice.apply(b, args);
//     expectEqual(a, b, ', splice arguments: ' + args);
//     expectEqual(resA, resB, ',splice result incorrect, splice arguments: ' + args);
// });