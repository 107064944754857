<template>
    <div class="message-wrapper">
        <div class="message">
            <div class="message-meta caption">
                <span class="font-weight-bold">{{ value.senderName }}</span>
                <span>&bull;</span>
                <span>{{ value.timestamp | formatDatetime }}</span>
            </div>
            <div class="message-content body-1">{{ value.content }}</div>
        </div>
    </div>
</template>

<script>
import date from "obj-fe/utils/date";

export default {
    props: ["value"],

    filters: {
        formatDatetime(value) {
            return date.formatDatetime(value);
        },
    },
};
</script>

<style lang="scss" scoped>
.message-wrapper {
    display: flex;
    justify-content: start;
}

.message {
    display: block;
    padding: 0.5rem 1rem;
    background-color: #eee;
    border-radius: 0.25rem;
}

.message-meta {
    opacity: 0.75;
    padding: 0;
    margin: 0;
}

.message-content {
    padding: 0;
    margin: 0;
}
</style>
