import store from 'obj-fe/app/store';
import Vue from 'vue';
import { WMSCapabilities } from 'ol/format';
import ImageWMS from 'ol/source/ImageWMS'

function InitState(){
    this.layers = null;
    this.layerData = null;
    this.selected = null
}

store.registerModule('INVENTORY_GIS_MAP', {
    namespaced: true,
    state: new InitState(),
    actions:{
        ROUTE_CHANGE_START:{
            root: true,
            handler(ctx, route){
                if(route.name === 'inventory-map-gis') {
                    ctx.dispatch('CLEAR');
                    ctx.dispatch('LOAD_LAYERS_LIST');
                }
            }
        },
        CLEAR(ctx){
            ctx.commit('CLEAR');
        },
        LOAD_LAYERS_LIST(ctx){
            let wmsFormatter = new WMSCapabilities();
            let endpoint =  '/geoserver/celine-workspace/';

            // wms
            fetch(endpoint + 'wms?request=GetCapabilities')
                .then(response => response.text())
                .then(data => {
                    let parsedResponse = wmsFormatter.read(data);
                    let layers = parsedResponse.Capability.Layer.Layer;
                    
                    let layersData = layers.map(layer => {
                        let center = null;

                        if(
                            layer.EX_GeographicBoundingBox[0] + layer.EX_GeographicBoundingBox[2] !== 0 || 
                            layer.EX_GeographicBoundingBox[1] + layer.EX_GeographicBoundingBox[3] !== 0
                        )   center = [
                                (layer.EX_GeographicBoundingBox[0] + layer.EX_GeographicBoundingBox[2]) / 2, 
                                (layer.EX_GeographicBoundingBox[1] + layer.EX_GeographicBoundingBox[3]) / 2
                            ];

                        return {
                            id: layer.Name,
                            displayString: layer.Name,
                            selected: false,
                            parent: null,
                            icon: 'fa-sitemap',
                            siaSource: endpoint,
                            // center: center
                        };
                    });

                    ctx.commit('SET_LAYERS_LIST', layersData);
                });

        },
        TOGGLE_LAYER(ctx, layer){
            ctx.commit('TOGGLE_LAYER', layer);
        },
        PROCESS_EVENT(ctx, eventData){
            let endpoint =  '/geoserver/celine-workspace/wms';
            let wmsSource = new ImageWMS({
                url: endpoint,
                params: {
                    LAYERS: eventData.selectedLayers
                },
                serverType: 'geoserver',
            });

            let url = wmsSource.getFeatureInfoUrl(
                eventData.event.coordinate,
                eventData.resolution,
                eventData.projection,
                { INFO_FORMAT: 'application/json'}
            )

            if(url) fetch(url)
                .then(response => response.text())
                .then(data => {
                    let pointData = JSON.parse(data)
                    if(pointData.numberReturned < 1 || pointData.features.length < 1) return;
                    
                    pointData.features
                        .forEach(feature => ctx.commit('SET_SELECTED', feature.id.split('.')[1]))
                    
                });
        },
        SET_SELECTED(ctx, newSelected){
            ctx.commit('SET_SELECTED', newSelected);
        }
    },
    mutations:{
        CLEAR(state){
            let initState = new InitState();
            for(let key in initState) Vue.set(state, key, initState[key]);
        },
        SET_SELECTED(state, newSelected){
            state.selected = newSelected;
        },
        SET_LAYERS_LIST(state, layers){
            state.layers = layers;
        },
        TOGGLE_LAYER(state, layer){
            layer.selected = !layer.selected;
            state.layers = state.layers.slice();
        }
    }
});