<template>
    <v-card flat>
		<v-card-title><h2>{{'batch_jobs' | translate}}</h2></v-card-title>

        <v-navigation-drawer v-model="detail.opened" absolute right clipped :width="drawerWidth" z-index="1">
            <v-card v-if="detail.opened && detail.service" flat class="fill-height">
                <v-toolbar dense flat>
                    <v-tooltip bottom>
                        <template v-slot:activator="{on}">
                            <v-btn
                                data-test="batchJobs-btn:closeDrawer"
                                icon
                                outlined 
                                @click="detail.opened = false;" 
                                v-on="on"
                                color="error"
                            >
                                <v-icon>fal fa-times</v-icon>
                            </v-btn>
                        </template>
                        <span>{{'close' | translate}}</span>
                    </v-tooltip>
                    <v-spacer/>
                        <v-btn
                            data-test="batchJobs-btn:runJob"
                            text
                            @click="openConfirmModal(detail.service)" 
                            :disabled="detail.service.running"
                            color="success"
                        >
                            {{'run' | translate}} <span v-if="detail.service.running">({{'batch_jobs_running' | translate}})</span>
                        </v-btn>
                    <v-spacer/>
                </v-toolbar>

                <v-card-text>
                    <span v-if="!detail.service.logs || !detail.service.logs.length">{{'batch_jobs_no_logs' | translate}}</span>
                    <div><pre>{{detail.service.logs}}</pre></div>
                </v-card-text>
            </v-card>
        </v-navigation-drawer>
		
		<obj-table :items="jobServices" :active-item="detail.opened ? detail.serviceId : null" @active-changed="openDetail">
			<template slot="headers">
				<th>{{'batch_jobs_name'|translate}}</th>
                <th></th>
			</template>

			<template slot="row" slot-scope="row">
				<td :data-test="'batchJobs-td:' + row.value.name">
                    <v-icon style="margin-right: 8px">{{'fal ' + row.value.icon}}</v-icon>&nbsp;{{row.value.name}}
                </td>
                <td>
                    {{row.value.description}}
                </td>
			</template>
		</obj-table>

        <v-dialog 
            v-model="confirmDialog.opened" 
            width="500px"
            content-class="small-dialog"
        >
			<v-card flat>
				<v-card-title><h2>{{'batch_jobs_confirm' | translate}}</h2></v-card-title>

				<v-card-text>
                    {{'batch_jobs_are_you_sure' | translate}}
                </v-card-text>

				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn 
                        text
                        @click="confirmDialog.opened = false"
                        color="error"
                    >
                        {{'cancel'|translate}}
                    </v-btn>
					<v-btn
                        data-test="batchJobs-btn:confirmRun"
                        color="success" 
                        outlined 
                        @click="runJob(confirmDialog.service.id); confirmDialog.opened = false"
                    >
                        {{'run'|translate}}
                    </v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

	</v-card>
</template>

<script>
    export default {
        data(){
            return {
                detail:{
                    opened: false,
                    serviceId: null,
                    service: null
                },
                confirmDialog:{
                    opened: false,
                    service: null
                }
            };
        },
        computed:{
            jobServices(){
                return this.$store.state['BATCH_JOBS'].services;
            },
            drawerWidth() {
                return Math.min(window.innerWidth / 1.7, 1200);
            }
        },
        methods:{
            openDetail(service){
                if(!service) this.detail.opened = false;
                else {
                    this.detail.serviceId = null;
                    this.$nextTick(() => {
                        this.detail.serviceId = service.id;
                        this.detail.service = service;
                        this.detail.opened = true;
                    })
                    
                }
            },
            openConfirmModal(service){
                this.confirmDialog.service = service;
                this.confirmDialog.opened = true;
            },
            runJob(serviceId){
                this.$store.dispatch('BATCH_JOBS/RUN', serviceId);
            }
        }
    };
</script>