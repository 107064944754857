import './config';
import 'obj-fe/app/polyfills';
import Vue from 'vue';

/*
 * vuetify
 */
import Vuetify from 'vuetify';
import './theme/main.scss';
Vue.use(Vuetify);


const vuetify = new Vuetify({
    theme: {
        options: { 
            customProperties: true 
        },
        themes: {
            light: {
                primary: '#484644',
                secondary: '#b0bec5',
                success: '#107c10',
                info: '#0078d4',
                accent: '#f16e00',
                anchor: '#f16e00',
                error: '#f44336',
                warning: '#ff9800',
            },
            dark: {
                primary: '#484644',
                secondary: '#b0bec5',
                success: '#107c10',
                info: '#0078d4',
                accent: '#f16e00',
                anchor: '#f16e00',
                error: '#f44336',
                warning: '#ff9800',
            },
        },
    },
    icons: {
        iconfont: 'fa',
    },
});

/*
 * Import shared app and layout modules from obj-fe
 */
import backend from 'obj-fe/app/backend';
import auth from 'obj-fe/app/auth';
import router from 'obj-fe/app/router';
import store from 'obj-fe/app/store';
import 'obj-fe/app/filters';

// application modules
import objLayoutModule from 'obj-fe/modules/layout';
import objReportsModule from 'obj-fe/modules/reports';
import objTableModule from 'obj-fe/modules/table';
import objCodeEditorModule from 'obj-fe/modules/code-editor';

// application modules must be inited after interface set
objLayoutModule.init();
objReportsModule.init();
objTableModule.init();
objCodeEditorModule.init();

// do auto sync state with router
store.syncWithRouter(router);

/*
 * Import app (not shared) modules
 */

// TODO: split resource registration into modules/folders
// backend routes
import './backend';

// TODO: split menu items registration into modules/folders
// menu items
import menu from './menu';
menu.init();

// global components
import AppComponent from './components';

// global directives
// import './directives';

// app modules
import './dashboard';
import './chat';
import './batch-jobs';
import './import-jobs';
import './jobs-dashboard';
import './inventory';
import './inventory/inventory-map';
import './impact-diagrams';
import './dsl/emdsl/emdsl-initiator';
import './collections';
import './print';
import './wizard';

// this import will edit what components will be used on /report/:reportId and /reports-list
import './custom-reports';

/*
 * Init vue app
 */
// init main component
const app = new Vue({
    data:{ langCode:'' },
    router,
    store,
    vuetify: vuetify,
    render: h => h(AppComponent)
});

// init localisation and register global filter
import localisation from 'obj-fe/services/localisation';
localisation.registerVueFilter(Vue, app, 'langCode');

// TODO: move to obj-be
Vue.use({
    install(Vue, options){

        Vue.prototype.$translate = function(text){
            return localisation.translate(text);
        };

        Vue.prototype.$localisation = localisation;

        Object.defineProperty(Vue.prototype, '$langCode', {
            get(){
                return localisation.getLangCode();
            }
        });
    }
});

// init app - must be inited after localisation, because of translation filter
app.$mount('#app');

// setup auth - must be after app started
auth.setupAndInit();

// load app settings and build info
backend.init.settings(data => {
    store.dispatch('SET_APP_NAME', data.whoami);
    store.dispatch('SET_BUILDINFO', data.buildInfo);
    store.dispatch('SET_APP_SETTINGS', {
        orgStructureSettings: data.orgStructureSettings,
        useOnlineExcelExports: data.useOnlineExcelExports,
        useAsyncExcelExports: data.useAsyncExcelExports,
        useEmdsl: data.useEmdsl,
        useDms: data.useDms,
        chatEnabled: data.chatEnabled,
        historizationEnabled: data.historizationEnabled,
        colorTheme: data.colorTheme,
        hideEmptyObjectFields: data.hideEmptyObjectFields,
        useSpreadsheetReport: data.reportRenderer === 'SPREADSHEET' // TABLE/SPREADSHEET
    });
    store.dispatch('SET_SWAGGER_URL', data.swaggerUrl || '/swagger-ui.html');
    
    if(data.colorTheme){
        const colorMapping = {
            'blue-mpp': '#00458a'
        }
        let accentColor = '#f16e00';
        
        if(colorMapping[data.colorTheme]) accentColor = colorMapping[data.colorTheme]; 

        

        app.$vuetify.theme.currentTheme.accent = accentColor;
        app.$vuetify.theme.currentTheme.anchor = accentColor;
        
    }

    // add map menu item only if settings actually require them
    if(data.mapService){
        if(data.mapService === 'LEGACY'){ // standard, without geoserver
            if(data.mapLayersUrl) {
                store.dispatch('MENU/ADD_ITEM_CHILDREN', {
                    item: 'project',
                    children: {
                        'map': {
                            text: 'Map',
                            icon: 'fal fa-map',
                            index: 8000,
                            to: { name:'inventory-map' }
                        },
                    },
                    requiresPermission: "MAP",
                });
            }
        }
        if(data.mapService === 'GIS'){
            store.dispatch('MENU/ADD_ITEM_CHILDREN', {
                item: 'project',
                children: {
                    'gis-map': {
                        text: 'Map',
                        icon: 'fal fa-map',
                        index: 9000,
                        to: { name:'inventory-map-gis' }
                    },
                },
                requiresPermission: "MAP",
            });
        }
    }
    

    if(data.externalUrls){
        let menuItems = {};
        
        data.externalUrls.forEach(url => {
            menuItems[url.displayString] = {
                text: url.displayString,
                icon: 'fal fa-external-link-square-alt',
                index: 8000,
                externalUrl: url.url,
                onClick: function(){
                    window.open(this.externalUrl, '_blank');
                }, 
                requiresPermission: "EXTERNAL_LINKS",
            };
        });
        store.dispatch('MENU/ADD_ITEMS', {
            externalUrls: {
                text: 'external_links',
                icon: 'fal fa-external-link-square-alt',
                index: 10000,
                children: menuItems,
                requiresPermission: "EXTERNAL_LINKS",
            }
        });
    }
            
    if(data.availableLanguages) store.dispatch('MENU/SET_LOCALE', data.availableLanguages)
    
    // TODO: find better solution
    window.document.querySelector('head > meta[name="description"]').innerHTML = data.whoami;
    window.document.querySelector('head > title').innerHTML = data.whoami;
});