export default {
    props:{
        objectId: String,
        tab: Object,
        expanded: Boolean,
        state: Object,
        readonly: Boolean
    },
    data(){
        return {
            isExpandedTirstTime: true,
            isStateChangedFirstTime: true,
            needsReloading: false,
            tabIsLoading: false
        };
    },
    watch:{
        expanded:{
            immediate: true,
            handler(isExpanded){
                if(isExpanded && this.isExpandedTirstTime) {
                    this.onFirstTimeExpand();
                    this.isExpandedTirstTime = false;
                }
                if(isExpanded && this.needsReloading) {
                    this.needsReloading = false;
                    this.onFirstTimeExpand();
                    this.attemptReload();
                }
            }
        },
        isDirty(val){
            this.$emit('is-dirty', val);
        }
    },
    methods:{
        onFirstTimeExpand(){
            let app = this;
            app.tabIsLoading = true;
            this.onFirstTimeExpandLoad(
                // successCb
                ()=>{
                    app.tabIsLoading = false;
                },
                // errorCB
                ()=>{
                    app.needsReloading = true;
                    app.tabIsLoading = false;
                }
            );
        },
        onFirstTimeExpandLoad(){},
        attemptReload(){},
        onFirstTimeStateChange(){}
    }
};