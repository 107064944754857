<template>
    <v-row>
        <v-col>
            <spreadsheet-row-form-input-container   :label="column.name | translate" 
                                                    :focused="row._state.editingColIndex > -1 && row._state.editingColIndex === colIndex"
                                                    :error-messages="row._state.cellErrors ? row._state.cellErrors[ colIndex ] : []">
                <table>
                    <tr>
                        <spreadsheet-cell   :row="row" 
                                            :row-index="rowIndex" 
                                            :col-index="colIndex"
                                            :columns="spreadsheet.columns"
                                            :column-data-types="spreadsheet.columnDataTypes"

                                            :hide-errors="true"
                                            :toggle-edit-on-click="true"
                                            
                                            @toggle-edit-cell="e => spreadsheet.toggleCellEdit(e.rowIndex, e.colIndex)"
                                            @cell-renderer-action="e => spreadsheet.callCellRendererAction(e.rowIndex, e.colIndex, e.data)"
                                            @cell-editor-action="e => spreadsheet.callCellEditorAction(e.rowIndex, e.colIndex, e.data)"
                                            @set-cell-value="e => spreadsheet.setCellValue(e.rowIndex, e.colIndex, e.value)"
                                            @close-editing-mode="e => spreadsheet.closeEditingMode(e)">
                        </spreadsheet-cell>
                    </tr>
                </table>
                
            </spreadsheet-row-form-input-container>
        </v-col>
        <v-col class="text-left">
            {{column.description | translate}}
        </v-col>
    </v-row>
    
</template>

<style scoped>
    table {
        width: 100%;
        height: 26px
    }

    >>> .cell-editor {
        position: static;
        min-width: 100% !important;
        padding: 0px;
    }
</style>

<script>
    import SpreadsheetRowFormInputContainer from './spreadsheet-row-form-input-container.vue';
    import SpreadsheetCell from './spreadsheet-cell.vue';

    export default {
        components:{
            SpreadsheetRowFormInputContainer,
            SpreadsheetCell
        },
        props:{
            rowIndex: Number,
            colIndex: Number,
            spreadsheet: Object // spreadsheet reference
        },
        data(){
            return {
                internalValue: undefined,
                isValid: false
            };
        },
        watch:{
            value:{
                immediate:true,
                handler(v){
                    this.internalValue = v;
                }
            }
        },
        computed:{
            row(){
                return this.spreadsheet.getRow(this.rowIndex);
            },
            column(){
                let row = this.spreadsheet.getRow(this.rowIndex);
                return this.spreadsheet.columns[row.rowType][this.colIndex];
            },
            errors(){
                let row = this.spreadsheet.getRow(this.rowIndex);
                if(!row._state.cellErrors) return [];
                else return row._state.cellErrors[ this.colIndex ] || [];
            }
        },
        methods:{
            setCellValue(value){
                this.spreadsheet.setCellValue(this.rowIndex, this.colIndex, value);
            }
        }
    };
</script>