import store from 'obj-fe/app/store';
import backend from 'obj-fe/app/backend';
import Vue from 'vue';

function InitState(){
    this.globalId = null;
    this.dataSource = null;
    this.printTemplate = null;
    this.loading = false;
}

store.registerModule('PRINT', {
    namespaced: true,
    state: new InitState(),
    actions:{
        ROUTE_CHANGE_START:{
            root: true,
            handler(ctx, route){
                if(route.name === 'print') {
                    ctx.commit('CLEAR');
                    ctx.dispatch('LOAD_PRINT_DATA', { globalId: route.params.globalId, dataSource: route.params.dataSource });
                }
            }
        },
        CLEAR(ctx){
            ctx.commit('CLEAR');
        },
        LOAD_PRINT_DATA(ctx, printRequestData){
            ctx.commit('SET_LOADING', true)
            backend.objects.printTemplate(
                // params
                {
                    globalId: printRequestData.globalId,
                    template: printRequestData.dataSource
                }, 
                // success
                printTemplate => {
                    ctx.commit('SET_PRINT_DATA', printTemplate)
                    ctx.commit('SET_LOADING', false)
                },
                // error
                error => {
                    ctx.commit('SET_LOADING', false)
                }
            )
        }
    },
    mutations:{
        CLEAR(state){
            let initState = new InitState();
            for(let key in initState) Vue.set(state, key, initState[key]);
        },
        SET_LOADING(state, newLoading){
            state.loading = newLoading;
        },
        SET_PRINT_DATA(state, printTemplate){
            state.printTemplate = printTemplate;
        }
    }
});