<template>
    <inv-object-tab-item :tab="tab" :tabIsLoading="tabIsLoading">

        <template v-if="expanded && tab" slot="header">
            <div class="inv-object-button-group">
                <v-tooltip top>
                    <template v-slot:activator="{on}">
                        <v-btn 
                            v-on="on"
                            @click="save" 
                            :disabled="!editorContentIsValid"
                            outlined
                            color="success"
                            icon
                        >
                            <v-icon>fal fa-save</v-icon>
                        </v-btn>
                    </template>
                    <span>
                        {{'save'|translate}}
                    </span>
                </v-tooltip>
            </div>
        </template>
        
        <template slot="body">
            <div class="inv-object-tab-graphviz-container" :class="{ 'tab-body-disabled' : tabIsLoading }">
                <div class="inv-object-tab-graphviz" ref="editor">
                    <inv-ihi-dsl-editor   
                        v-model="ihiDslCode" 
                        :placeholder="'enter_graph_code_here' | translate"
                        @valid="isValid => editorContentIsValid = isValid"
                        auto-height 
                        @parser-result="result => ihiParserResult = result"
                        @is-dirty="editorIsDirty = $event"
                    />
                        <!-- :suggest-attributes="suggestAttributes"
                        :suggest-attribute-values="suggestAttributeValues"
                        :suggest-column-values="suggestColumnValues"
                        :suggest-column-attributes="suggestColumnAttributes"
                        :suggest-column-attribute-values="suggestColumnAttributeValues"
                        :suggest-expression-constant="suggestExpressionConstant" -->
                </div>
            </div>
        </template>
    </inv-object-tab-item>
</template>

<style scoped>
    .inv-object-tab-graphviz-container{
        display: flex;
        flex-direction: row;
        width: 100%;
        height: 100%;
    }
    .inv-object-tab-graphviz-container .inv-object-tab-graphviz{
        width: 100%;
        height: 100%;
        overflow: hidden;
    }
</style>

<script>

    import backend from 'obj-fe/app/backend';
    import notify from 'obj-fe/services/notifications';
    import tabBehaviour from './inv-object-tab-behaviour';
    import InvIhiDslEditor from '../../dsl/ihidsl/inv-ihidsl-editor.vue';
    import InvObjectTabItem from './inv-object-tab-item.vue';
    import InvObjectForm from '../inv-object-form.vue';
    
    export default {
        mixins:[ tabBehaviour ],
        components:{
            InvObjectTabItem,
            InvObjectForm,
            InvIhiDslEditor
        },
        data(){
            return {
                // dsl specific data
                ihiDslCode: ``,
                ihiParserResult: null,
                ihiErrors: [],
                editorContentIsValid: false,
                cachedResourceDefinitions:{},
                firstTimeLoaded: true,
                editorIsDirty: false
            };
        },
        computed:{
            isDirty(){
                return this.editorIsDirty && this.initValue !== this.ihiDslCode;
            }
        },
        methods:{
            onFirstTimeExpandLoad(successCb, errorCb){
                backend.customGrids.loadGrid(
                    { customGridId: this.tab.parameters.dslSourceId }, 
                    data => {
                        this.ihiDslCode = data;
                        this.initValue = data;
                        successCb()
                    },  
                    err => {
                        errorCb()
                    }
                );
            },
            
            save(){
                backend.customGrids.updateGrid(
                    {customGridId: this.tab.parameters.dslSourceId }, { src:this.ihiDslCode, data: this.ihiParserResult.value.data }, 
                    data => {
                        notify.success('saved');
                        this.initValue = this.ihiDslCode;
                    },
                    err => notify.error('saving_grid_error')
                );
            },
            
        },
        watch:{
            ihiDslCode: function(){
                if(this.firstTimeLoaded) this.firstTimeLoaded = false;  
            }
        }
    };
</script>