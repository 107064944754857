<template>
    <tr :class="{ 'row-delete':row.delete, ['row-type-' + row.rowType]:true }" :style="{ height: row._state.height ? row._state.height+'px' : 'auto' }" @contextmenu="$emit('show-context-menu', { $event, rowIndex })">
        <td @mousedown.left="$emit('row-selection-start', rowIndex)" :class="rowSelectionClasses" data-cell-type="row" :data-row-index="rowIndex">
            <v-tooltip v-if="row._state.rowErrors" right>
                <template v-slot:activator="{on}">
                    <div v-on="on" class="row-errors">!</div>
                </template>

                <div v-for="(err,i) in row._state.rowErrors" :key="i">
                    - {{err}}
                </div>
            </v-tooltip>
            {{rowIndex+1}}
        </td>
        <spreadsheet-cell   v-for="(column, colIndex) in columns" 
                            :key="colIndex + ':' + columns[row.rowType][colIndex].dataKey" 
                            :row="row" 
                            :row-index-string="rowIndex" 
                            :col-index-string="colIndex"
                            :columns="columns"
                            :column-data-types="columnDataTypes"
                            :disable-inline-edit="disableInlineEdit"
                            :cell-selection-classes="cellSelectionClasses[ rowIndex + ':' + colIndex ]"
                            @cell-selection-start="v => $emit('cell-selection-start', v)"
                            @toggle-edit-cell="v => $emit('toggle-edit-cell', v)"
                            @cell-renderer-action="e => $emit('cell-renderer-action', e)"
                            @cell-editor-action="e => $emit('cell-editor-action', e)"
                            @set-cell-value="e => $emit('set-cell-value', e)"
                            @close-editing-mode="e => $emit('close-editing-mode', e)">
        </spreadsheet-cell>
    </tr>
</template>

<script>
    import SpreadsheetCell from './spreadsheet-cell.vue';

    export default {
        components:{
            SpreadsheetCell
        },
        props:{
            rowSelectionClasses: Array,
            cellSelectionClasses: Object,
            rowIndex: Number,
            row: Object,
            columns: {},
            columnDataTypes: Object,
            disableInlineEdit: Boolean
        }
    };
</script>