import store from 'obj-fe/app/store';
import backend from 'obj-fe/app/backend';
import router from 'obj-fe/app/router';
import object from 'obj-fe/utils/object';
import locals from 'obj-fe/services/localisation';
import notify from 'obj-fe/services/notifications';
import Vue from 'vue';

store.registerModule('JOBS_DASHBOARD', {
    namespaced: true,
    state:{
        pendingJobs:[],

        jobsById: {},
        jobIds: [],

        query: {
            page: 1,
            limit: 20
        },

        pagination: {
            next: false,
            prev: false,
            page: 1,
            pages: 1,
            limit: 10,
            count: 0
        }
    },
    actions:{
        ROUTE_CHANGE_START:{
            root: true,
            handler(ctx, route){
                if(route.name === 'jobs-dashboard') {
                    ctx.dispatch('RELOAD_ALL');
                }
            }
        },
        RELOAD_ALL(ctx, silent){
            // reload jobs only if page 1 when silent mode
            if(!silent || ctx.state.pagination.page === 1) ctx.dispatch('LOAD_JOBS', silent);
            ctx.dispatch('LOAD_PENDING_JOBS', silent);
        },
        LOAD_PENDING_JOBS(ctx, silent){
            backend.jobs[ silent ? 'pendingSilent' : 'pending' ](data => {
                ctx.commit('SET_PENDING_JOBS', data);
            });
        },
        LOAD_JOBS(ctx, silent){
            backend.jobs[ silent ? 'historySilent' : 'history' ](ctx.state.query, data => {
                ctx.commit('SET_PAGINATION', data.pagination);
                ctx.commit('SET_JOBS', data);
            });
        },
        UPDATE_QUERY(ctx, query){
            ctx.commit('UPDATE_QUERY', query);
            ctx.dispatch('LOAD_JOBS');
        },
        // LOAD_JOB_CHANGES(ctx, jobId){
        //     let job = ctx.state.jobsById[jobId];
        //     let query = job.changes ? job.changes.query : { page:1, limit:20 };

        //     backend.jobs.changes({ id:jobId, ...query }, changes => {
        //         changes.query = query;
        //         ctx.commit('SET_JOB_CHANGES', { jobId, changes });
        //     });
        // }
    },
    mutations:{
        SET_PAGINATION(state, pagination){
            state.pagination = pagination;
        },
        SET_JOBS(state, jobs){
            let jobIds = [], jobsById = {};

            jobs.forEach(obj => {
                jobIds.push(obj.id);
                jobsById[ obj.id ] = obj;
            });

            state.jobIds = jobIds;
            state.jobsById = jobsById;
        },
        SET_PENDING_JOBS(state, pendingJobs){
            state.pendingJobs = pendingJobs;
        },
        
        // SET_JOB_CHANGES(state, { jobId, changes }){
        //     let job = state.jobsById[ jobId ] || {};
        //     job.changes = {
        //         query: changes.query,
        //         headers: changes.header,
        //         pagination: changes.pagination,
        //         list: changes.slice()
        //     };
        // },
        // EXTEND_JOB(state, { jobId, data }){
        //     let currJob = state.jobsById[ jobId ] || {};
        //     Vue.set(state.jobsById, jobId, { ...currJob, ...data });
        // },
        UPDATE_QUERY(state, query){
            for(let key in query) {
                state.query[key] = query[key];
            }
        },
    }
});