<template>
    <div>
        <small>({{imagesPreviewUrls.length}})</small>
        <img v-for="(imgUrl, i) in imagesPreviewUrls" v-if="i < maxPreviewCount" :key="i" :src="imgUrl">
        <small v-if="imagesPreviewUrls.length > maxPreviewCount">...</small>
    </div>
</template>

<script>
    import spreadsheetRendererMixin from './spreadsheet-renderer-mixin.js';

    export default {
        mixins:[
            spreadsheetRendererMixin
        ],
        data(){
            return {
                maxPreviewCount: 3
            };
        },
        computed:{
            imagesPreviewUrls(){
                let dataTypeId = typeof this.dataTypeId === 'function' ? this.dataTypeId.call(this.getSpreadsheetComponent(), { row:this.row, column:this.column, rowIndex:this.rowIndex, colIndex:this.colIndex, value:this.value, rowContext:this.getSpreadsheetComponent().getRowContext(this.rowIndex) }) : this.dataTypeId;
                let dataType = this.dataTypes[ dataTypeId ];
                let valueRender = dataType ? dataType.render : this.column.render;

                return typeof valueRender === 'function' ? valueRender({ value:this.value, returnPreviewUrlArray:true }) : [];
            }
        }
    }
</script>

<style scoped>
    div {
        margin:-2px 0px -4px 0px;
    }

    img {
        height:20px;
        width:20px;
        vertical-align:middle;
        margin-left:1px;
    }
</style>