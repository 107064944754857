<template>
    <div v-if="segment && segment.displayVals.length || segment.resources.length" class="attributes-legend">

        <span class="attributes-legend-edge"></span>

        <span v-if="segment.resources.length">
            <span v-for="(namedResource,i) in segment.resources" :key="i">
                <inv-object-link :object="namedResource.resource" :custom-name="namedResource.name" class="attributes-legend-value"></inv-object-link>
                <br v-if="i !== segment.resources.length-1">
            </span>
        </span>
        <br v-if="segment.resources.length">

        <span v-if="!segment.visualHint.displayValsCollapsed || expanded">
            <span   class="attributes-legend-value"
                    v-for="(attr,i) in segment.displayVals"
                    :key="i"
                    :style="{'font-size':attr.fontSize,'color':attr.color,'font-weight':attr.fontWeight}">
                    
                    {{attr.attrNameDS}}={{attr.attrValueDS}}<br>
            </span>
        </span>
        <span class="attributes-legend-value" 
                v-if="!expanded && segment.visualHint.displayValsCollapsed && segment.displayVals.length" 
                style="cursor:pointer" 
                @click="segment.visualHint.displayValsCollapsed = false">
            <i class="fal fa-ellipsis-h"></i><br>
        </span>

        <span class="attributes-legend-edge"></span>
    </div>
</template>

<script>
    import InvConnVisualLegend from './inv-conn-visual-legend.vue';
    import InvObjectLink from '../../inv-object-link.vue';

    export default {
        components:{
            InvConnVisualLegend,
            InvObjectLink
        },
        props:{
            segment: {},
            expanded: Boolean
        }
    };
</script>