<template>
    <div class="data-correction-field-wrapper">
        <obj-input-container style="" hide-details hide-label class="field-value-input" append-inner="fal fa-pencil" @click:append-inner="changeRequestModal">
            <div :style="{'font-size':field.fontSize,'color':field.color || 'inherit'}" v-html="field.displayString"></div>
        </obj-input-container>
        
        <div v-if="field.dataCorrectionRequests && field.dataCorrectionRequests.length > 0 && field.dataCorrectionRequests[0].requestedValue !== field.displayString" 
             class="field-value-readonly requested-value">
            <span v-html="field.dataCorrectionRequests[0].requestedValue"></span>
        </div>

        <v-dialog 
            v-model="changeRequestDialogOpened" 
            width="500px"
            content-class="small-dialog"
        >
			<v-card flat>
				<v-card-title><h2>{{'request_change_value_of_attribute' | translate}} <span style="text-decoration: underline">{{field.displayName}}</span></h2></v-card-title>

				<v-card-text>
                    <v-text-field type="text" :label="'current_value' | translate" :value="field.displayString" disabled></v-text-field>

                    <v-text-field type="text" :label="'proposed_value' | translate" v-model="changeNewValue"></v-text-field>

                    <v-combobox :label="'problem_type' | translate" :items="changeRequestTypes" v-model="changeRequestType"></v-combobox>

                    <v-textarea :label="'additional_description' | translate" v-model="changeDescription"></v-textarea>
                </v-card-text>

				<v-card-actions>
					<v-btn 
                        text
                        color="error" 
                        @click="changeRequestDialogOpened = false"
                    >
                        {{'cancel'|translate}}
                    </v-btn>
					<v-spacer></v-spacer>
                    <v-btn 
                        outlined 
                        color="info" 
                        @click="saveChange"
                    >
                        {{'save'|translate}}
                    </v-btn>
					<v-btn 
                        v-if="changeRequestGlobalId" 
                        outlined 
                        color="success" 
                        @click="makeChangeRequest"
                    >
                        {{'confirm_correction'|translate}}
                    </v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
    </div>
</template>

<style scoped>
    .field-value-input {
        background-color:#eaf9f0; /* #3bca7f33 */
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
    }
    
    .requested-value {
        font-style:italic;
        background-color:#fff7e2; /* #caa33b33 */
        margin-bottom:4px;
    }
</style>

<script>
    import backend from 'obj-fe/app/backend';
    import notify from 'obj-fe/services/notifications';

    export default {
        props:{
            field: Object,
            value: {},
            required: Boolean,
            rules: Array,
            meta: {}
        },
        data(){
            return {
                changeRequestDialogOpened: false,
                changeRequestDialogOpenedFirstTime: true,
                changeRequestTypes: [],
                changeNewValue: null,
                changeDescription: null,
                changeRequestType: null,
            };
        },
        computed:{
            changeRequestGlobalId() {
                if (this.field.dataCorrectionRequests && this.field.dataCorrectionRequests[0]) {
                    return this.field.dataCorrectionRequests[0].id;
                }
            }
        },
        methods:{
            changeRequestModal(){
                if(this.changeRequestDialogOpenedFirstTime) {
                    this.changeNewValue = this.field.displayString;
                    
                    let currChangeReq = (this.field.dataCorrectionRequests || [])[0];
                    if (currChangeReq) {
                        this.changeNewValue = currChangeReq.requestedValue;
                        this.changeDescription = currChangeReq.note;
                        this.changeRequestType = this.changeRequestTypes.filter(type => type.value === currChangeReq.requestType)[0];
                    }

                    this.loadChangeRequestTypes();
                    this.changeRequestDialogOpenedFirstTime = false;
                }

                this.changeRequestDialogOpened = true;
            },
            loadChangeRequestTypes(){
                backend.objects.correctionRequestType(data => {
                    this.changeRequestTypes = data.values.map(item => {
                        return {
                            text: item.title,
                            value: item.name
                        };
                    });

                    if(!this.changeRequestType) this.changeRequestType = this.changeRequestTypes[0];
                });
            },
            saveChange(){
                this.changeRequest();
            },
            makeChangeRequest(){
                this.changeRequest(true);
            },
            changeRequest(isConfirmed){
                backend.objects.dataCorrection(
                    {
                        globalId: this.meta.objectId, 
                        fieldAttribute: this.field.attribute
                    },
                    {
                        globalId: this.changeRequestGlobalId,
                        fieldId: this.meta.objectId,
                        fieldAttribute: this.field.attribute,
                        note: this.changeDescription,
                        isConfirmed: isConfirmed || false,
                        requestType: this.changeRequestType.value,
                        requestedValue: this.changeNewValue
                    }, 
                data => {
                    this.changeRequestDialogOpened = false;
                    notify.success(isConfirmed ? 'requested' : 'saved');

                    if(!isConfirmed) {
                        this.$store.dispatch('INVENTORY/SET_OBJECT_DETAIL_FIELD_CORRECTION', { field:this.field, dataCorrectionRequests:[data] });
                    }
                    else this.$store.dispatch('INVENTORY/SET_OBJECT_DETAIL_FIELD_CORRECTION', { field:this.field, dataCorrectionRequests:[] });
                });
            }
        }
    }
</script>