<template>
    <div v-obj-fill-height="fullScreen" style="height:100%">
        <v-tooltip left v-if="fullScreen">
            <template v-slot:activator="{on}">
                <div v-on="on" class="menu-button" :class="{ expanded:mapOptionsOpened }" @click="mapOptionsOpened = !mapOptionsOpened">
                    <v-icon style="margin-top:15px">{{ mapOptionsOpened ? 'arrow_right' : 'arrow_left' }}</v-icon>
                </div>
            </template>
            {{'toggle_side_panel' | translate}}
        </v-tooltip>
        
        <v-navigation-drawer v-if="fullScreen" class="document-side-panel" :value="mapOptionsOpened" absolute right clipped width="510">
            <v-card flat>

		        <v-card-text style="padding-bottom:0px;"> 
                    <v-list>
                        <v-subheader>{{'legend' | translate}}</v-subheader>
                        <v-list-item @click="showLineNames = !showLineNames">
                            <v-list-item-action>
                                <v-checkbox off-icon="fal fa-square" :value="showLineNames"></v-checkbox>
                            </v-list-item-action>

                            <v-list-item-content>
                                <v-list-item-title>
                                    {{'show_line_names' | translate}}
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item @click="showNodeNames = !showNodeNames">
                            <v-list-item-action>
                                <v-checkbox off-icon="fal fa-square" :value="showNodeNames"></v-checkbox>
                            </v-list-item-action>

                            <v-list-item-content>
                                <v-list-item-title>
                                    {{'show_node_names' | translate}}
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>

                    <v-list>
                        <v-subheader>{{'layers' | translate}}</v-subheader>
                        <v-list-item v-for="layer in layers" :key="layer.id" @click="toggleLayer(layer)">
                            <v-list-item-action>
                                <v-checkbox off-icon="fal fa-square" :value="layer.selected"></v-checkbox>
                            </v-list-item-action>

                            <v-list-item-icon>
                                <v-icon small>{{'fal ' + layer.icon}}</v-icon>
                            </v-list-item-icon>

                            <v-list-item-content>
                                <v-list-item-title>
                                    {{layer.displayString}}
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
		        </v-card-text>
            </v-card>
        </v-navigation-drawer>

        <div ref="mapContainer" style="width:100%;height:100%"></div>

        <div ref="mapOverlay">
            <v-card v-if="selectedItems">
                <v-list>
                    <v-list-item v-for="obj in selectedItems" :key="obj.id">
                        <v-list-item-content>
                            <v-list-item-title>
                                <inv-object-link :object="{ id:obj.globalId, name:obj.name || obj.displayString, typeDisplayString:obj.objectType }" open-new-tab>
                                    <v-icon small>{{'fal ' + obj.icon}}</v-icon> {{obj.name}} ({{obj.objectType}})
                                </inv-object-link>
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </v-card>
        </div>
    </div>
</template>

<style scoped>
    /* >>> .ol-zoom {
        position:absolute;
        top:5px;
        left:5px;
        outline:1px solid yellow;
    } */

    /* hide copyright - only for internal purpose */
    >>> .ol-attribution.ol-unselectable.ol-control.ol-uncollapsible > ul{
        display:none;
    }

    .menu-button {
        position:fixed;
        right:0px;
        top: 74px;
        z-index:1;
        width: 530px;
        height: 50px;
        background-color: #fff;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
        box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.3);
        cursor: pointer;
        /* transform: translateX(97%); */

        transform: translateX(96%);
        transition-duration: 0.2s;
        transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
        will-change: transform;
        transition-property: transform, width;
    }

    .menu-button.expanded {
        transform: translateX(0%);
    }
</style>

<script>
    import object from 'obj-fe/utils/object';

    import 'ol/ol.css';
    import Map from 'ol/Map';
    import View from 'ol/View';
    import Feature from 'ol/Feature';
    import Overlay from 'ol/Overlay';
    import Point from 'ol/geom/Point';
    import LineString from 'ol/geom/LineString';
    import Vector from 'ol/source/Vector';
    import {defaults as defaultInteractions, MouseWheelZoom} from 'ol/interaction';
    import {Tile as TileLayer, Vector as VectorLayer} from 'ol/layer';
    import {OSM} from 'ol/source';
    import {Style, Icon, Stroke, Text, Fill} from 'ol/style'; // Circle, Fill, Stroke,
    import {fromLonLat} from 'ol/proj';
    import {defaults as defaultControls, ZoomToExtent} from 'ol/control';
    import {boundingExtent} from 'ol/extent';

    import mapIcons from './fa-icons-list.json';

    import InvObjectLink from '../inv-object-link.vue';

    export default {
        components:{
            InvObjectLink
        },
        props:{
            layers:{},
            layerData:{},
            fullScreen: Boolean,
            lineNames: Boolean,
            nodeNames: Boolean,
            resizeNeeded: Boolean
        },
        data(){
            return {
                defaultProjection: 'EPSG:3857',
                slovakiaEdges:[
                    [ 48.380742, 16.833229 ],
                    [ 47.731095, 18.289913 ],
                    [ 49.613754, 19.448545 ],
                    [ 49.087846, 22.565402 ]
                ],
                usedLayers: {},
                mapOptionsOpened: true,
                showLineNames: false,
                showNodeNames: false,
                selectedItems: null,
                objects:[],
                connections:[],

                overlayData: null
            };
        },
        computed:{
            layerExtent(){
                if(this.usedLayers && this.usedLayers.objLayer) {
                    let extent = this.usedLayers.objLayer.getSource().getExtent();
                    if(extent.filter(gps => gps === Infinity || gps === -Infinity).length === 0) return extent;
                }

                return boundingExtent([
                    fromLonLat([ this.slovakiaEdges[0][1], this.slovakiaEdges[0][0] ], this.defaultProjection),
                    fromLonLat([ this.slovakiaEdges[1][1], this.slovakiaEdges[1][0] ], this.defaultProjection),
                    fromLonLat([ this.slovakiaEdges[2][1], this.slovakiaEdges[2][0] ], this.defaultProjection),
                    fromLonLat([ this.slovakiaEdges[3][1], this.slovakiaEdges[3][0] ], this.defaultProjection)
                ]);
            }
        },
        watch:{
            layerData:{
                immediate:true,
                handler(layerData){
                    this.$nextTick(() => {
                        this.createMapInstance(this.$refs.mapContainer);
                        if(layerData) this.createLayer();
                        else this.destroyLayer();
                        this.fitMap();
                    });
                }
            },
            lineNames:{
                immediate: true,
                handler(val){
                    this.showLineNames = val;
                }
            },
            nodeNames:{
                immediate: true,
                handler(val){
                    this.showNodeNames = val;
                }
            },
            showLineNames(){
                if(this.layerData) this.createLayer(); // re-render layer
            },
            showNodeNames(){
                if(this.layerData) this.createLayer(); // re-render layer
            },
            resizeNeeded: function(){
                if(this.resizeNeeded){
                    setTimeout(()=>{
                        window.dispatchEvent(new Event('resize'));
                    }, 200)
                }
            }
        },
        methods:{
            convertDegress(input) {
                var parts = input.split(/[^\d\w]+/g);
                return this.convertDMSToDD(parts[0], parts[1], parts[2], parts[3], parts[4]);
            },

            convertDMSToDD(degrees, minutes, seconds, miliseconds, direction) {
                var dd = parseInt(degrees,10) + parseInt(minutes,10)/60 + parseInt(seconds,10)/(60*60) + parseInt(miliseconds,10)/(60*60*60);
                if (direction === 'S' || direction === 'W') {
                    dd = dd * -1;
                } // Don't do anything for N or E
                return parseFloat(dd+'');
            },

            createObjectPlace(object){
                let place = new Feature({
                    geometry: new Point(fromLonLat([ object.longitude, object.latitude ], this.defaultProjection))
                });

                let text = object.icon || 'fa-map-marker-alt';
                let font = '400 20px "Material Icons"';
                if(text.indexOf('fa-') === 0) {
                    font = '900 20px "Font Awesome 5 Free"';
                    text = String.fromCharCode(parseInt(mapIcons[ text.replace(/^fa-/, '') ], 16));
                }
 
                let styles = [
                    new Style({
                        text: new Text({
                            text,
                            font,
                            textBaseline: 'middle',
                            fill: new Fill({
                                color: object.color || 'red',
                            })
                        })
                    })
                ];

                if(this.showNodeNames) styles.push(new Style({
                    text: new Text({
                        text: object.name,
                        font: '400 14px Roboto, sans-serif',
                        offsetY: -16,
                        fill: new Fill({
                            color: object.color || 'red',
                        })
                    })
                }));

                place.setStyle(styles);

                return place;
            },

            createObjectsLayer(objects){
                let layer = new VectorLayer({
                    source: new Vector({
                        features: objects.map(obj => {
                            let place = this.createObjectPlace(obj);
                            place.dataId = obj.id;
                            return place;
                        })
                    })
                });

                return layer;
            },

            createConnectionsLayer(connections){
                let lineFeatures = connections.map(connection => {

                    let coordinates = [
                        fromLonLat([ connection.longitudeSrc, connection.latitudeSrc ], this.defaultProjection),
                        fromLonLat([ connection.longitudeDst, connection.latitudeDst ], this.defaultProjection)
                    ];

                    let lineFeature = new Feature(new LineString(coordinates));

                    let styleObj = {
                        stroke: new Stroke({
                            // color: 'red',
                            width: 2,
                            lineDash: [ 5, 5 ]
                        })
                    };

                    if(this.showLineNames) styleObj.text = new Text({
                        text: connection.name,
                        font: '400 12px Roboto, sans-serif',
                        stroke: new Stroke({ color:'black', width:4 }),
                        placement: 'line',
                        fill: new Fill({
                            color: 'white',
                        })
                    });

                    lineFeature.setStyle(new Style(styleObj));
                    lineFeature.dataId = connection.id;

                    return lineFeature;
                });

                let layer = new VectorLayer({
                    source: new Vector({
                        features: lineFeatures
                    })
                });

                return layer;
            },

            toggleLayer(layer){
                this.$store.dispatch('INVENTORY_MAP/TOGGLE_LAYER', layer);
            },
            createLayer(){
                if(this.usedLayers) this.destroyLayer();

                this.objects = this.layerData.nodeGroups.map(obj => {
                    obj = object.deepClone(obj);
                    obj.id = obj.id || obj.globalId || obj.nodes[0].id || obj.nodes[0].globalId;
                    obj.infoOpened = false;
                    obj.icon = obj.icon || '';
                    obj.objects = obj.nodes;
                    obj.name = obj.name || obj.displayString;
                    return obj;
                });

                this.connections = this.layerData.links.map(conn => {
                    conn = object.deepClone(conn);
                    conn.id = conn.id || conn.globalId;
                    conn.infoOpened = false;
                    conn.name = conn.name || conn.displayString;
                    return conn;
                });

                let connLayer = this.createConnectionsLayer(this.connections);
                let objLayer = this.createObjectsLayer(this.objects);

                this.usedLayers = { connLayer, objLayer };
                this.map.addLayer(connLayer);
                this.map.addLayer(objLayer);
            },
            destroyLayer(){
                if(!this.usedLayers) return;

                this.toggleEntityInfo();
                this.map.removeLayer(this.usedLayers.connLayer);
                this.map.removeLayer(this.usedLayers.objLayer);
                this.usedLayers = null;
            },

            toggleEntityInfo(mapEvent, mapEntityId){
                let targetObj = this.objects.filter(obj => obj.id === mapEntityId)[0];
                let targetConn = this.connections.filter(conn => conn.id === mapEntityId)[0];

                let selectedObjects = targetObj ? targetObj.objects || [targetObj] : null;
                let selectedConnections = targetConn ? [targetConn] : null;

                this.selectedItems = selectedObjects || selectedConnections;

                if(this.selectedItems) {
                    // openlayer overlays example: https://openlayers.org/en/latest/examples/geographic.html?q=line
                    this.mapOverlay.setPosition(mapEvent.coordinate);
                }
            },

            fitMap(){
                if(this.map) {
                    this.map.fitControl.extent = this.layerExtent;
                    this.map.getView().fit(this.layerExtent, { minResolution: 5});
                }
            },

            createMapInstance(mapContainerElm){
                if(this.map) return;

                let mapInteractions;
                
                if(!this.fullScreen) {
                    let mouseWheelCtrlInteraction = new MouseWheelZoom();
                    let mouseWheelHandler = mouseWheelCtrlInteraction.handleEvent;
                    
                    mouseWheelCtrlInteraction.handleEvent = function(e) {
                        if(e.type !== 'wheel') return true;
                        if(!e.originalEvent.altKey && !e.originalEvent.ctrlKey && !e.originalEvent.metaKey) return true;
                        mouseWheelHandler.call(this,e);
                    };

                    mapInteractions = defaultInteractions({ mouseWheelZoom:false }).extend([mouseWheelCtrlInteraction]);
                }
                else mapInteractions = defaultInteractions({ mouseWheelZoom:true });

                let map = new Map({
                    controls: defaultControls({ zoom:true }),
                    interactions: mapInteractions,
                    // view: new View({
                    //     // center: fromLonLat([ this.objects[0].longitude, this.objects[0].latitude ], this.defaultProjection),
                    //     // zoom: this.zoom || 16
                    // }),
                    layers: [
                        new TileLayer({
                            source: new OSM()
                        })
                    ],
                    target: mapContainerElm
                });

                this.map = map;

                map.on('pointermove', event => {
                    if (map.hasFeatureAtPixel(event.pixel)) {
                        map.getViewport().style.cursor = 'pointer';
                    } else {
                        map.getViewport().style.cursor = 'inherit';
                    }
                });

                this.map.on('click', event => {
                    let feature = this.map.getFeaturesAtPixel(event.pixel)[0];
                    this.toggleEntityInfo(event, (feature || {}).dataId);
                });

                map.fitControl = new ZoomToExtent({ extent: this.layerExtent, label:'⦿', tipLabel: 'Fit view to objects' });
                map.addControl(map.fitControl);

                this.mapOverlay = new Overlay({
                    element: this.$refs.mapOverlay,
                    positioning: 'bottom-center',
                    // position: mapEvent.coordinate,
                    // stopEvent: false,
                    offset: [0, -10]
                });

                this.map.addOverlay(this.mapOverlay);
            }
        },
        mounted(){
            this.$store.dispatch('INVENTORY/SET_HEAD_TITLE', 'Map');
        },
        destroyed(){
            this.$store.dispatch('INVENTORY/SET_HEAD_TITLE', '');
            if(this.map) this.map.setTarget(null); // destroy map instance
        }
    };
</script>