<template>
    <div>
        {{viewValue}}
    </div>
</template>

<script>
    import rendererMixin from './spreadsheet-renderer-mixin';

    export default {
        mixins:[ rendererMixin ],
        computed:{
            viewValue(){
                let maxPreviewLen = 15;

                let viewValue = this.value ? JSON.stringify(this.value) : '';
                if(viewValue.length > maxPreviewLen) viewValue = viewValue.slice(0,maxPreviewLen) + '...';

                return viewValue;
            }
        }
    }
</script>