export default {
    data(){
        return {
            selectedCells:{
                fromRowIndex: -1,
                fromColIndex: -1,
                toRowIndex: -1,
                toColIndex: -1,
                firstRowIndex: -1,
                firstColIndex: -1,
                count: 0,
                rowsCount: 0
            }
        };
    },
    methods:{
        handleRowSelectionStart(rowIndex){
            // select current row
            this.selectRow(rowIndex);

            let targetFilter = data => data.cellType === 'row';
            let targetComparator = (prevData, data) => data.rowIndex !== prevData.rowIndex;
            let selector = data => this.selectCells(rowIndex, 0, parseInt(data.rowIndex, 10), this.columns.length-1);

            this.handleSelectionStart(rowIndex, 0, targetFilter, targetComparator, selector);
        },
        handleColSelectionStart(colIndex){
            // select current column
            this.selectCol(colIndex);

            let targetFilter = data => data.cellType === 'column';
            let targetComparator = (prevData, data) => data.colIndex !== prevData.colIndex;
            let selector = data => this.selectCells(0, colIndex, this.rows.length-1, parseInt(data.colIndex, 10));

            this.handleSelectionStart(0, colIndex, targetFilter, targetComparator, selector);
        },
        handleCellSelectionStart(rowIndex, colIndex){
            this.closeEditingMode();

            // select current cell
            this.selectCells(rowIndex, colIndex);

            let targetFilter = data => data.cellType === 'cell';
            let targetComparator = (prevData, data) => data.rowIndex !== prevData.rowIndex || data.colIndex !== prevData.colIndex;
            let selector = data => this.selectCells(rowIndex, colIndex, parseInt(data.rowIndex, 10), parseInt(data.colIndex, 10));

            this.handleSelectionStart(rowIndex, colIndex, targetFilter, targetComparator, selector, true);
        },

        handleSelectionStart(rowIndex, colIndex, targetFilter, targetComparator, selector, isCellSelection){
            // mousedown already appeared
            // register mouseup to end selection
            // register mousemove to change selection
            window.addEventListener('mousemove', changeSelection);

            let prevData;

            let spreadsheet = this;
            function changeSelection(e){
                if(spreadsheet.editingCell.active) return;
                e.preventDefault();

                spreadsheet.isMovingOnScrollEdge(e, isCellSelection);

                // traverse mousemove path to find nearest table cell
                let targetElm;
                let path = e.path || (e.composedPath && e.composedPath());
                for(let i=0; i < path.length; i++) {
                    targetElm = path[i];
                    if(!targetElm || targetElm === spreadsheet.$refs.table || targetElm === window) return; // target cell not found
                    else if(targetElm.dataset && targetFilter(targetElm.dataset)) break; // target cell found
                }

                let data = targetElm.dataset;
                prevData = prevData || data;

                if(targetComparator(prevData, data)) {
                    prevData = data;
                    selector(data);
                }
            };

            window.addEventListener('mouseup', e => {
                window.removeEventListener('mousemove', changeSelection);
            });
        },

        isMovingOnScrollEdge(e, isCellSelection){
            const edgeXSize = 20;
            const edgeXScrollStep = 10;
            const edgeYSize = 20;
            const edgeYScrollStep = 10;

            let container = this.$refs.spreadsheetContainer;
            if(!container) return;

            let rect = container.getBoundingClientRect();

            let scrollTopIncrement = 0;
            let scrollLeftIncrement = 0;

            if(e.clientY > (rect.bottom-edgeYSize)) scrollTopIncrement += edgeYScrollStep; // scroll down
            if(e.clientY < (rect.top+edgeYSize)) scrollTopIncrement -= edgeYScrollStep; // scroll up
            if(e.clientX > (rect.right-edgeXSize)) scrollLeftIncrement += edgeXScrollStep; // scroll right
            if(e.clientX < (rect.left+edgeXSize)) scrollLeftIncrement -= edgeXScrollStep; // scroll left

            if(scrollTopIncrement !== 0) container.scrollTop += scrollTopIncrement;
            if(scrollLeftIncrement !== 0) container.scrollLeft += scrollLeftIncrement;
        },

        selectCells(fromRowIndex, fromColIndex, toRowIndex = fromRowIndex, toColIndex = fromColIndex, firstRowIndex = fromRowIndex, firstColIndex = fromColIndex){
            if(toRowIndex < fromRowIndex) {
                let to = fromRowIndex;
                fromRowIndex = toRowIndex;
                toRowIndex = to;
            }

            if(toColIndex < fromColIndex) {
                let from = fromColIndex;
                fromColIndex = toColIndex;
                toColIndex = from;
            }

            if(fromColIndex < 0) fromColIndex = 0;
            if(toColIndex < 0) toColIndex = 0;
            if(fromRowIndex < 0) fromRowIndex = 0;
            if(toRowIndex < 0) toRowIndex = 0;
            if(firstColIndex < 0) firstColIndex = 0;
            if(firstRowIndex < 0) firstRowIndex = 0;

            if(fromColIndex > this.columns.length - 1) fromColIndex = this.columns.length - 1;
            if(toColIndex > this.columns.length - 1) toColIndex = this.columns.length - 1;
            if(fromRowIndex > this.rows.length - 1) fromRowIndex = this.rows.length - 1;
            if(toRowIndex > this.rows.length - 1) toRowIndex = this.rows.length - 1;

            if(firstColIndex > this.columns.length - 1) firstColIndex = this.columns.length - 1;
            if(firstRowIndex > this.rows.length - 1) firstRowIndex = this.rows.length - 1;

            this.selectedCells = {
                firstRowIndex, firstColIndex, fromRowIndex, fromColIndex, toRowIndex, toColIndex, count: (1+toRowIndex-fromRowIndex)*(1+toColIndex-fromColIndex), rowsCount: (1+toRowIndex-fromRowIndex)
            };

            this.$nextTick(() => this.scrollSelectedCellsToView());
        },

        clearCellsSelection(){
            this.selectedCells = {
                firstRowIndex:-1, firstColIndex:-1, fromRowIndex:-1, fromColIndex:-1, toRowIndex:-1, toColIndex:-1, count: 0, rowsCount: 0
            };
        },

        minimizeCellsSelection(){
            let wasSelected = this.selectedCells.count > 0;
            if(!wasSelected) return;

            let rowIndex = this.selectedCells.firstRowIndex;
            let colIndex = this.selectedCells.firstColIndex;

            this.selectedCells = {
                firstRowIndex: rowIndex,
                firstColIndex: colIndex,
                fromRowIndex: rowIndex,
                fromColIndex: colIndex,
                toRowIndex: rowIndex,
                toColIndex: colIndex,
                count: 1,
                rowsCount: 1
            };
        },

        // TODO: fix by calc scrolling spreadsheet, because this approach sometimes does not work
        scrollSelectedCellsToView(){
            if(this.selectedCells.count !== 1) return;

            let rowIndex = this.selectedCells.firstRowIndex;
            let colIndex = this.selectedCells.firstColIndex;

            let cellElm = this.$refs.table.querySelector('[data-cell-type="cell"][data-row-index="'+rowIndex+'"][data-col-index="'+colIndex+'"]');
            if(cellElm) cellElm.scrollIntoViewIfNeeded(true);
        },

        selectAll(){
            this.selectCells(0, 0, this.rows.length-1, this.columns.length-1);
        },

        selectRow(rowIndex){
            this.selectCells(rowIndex, 0, rowIndex, this.columns.length-1);
        },

        selectCol(colIndex){
            this.selectCells(0, colIndex, this.rows.length-1, colIndex);
        },

        isRowSelected(rowIndex){
            if(this.selectedCells.toColIndex !== this.columns.length-1) return false;
            return (rowIndex >= this.selectedCells.fromRowIndex) && (rowIndex <= this.selectedCells.toRowIndex);
        },

        iterateSelectedCells(cb){
            if(this.selectedCells.fromRowIndex > -1) {
                for(let rowIndex = this.selectedCells.fromRowIndex; rowIndex <= this.selectedCells.toRowIndex; rowIndex++){
                    for(let colIndex = this.selectedCells.fromColIndex; colIndex <= this.selectedCells.toColIndex; colIndex++){
                        cb(rowIndex, colIndex);
                    }
                }
            }
        },
    }
};