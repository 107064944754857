
export default {
    handleStart(){
        throw new Error('not implemented');
    },
    handleEnd(){
        throw new Error('not implemented');
    },
    start(wizardId){
        this.handleStart(wizardId);
    },
    end(){
        this.handleEnd();
    }
};

