// import shortid from 'shortid'; - used in UID
import locals from 'obj-fe/services/localisation';

import SpreadSheetRendererDelete from './spreadsheet-renderer-delete.vue';
import SpreadSheetRendererBoolean from './spreadsheet-renderer-boolean.vue';
import SpreadSheetRendererUID from './spreadsheet-renderer-uid.vue';
import SpreadSheetRendererImages from './spreadsheet-renderer-images.vue';
import SpreadSheetRendererObject from './spreadsheet-renderer-object.vue';
import SpreadSheetRendererRowDetails from './spreadsheet-renderer-row-details.vue';

import date from 'obj-fe/utils/date';
import isEmptyValue from './is-empty-value';

function parseImageIdFromUrl(url){
    url = url + '';
    if(!url) return null;

    if(url.indexOf(window.location.origin + CONFIG.imagesBasePath) === 0){
        let imageName = url.replace(window.location.origin + CONFIG.imagesBasePath, '').split('/')[0];
        return imageName.split('.')[0];
    }
    else return null;
}

function translateIfNeeded(column, value){
    if(column.translateValue === true) return locals.translate(value);
    else return value;
}

function untranslateIfNeeded(column, value){
    if(column.translateValue === true) return locals.untranslate(value);
    else return value;
}

function isIntegerString(value){
    return typeof value === 'string' && (parseInt(value, 10)+'') === value;
}

function parseIntegerString(value){
    if(typeof value === 'string'){
        value = (value.match(/\d+/g) || [])[0] || '';
        value = parseInt(value);
    }
    return value;
}

function parseNumericString(value){
    if(typeof value === 'string'){
        value = (value.match(/^([0-9\.\,]*).*$/) || [])[1] || '';

        // auto match comma and dot format
        if(value.match(/^[0-9]+\,[0-9]+$/)) value = value.replace(',','.');
        else if(value.match(/^[0-9]+\,[0-9]+\.[0-9]+$/)) value = value.replace(',','');
        value = parseFloat(value);
    }
    return value;
}

function renderNumericString(value, toFixedDigits){
    if(!isNaN(value) && value !== null) return (!isNaN(toFixedDigits) ? value.toFixed(toFixedDigits) : value).toString().replace('.',','); // replace dot with comma
    else return value;
}

// TODO: move this to obj-fe/utils/date
function tryParseDate(val){
    return toDate(val, val => val);
}

function isValidDate(value){
    return value && value.getTime && value.getTime() === value.getTime();
}

// european date time format parser: 'D.M.yyyy, HH:mm:ss'
function parseDateEuropean(input) {
    const [dateString, timeString] = input.split(',');

    const [day, month, year] = dateString.trim().split('.').map(Number);
    if(!(day >= 1 && day <= 31 && month >= 1 && month <= 12 && !isNaN(year))) return null;
    
    if (!timeString) {
        return new Date(year, month - 1, day);
    }

    const [hour, minute, second] = timeString.trim().split(':').map(Number);
    if(!(hour >= 0 && hour <= 23 && minute >= 0 && minute <= 59 && !isNaN(second))) return null;

    return new Date(year, month - 1, day, hour, minute, second);
}

function toDate(val, sanitizer){
    if(Array.isArray(val)) return val.map(val => toDate(val, sanitizer));

    if(!val && val !== 0) return sanitizer(null);

    // don't do this, because input may be only year "2023"
    // // value as numeric string
    // if(isIntegerString(val)){
    //     let dt = new Date(parseInt(val, 10));
    //     if(isValidDate(dt)) return sanitizer(dt);
    // }

    // try formated string parsing
    if(typeof val === 'string') {
        let dt = parseDateEuropean(val);
        if(isValidDate(dt)) return sanitizer(dt);
    }

    // fallback to native Date parser
    try { return sanitizer(new Date(val)); }
    catch(err) { return sanitizer(null); }
}

// function dateFromSeconds(dt){
//     if(!dt) return null;
//     else return new Date(dt*1000);
// }

// function dateToSeconds(dt){
//     if(!dt) return null;
//     else return Math.round(dt.getTime()/1000);
// }

export default {
    datetime:{
        validate({ value }){
            if(isEmptyValue(value)) return true;

            let valueType = typeof value;
            if(valueType === 'string' && isValidDate(tryParseDate(value))) return true;
            else if(value instanceof Date && isValidDate(value)) return true;
            else return false;
        },
        parse({ value }){
            if(isEmptyValue(value)) return value;
            return tryParseDate(value) || value;
        },
        render({ value }){
            return isValidDate(value) ? date.formatDatetime(value) : value;
        },
        placeholder: 'dd.MM.yyyy hh:mm:ss'
    },

    date:{
        validate({ value }){
            if(isEmptyValue(value)) return true;

            let valueType = typeof value;
            if(valueType === 'string' && isValidDate(tryParseDate(value))) return true;
            else if(value instanceof Date && isValidDate(value)) return true;
            else return false;
        },
        parse({ value }){
            if(isEmptyValue(value)) return value;
            return tryParseDate(value) || value;
        },
        render({ value }){
            return isValidDate(value) ? date.formatDate(value) : value;
        },
        placeholder: 'dd.MM.yyyy'
    },

    string:{
        validate({ value }){
            if(isEmptyValue(value)) return true;

            let valueType = typeof value;
            return valueType === 'string' || valueType === 'number' || value instanceof Date || valueType === 'boolean'; // any primitive value can be string
        },
        parse({ value, column }){
            return untranslateIfNeeded(column, isEmptyValue(value) ? value : value+'');
        },
        render({ value, column }){
            if(isEmptyValue(value)) return '';
            return translateIfNeeded(column, value);
        },
        clear({ value, initValue }){
            if(initValue !== undefined) return '';
            else return undefined;
        },
        placeholder:'text'
    },

    stringArray:{
        multi: true,
        validate({ value }){
            if(isEmptyValue(value)) return true;
            
            let valueType = typeof value;
            if(valueType !== 'string' && !Array.isArray(value)) return false;
            else if(valueType === 'string') value = value.split(/\s*,\s*/).filter(v => !!v);

            // all values must be a string
            let allValuesAreString = value.filter(v => {
                let valueType = typeof v;
                if(valueType === 'string' || valueType === 'number' || valueType === 'boolean') return false;
                else return true;
            }).length === 0;

            return allValuesAreString;
        },
        parse({ value, column, suggestMode }){
            if(isEmptyValue(value)) return value;
            else if(Array.isArray(value)) return untranslateIfNeeded(column, value).map(v => v+'');
            else return untranslateIfNeeded(column, value.split(/\s*,\s*/)).filter(v => suggestMode || !!v);
        },
        render({ value, column }){
            return Array.isArray(value) ? translateIfNeeded(column, value).join(', ') : value;
        },
        clear({ value, initValue }){
            if(Array.isArray(initValue)) return [];
            else return undefined;
        },
        placeholder: 'text1, text2, ...'
    },

    stringArrayUnique:{
        multi: true,
        validate({ value }){
            if(isEmptyValue(value)) return true;
            
            let valueType = typeof value;
            if(valueType !== 'string' && !Array.isArray(value)) return false;
            else if(valueType === 'string') value = value.split(/\s*,\s*/).filter(v => !!v);

            // all values must be a string
            let allValuesAreUnique = true;
            let valuesObj = {};
            let allValuesAreString = value.filter(v => {
                let valueType = typeof v;
                if(valueType === 'string' || valueType === 'number' || valueType === 'boolean') {

                    // all values must be unique
                    if(valuesObj[v]) allValuesAreUnique = false;
                    valuesObj[v] = true;

                    return false;
                }
                else return true;
            }).length === 0;

            return allValuesAreString && allValuesAreUnique;
        },
        parse({ value, column, suggestMode }){
            if(isEmptyValue(value)) return undefined;
            else if(Array.isArray(value)) return untranslateIfNeeded(column, value).map(v => v+'');
            else return untranslateIfNeeded(column, value.split(/\s*,\s*/)).filter(v => suggestMode || !!v);
        },
        render({ value, column }){
            return Array.isArray(value) ? translateIfNeeded(column, value).join(', ') : value;
        },
        clear({ value, initValue }){
            if(Array.isArray(initValue)) return [];
            else return undefined;
        },
        placeholder: 'text1, text2, ...'
    },

    integer:{
        validate({ value }){
            if(isEmptyValue(value)) return true;

            let valueType = typeof value;
            if(valueType === 'string') return (parseInt(value, 10)+'') === value.trim();
            else if(valueType === 'number') return Number.isInteger(value);
        },
        parse({ value }){
            return isEmptyValue(value) ? value : parseInt(value, 10);
        },
        placeholder: '123'
    },

    integerArray:{
        multi: true,
        validate({ value }){
            if(isEmptyValue(value)) return true;
            
            let valueType = typeof value;
            if(valueType !== 'string' && !Array.isArray(value)) return false;
            else if(valueType === 'string') value = value.split(/\s*,\s*/).filter(v => !!v);

            // all values must be a integer like
            let allValuesAreInteger = value.filter(v => {
                let isInteger = false;
                if(typeof v === 'string') isInteger = (parseInt(v, 10)+'') === v;
                else isInteger = Number.isInteger(v);
                return !isInteger;
            }).length === 0;

            return allValuesAreInteger;
        },
        parse({ value, suggestMode }){
            if(isEmptyValue(value)) return value;
            else if(Array.isArray(value)) return value.map(v => parseInt(v, 10));
            else return value.split(/\s*,\s*/).filter(v => suggestMode || !!v).map(v => parseInt(v, 10));
        },
        render({ value }){
            return Array.isArray(value) ? value.join(', ') : value;
        },
        clear({ value, initValue }){
            if(Array.isArray(initValue)) return [];
            else return undefined;
        },
        placeholder: '123, 123, ...'
    },

    integerArrayUnique:{
        multi: true,
        validate({ value }){
            if(isEmptyValue(value)) return true;
            
            let valueType = typeof value;
            if(valueType !== 'string' && !Array.isArray(value)) return false;
            else if(valueType === 'string') value = value.split(/\s*,\s*/).filter(v => !!v);

            // all values must be a integer like
            let allValuesAreUnique = true;
            let valuesObj = {};
            let allValuesAreInteger = value.filter(v => {
                let isInteger = false;
                if(typeof v === 'string') isInteger = (parseInt(v, 10)+'') === v;
                else isInteger = Number.isInteger(v);

                // all values must be unique
                if(valuesObj[v]) allValuesAreUnique = false;
                valuesObj[v] = true;

                return !isInteger;
            }).length === 0;

            return allValuesAreInteger && allValuesAreUnique;
        },
        parse({ value, suggestMode }){
            if(isEmptyValue(value)) return value;
            else if(Array.isArray(value)) return value.map(v => parseInt(v, 10));
            else return value.split(/\s*,\s*/).filter(v => suggestMode || !!v).map(v => parseInt(v, 10));
        },
        render({ value }){
            return Array.isArray(value) ? value.join(', ') : value;
        },
        clear({ value, initValue }){
            if(Array.isArray(initValue)) return [];
            else return undefined;
        },
        placeholder: '123, 123, ...'
    },

    number:{
        validate({ value }){
            if(isEmptyValue(value)) return true;
            
            let valueType = typeof value;
            if(valueType === 'string') {
                value = value.trim();
                return !isNaN(parseNumericString(value));
            }
            else if(valueType === 'number') return true;
        },
        parse({ value }){
            return (isEmptyValue(value)) ? value : parseNumericString(value);
        },
        render({ value }){
            return renderNumericString(value);
        },
        placeholder: '123.45'
    },

    percentage:{
        validate({ value }){
            if(isEmptyValue(value)) return true;

            let valueType = typeof value;
            if(valueType === 'string') {
                value = value.trim();
                return !isNaN(parseNumericString(value));
            }
            else if(valueType === 'number') return true;
        },
        parse({ value }){
            return (isEmptyValue(value)) ? value : parseNumericString(value);
        },
        render({ value }){
            return !isNaN(value) ? (renderNumericString(value, 2) + ' %') : '';
        },
        placeholder: '123.45'
    },

    price:{
        validate({ value }){
            if(isEmptyValue(value)) return true;
            
            let valueType = typeof value;
            if(valueType === 'string') {
                value = value.trim();
                return !isNaN(parseNumericString(value));
            }
            else if(valueType === 'number') return true;
        },
        parse({ value }){
            return (isEmptyValue(value)) ? value : parseNumericString(value);
        },
        render({ value }){
            return !isNaN(value) ? (renderNumericString(value, 2) + ' ' + locals.translate('currency_symbol')) : '';
        },
        placeholder: '123.45'
    },

    numberArray:{
        multi: true,
        validate({ value }){
            if(isEmptyValue(value)) return true;
            
            let valueType = typeof value;
            if(valueType !== 'string' && !Array.isArray(value)) return false;
            else if(valueType === 'string') value = value.split(/\s*,\s+/).filter(v => !!v);

            // all values must be a number like
            let allValuesAreNumber = value.filter(v => {
                let isNumber = false;
                if(typeof v === 'string') isNumber = !isNaN(parseNumericString(v));
                else isNumber = typeof v === 'number';
                return !isNumber;
            }).length === 0;

            return allValuesAreNumber;
        },
        parse({ value, suggestMode }){
            if(isEmptyValue(value)) return value;
            else if(Array.isArray(value)) return value.map(v => parseNumericString(v));
            else return value.split(/\s*,\s+/).filter(v => suggestMode || !!v).map(v => parseNumericString(v));
        },
        render({ value }){
            return Array.isArray(value) ? value.map(v => renderNumericString(v)).join(', ') : value;
        },
        clear({ value, initValue }){
            if(Array.isArray(initValue)) return [];
            else return undefined;
        },
        placeholder: '123.45, 123.45, ...'
    },

    numberArrayUnique:{
        multi: true,
        validate({ value }){
            if(isEmptyValue(value)) return true;
            
            let valueType = typeof value;
            if(valueType !== 'string' && !Array.isArray(value)) return false;
            else if(valueType === 'string') value = value.split(/\s*,\s+/).filter(v => !!v);

            // all values must be a number like
            let allValuesAreUnique = true;
            let valuesObj = {};
            let allValuesAreNumber = value.filter(v => {
                let isNumber = false;
                if(typeof v === 'string') isNumber = !isNaN(parseNumericString(v));
                else isNumber = typeof v === 'number';

                // all values must be unique
                if(valuesObj[v]) allValuesAreUnique = false;
                valuesObj[v] = true;

                return !isNumber;
            }).length === 0;

            return allValuesAreNumber && allValuesAreUnique;
        },
        parse({ value, suggestMode }){
            if(isEmptyValue(value)) return value;
            else if(Array.isArray(value)) return value.map(v => parseNumericString(v));
            else return value.split(/\s*,\s+/).filter(v => suggestMode || !!v).map(v => parseNumericString(v));
        },
        render({ value }){
            return Array.isArray(value) ? value.map(v => renderNumericString(v)).join(', ') : value;
        },
        clear({ value, initValue }){
            if(Array.isArray(initValue)) return [];
            else return undefined;
        },
        placeholder: '123.45, 123.45, ...'
    },

    boolean:{
        validate({ value, column }){
            if(isEmptyValue(value)) return true;

            let valueType = typeof value;
            if(valueType === 'boolean') return true;
            else if(valueType === 'string') {
                value = untranslateIfNeeded(column, value).trim().toLowerCase();
                return value === 'true' || value === 'false' || value === '';
            }
        },
        parse({ value, column }){
            if(isEmptyValue(value)) return value;
            else if(typeof value === 'boolean') return value;
            else {
                value = untranslateIfNeeded(column, value+'').trim().toLowerCase();
                if(value === 'true') return true;
                else if(value === 'false') return false;
                else return value;
            }
        },
        render({ value, column }){
            if(isEmptyValue(value)) return '';
            return translateIfNeeded(column, value+'');
        },
        values:[ 'true', 'false' ],
        // renderer: SpreadSheetRendererBoolean
        placeholder: 'true/false'
    },

    delete:{
        validate({ value }){
            return true; // this is readonly type, it must be valid allways, and parsing any value
        },
        parse({ value, column }){
            if(isEmptyValue(value)) return value;
            else if(untranslateIfNeeded(column, value+'').trim().toLowerCase() === 'true') return true;
            else return undefined;
        },
        render({ value, column }){
            return translateIfNeeded(column, value ? 'true' : 'false');
        },
        renderer: SpreadSheetRendererDelete,
        // readonly({ row }){
        //     return !row.createdDT;
        // },
        editable: false
    },

    // UID:{
    //     validate({ value }){
    //         if(isEmptyValue(value)) return true;
    //         else if(typeof value === 'string') return shortid.isValid(value);
    //         else false;
    //     },
    //     parse({ value }){
    //         if(isEmptyValue(value)) return shortid.generate();
    //         else if(typeof value === 'string') return value;
    //         else return undefined;
    //     },
    //     renderer: SpreadSheetRendererUID,
    //     clear(){
    //         return shortid.generate(); // UID must be allways set, so cleading means setting new UID
    //     }
    //     // readonly({ row }){
    //     //     return !row.createdDT;
    //     // },
    //     // editable: false
    // },

    images:{
        multi: true,
        validate({ value }){
            if(isEmptyValue(value)) return true;
            
            let valueType = typeof value;
            if(valueType !== 'string' && !Array.isArray(value)) return false;
            else if(valueType === 'string') value = value.split(/\s*,\s*/).filter(v => !!v);

            // all values must be a string
            let allValuesAreString = value.filter(v => {
                let valueType = typeof v;
                if(valueType === 'string' || valueType === 'number' || valueType === 'boolean') return false;
                else return true;
            }).length === 0;

            return allValuesAreString;
        },
        parse({ value, suggestMode }){
            let separatorRegexp = /\s*,\s+/;
            if(isEmptyValue(value)) return value;
            else if(Array.isArray(value)) return value.map(v => v+'');
            else return (value+'').split(separatorRegexp).filter(v => suggestMode || !!v);
        },
        render({ value, returnPreviewUrlArray }){
            if(Array.isArray(value)) {
                let urlArray = value.map(imgId => {
                    // item value is url
                    if((imgId+'').match(/^http(s|):\/\//)) {
                        // try parse url and extract image ID
                        let parsedImgId = parseImageIdFromUrl(imgId);
                        if(parsedImgId) return window.location.origin + CONFIG.imagesBasePath + parsedImgId + ( returnPreviewUrlArray ? '/100x100.png' : '.png');
                        else return imgId;
                    }
                    else return window.location.origin + CONFIG.imagesBasePath + imgId + ( returnPreviewUrlArray ? '/100x100.png' : '.png');
                }).filter(v => !!v);

                if(returnPreviewUrlArray) return urlArray;
                else return urlArray.join(' , ');
            }
            else if(returnPreviewUrlArray) return [];
            else return value;
        },
        renderer: SpreadSheetRendererImages,
        clear({ value, initValue }){
            if(Array.isArray(initValue)) return [];
            else return undefined;
        }
    },

    // single image
    image:{
        validate({ value }){
            if(isEmptyValue(value)) return true;
            return typeof value === 'string';
        },
        parse({ value }){
            return (isEmptyValue(value)) ? '' : value+'';
        },
        render({ value, returnPreviewUrlArray }){
            if(value) {
                let urlArray = [value].map(imgId => {
                    // item value is url
                    if((imgId+'').match(/^http(s|):\/\//)) {
                        // try parse url and extract image ID
                        let parsedImgId = parseImageIdFromUrl(imgId);
                        if(parsedImgId) return window.location.origin + CONFIG.imagesBasePath + parsedImgId + ( returnPreviewUrlArray ? '/100x100.png' : '.png');
                        else return imgId;
                    }
                    else return window.location.origin + CONFIG.imagesBasePath + imgId + ( returnPreviewUrlArray ? '/100x100.png' : '.png');
                }).filter(v => !!v);

                if(returnPreviewUrlArray) return urlArray;
                else return urlArray.length > 0 ? urlArray[0] : value;
            }
            else if(returnPreviewUrlArray) return [];
            else return value;
        },
        renderer: SpreadSheetRendererImages,
        clear({ value, initValue }){
            if(initValue !== undefined) return '';
            else return undefined;
        }
    },

    object:{
        validate({ value }){
            if(isEmptyValue(value)) return true;

            if(Object.prototype.toString.call(value) === '[object Object]') return true;
            
            try {
                JSON.parse(value);
                return true;
            }
            catch(err){
                return false;
            }
        },
        parse({ value }){
            if(isEmptyValue(value)) return value;
            if(Object.prototype.toString.call(value) === '[object Object]') return value;

            try {
                return JSON.parse(value || '{}');
            }
            catch(err){
                return {};
            }
        },
        render({ value }){
            if(isEmptyValue(value)) return '';
            return JSON.stringify(value);
        },
        renderer: SpreadSheetRendererObject
    },

    textValueObject:{
        // { text:'some text to display', value:'87' }
        validate({ value }){
            if(isEmptyValue(value) || typeof value === 'string') return true;
            if(Object.prototype.toString.call(value) === '[object Object]') {
                return value.hasOwnProperty('text') && value.hasOwnProperty('value');
            }
        },
        parse({ value }){
            if(isEmptyValue(value)) return value;
            if(Object.prototype.toString.call(value) === '[object Object]') return value;

            try {
                value = JSON.parse(value+'');
                if(Object.prototype.toString.call(value) === '[object Object]'){
                    if(value.hasOwnProperty('text') && value.hasOwnProperty('value')) return value;
                }
            }
            catch(err){}

            return value + '';
        },
        render({ value }){
            if(isEmptyValue(value)) return value;
            else if(Object.prototype.toString.call(value) === '[object Object]') return value.text || '';
            else return value + '';
        },
        placeholder:'type_or_choose'
    },

    array:{
        multi: true,
        validate({ value }){
            if(isEmptyValue(value)) return true;

            if(Array.isArray(value)) return true;
            
            try {
                JSON.parse(value);
                return true;
            }
            catch(err){
                return false;
            }
        },
        parse({ value }){
            if(isEmptyValue(value)) return value;
            if(Array.isArray(value)) return value;

            try {
                return JSON.parse(value || '[]');
            }
            catch(err){
                return [];
            }
        },
        render({ value }){
            if(isEmptyValue(value)) return '';
            return JSON.stringify(value);
        },
        renderer: SpreadSheetRendererObject
    },

    rowDetailsOpener:{
        validate({ value }){
            return true; // this is readonly type, it must be valid allways, and parsing any value
        },
        parse({ value, column }){
            return value;
        },
        renderer: SpreadSheetRendererRowDetails,
        editable: false
    }
};