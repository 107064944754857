<template>
    <inv-object-tab-item :tab="tab" :tabIsLoading="tabIsLoading">
        <template slot="body">
            <div v-if="mapData" style="height:100%" :class="{ 'tab-body-disabled' : tabIsLoading }">
                <inv-map :layer-data="mapData" line-names node-names :resizeNeeded="expanded"></inv-map>
            </div>
        </template>
    </inv-object-tab-item>
</template>

<script>
    import backend from 'obj-fe/app/backend';

    import tabBehaviour from './inv-object-tab-behaviour';
    import InvObjectTabItem from './inv-object-tab-item.vue';
    
    import InvMap from '../inventory-map/inv-map.vue';

    export default {
        mixins:[ tabBehaviour ],
        components:{
            InvObjectTabItem,
            InvMap
        },
        data(){
            return {
                mapData: null,
            };
        },
        computed:{
            dataLoaded(){
                return this.connections && this.objects;
            }
        },
        methods:{
            onFirstTimeExpandLoad(successCb, errorCb){
                backend.objects.layeredMapInfo(
                    this.objectId, 
                    mapData => {
                        this.mapData = mapData;
                        successCb()
                    }, 
                    errorCb
                );
            }
        }
    };

</script>