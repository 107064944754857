var api = require("!../node_modules/style-loader/dist/runtime/injectStylesIntoStyleTag.js");
            var content = require("!!../node_modules/css-loader/dist/cjs.js!../node_modules/vue-loader/lib/loaders/stylePostLoader.js!../node_modules/sass-loader/dist/cjs.js??ref--2-2!../node_modules/vue-loader/lib/index.js??vue-loader-options!./chat-room-messages.vue?vue&type=style&index=0&id=8bff22ce&lang=scss&scoped=true&");

            content = content.__esModule ? content.default : content;

            if (typeof content === 'string') {
              content = [[module.id, content, '']];
            }

var options = {};

options.insert = "head";
options.singleton = false;

var update = api(content, options);

var exported = content.locals ? content.locals : {};



module.exports = exported;