<template>
    <div class="wrapper">
        <div class="d-flex justify-center mb-4">
            <v-btn v-if="noMoreMessages !== true" text tile @click="$emit('load-more')">
                {{ "chat_load_previous_messages" | translate }}
            </v-btn>
            <span v-else class="caption">
                {{ "chat_no_more_messages" | translate }}
            </span>
        </div>

        <div class="entities" ref="entities">
            <template v-for="entity in toEntities(messages)">
                <div :key="entity.id">
                    <template v-if="entity.type === 'DATE'">
                        <chat-room-message-date-delimiter :value="entity.value" />
                    </template>

                    <template v-else-if="entity.type === 'NEW'">
                        <chat-room-message-new-delimiter :value="entity.value" />
                    </template>

                    <template v-else-if="entity.type === 'MESSAGE'">
                        <template v-if="entity.value.senderId === $store.state.user.personId">
                            <chat-room-message-own-message :value="entity.value" />
                        </template>

                        <template v-else>
                            <chat-room-message-foreign-message :value="entity.value" />
                        </template>
                    </template>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import ChatRoomMessageOwnMessage from "./chat-room-messages/chat-room-message-own-message.vue";
import ChatRoomMessageNewDelimiter from "./chat-room-messages/chat-room-message-new-delimiter.vue";
import ChatRoomMessageDateDelimiter from "./chat-room-messages/chat-room-message-date-delimiter.vue";
import ChatRoomMessageForeignMessage from "./chat-room-messages/chat-room-message-foreign-message.vue";

/**
 * TODO
 *
 * Message rendering can be improved. For example displaying a horizontal
 * line which messages are new, which day they were sent. A prototype of
 * how this might work is in the `toEntities` method.
 * - Peter
 */
export default {
    props: ["messages", "lastReadMessageId", "noMoreMessages"],

    components: {
        ChatRoomMessageOwnMessage,
        ChatRoomMessageNewDelimiter,
        ChatRoomMessageDateDelimiter,
        ChatRoomMessageForeignMessage,
    },

    methods: {
        toEntities(messages) {
            const dateExtractor = (message) =>
                message && message.timestamp ? new Date(message.timestamp).toISOString().split("T")[0] : "";

            let hasNew = false;
            const entities = [];

            // if (messages.length > 0) {
            //     entities.push({
            //         type: "DATE",
            //         id: dateExtractor(messages[0]),
            //         value: dateExtractor(messages[0]),
            //     });
            // }

            messages.forEach((message, idx) => {
                const date = dateExtractor(message);
                const lastEntity = entities[entities.length - 1];

                // TODO
                // if (lastEntity.type === "DATE" && lastEntity.value !== date) {
                //     entities.push({
                //         type: "DATE",
                //         id: date,
                //         value: date,
                //     });
                // } else if (message.id > this.lastReadMessageId && !hasNew && idx !== messages.length - 1) {
                //     entities.push({
                //         type: "NEW",
                //         id: "new",
                //         value: undefined,
                //     });
                //     hasNew = true;
                // }

                entities.push({
                    type: "MESSAGE",
                    id: message.id,
                    value: message,
                });
            });

            return entities;
        },
    },
};
</script>

<style lang="scss" scoped>
.wrapper {
    padding: 1rem 2rem;
    background-color: white;
}

.entities > * + * {
    margin-top: 0.5rem;
}
</style>
