<template>
    <v-card flat style="padding:0px;height:100%">
        <inv-map :layers="layers" :layer-data="layerData" full-screen></inv-map>
    </v-card>
</template>

<script>
    import InvMap from './inv-map.vue';

    export default {
        components:{
            InvMap
        },
        data(){
            return {};
        },
        computed:{
            layers(){
                return this.$store.state.INVENTORY_MAP.layers;
            },
            layerData(){
                return this.$store.state.INVENTORY_MAP.layerData;
            },
        },
    };

</script>