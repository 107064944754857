<template>
    <div>
    <v-dialog v-model="isOpened" fullscreen transition="dialog-bottom-transition" persistent>
        <v-card>

            <v-toolbar dark color="primary">
                <v-btn icon dark @click="close">
                    <v-icon>fa-close</v-icon>
                </v-btn>
                <v-toolbar-title>{{(header || 'wizard') | translate}}</v-toolbar-title>

                <v-progress-linear
                    :active="isLoading"
                    :indeterminate="isLoading"
                    bottom
                    absolute
                    color="accent"
                    :data-test="'loading-indicator-' + isLoading"
                ></v-progress-linear>
            </v-toolbar>

            <div style="height: calc(100vh - 120px);">
                <div v-if="!steps.length && isLoading" style="padding-top:30vh" class="text-center">
                    {{ 'loading' | translate }}
                </div>
                <v-container v-else class="fill-height">
                    <v-row class="fill-height">
                        <v-col cols="4" class="fill-height" style="overflow:auto">
                            <v-list three-line>
                                <v-subheader>{{ 'wizard_steps' | translate }}</v-subheader>

                                <template v-for="(step, index) in steps">
                                    <v-divider v-if="index > 0" :key="index"></v-divider>

                                    <v-list-item @click="onStepClick(index)" link :key="step.id" :input-value="isStepActive(index)" :disabled="isStepDisabled(index)">
                                        <v-list-item-avatar>
                                            <v-icon>{{ isStepActive(index) ? 'fa-arrow-right' : (isStepDone(index) ? 'fa-check' : '') }}</v-icon>
                                        </v-list-item-avatar>

                                        <v-list-item-content>
                                            <v-list-item-title>{{step.title}}</v-list-item-title>
                                            <v-list-item-subtitle>{{step.description}}</v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </template>

                            </v-list>
                        </v-col>
                        <v-col cols="8" class="fill-height" style="overflow:auto">
                            <v-card flat v-if="objectId">
                                <v-card-text>
                                    <inv-object :id="objectId" disable-buttons @is-dirty="isDirty = $event" @tab-data-changes="tabChanges = $event" tabs-height="300px"></inv-object>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-container>
            </div>

            <v-card-actions>
                <v-container class="py-0">
                    <v-row class="justify-end px-4">
                        <v-col class="text-end py-0">
                            <v-btn text @click="close" color="light">
                                <v-icon>fal fa-times</v-icon> {{'cancel' | translate}}
                            </v-btn>
                            <v-btn v-if="hasBackButton" depressed @click="back" color="light">
                                <v-icon>fal fa-arrow-left</v-icon> {{'back' | translate}}
                            </v-btn>
                            <v-btn v-if="hasNextButton && !hasFinishButton" depressed @click="next" color="success">
                                {{'next' | translate}} <v-icon>fal fa-arrow-right</v-icon> 
                            </v-btn>
                            <v-btn v-if="hasFinishButton" depressed @click="next" color="success">
                                {{'finish' | translate}}
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-actions>
        </v-card>
    </v-dialog>

    <v-dialog   v-model="confirmCloseOpened" 
                persistent 
                width="500px"
                content-class="small-dialog">
        <v-card data-test="close-confirm-modal">
            <v-card-title><h2>{{ 'you_have_unsaved_changes' | translate}}</h2></v-card-title>
            <v-card-text>{{'do_you_want_to_close_without_save' | translate}}</v-card-text>
            <v-card-actions>
                <v-spacer/>
                <v-btn 
                    text
                    color="error"
                    @click="confirmDialogAction(true)" 
                >
                    {{'close_without_saving' | translate}}
                </v-btn>
                <v-btn 
                    outlined 
                    color="green"
                    @click="confirmDialogAction(false)" 
                >
                    {{'stay_editing' | translate}}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
    </div>
</template>

<style scoped>
    .dialog-bottom-transition-enter-active,
    .dialog-bottom-transition-leave-active {
        transition: transform .2s ease-in-out;
    }

    >>> .v-list-item {
        min-height: 60px;
    }
</style>

<script>
    import loading from 'obj-fe/services/loading';
    import backend from 'obj-fe/app/backend';
    import notify from 'obj-fe/services/notifications';
    import wizardService from './service';

    export default {
        data(){
            return {
                isOpened: false,
                header: '',
                isLoading: true,
                steps:[],
                activeStepId: null,
                objectId: null,
                run: {},
                isDirty: false,
                tabChanges: {},

                hasBackButton: false,
                hasNextButton: false,
                hasFinishButton: false,
                confirmCloseOpened: false,
            };
        },
        computed:{
            object(){
                return this.$store.state.INVENTORY.objectsById[ this.objectId ];
            }
        },
        methods:{
            getActiveStepIndex(){
                return this.steps.findIndex(step => step.id === this.activeStepId);
            },
            isStepActive(index){
                return this.activeStepId === this.steps[index].id;
            },
            isStepDisabled(index){
                let activeStepIndex = this.getActiveStepIndex();
                return index > activeStepIndex || index < activeStepIndex - (this.hasBackButton ? 1 : 0);
            },
            isStepDone(index){
                let activeStepIndex = this.getActiveStepIndex();
                return index < activeStepIndex;
            },
            onStepClick(index){
                if(this.hasBackButton && this.getActiveStepIndex() - 1 === index) {
                    this.back();
                }
            },
            next(){
                let detailFields = [];
                this.object.fieldsGrouped.forEach(fieldGroup => {
                    fieldGroup.fields.forEach(field => {
                        if(field.hasOwnProperty('origValue')) detailFields.push({ id:field.id, attribute:field.attribute, value:field.value });
                    });
                });

                let reportFields = [];
                let deletedRows = [];
                for(let key in this.tabChanges){
                    let changes = this.tabChanges[key] || {};
                    
                    if(changes.removedRows?.length > 0) {
                        deletedRows = deletedRows.concat(changes.removedRows);
                    }
                    if(changes.fields?.length > 0) {
                        reportFields = reportFields.concat(changes.fields.map(field => ({ id:field.id, attribute:field.attribute, value:field.value })));
                    }
                }

                let updateRequests = [];
                if(detailFields.length > 0) {
                    updateRequests.push({ '@type': 'detail', updatedFields:detailFields });
                }
                if(reportFields.length > 0 || deletedRows.length > 0) {
                    updateRequests.push({ '@type': 'report', updatedFields:reportFields, deletedRows });
                }

                let updateData = {
                    updateRequests
                };

                backend.wizard.next(this.run.runId, updateData, data => {
                    this.handleResponseData(data);
                });
            },
            back(){
                backend.wizard.back(this.run.runId, data => {
                    this.handleResponseData(data);
                });
            },
            close(){
                if(this.getActiveStepIndex() === 0 && !this.isDirty) this.confirmDialogAction(true);
                else this.confirmCloseOpened = true;
            },
            confirmDialogAction(leave){
                this.confirmCloseOpened = false;
                if(leave) wizardService.end();
            },
            reset(){
                this.header = '';
                this.steps = [];
                this.objectId = null;
                this.isDirty = false;
            },
            handleResponseData(data){
                this.wizardData = data; // keep cached for response
                this.run = data?.runState;
                this.isDirty = false;

                this.header = data.description.title;
                this.steps = data.description.pages;

                let stepState = this.run?.currentPageState;
                this.hasFinishButton = stepState?.canFinish;
                this.hasBackButton = stepState?.canGoBack;
                this.hasNextButton = stepState?.canGoNext;
                this.activeStepId = stepState.pageId;

                let object = stepState?.pageUI;

                if(object){
                    object.id = object.id || object.globalId || -new Date().getTime();
                    this.objectId = object.id;
                    
                    this.$store.dispatch('INVENTORY/REPLACE_OBJECT', object);
                }

                // transition messages
                let transResult = this.run.transitionResult;

                if(transResult) {
                    if(transResult.transitionType === 'SUCCESS_TRANSITION'){
                        notify.success('saved', transResult.message);
                    }
                    else if(transResult.transitionType === 'VALIDATION_ERROR'){
                        notify.error('validation_failed', transResult.message);
                    }
                    else if(transResult.transitionType === 'FINISHED'){
                        notify.success('finished', transResult.message);
                        wizardService.end();
                    }
                }
            }
        },
        mounted(){
            loading.subscribe(state => this.isLoading = state.active);

            wizardService.handleStart = (wizardId) => {
                this.isOpened = true;
                
                backend.wizard.start(wizardId, data => {
                    this.handleResponseData(data);
                });
            };

            wizardService.handleEnd = () => {
                this.isOpened = false;
                this.reset();
            };
        }
    }
</script>