<template>
    <div>
        <obj-table v-if="isLoaded" :items="rows">
            <template slot="headers">
                <th v-for="(col, i) in columns" :key="i">
                    <span class="report-header">{{col.columnLabel}}</span>
                </th>
			</template>

            <template slot="row" slot-scope="row">
                <td v-for="field of row.value.fields"
                    :key="field.cellKey"
                    class="report-table-cell"
                >
                    <v-row
                        no-gutters
                        align="baseline"
                    >
                        <v-col>
                            <slot :name="field.columnName" :field="field" :row="row">
                                <obj-generic-field
                                    :field="field"
                                    :value="handleGenericFieldValue(field.editor, field.value)"
                                />
                            </slot>
                        </v-col>
                    </v-row>
                </td>
            </template>
        </obj-table>

        <span
            v-if="isLoaded && !hasAnyRows"
            class="d-flex justify-center caption"
        >
            {{ "no_data_found" | translate }}
        </span>
    </div>
</template>

<script>
    import backend from 'obj-fe/app/backend';
    import tabBehaviour from '../object-tabs/inv-object-tab-behaviour';
    import boolean from 'obj-fe/utils/boolean';
    import { rowsArrayToObj } from 'obj-fe/modules/reports/store'

    export default {
        mixins:[ tabBehaviour ],
        data(){
            return {
                reportState: {
                    description: null,
                    rows: null,
                    rowIds: null,
                },
            };
        },
        computed:{
            reportId(){
                return this.tab.parameters.dataSource;
            },
            tabObjectId() {
                return this.tab.parameters.globalId || this.objectId;
            },
            hasAnyRows(){
                return this.rows.length > 0;
            },
            isLoaded(){
                return this.reportState.rowIds;
            },
            rows(){
                return (this.reportState.rowIds || []).map(id => this.reportState.rows[id]);
            },
            description(){
                return this.reportState.description;
            },
            columns(){
                return !this.description ? [] : this.description.columns || [];
            },
        },
        methods:{
            onFirstTimeExpandLoad(){
                backend.objects.reportDescription(
                    {
                        objectId: this.tabObjectId,
                        reportId: this.reportId
                    },
                    (description) => this.setDescriptionAndLoadData(description)
                );
            },
            setDescriptionAndLoadData(description){
                const { colDesc, ...rest } = description;
                this.reportState.description = { columns: colDesc, ...rest };

                backend.objects.reportData(
                    {
                        objectId: this.tabObjectId,
                        reportId: this.reportId
                    },
                    {
                        filters: [],
                        order: [],
                        pageNumberFromOne: 1,
                        pageSize: 10,
                        reportName: this.reportId
                    },
                    (data) => {
                        let rowIds = [];
                        let rowsObj = rowsArrayToObj(this.description, data, rowIds);

                        this.reportState.rows = rowsObj;
                        this.reportState.rowIds = rowIds;
                    },
                    () => console.error(data),
                );
            },
            handleGenericFieldValue(editor, value) {
                return editor === 'BOOLEAN' ? boolean.booleanToString(value) : value;
            },
        },
    };
</script>

<style scoped>
.report-header{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
>>> .obj-table-footer {
    display: none;
    height: 0;
}
/* display only first column - BEGIN */
>>> .obj-table thead > tr:nth-child(2) {
    display: none;
}
hr{
    margin-left: 8px;
}
>>> th:not(:first-child) {
    display: none;
}
>>> td:not(:first-child) {
    display: none;
}
>>> th:first-child {
    border-right: none;
}
>>> td:first-child {
    border-right: none;
}
/* display only first column - END */
</style>
