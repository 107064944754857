import store from 'obj-fe/app/store';
import backend from 'obj-fe/app/backend';
import router from 'obj-fe/app/router';
import object from 'obj-fe/utils/object';
import locals from 'obj-fe/services/localisation';
import notify from 'obj-fe/services/notifications';
import Vue from 'vue';

store.registerModule('IMPORT_JOBS', {
    namespaced: true,
    state:{
        jobsById: {},
        jobIds: [],

        query: {
            page: 1,
            limit: 20
        },

        pagination: {
            next: false,
            prev: false,
            page: 1,
            pages: 1,
            limit: 10,
            count: 0
        },

        selectedJobId: null
    },
    actions:{
        ROUTE_CHANGE_START:{
            root: true,
            handler(ctx, route){
                if(route.name === 'import-jobs' || route.name === 'import-jobs-selected') {
                    ctx.dispatch('LOAD_JOBS', route.params.jobId);
                }
                    
            }
        },
        LOAD_JOBS(ctx, jobId){
            backend.importJobs.all(ctx.state.query, data => {
                ctx.commit('SET_PAGINATION', data.pagination);
                ctx.commit('SET_JOBS', data);
                if(jobId) ctx.commit('DISPLAY_JOB', jobId);
            });
        },
        UPDATE_QUERY(ctx, query){
            ctx.commit('UPDATE_QUERY', query);
            ctx.dispatch('LOAD_JOBS');
        },
        LOAD_JOB_CHANGES(ctx, jobId){
            let job = ctx.state.jobsById[jobId];
            let query = job.changes ? job.changes.query : { page:1, limit:20 };

            backend.importJobs.changes({ id:jobId, ...query }, changes => {
                changes.query = query;
                ctx.commit('SET_JOB_CHANGES', { jobId, changes });
            });
        },
        UPDATE_JOB_CHANGES_QUERY(ctx, { jobId, page, limit }){
            ctx.commit('UPDATE_JOB_CHANGES_QUERY', { jobId, page, limit });
            ctx.dispatch('LOAD_JOB_CHANGES', jobId);
        },
        REMOVE_JOB(ctx, jobId){
            backend.importJobs.remove(jobId, data => {
                ctx.commit('REMOVE_JOB', jobId);
                notify.success('import_job_removed');
            });
        }
    },
    mutations:{
        SET_PAGINATION(state, pagination){
            state.pagination = pagination;
        },
        SET_JOBS(state, jobs){
            let jobIds = [], jobsById = {};

            jobs.forEach(obj => {
                obj.changes = null;
                jobIds.push(obj.id);
                jobsById[ obj.id ] = obj;
            });

            state.jobIds = jobIds;
            state.jobsById = jobsById;
        },
        REMOVE_JOB(state, jobId){
            state.jobIds = state.jobIds.filter(id => id !== jobId);
            Vue.delete(state.jobsById, jobId);
        },
        SET_JOB_CHANGES(state, { jobId, changes }){
            let job = state.jobsById[ jobId ] || {};
            job.changes = {
                query: changes.query,
                headers: changes.header,
                pagination: changes.pagination,
                list: changes.slice()
            };
        },
        UPDATE_JOB_CHANGES_QUERY(state, { jobId, page, limit }){
            let job = state.jobsById[ jobId ] || {};
            if(page > 0) job.changes.query.page = page;
            if(limit > 0) job.changes.query.limit = limit;
        },
        EXTEND_JOB(state, { jobId, data }){
            let currJob = state.jobsById[ jobId ] || {};
            Vue.set(state.jobsById, jobId, { ...currJob, ...data });
        },
        UPDATE_QUERY(state, query){
            for(let key in query) {
                state.query[key] = query[key];
            }
        },
        DISPLAY_JOB(state, jobId){
            state.selectedJobId = jobId;
        },
    }
});