<template>
	<v-card 
		tile 
		outline
		class="widget-card"
		:data-test="'widget-' + widgetTestId"
	>
		<v-card-title
			data-test="widget-title"
		>
			<span style="display: flex; align-items: baseline">
				<v-icon 
					style="margin-right: 15px;"
					color="accent"	
				>
					{{isLastVisited ? 'fal fa-history' : 'fal fa-star'}}
				</v-icon>

				<strong>
					{{displayString}}
				</strong>
			</span>
			
		</v-card-title>

		<v-card-text>

			<v-data-table
				:items="items"
				:headers="headers"
				:no-data-text="'No items found. Browse around reports and objects.'"
				height="300"
				hide-default-header
			>
				<template v-slot:item="{ item }">
					<tr>
						<!-- entity name  -->
						<td>
							<a 
								v-if="item.type === 'URL'" 
								:href="item.link"
							>
								{{ item.name }}
							</a>

							<router-link
								v-else
								:to="item.link + item.id"
							>
								{{ item.name }}
							</router-link>
						</td>

						<!-- entity actions -->
						<td style="text-align: right">
							<a 
								v-if="item.type === 'URL'" 
								:href="item.link"
								target="_blank"
							>
								<v-tooltip top>
									<template v-slot:activator="{on}">
										<v-icon v-on="on">fal fa-external-link</v-icon>
									</template>
									<span>
										{{"open_in_a_new_tab" | translate}}
									</span>
								</v-tooltip>
							</a>

							<router-link
								v-else
								target="_blank"
								:to="item.link + item.id"
							>
								<v-tooltip top>
									<template v-slot:activator="{on}">
										<v-icon v-on="on">fal fa-external-link</v-icon>
									</template>
									<span>
										{{"open_in_a_new_tab" | translate}}
									</span>
								</v-tooltip>
							</router-link>
						</td>
					</tr>


				</template>
			</v-data-table>
			
		</v-card-text>

	</v-card>
</template>

<script>
import backend from 'obj-fe/app/backend'

export default {
	props:{
		sourceUrl: String,
		displayString: String
	},
	data(){
		return {
			items: [],
			headers: [
				{
					text: 'Object name',
					value: 'name',
					sortable: true
				},
				{ 
					text: '', 
					value: 'actions',
					align: 'right'
				},
			]
		}
	},
	computed:{
		isLastVisited(){
			return ['Last visited entities', 'Last visited reports'].includes(this.displayString);
		},
		widgetTestId(){
			return this.displayString.replaceAll(' ', '_').toLowerCase();
		}
	},
	methods:{
		loadItems(){
			if(!this.sourceUrl) return;
		
			backend.widgets.sourceUrlGetMethod(
				{ customRestUrl: this.sourceUrl },
				data => this.fillAvailableItems(data),
				error => console.error(error)
			)
		},
		fillAvailableItems(data){
			this.items = [];
			let items = [];

			if(data.widgetType === 'WIDGET_ENTITIES' || data.widgetType === 'WIDGET_REPORTS') items = data.items
				.map(e => ({
					name: e.displayString,
					id: e.globalId || e.sourceId,
					link: data.widgetType === 'WIDGET_ENTITIES' ? '/objects/' : '/reports/' ,
					type: data.widgetType === 'WIDGET_ENTITIES' ? 'ENTITY' : 'REPORT'
				}))

			if(data.widgetType === 'WIDGET_URLS') items = data.items
				.map(e => ({
					name: e.displayString,
					link: e.url ,
					type: 'URL'
				}))

			this.items = items;
		}
	},
	mounted(){
		this.loadItems()
	}
};
</script>
