export default {
    methods:{
        hasCellErrors(){
            let rowsLen = this.rows.length;
            for(let i=0;i<rowsLen;i++){
                if(this.getRow(i)._state.cellErrors && Object.keys(this.getRow(i)._state.cellErrors).length > 0) return true;
            }
            return false;
        },
        getCellErrors(rowIndex, colIndex){
            this.getRow(rowIndex)._state.cellErrors[colIndex];
        },
        addCellError(rowIndex, colIndex, errMessage){
            let row = this.getRow(rowIndex);
            if(!row) return;

            let cellErrorsInRow = row._state.cellErrors || {};
            cellErrorsInRow[colIndex] = cellErrorsInRow[colIndex] || [];
            if(cellErrorsInRow[colIndex].indexOf(errMessage) === -1) cellErrorsInRow[colIndex].push(errMessage);

            row._state.cellErrors = { ...cellErrorsInRow };
        },
        removeCellError(rowIndex, colIndex, errMessage){
            let row = this.getRow(rowIndex);
            if(!row) return;

            let cellErrorsInRow = row._state.cellErrors || {};
            if(!cellErrorsInRow || !cellErrorsInRow[colIndex]) return;

            let msgIndex = cellErrorsInRow[colIndex].indexOf(errMessage);
            if(msgIndex > -1) cellErrorsInRow[colIndex].splice(msgIndex, 1);

            if(cellErrorsInRow[colIndex].length === 0){
                delete cellErrorsInRow[colIndex];
                if(Object.keys(cellErrorsInRow).length === 0) {
                    cellErrorsInRow = null;
                }
            }

            row._state.cellErrors = { ...cellErrorsInRow };
        },
        removeCellErrors(rowIndex, colIndex){
            let row = this.getRow(rowIndex);

            let cellErrorsInRow = row._state.cellErrors;
            if(!cellErrorsInRow || !cellErrorsInRow[colIndex]) return;

            delete cellErrorsInRow[colIndex];
            if(Object.keys(cellErrorsInRow).length === 0) {
                cellErrorsInRow = null;
            }

            row._state.cellErrors = { ...cellErrorsInRow };
        },
        clearAllCellErrors(){
            this.rows.forEach(row => {
                if(row._state.cellErrors) row._state.cellErrors = null;
            });
        },
        removeAllCellErrorsInRow(rowIndex){
            let row = this.getRow(rowIndex);
            if(!row) return;
            row._state.cellErrors = null;
        },
        setRowErrors(rowErrors = {}){
            this.rows.forEach((row, rowIndex) => {
                if(rowErrors[rowIndex]) row._state.rowErrors = rowErrors[rowIndex];
                else if(row._state.rowErrors) row._state.rowErrors = null;
            });
        }
    }
};