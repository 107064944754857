import {
    Lexer,
    CstParser,
    EMPTY_ALT,
    createSyntaxDiagramsCode
} from 'chevrotain';
import {
    createInterpreter
} from './emdsl-interpreter.js';
import {
    createTokenList,
    createToken,
    tokens
} from './emdsl-tokens.js';

import DslProcessor from './emdsl-processor.js';
import DslSuggester from './emdsl-suggester.js';


class EmDslParser extends CstParser {
    constructor() {
        super(createTokenList(), {
            // by default the error recovery flag is **false**
            // use recoveryEnabled flag in the IParserConfig object to enable enable it.
            recoveryEnabled: true,
            dynamicTokensEnabled: true
        });

        const $ = this;

        $.RULE('rootExpression', () => {
            $.SUBRULE($.entitiesDefinitions, { LABEL: 'entitiesDefinitions'});
        });

        $.RULE('entitiesDefinitions', () => {
            $.MANY(() => $.SUBRULE1($.entity, { LABEL: 'entity'}));
        });

        $.RULE('entity', () => {
            $.OPTION(() => {
                $.SUBRULE1($.entityType, { LABEL: 'entityType'});
            });

            $.SUBRULE2($.entityIdentifier, { LABEL: 'entityIdentifier' });
            $.CONSUME2(tokens.CurlyBracketLeft, { LABEL: 'curlyBracket' });
            $.SUBRULE2($.entityContent, { LABEL: 'entityContent'});
            $.CONSUME3(tokens.CurlyBracketRight, { LABEL: 'curlyBracket' });
        });

        $.RULE('entityType', () => {
            $.CONSUME(tokens.EntityTypeAbstract, { LABEL: 'entityType' });
        });

        $.RULE('entityIdentifier', ()=>{
            $.SUBRULE($.identifierValue, { LABEL: 'entityIdentifier'});
        });

        $.RULE('entityContent', () => {
            $.MANY(() => {
                $.OR([
                    { ALT: () => $.SUBRULE($.relation, { LABEL: 'relation' }) },
                    { ALT: () => $.SUBRULE($.attribute, { LABEL: 'attribute' }) },
                    // { ALT: () => EMPTY_ALT } - not needed, because comma is optional now
                ]);

                $.OPTION(() => {
                    $.CONSUME(tokens.Comma);
                });
            });

            // TODO: decide MANY_SEP or MANY will be better
            // $.MANY_SEP({
            //     SEP: tokens.Comma,
            //     DEF: () => {
            //         $.OR([
            //             { ALT: () => $.SUBRULE($.relation, { LABEL: 'relation' }) },
            //             { ALT: () => $.SUBRULE($.attribute, { LABEL: 'attribute' }) },
            //             { ALT: () => EMPTY_ALT }
            //         ]);
            //     }
            // });
        });
        
        $.RULE('attributes', () => {
            $.MANY(() => {
                $.OR([
                    { ALT: () => $.SUBRULE($.attribute, { LABEL: 'attribute' }) },
                    // { ALT: () => EMPTY_ALT } - not needed, because comma is optional now
                ])

                $.OPTION(() => {
                    $.CONSUME(tokens.Comma);
                });
            });
        });

        $.RULE('attribute', () => {
            $.SUBRULE($.attributeName, { LABEL: 'attributeName' });
            $.CONSUME(tokens.Equal, { LABEL: 'equal' });
            $.SUBRULE($.attributeValue, { LABEL: 'attributeValue' });
        });

        $.RULE('attributeName', () => {
            $.OR([
                { ALT: () => $.CONSUME(tokens.AttributeNameAbstract, { LABEL: 'attributeName' }) },

                // default fallback due to autocomplete, because if attribute name or relation name is not complete, parser does not know what it may be
                { ALT: () => $.CONSUME(tokens.Identifier, { LABEL: 'attributeName' }) }
            ]);
        });

        $.RULE('relation', () => {
            $.CONSUME(tokens.RelationNameAbstract, { LABEL:'relationType' })
            $.OPTION({
                DEF: ()=> {
                    $.CONSUME(tokens.CurlyBracketLeft, { LABEL: 'curlyBracket' });
                    $.SUBRULE($.attributes, {LABEL: 'attributes'});
                    $.CONSUME(tokens.CurlyBracketRight, { LABEL: 'curlyBracket' });
                }
            });
            $.CONSUME(tokens.Arrow, { LABEL: 'arrow' });
            $.SUBRULE($.relationTarget, { LABEL: 'relationTarget' });
        });

        $.RULE('relationTarget', () => {
            $.OR([
                {
                    GATE: () => $.LA(3).tokenType === tokens.CurlyBracketLeft,
                    ALT: () => $.SUBRULE2($.entity, { LABEL: 'inlineEntity' })
                },
                {
                    ALT: () => $.SUBRULE2($.identifierValue, { LABEL: 'relationTo' })
                },
                {
                    ALT: () => $.SUBRULE2($.multipleRelationsTo, { LABEL: 'multipleRelationsTo' })
                }
            ]);
        });

        $.RULE('multipleRelationsTo', ()=>{
            $.CONSUME(tokens.SquareBracketLeft, { LABEL: 'squareBracket' });
            
            $.MANY(() => {
                $.OR([
                    {
                        GATE: () => $.LA(3).tokenType === tokens.CurlyBracketLeft,
                        ALT: () => $.SUBRULE2($.entity, { LABEL: 'inlineEntity' })
                    },
                    { ALT: () => $.SUBRULE2($.identifierValue, { LABEL: 'relationTo' }) },
                    // { ALT: () => EMPTY_ALT } - not needed, because comma is optional now
                ]);

                $.OPTION(() => {
                    $.CONSUME(tokens.Comma);
                });
            });

            // TODO: decide MANY_SEP or MANY will be better
            // $.MANY_SEP({
            //     SEP: tokens.Comma,
            //     DEF: () => {
            //         $.OR([
            //             {
            //                 GATE: () => $.LA(3).tokenType === tokens.CurlyBracketLeft,
            //                 ALT: () => $.SUBRULE2($.entity, { LABEL: 'inlineEntity' })
            //             },
            //             { ALT: () => $.SUBRULE2($.identifierValue, { LABEL: 'relationTo' }) },
            //             { ALT: () => EMPTY_ALT }
            //         ])
            //     }
            // });

            $.CONSUME(tokens.SquareBracketRight, { LABEL: 'squareBracket' });
        });
        
        $.RULE('identifierValue', () => {
            $.OR([{
                ALT: ()=> $.CONSUME1(tokens.Identifier)
            },{
                ALT: ()=> $.CONSUME2(tokens.StringQuoted)
            }]);
        });

        $.RULE('attributeValue', () => {
            $.OR([{
                ALT: () => $.CONSUME(tokens.False, { LABEL: 'value' })
            },{ 
                ALT: () => $.CONSUME(tokens.True, { LABEL: 'value' })
            },{
                ALT: () => $.CONSUME(tokens.Identifier, { LABEL: 'value' })
            },{
                ALT: () => $.CONSUME(tokens.Number, { LABEL: 'value' })
            },{
                ALT: () => $.CONSUME(tokens.StringQuoted, { LABEL: 'value' })
            }]);
        });

        this.performSelfAnalysis();
    }
}

function repairTokenColumnsPositions(lexResult, text){

    let lineStartOffsets = { '0':0 };
    let lines = text.split('\n');
    lines.forEach((line, index) => {
        let prevLine = lines[ index-1 ];
        if(index > 0) lineStartOffsets[ index ] = lineStartOffsets[ index - 1 ] + prevLine.length + 1;
    });

    lexResult.tokens = lexResult.tokens.map(token => {
        token.startColumn = token.startOffset - lineStartOffsets[ token.startLine - 1 ] + 1;
        token.endColumn = token.endOffset - lineStartOffsets[ token.endLine - 1 ] + 1;
        return token;
    });

    return lexResult;
}

function parse(text) {
    const parser = this;

    // 1. Tokenize the input.
    // need to repairTokenPosColumns, because in fault tolerant mode, columns are moved and are not in sync with source text
    const lexResult = repairTokenColumnsPositions(this.emLexer.tokenize(text), text);
    const emLexer = this.emLexer;

    // 2. Parse the Tokens vector.
    const emDslParser = this.emDslParser;
    emDslParser.input = lexResult.tokens;
    const cst = emDslParser.rootExpression();

    // 3. Perform semantics using a CstVisitor.
    // Note that separation of concerns between the syntactic analysis (parsing) and the semantics.
    const rootTokenContext = createInterpreter(this.BaseCstVisitor).visit(cst);

    const dslDefinition = this.dslDefinition;
    const processedResult = new DslProcessor(dslDefinition).process(rootTokenContext);

    this.text = text;

    this.result = {
        getNextTokenSuggestions(line, column, cb){
            return new DslSuggester(emDslParser, emLexer, processedResult.rootTokenContext, emDslParser.errors, dslDefinition, {
                entitySuggestion: parser.suggestEntityName,
                attributeValueSuggestion: parser.suggestAttributeValue
            }).suggest(line, column, cb);
        },

        tokens: lexResult.tokens,
        lexErrors: lexResult.errors,
        parseErrors: emDslParser.errors.concat(processedResult.errors || []), // append interpreter errors
        value: processedResult,
        // aliases: interpreter.aliases,
        dslDefinition: {
            entityTypes: this.dslDefinition.nodes.map(node => node.name),
            entitiesDefinitions: this.dslDefinition.nodes
        },
        isValid: lexResult.errors.length === 0 && emDslParser.errors.length === 0,
        validated: false,
        validate: validateResources.bind(this),

        validQueue: [],
        onValidated(cb) {
            if (this.validated) cb();
            else this.validQueue.push(cb);
        },
        validationFinished() {
            this.isValid = this.lexErrors.length === 0 && this.parseErrors.filter(e => !e.isWarning).length === 0;
            this.validated = true;
            this.validQueue.forEach(cb => cb());
            this.validQueue = [];
        }
    };

    // trigger aditional async validations
    this.result.validate();

    return this.result;
}

function validateResources(cb) {
    let resourcesToValidate = [];

    let parser = this;

    function done() {
        parser.result.validationFinished();
        if (cb) cb();
    }

    if (!parser.validateResources) return done();

    parser.validateResources(resourcesToValidate.map(e => e.data), result => {

        result.forEach((err, index) => {
            if (err) {
                let data = resourcesToValidate[index];
                err.token = data.data.token;
                this.result.parseErrors.push(err);
            }
        });

        done();
    });
}

// helper for generating dsl syntax diagram html
// function saveSyntaxDiagramAsHTML(){
//     const html = createSyntaxDiagramsCode( new EmDslParser(createTokenList()).getSerializedGastProductions());
//     const tempLink = document.createElement('a');
//     const taBlob = new Blob([html], {type: 'text/html'});
//     tempLink.setAttribute('href', URL.createObjectURL(taBlob));
//     tempLink.setAttribute('download', 'syntax-diagram.html');
//     tempLink.click();
    
//     URL.revokeObjectURL(tempLink.href);
// }
// saveSyntaxDiagramAsHTML();

export default function Parser(opts = {}) {

    this.dslDefinition = opts.dslDefinition;
    this.parse = parse;
    this.getNextTokenSuggestions = function(line, column, cb){ 
        return this.result.getNextTokenSuggestions(line, column, cb);
    };

    this.validateResources = opts.validateResources;
    this.suggestEntityName = opts.suggestEntityName;
    this.suggestAttributeValue = opts.suggestAttributeValue;
    
    const emdslNodes = (this.dslDefinition || {}).nodes || [];
    const entityTypeNamesObj = {};
    const relationNamesObj = {};
    const attributeNamesObj = {};

    emdslNodes.forEach(node => {
        entityTypeNamesObj[node.name] = true;
        (node.attributes || []).forEach(attribute => attributeNamesObj[attribute.name] = true);
        (node.relations || []).forEach(relation => {
            relationNamesObj[relation.name] = true;
            (relation.attributes || []).forEach(attribute => attributeNamesObj[attribute.name] = true);
        });
    });

    const tokens = createTokenList(Object.keys(entityTypeNamesObj), Object.keys(relationNamesObj), Object.keys(attributeNamesObj));

    this.emDslParser = new EmDslParser(tokens);
    this.BaseCstVisitor = this.emDslParser.getBaseCstVisitorConstructor();
    this.emLexer = new Lexer(tokens);
}