<template>
    <v-card flat style="padding:0px;height:100%">
        <inv-gis-map 
            :layers="layers" 
            full-screen
            :computeCenter="false"
        />
    </v-card>
</template>

<script>
    import InvGisMap from './inv-gis-map.vue';

    export default {
        components:{
            InvGisMap
        },
        data(){
            return {};
        },
        computed:{
            layers(){
                return this.$store.state.INVENTORY_GIS_MAP.layers;
            }
        },
    };

</script>