var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "thumbViewClass",
      on: { click: _vm.updateMainViewPan, mousemove: _vm.updateMainViewPan }
    },
    [
      _c("rect", {
        staticClass: "scope",
        attrs: { x: _vm.x, y: _vm.y, width: _vm.width, height: _vm.height }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }