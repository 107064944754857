<template>
    <div class="editor-container" ref="container" :class="{ editable: editable }">
        <editor-menu-bar :editor="editor" v-show="editable">
            <div class="menubar" slot-scope="{ commands, isActive, getMarkAttrs }">
                <!-- <div v-if="!focused" class="menubar-backdrop"></div> -->

                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn text icon v-on="on" :class="{ active: isActive.bold() }" @click="commands.bold">
                            <v-icon>format_bold</v-icon>
                        </v-btn>
                    </template>
                    <span>{{ "bold" | translate }}</span>
                </v-tooltip>

                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn text icon v-on="on" :class="{ active: isActive.italic() }" @click="commands.italic">
                            <v-icon>format_italic</v-icon>
                        </v-btn>
                    </template>
                    <span>{{ "italic" | translate }}</span>
                </v-tooltip>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn
                            text
                            icon
                            v-on="on"
                            :class="{ active: isActive.underline() }"
                            @click="commands.underline"
                        >
                            <v-icon>format_underlined</v-icon>
                        </v-btn>
                    </template>
                    <span>{{ "underline" | translate }}</span>
                </v-tooltip>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn text icon v-on="on" :class="{ active: isActive.strike() }" @click="commands.strike">
                            <v-icon>format_strikethrough</v-icon>
                        </v-btn>
                    </template>
                    <span>{{ "strikethrough" | translate }}</span>
                </v-tooltip>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn
                            text
                            icon
                            v-on="on"
                            :class="{ active: isActive.paragraph() }"
                            @click="commands.paragraph"
                        >
                            <v-icon>format_textdirection_l_to_r</v-icon>
                        </v-btn>
                    </template>
                    <span>{{ "paragraph" | translate }}</span>
                </v-tooltip>

                <v-tooltip bottom v-for="n in 6" :key="n">
                    <template v-slot:activator="{ on }">
                        <v-btn
                            text
                            icon
                            v-on="on"
                            :class="{ active: isActive.heading({ level: n }) }"
                            @click="commands.heading({ level: n })"
                        >
                            H{{n}}
                        </v-btn>
                    </template>
                    <span>{{ `heading_${n}` | translate }}</span>
                </v-tooltip>

                <!-- <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn  text icon
                                v-on="on"
                           :class="{ 'active': isActive.heading({ level: 6 }) }"
                           @click="commands.heading({ level: 6 })">
                        H6
                    </v-btn>
                    </template>
                    <span>{{ 'heading_6' | translate }}</span>
                </v-tooltip> -->

                <v-menu
                    content-class="button-menu"
                    offset-y
                    v-model="linkMenu.opened"
                    :open-on-click="false"
                    :nudge-width="200"
                >
                    <template v-slot:activator="{ on }">
                        <template slot="activator" v-on="on">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <v-btn
                                        text
                                        icon
                                        v-on="on"
                                        :class="{ active: isActive.link() }"
                                        @click="openLinkMenu(getMarkAttrs)"
                                    >
                                        <v-icon>link</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ (isActive.link() ? "update_link" : "add_link") | translate }}</span>
                            </v-tooltip>
                        </template>
                    </template>

                    <v-tabs fixed-tabs :class="{ 'hidden-tabs-bar': predefinedLinks.length === 0 }">
                        <v-tab>{{ "custom" | translate }}</v-tab>
                        <v-tab-item>
                            <v-card>
                                <v-card-text>
                                    <v-text-field
                                        v-if="!isSelectedRange()"
                                        v-model="linkMenu.name"
                                        :label="'link_text' | translate"
                                    ></v-text-field>
                                    <v-text-field
                                        v-model="linkMenu.url"
                                        :label="'link_url' | translate"
                                        placeholder="https://..."
                                    ></v-text-field>
                                </v-card-text>

                                <v-card-actions>
                                    <v-btn
                                        v-if="isActive.link()"
                                        text
                                        @click="
                                            commands.link({ href: '' });
                                            linkMenu.opened = false;
                                        "
                                        >{{ "unlink" | translate }}</v-btn
                                    >
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        color="primary"
                                        text
                                        :disabled="!linkMenu.url || !(linkMenu.name || isSelectedRange())"
                                        @click="
                                            addLink();
                                            linkMenu.opened = false;
                                        "
                                        >{{ (isActive.link() ? "update_link" : "add_link") | translate }}</v-btn
                                    >
                                </v-card-actions>
                            </v-card>
                        </v-tab-item>

                        <v-tab v-if="predefinedLinks.length > 0">{{ predefinedLabel | translate }}</v-tab>
                        <v-tab-item>
                            <v-list>
                                <v-list-tile
                                    v-for="(link, i) in predefinedLinks"
                                    :key="i"
                                    @click="
                                        addLink(link);
                                        linkMenu.opened = false;
                                    "
                                >
                                    {{ link.name }}
                                </v-list-tile>
                            </v-list>
                        </v-tab-item>
                    </v-tabs>
                </v-menu>

                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn
                            text
                            icon
                            v-on="on"
                            :class="{ active: isActive.bullet_list() }"
                            @click="commands.bullet_list"
                        >
                            <v-icon>format_list_bulleted</v-icon>
                        </v-btn>
                    </template>
                    <span>{{ "list_bulleted" | translate }}</span>
                </v-tooltip>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn
                            text
                            icon
                            v-on="on"
                            :class="{ active: isActive.ordered_list() }"
                            @click="commands.ordered_list"
                        >
                            <v-icon>format_list_numbered</v-icon>
                        </v-btn>
                    </template>
                    <span>{{ "list_numbered" | translate }}</span>
                </v-tooltip>
                <!-- <v-tooltip v-if="!basic" bottom>
                    <v-btn text icon
                        slot="activator"
                        :class="{ 'active': isActive.todo_list() }"
                        @click="commands.todo_list">
                        <div style="padding:8px;">
                            <svg viewBox="0 0 24 24" id="icon--checklist" width="100%" height="100%"><path d="M21 0H3a3 3 0 0 0-3 3v18a3 3 0 0 0 3 3h18a3 3 0 0 0 3-3V3a3 3 0 0 0-3-3zm1 21a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3a1 1 0 0 1 1-1h18a1 1 0 0 1 1 1z"></path><path d="M11.249 4.5a1.251 1.251 0 0 0-1.75.25L7.365 7.6l-.482-.481a1.25 1.25 0 0 0-1.767 1.764l1.5 1.5a1.262 1.262 0 0 0 1.884-.134l3-4a1.25 1.25 0 0 0-.251-1.749zm0 9a1.251 1.251 0 0 0-1.75.25L7.365 16.6l-.482-.481a1.25 1.25 0 1 0-1.767 1.768l1.5 1.5a1.265 1.265 0 0 0 1.884-.138l3-4a1.25 1.25 0 0 0-.251-1.749zM18.5 7.749H14a1.25 1.25 0 0 0 0 2.5h4.5a1.25 1.25 0 0 0 0-2.5zm0 8H14a1.25 1.25 0 0 0 0 2.5h4.5a1.25 1.25 0 1 0 0-2.5z"></path></svg>
                        </div>
                    </v-btn>
                    <span>{{ 'todo_list' | translate }}</span>
                </v-tooltip> -->
                <v-tooltip v-if="!basic" bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn
                            text
                            icon
                            v-on="on"
                            :class="{ active: isActive.blockquote() }"
                            @click="commands.blockquote"
                        >
                            <v-icon>format_quote</v-icon>
                        </v-btn>
                    </template>
                    <span>{{ "quote" | translate }}</span>
                </v-tooltip>
                <v-tooltip v-if="!basic" bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn text icon v-on="on" :class="{ active: isActive.code() }" @click="commands.code">
                            <v-icon>code</v-icon>
                        </v-btn>
                    </template>
                    <span>{{ "code" | translate }}</span>
                </v-tooltip>
                <v-tooltip v-if="!basic" bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn
                            text
                            icon
                            v-on="on"
                            :class="{ active: isActive.code_block() }"
                            @click="commands.code_block"
                        >
                            <v-icon>notes</v-icon>
                        </v-btn>
                    </template>
                    <span>{{ "code_block" | translate }}</span>
                </v-tooltip>
                <v-tooltip v-if="!basic" bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn text icon v-on="on" @click="commands.horizontal_rule">
                            –
                        </v-btn>
                    </template>
                    <span>{{ "horizontal_line" | translate }}</span>
                </v-tooltip>
                <v-tooltip v-if="!basic" bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn text icon v-on="on" @click="commands.undo">
                            <v-icon>undo</v-icon>
                        </v-btn>
                    </template>
                    <span>{{ "undo" | translate }}</span>
                </v-tooltip>
                <v-tooltip v-if="!basic" bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn text icon v-on="on" @click="commands.redo">
                            <v-icon>redo</v-icon>
                        </v-btn>
                    </template>
                    <span>{{ "redo" | translate }}</span>
                </v-tooltip>
                <v-tooltip v-if="!basic" bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn
                            text
                            icon
                            v-on="on"
                            :class="{
                                active: isActive.alignment() && editor.activeMarkAttrs.alignment.textAlign === 'left'
                            }"
                            @click="commands.alignment({ textAlign: 'left' })"
                        >
                            <v-icon>format_align_left</v-icon>
                        </v-btn>
                    </template>
                    <span>{{ "align_left" | translate }}</span>
                </v-tooltip>
                <v-tooltip v-if="!basic" bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn
                            text
                            icon
                            v-on="on"
                            :class="{
                                active: isActive.alignment() && editor.activeMarkAttrs.alignment.textAlign === 'center'
                            }"
                            @click="commands.alignment({ textAlign: 'center' })"
                        >
                            <v-icon>format_align_center</v-icon>
                        </v-btn>
                    </template>
                    <span>{{ "align_center" | translate }}</span>
                </v-tooltip>
                <v-tooltip v-if="!basic" bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn
                            text
                            icon
                            v-on="on"
                            :class="{
                                active: isActive.alignment() && editor.activeMarkAttrs.alignment.textAlign === 'right'
                            }"
                            @click="commands.alignment({ textAlign: 'right' })"
                        >
                            <v-icon>format_align_right</v-icon>
                        </v-btn>
                    </template>
                    <span>{{ "align_right" | translate }}</span>
                </v-tooltip>

                <v-tooltip v-if="!basic" bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn
                            text
                            icon
                            v-on="on"
                            @click="commands.createTable({ rowsCount: 3, colsCount: 3, withHeaderRow: false })"
                        >
                            <div style="padding: 8px;">
                                <svg viewBox="0 0 24 24" width="100%" height="100%">
                                    <path
                                        fill-rule="evenodd"
                                        d="M17 17v5h2a3 3 0 0 0 3-3v-2h-5zm-2 0H9v5h6v-5zm2-2h5V9h-5v6zm-2 0V9H9v6h6zm2-8h5V5a3 3 0 0 0-3-3h-2v5zm-2 0V2H9v5h6zm9 9.177V19a5 5 0 0 1-5 5H5a5 5 0 0 1-5-5V5a5 5 0 0 1 5-5h14a5 5 0 0 1 5 5v2.823a.843.843 0 0 1 0 .354v7.646a.843.843 0 0 1 0 .354zM7 2H5a3 3 0 0 0-3 3v2h5V2zM2 9v6h5V9H2zm0 8v2a3 3 0 0 0 3 3h2v-5H2z"
                                    ></path>
                                </svg>
                            </div>
                        </v-btn>
                    </template>
                    <span>{{ "add_table" | translate }}</span>
                </v-tooltip>

                <template v-if="!basic && isActive.table()">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn text icon v-on="on" @click="commands.deleteTable">
                                <div style="padding: 8px;">
                                    <svg viewBox="0 0 24 24" id="icon--delete_table" width="100%" height="100%">
                                        <path
                                            d="M19 14a5 5 0 1 1 0 10 5 5 0 0 1 0-10zm-2.5 5.938h5a.937.937 0 1 0 0-1.875h-5a.937.937 0 1 0 0 1.875zM12.29 17H9v5h3.674c.356.75.841 1.426 1.427 2H5a5 5 0 0 1-5-5V5a5 5 0 0 1 5-5h14a5 5 0 0 1 5 5v2.823a.843.843 0 0 1 0 .354V14.1a7.018 7.018 0 0 0-2-1.427V9h-5v3.29a6.972 6.972 0 0 0-2 .965V9H9v6h4.255a6.972 6.972 0 0 0-.965 2zM17 7h5V5a3 3 0 0 0-3-3h-2v5zm-2 0V2H9v5h6zM7 2H5a3 3 0 0 0-3 3v2h5V2zM2 9v6h5V9H2zm0 8v2a3 3 0 0 0 3 3h2v-5H2z"
                                        ></path>
                                    </svg>
                                </div>
                            </v-btn>
                        </template>
                        <span>{{ "delete_table" | translate }}</span>
                    </v-tooltip>

                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn text icon v-on="on" @click="commands.addColumnBefore">
                                <div style="padding: 8px;">
                                    <svg viewBox="0 0 24 24" id="icon--add_col_before" width="100%" height="100%">
                                        <path
                                            d="M19 14a5 5 0 1 1 0 10 5 5 0 0 1 0-10zm2.5 5.938a.937.937 0 1 0 0-1.875h-1.25a.312.312 0 0 1-.313-.313V16.5a.937.937 0 1 0-1.875 0v1.25c0 .173-.14.313-.312.313H16.5a.937.937 0 1 0 0 1.875h1.25c.173 0 .313.14.313.312v1.25a.937.937 0 1 0 1.875 0v-1.25c0-.173.14-.313.312-.313h1.25zM2 19a3 3 0 0 0 6 0V5a3 3 0 1 0-6 0v14zm-2 0V5a5 5 0 1 1 10 0v14a5 5 0 0 1-10 0z"
                                        ></path>
                                    </svg>
                                </div>
                            </v-btn>
                        </template>
                        <span>{{ "add_col_before" | translate }}</span>
                    </v-tooltip>

                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn text icon v-on="on" @click="commands.addColumnAfter">
                                <div style="padding: 8px;">
                                    <svg viewBox="0 0 24 24" id="icon--add_col_after" width="100%" height="100%">
                                        <path
                                            d="M5 14a5 5 0 1 1 0 10 5 5 0 0 1 0-10zm2.5 5.938a.937.937 0 1 0 0-1.875H6.25a.312.312 0 0 1-.313-.313V16.5a.937.937 0 1 0-1.875 0v1.25c0 .173-.14.313-.312.313H2.5a.937.937 0 1 0 0 1.875h1.25c.173 0 .313.14.313.312v1.25a.937.937 0 1 0 1.875 0v-1.25c0-.173.14-.313.312-.313H7.5zM16 19a3 3 0 0 0 6 0V5a3 3 0 0 0-6 0v14zm-2 0V5a5 5 0 0 1 10 0v14a5 5 0 0 1-10 0z"
                                        ></path>
                                    </svg>
                                </div>
                            </v-btn>
                        </template>
                        <span>{{ "add_col_after" | translate }}</span>
                    </v-tooltip>

                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn text icon v-on="on" @click="commands.deleteColumn">
                                <div style="padding: 8px;">
                                    <svg viewBox="0 0 24 24" id="icon--delete_col" width="100%" height="100%">
                                        <path
                                            d="M12.641 21.931a7.01 7.01 0 0 0 1.146 1.74A5 5 0 0 1 7 19V5a5 5 0 1 1 10 0v7.29a6.972 6.972 0 0 0-2 .965V5a3 3 0 0 0-6 0v14a3 3 0 0 0 3.641 2.931zM19 14a5 5 0 1 1 0 10 5 5 0 0 1 0-10zm-2.5 5.938h5a.937.937 0 1 0 0-1.875h-5a.937.937 0 1 0 0 1.875z"
                                        ></path>
                                    </svg>
                                </div>
                            </v-btn>
                        </template>
                        <span>{{ "delete_col" | translate }}</span>
                    </v-tooltip>

                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn text icon v-on="on" @click="commands.addRowBefore">
                                <div style="padding: 8px;">
                                    <svg viewBox="0 0 24 24" id="icon--add_row_before" width="100%" height="100%">
                                        <path
                                            d="M19 14a5 5 0 1 1 0 10 5 5 0 0 1 0-10zm2.5 5.938a.937.937 0 1 0 0-1.875h-1.25a.312.312 0 0 1-.313-.313V16.5a.937.937 0 1 0-1.875 0v1.25c0 .173-.14.313-.312.313H16.5a.937.937 0 1 0 0 1.875h1.25c.173 0 .313.14.313.312v1.25a.937.937 0 1 0 1.875 0v-1.25c0-.173.14-.313.312-.313h1.25zM5 2a3 3 0 1 0 0 6h14a3 3 0 0 0 0-6H5zm0-2h14a5 5 0 0 1 0 10H5A5 5 0 1 1 5 0z"
                                        ></path>
                                    </svg>
                                </div>
                            </v-btn>
                        </template>
                        <span>{{ "add_row_before" | translate }}</span>
                    </v-tooltip>

                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn text icon v-on="on" @click="commands.addRowAfter">
                                <div style="padding: 8px;">
                                    <svg viewBox="0 0 24 24" id="icon--add_row_after" width="100%" height="100%">
                                        <path
                                            d="M19 0a5 5 0 1 1 0 10 5 5 0 0 1 0-10zm2.5 5.938a.937.937 0 1 0 0-1.875h-1.25a.312.312 0 0 1-.313-.313V2.5a.937.937 0 1 0-1.875 0v1.25c0 .173-.14.313-.312.313H16.5a.937.937 0 1 0 0 1.875h1.25c.173 0 .313.14.313.312V7.5a.937.937 0 1 0 1.875 0V6.25c0-.173.14-.313.312-.313h1.25zM5 16a3 3 0 0 0 0 6h14a3 3 0 0 0 0-6H5zm0-2h14a5 5 0 0 1 0 10H5a5 5 0 0 1 0-10z"
                                        ></path>
                                    </svg>
                                </div>
                            </v-btn>
                        </template>
                        <span>{{ "add_row_after" | translate }}</span>
                    </v-tooltip>

                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn text icon v-on="on" @click="commands.deleteRow">
                                <div style="padding: 8px;">
                                    <svg viewBox="0 0 24 24" id="icon--delete_row" width="100%" height="100%">
                                        <path
                                            d="M13.255 15a6.972 6.972 0 0 0-.965 2H5A5 5 0 0 1 5 7h14a5 5 0 0 1 4.671 6.787 7.01 7.01 0 0 0-1.74-1.146A3 3 0 0 0 19 9H5a3 3 0 0 0 0 6h8.255zM19 14a5 5 0 1 1 0 10 5 5 0 0 1 0-10zm-2.5 5.938h5a.937.937 0 1 0 0-1.875h-5a.937.937 0 1 0 0 1.875z"
                                        ></path>
                                    </svg>
                                </div>
                            </v-btn>
                        </template>
                        <span>{{ "delete_row" | translate }}</span>
                    </v-tooltip>

                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn text icon v-on="on" @click="commands.toggleCellMerge">
                                <div style="padding: 8px;">
                                    <svg viewBox="0 0 24 24" id="icon--combine_cells" width="100%" height="100%">
                                        <path
                                            d="M2 19a3 3 0 0 0 3 3h14a3 3 0 0 0 3-3V5a3 3 0 0 0-3-3H5a3 3 0 0 0-3 3v14zm-2 0V5a5 5 0 0 1 5-5h14a5 5 0 0 1 5 5v14a5 5 0 0 1-5 5H5a5 5 0 0 1-5-5zm12-9a1 1 0 0 1 1 1v2a1 1 0 0 1-2 0v-2a1 1 0 0 1 1-1zm0 6a1 1 0 0 1 1 1v3a1 1 0 0 1-2 0v-3a1 1 0 0 1 1-1zm0-13a1 1 0 0 1 1 1v3a1 1 0 0 1-2 0V4a1 1 0 0 1 1-1z"
                                        ></path>
                                    </svg>
                                </div>
                            </v-btn>
                        </template>
                        <span>{{ "combine_cells" | translate }}</span>
                    </v-tooltip>
                </template>
            </div>
        </editor-menu-bar>

        <editor-content :editor="editor" />
    </div>
</template>

<script>
import object from "obj-fe/utils/object";
import { Editor, EditorContent, EditorMenuBar } from "tiptap";
import Alignment from "./richtext-alignment-plugin.js";

import {
    Blockquote,
    CodeBlock,
    Heading,
    HorizontalRule,
    OrderedList,
    BulletList,
    ListItem,
    Bold,
    Code,
    Italic,
    Link,
    Strike,
    Underline,
    History,
    Table,
    TableHeader,
    TableCell,
    TableRow,
    TodoItem,
    TodoList
} from "tiptap-extensions";

export default {
    components: {
        EditorContent,
        EditorMenuBar
    },
    props: {
        value: String,
        editable: Boolean,
        basic: Boolean,
        autoFocus: Boolean,
        predefinedLabel: {
            type: String,
            default: "predefined"
        }
    },
    data() {
        return {
            editor: null,
            currentHtml: "",
            linkMenu: {
                opened: false,
                url: "",
                name: ""
            },
            focused: false,
            predefinedLinks: []
        };
    },
    computed: {
        canHandleUpload() {
            return this.editable && !this.basic;
        }
    },
    methods: {
        createEditorInstance() {
            if (this.editor) return this.editor;

            let plugins = [
                new Heading({ levels: [1, 2, 3, 4, 5, 6] }),
                new ListItem(),
                new BulletList(),
                new OrderedList(),
                new Bold(),
                new Italic(),
                new Link(),
                new Strike(),
                new Underline()
            ];

            if (!this.basic)
                plugins = plugins.concat([
                    new Blockquote(),
                    new CodeBlock(),
                    new HorizontalRule(),
                    new Code(),
                    new History(),
                    new Alignment(),
                    new Table(),
                    new TableHeader(),
                    new TableCell(),
                    new TableRow(),
                    new TodoItem(),
                    new TodoList()
                ]);

            let thisComponent = this;

            this.editor = new Editor({
                editable: true,
                extensions: plugins,
                content: this.value,
                onUpdate: object.debounce(event => this.onUpdate(event), 350),
                onPaste(view, event) {
                    // FIX: when pasting files through ctrl+v in windows, this "onPaste" callback is not calling - tiptap bug
                    // so catch this in custom paste handler below
                    if (thisComponent.canHandleUpload && event.clipboardData.files.length > 0) return true;
                },
                onFocus() {
                    thisComponent.focused = true;
                },
                onBlur() {
                    thisComponent.focused = false;
                }
            });

            // prevent links follow if edit mode
            this.editor.view.dom.addEventListener(
                "click",
                e => {
                    if (this.editable) {
                        e.preventDefault();
                        e.stopPropagation();
                    }
                },
                true
            );

            return this.editor;
        },
        onUpdate(event) {
            this.currentHtml = event.getHTML();
            // handle empty paragraph state - this happens when all text is removed
            // TODO: investigate tiptap editor if this state can be avoided
            if (this.currentHtml === "<p></p>") this.currentHtml = "";

            this.$emit("input", this.currentHtml);
        },
        isSelectedRange() {
            return this.editor.state.selection.from !== this.editor.state.selection.to;
        },
        getPredefinedLinks() {
            this.$emit("predefinedLinks", links => {
                this.predefinedLinks = links || [];
            });
        },
        openLinkMenu(getMarkAttrs) {
            this.linkMenu.url = getMarkAttrs("link").href;
            this.linkMenu.name = "";
            this.linkMenu.opened = true;
            this.getPredefinedLinks();
        },
        addLink(link) {
            let from = this.editor.state.selection.from;
            let to = this.editor.state.selection.to;

            if (link) {
                if (link.isImage) {
                    this.editor.view.dispatch(this.editor.view.state.tr.deleteSelection());
                    this.insertImage(from, link.url);
                } else if (this.isSelectedRange()) this.markLink(from, to, link.url);
                else this.insertLink(from, link.name, link.url);
            } else if (this.isSelectedRange()) this.markLink(from, to, this.linkMenu.url);
            else this.insertLink(from, this.linkMenu.name, this.linkMenu.url);
        },
        markLink(from, to, url) {
            let linkMark = this.editor.schema.marks.link.create({ href: url });
            let transaction = this.editor.view.state.tr.addMark(from, to, linkMark);
            this.editor.view.dispatch(transaction);
        },
        insertLink(pos, name, url, addOneCharToPos) {
            let schema = this.editor.schema;
            let view = this.editor.view;

            let transaction = view.state.tr.insertText(name + " ", pos);
            view.dispatch(transaction);

            let linkMark = schema.marks.link.create({ href: url });
            transaction = view.state.tr.addMark(pos, pos + name.length + (addOneCharToPos ? 1 : 0), linkMark);
            view.dispatch(transaction);
        },
        insertImage(pos, imageUrl) {
            let schema = this.editor.schema;
            let view = this.editor.view;

            let node = schema.nodes.image.create({ src: imageUrl });
            let transaction = view.state.tr.insert(pos, node);
            view.dispatch(transaction);
        },
        onFileDrop(event, from, to) {
            if (!this.canHandleUpload) return;

            let fileLinksHandler = links => {
                // let schema = this.editor.schema;
                let view = this.editor.view;
                let coordinates;

                // remove selection if range defined
                if (from !== to && links.length > 0) {
                    view.dispatch(view.state.tr.deleteSelection());
                }

                if (from !== undefined) coordinates = { pos: from };
                else
                    coordinates = view.posAtCoords({
                        left: event.clientX,
                        top: event.clientY
                    });

                // TODO: investigate, this is dirty fix for creating marks, because if inside is -1, there is one char moved after text insertion
                let addOneCharToPos = coordinates.inside === -1;

                for (let i = links.length - 1; i >= 0; i--) {
                    if (links[i].isImage) this.insertImage(coordinates.pos, links[i].url);
                    else this.insertLink(coordinates.pos, links[i].name, links[i].url, addOneCharToPos);
                }
            };

            this.$emit("fileDrop", event, fileLinksHandler);
        }
    },
    watch: {
        value: {
            immediate: true,
            handler(html) {
                // only change editor content if changed externally, because it will reset cursor
                if (html !== this.currentHtml) this.createEditorInstance().setContent(html || "");
            }
        },
        editable: {
            immediate: true,
            handler() {
                this.createEditorInstance().setOptions({
                    editable: this.editable
                });
            }
        }
    },
    mounted() {
        this.createEditorInstance();
        if (this.autoFocus) this.editor.focus();
    },
    beforeDestroy() {
        this.editor.destroy();
    }
};
</script>

<style scoped>
.editor-container {
    position: relative;
}

.editor-container.editable {
    border: 1px solid #ddd;
}

.menubar {
    padding: 10px;
    border-bottom: 1px solid #ddd;
    position: relative;
}

.menubar-backdrop {
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    background-color: #fff;
    opacity: 0.9;
    z-index: 1;
}

.v-btn {
    margin: 0;
}

.v-btn.active {
    background-color: #ddd;
}

>>> .ProseMirror {
    padding: 10px;
    min-height: 100px;
    overflow: auto;
}

>>> .ProseMirror-focused {
    outline: none;
}
>>> .ProseMirror pre code {
    box-shadow: none;
}

>>> .ProseMirror .tableWrapper {
    margin: 1em 0;
    overflow-x: auto;
}

>>> .ProseMirror table {
    border-collapse: collapse;
    table-layout: fixed;
    width: 95%;
    margin: 0;
    overflow: hidden;
}

>>> .ProseMirror table th {
    font-weight: 700;
    text-align: left;
}

>>> .ProseMirror table td,
>>> .ProseMirror table th {
    min-width: 1em;
    border: 2px solid #ddd;
    padding: 3px 5px;
    vertical-align: top;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    position: relative;
}

>>> .v-tabs.hidden-tabs-bar > .v-tabs__bar {
    height: 0px;
}

>>> .ProseMirror p {
    margin: 0px;
}
</style>
