<template>
  <v-card id="search-screen" flat style="padding-bottom: 0px">
    <v-navigation-drawer
      class="inv-side-filter"
      :value="true"
      left
      clipped
      app
      permanent
      width="350"
      disable-resize-watcher
      data-test="facet-search"
    >
      <v-card flat style="overflow: auto" :style="{ height: 'calc(100% - ' + blockResetButtonHeight + 'px)' }">
        <v-card-actions>
          <v-text-field
            v-model="tagSearchPhrase"
            :placeholder="'facet_search_inventory_fulltext' | translate"
            @input="tagsSearchDebounced"
            prepend-inner-icon="fal fa-search"
            hide-details
            clearable
            clear-icon="fal fa-times-circle"
            data-test="facets-filter"
          />
        </v-card-actions>

        <v-list
          v-for="(category, i) in !!tagSearchPhrase && tagSearchPhrase.length >= 1
            ? tagFilterCategories
            : filterCategories"
          :key="i"
        >
          <v-list-item style="height: 40px" @click="toggleExpandedCat(category)">
            <v-list-item-action style="margin: 0px">
              <v-btn
                icon
                @click="
                  $event.stopPropagation();
                  toggleExpandedCat(category);
                "
                text
              >
                <v-icon v-if="category.expanded">fal fa-chevron-down</v-icon>
                <v-icon v-else>fal fa-chevron-right</v-icon>
              </v-btn>
            </v-list-item-action>
            <v-list-item-content>
              <v-subheader>{{ category.displayString }}</v-subheader>
            </v-list-item-content>
          </v-list-item>

          <template v-for="(tag, i) in category.tags">
            <v-list-item v-if="!tag.hidden" :key="i" class="clickable type">
              <v-list-item-action style="margin: 0px" :style="{ 'margin-left': tag.level * 18 + 'px' }">
                <v-btn
                  :disabled="!tag.children || tag.children.length === 0"
                  icon
                  @click="
                    $event.stopPropagation();
                    toggleExpanded(tag);
                  "
                  text
                >
                  <v-icon v-if="!tag.children || tag.children.length === 0" />
                  <v-icon v-else-if="tag.expanded">fal fa-chevron-down</v-icon>
                  <v-icon v-else>fal fa-chevron-right</v-icon>
                </v-btn>
              </v-list-item-action>

              <v-list-item-action>
                <v-checkbox
                  off-icon="fal fa-square"
                  hide-details
                  :input-value="tag.selected"
                  @click="
                    $event.stopPropagation();
                    toggleSelected(tag);
                  "
                />
              </v-list-item-action>

              <v-list-item-content>
                <v-list-item-title
                  style="cursor: pointer"
                  @click="
                    $event.stopPropagation();
                    toggleSelected(tag);
                  "
                >
                  {{ tag.displayString }}
                </v-list-item-title>
              </v-list-item-content>

              <v-list-item-action>
                <span class="tag-occurance-count">{{ tag.occurance }}</span>
              </v-list-item-action>
            </v-list-item>
          </template>
        </v-list>
      </v-card>

      <div :style="{ height: blockResetButtonHeight + 'px' }">
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn
              class="fill-height my-0"
              style="border-top: 1px solid rgba(0, 0, 0, 0.12)"
              block
              @click="resetAllFilters()"
              v-on="on"
              text
              data-test="facet-reset-button"
            >
              <v-icon>fal fa-times</v-icon>
              {{ "reset" | translate }}
            </v-btn>
          </template>
          <span>{{ "reset_all_filters" | translate }}</span>
        </v-tooltip>
      </div>
    </v-navigation-drawer>

    <v-card-text data-test="search-results-table">
      <div class="search-results-root">
        <div class="search-results-header">
          <div class="search-results-header__title"></div>
          <div class="search-results-header__actions">
            <v-btn-toggle tile group dense mandatory color="accent" v-model="viewMode">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on">
                    <v-icon>fal fa-grid</v-icon>
                  </v-btn>
                </template>
                <span>{{ "search_inventory_view_table" | translate }}</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on">
                    <v-icon>fal fa-grid-2</v-icon>
                  </v-btn>
                </template>
                <span>{{ "search_inventory_view_list" | translate }}</span>
              </v-tooltip>
            </v-btn-toggle>
          </div>
        </div>
        <div class="search-results-main">
          <div class="search-results-content">
            <obj-table
              v-if="viewMode === 0"
              :headers="headers"
              :items="objects"
              :fillParentHeight="!dmsStatus"
              data-test="celine-results-table"
            >
              <template slot="row" slot-scope="row">
                <td>
                  <inv-object-link :object="row.value"></inv-object-link>
                </td>
                <td>
                  <!-- full text context -->
                  <v-menu offset-y>
                    <template v-slot:activator="{ on }">
                      <v-btn v-on="on" @click="loadSearchContext(row.value.id)" text>
                        <v-icon>fal fa-search</v-icon>
                        {{ "full_text_context" | translate }}
                      </v-btn>
                    </template>

                    <v-list>
                      <v-list-item v-for="(item, i) in searchContextsById[row.value.id]" :key="i">
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ (item.path || "") + item.name + ": " }} <span v-html="item.value"></span>
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-menu>

                  <!-- placement info -->
                  <v-menu
                    v-if="row.value.objectType.includes('GEOSITE') && !row.value.objectType.includes('FUNCTIONALITY')"
                    offset-y
                  >
                    <template v-slot:activator="{ on }">
                      <v-btn v-on="on" @click="loadPlacementInfo(row.value.id)" text>
                        <v-icon>fal fa-map-marker-alt</v-icon>
                        {{ "placement" | translate }}
                      </v-btn>
                    </template>

                    <v-list>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>
                            <template
                              v-if="!placementInfosById[row.value.id] || placementInfosById[row.value.id].isEmpty"
                              >{{ "no_placement_info" | translate }}</template
                            >
                            <template v-else>
                              <span
                                v-if="
                                  placementInfosById[row.value.id].region || placementInfosById[row.value.id].district
                                "
                              >
                                {{ placementInfosById[row.value.id].region }}
                                <span v-if="placementInfosById[row.value.id].district"
                                  >({{ placementInfosById[row.value.id].district }})</span
                                >
                                , <br />
                              </span>
                              <span
                                v-if="placementInfosById[row.value.id].zip || placementInfosById[row.value.id].city"
                              >
                                {{ placementInfosById[row.value.id].zip }} {{ placementInfosById[row.value.id].city }},
                                <br />
                              </span>
                              {{ placementInfosById[row.value.id].streetName }}
                              {{ placementInfosById[row.value.id].streetNo }}
                            </template>
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-menu>

                  <!-- node resources info -->
                  <v-menu
                    v-if="
                      row.value.objectType.includes('NODE_RESOURCE') || row.value.objectType.includes('FUNCTIONALITY')
                    "
                    offset-y
                  >
                    <template v-slot:activator="{ on }">
                      <v-btn v-on="on" @click="loadNodeResources(row.value.id)" text>
                        <v-icon>fal fa-map-marker-alt</v-icon>
                        {{ "placement" | translate }}
                      </v-btn>
                    </template>

                    <v-list>
                      <v-list-item v-for="(item, i) in nodeResourcesById[row.value.id]" :key="i">
                        <v-list-item-content>
                          <v-list-item-title>
                            <template v-if="!item.nodeResources || item.nodeResources.length === 0">{{
                              "no_placement_info" | translate
                            }}</template>
                            <span v-for="(object, i) in item.nodeResources" :key="i">
                              <v-icon v-if="i > 0">fal fa-chevron-right</v-icon>
                              <inv-object-link :object="object"></inv-object-link>
                            </span>
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-menu>

                  <!-- circuit borders info -->
                  <v-menu v-if="row.value.objectType.includes('LINE_RESOURCE')" offset-y>
                    <template v-slot:activator="{ on }">
                      <v-btn v-on="on" @click="loadCircuitBorders(row.value.id)" text>
                        <v-icon>swap_calls</v-icon>
                        {{ "circuit_borders" | translate }}
                      </v-btn>
                    </template>

                    <v-list>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>
                            <span v-for="(object, i) in circuitBordersById[row.value.id]" :key="i">
                              <v-icon v-if="i > 0">sync_alt</v-icon>
                              <inv-object-link :object="object"></inv-object-link>
                            </span>
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </td>
              </template>
            </obj-table>
            <inv-search-details v-else :items="objects" />
          </div>
        </div>
        <div class="search-results-footer">
          <obj-table-pagination
            :page="pagination.page"
            :pages="pagination.pages"
            :page-size="pagination.limit"
            :total-count="pagination.count"
            :next="pagination.next"
            :prev="pagination.prev"
            @prev="$store.dispatch('INVENTORY/UPDATE_QUERY', { page: pagination.page - 1 })"
            @next="$store.dispatch('INVENTORY/UPDATE_QUERY', { page: pagination.page + 1 })"
            @page-size="(v) => $store.dispatch('INVENTORY/UPDATE_QUERY', { limit: v, page: 1 })"
          />
        </div>
      </div>

      <obj-table v-if="dmsStatus" :headers="dmsHeaders" :items="dmsObjects" data-test="dms-results-table">
        <template slot="row" slot-scope="row">
          <td>
            <a :href="getDmsLink(row.value.id)" target="_blank">
              <span>{{ row.value.name }}</span>
            </a>
          </td>
          <td>
            <span>{{ row.value.description }}</span>
          </td>
          <td>
            <span>{{ row.value.lastChangeTime | formatDate }}</span>
          </td>
          <td>
            <span>{{ row.value.uploadTime | formatDate }}</span>
          </td>
        </template>

        <template slot="no-data">
          <span v-if="dmsIsUp">
            {{ "no_dms_documents_found" | translate }}
          </span>
          <span v-else>
            {{ dmsErrorMessage }}
          </span>
        </template>

        <template slot="footer">
          <obj-table-pagination
            :page="dmsPagination.page"
            :pages="dmsPagination.pages"
            :page-size="dmsPagination.limit"
            :total-count="dmsPagination.count"
            :next="dmsPagination.next"
            :prev="dmsPagination.prev"
            @prev="$store.dispatch('DMS_INVENTORY/UPDATE_PAGE', dmsPagination.page - 1)"
            @next="$store.dispatch('DMS_INVENTORY/UPDATE_PAGE', dmsPagination.page + 1)"
            @page-size="(v) => $store.dispatch('DMS_INVENTORY/UPDATE_PAGINATION', { limit: v, page: 1 })"
          >
          </obj-table-pagination>
        </template>
      </obj-table>
    </v-card-text>
  </v-card>
</template>

<script>
import backend from "obj-fe/app/backend";
import object from "obj-fe/utils/object";
import date from "obj-fe/utils/date";

import InvObjectLink from "./inv-object-link.vue";
import InvSearchDetails from "./inv-search-details.vue";

export default {
  components: {
    InvObjectLink,
    InvSearchDetails,
  },
  data() {
    return {
      viewMode: 0,
      blockResetButtonHeight: 57,
      headers: [
        { text: this.$options.filters.translate("object_name") },
        { text: this.$options.filters.translate("additional_information") },
      ],
      searchContextsById: {},
      nodeResourcesById: {},
      placementInfosById: {},
      circuitBordersById: {},
      tagSearchPhrase: "",
      tagFilterCategories: [],

      dmsHeaders: [
        { text: "DMS Object Name" },
        { text: "Description" },
        { text: "Last Changed" },
        { text: "Uploaded" },
      ],
    };
  },
  filters: {
    formatDate(value) {
      const now = new Date().toISOString().split("T")[0];
      if (value === now) {
        return "Today";
      } else {
        return date.formatDate(value) + " " + date.formatTime(value);
      }
    },
  },
  computed: {
    filterCategories() {
      return this.$store.state.INVENTORY.filterCategories || [];
    },
    objects() {
      let objectsById = this.$store.state.INVENTORY.objectsById;
      return this.$store.state.INVENTORY.objectIds.map((id) => objectsById[id]);
    },
    pagination() {
      return this.$store.state.INVENTORY.pagination;
    },
    query() {
      return this.$store.state.INVENTORY.query;
    },
    queryText() {
      return this.query.text;
    },
    dmsStatus() {
      return this.$store.state.settings.useDms;
    },
    dmsPagination() {
      return this.$store.state.DMS_INVENTORY.pagination;
    },
    dmsObjects() {
      let objectsById = this.$store.state.DMS_INVENTORY.objectsById;
      return this.$store.state.DMS_INVENTORY.objectIds.map((id) => objectsById[id]);
    },
    dmsInventoryState() {
      return this.$store.state.DMS_INVENTORY;
    },
    dmsQuery() {
      return this.$store.state.DMS_INVENTORY.query;
    },
    dmsIsUp() {
      return this.$store.state.DMS_INVENTORY.dmsIsUp;
    },
    dmsErrorMessage() {
      return this.$store.state.DMS_INVENTORY.dmsErrorMessage;
    },
  },
  watch: {
    queryText: {
      immediate: true,
      handler(text) {
        this.searchPhrase = text === "*" ? "" : text;
      },
    },
  },
  methods: {
    tagsSearchDebounced: object.debounce(function (val) {
      if (!!val && val.length >= 1) this.filterTags(val);
    }, 150),
    filterTags(tagString) {
      this.tagFilterCategories = this.filterCategories.map(this.tagMapFunction);
    },
    tagMapFunction(category) {
      let tagSearchPhrase = this.tagSearchPhrase.toLowerCase();
      let filteredCategory = object.deepClone(category);
      let visibleTags = [];
      filteredCategory.tags.map((tag) => {
        if (tag.displayString.toLowerCase().includes(tagSearchPhrase)) {
          tag.hidden = false;
          visibleTags.push(tag.value);
        } else tag.hidden = true;
      });

      filteredCategory.tags
        .filter((tag) => tag.hidden && tag.children)
        .map((tag) => {
          if (tag.children && this.recursivelyCheckForVisibleChildrenTags(tag.children, tagSearchPhrase, visibleTags))
            tag.hidden = false;
        });
      return filteredCategory;
    },
    recursivelyCheckForVisibleChildrenTags(childrenTags, tagSearchPhrase, visibleTags) {
      let retval = false;
      childrenTags.forEach((childTag) => {
        if (visibleTags.indexOf(childTag.value) !== -1) retval = true;
        else if (
          childTag.children &&
          this.recursivelyCheckForVisibleChildrenTags(childTag.children, tagSearchPhrase, visibleTags)
        )
          retval = true;
      });
      return retval;
    },
    search(searchPhrase) {
      this.$store.dispatch("INVENTORY/UPDATE_QUERY", { text: searchPhrase || "*" });
    },
    toggleSelected(tag) {
      this.$store.dispatch("INVENTORY/TOGGLE_SELECT_FILTER_TAG", tag);
    },
    toggleExpanded(tag) {
      this.$store.dispatch("INVENTORY/TOGGLE_EXPAND_FILTER_TAG", tag);
    },
    toggleExpandedCat(cat) {
      this.$store.dispatch("INVENTORY/TOGGLE_EXPAND_FILTER_CATEGORY", cat);
    },
    resetAllFilters() {
      this.$store.dispatch("INVENTORY/RESET_QUERY");
      if (this.dmsStatus) this.$store.dispatch("DMS_INVENTORY/RESET_QUERY");
    },
    loadSearchContext(objectId) {
      backend.objects.fullTextContext({ id: objectId, text: this.query.text }, (data) => {
        this.$set(this.searchContextsById, objectId, data);
      });
    },
    loadNodeResources(objectId) {
      if (this.nodeResourcesById[objectId]) return; // already loaded
      backend.objects.nodeResources(objectId, (data) => {
        this.$set(this.nodeResourcesById, objectId, data);
      });
    },
    loadPlacementInfo(objectId) {
      if (this.placementInfosById[objectId]) return; // already loaded
      backend.objects.placement(objectId, (data) => {
        this.$set(this.placementInfosById, objectId, data);
      });
    },
    loadCircuitBorders(objectId) {
      if (this.circuitBordersById[objectId]) return; // already loaded
      backend.objects.circuitBorders(objectId, (data) => {
        this.$set(this.circuitBordersById, objectId, data);
      });
    },
    getDmsLink(id) {
      return "" + window.location.pathname + "rest/dms/documentRedirect/" + id;
    },
    getDate(time) {
      let date = new Date(time);
      return date;
    },
  },
};
</script>

<style scoped>
#search-screen .v-card__text {
  height: 100%;
  overflow: auto;
  width: 100%;

  padding-left: 350px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
#search-screen .obj-table-container {
  height: 50%;
  width: 100%;
}
#search-screen .obj-table {
  width: 100%;
}
>>> .obj-table table thead tr th {
  z-index: 1;
}
/* >>> .type.selected, .type.expanded {
        font-weight: bold;
    } */

>>> .no-hover:hover {
  background-color: transparent !important;
}

>>> .not-clickable {
  cursor: default !important;
}

.inv-side-filter {
  box-shadow: none;
  left: 56px;
  z-index: 3 !important;
}
.inv-side-filter >>> .v-list-item {
  height: 20px;
  min-height: 0px;
}

.inv-side-filter >>> .v-list-item .v-list-item__action {
  justify-content: flex-end;
}

.inv-side-filter >>> .v-expansion-panel-header {
  font-size: 16px;
  font-weight: 500;
}

.tag-occurance-count {
  background-color: var(--v-anchor-base);
  padding: 2px 4px;
  line-height: 14px;
  font-size: 12px;
  border-radius: 3px;
  color: #fff;
  /* &.plus:before {
            content: '+ ';
        } */
}

.search-results-root {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.search-results-main {
  position: relative;
  overflow: hidden;
  flex: 1;
}

.search-results-content {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

.search-results-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #949494;
  margin-top: -8px;
}

.search-results-footer {
  width: 100%;
  flex-shrink: 0;
}
</style>
