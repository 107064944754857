
function createInterpreter(BaseCstVisitor){

    // All our semantics go into the visitor, completly separated from the grammar.
    class QueryInterpreter extends BaseCstVisitor {
        constructor() {
            super();
            // This helper will detect any missing or redundant methods on this visitor
            this.validateVisitor();
        }

        // this is calculated only for syntax highlighting purposes
        createTokenResult(typeId, token){
            if(arguments.length === 1) {
                token = arguments[0];
                typeId = null;
            }

            token = this.getFaultTolerantToken(token);
            typeId = typeId ? typeId : (token.tokenType || {}).typeId;

            // { lineNo:{ colNo:type, colNo:type, colNo:type, ... } }
            let tokenBoundaries = {};

            if(!isNaN(token.startOffset)) {
                let startLine = token.startLine - 1;
                tokenBoundaries[ startLine ] = tokenBoundaries[ startLine ] || {};
                tokenBoundaries[ startLine ][ token.startColumn - 1 ] = typeId;

                let endLine = token.endLine - 1;
                tokenBoundaries[ endLine ] = tokenBoundaries[ endLine ] || {};
                tokenBoundaries[ endLine ][ token.endColumn ] = null;
            }

            return {
                tokenBoundaries,
                errors:[],
                merge(subResult){
                    if(!subResult) return this;

                    let subBoundaries = subResult.tokenBoundaries;
                    let boundaries = this.tokenBoundaries;
        
                    for(let lineNo in subBoundaries){
                        if(boundaries[ lineNo ]) {
                            for(let colNo in subBoundaries[ lineNo ]) boundaries[ lineNo ][ colNo ] = subBoundaries[ lineNo ][ colNo ];
                        }
                        else boundaries[ lineNo ] = subBoundaries[ lineNo ];
                    }
        
                    if(subResult.errors) this.errors = this.errors.concat(subResult.errors);
        
                    return this;
                },
                toJSON(){
                    let result = this.data;
                    if(typeof result !== 'string') result.lineIndex = this.data.token ? this.data.token.startLine : -1;
                    if(result.token) delete result.token;

                    return result;
                }
            };
        }

        createError(msg, token, prevToken){
            let err = new Error(msg);
            err.token = token;
            err.previousToken = prevToken;
            return err;
        }

        checkUnquoteText(str = ''){
            let quoteChar = str[0];
            let lastChar = str[ str.length-1 ];
            if(quoteChar === '"' || quoteChar === '\'') return str.slice(1, lastChar === quoteChar ? str.length-1 : undefined);
            else return str;
        }

        getFaultTolerantToken(token){
            if(Array.isArray(token)) token = token[0];
            return token || {};
        }
    
        rootExpression(ctx) {
            let result = this.createTokenResult();
            let data = [];
            let aliases = {}; // { alias:[ { token, type, id, name } ] };

            if(ctx.expression) ctx.expression.forEach(expression => {
                let subResult = this.visit(expression, aliases);
                result.merge(subResult);
                data.push(subResult);
            });

            result.data = data;
            result.aliases = aliases;

            return result;
        }
        entitiesDefinition(ctx, aliases){
            let result = this.resourceDefinition('ENTITIES_DEFINITION', ctx, []);
            
            let entitiesDefinitions = [];
            
            if(ctx.expression) ctx.expression.forEach( e => {
                let subResult = this.visit(e, aliases);
                result.merge(subResult);
                entitiesDefinitions.push(subResult);
            });

            result.data.entitiesDefinitions = entitiesDefinitions;

            return result;
        }

        connectionsDefinition(ctx, aliases){
            let result = this.resourceDefinition('CONNECTIONS_DEFINITION', ctx, []);
            
            let connectionsDefinitions = [];
            
            if(ctx.expression) ctx.expression.forEach( e => {
                let subResult = this.visit(e, aliases);
                result.merge(subResult);
                connectionsDefinitions.push(subResult);
            });

            result.data.connectionsDefinitions = connectionsDefinitions;

            return result;
        }
        redundant(ctx, aliases){
            let result = this.resourceDefinition('REDUNDANCY', ctx, aliases);
            
            let redundancies = [];
            
            if(ctx.expression) ctx.expression.forEach(e=>{
                let subResult = this.visit(e, aliases);
                result.merge(subResult);
                redundancies.push(subResult);
            });

            if(ctx.subRedundancies) ctx.subRedundancies.forEach(e => {
                let subResult = this.visit(e, aliases);
                result.merge(subResult);
                redundancies.push(subResult);
            });

            result.data.redundancies = redundancies;
            
            return result;
        }
        redundanciesDefinition(ctx, aliases){
            let result = this.resourceDefinition('REDUNDANCIES_DEFINITION', ctx, []);
            
            let redundanciesDefinitions = [];
            
            if(ctx.expression) ctx.expression.forEach( e => {
                let subResult = this.visit(e, aliases);
                result.merge(subResult);
                redundanciesDefinitions.push(subResult);
            });

            result.data.redundanciesDefinitions = redundanciesDefinitions;

            return result;
        }
    
        expression(ctx, input) {
            return this.visit(ctx.subExpression, input);
        }

        linkDefinition(ctx, aliases){
            return this.resourceDefinition('LINK', ctx, aliases); 
        }

        group(ctx, aliases){
            let result = this.resourceDefinition('GROUP', ctx, []);
            
            let groupMembers = [];
            
            if(ctx.expression) ctx.expression.forEach(e=>{
                let subResult = this.visit(e, aliases);
                result.merge(subResult);
                groupMembers.push(subResult);
            });

            if(ctx.subGroup) ctx.subGroup.forEach(e => {
                let subResult = this.visit(e, aliases);
                result.merge(subResult);
                groupMembers.push(subResult);
            });

            result.data.groupMembers = groupMembers;
            
            return result;
        }

        nodeDefinition(ctx, aliases){
            return this.resourceDefinition('NODE', ctx, aliases);
        }

        resourceDefinition(typeId, ctx, aliases){
            let typeToken = this.getFaultTolerantToken(ctx.type);
            let result = this.createTokenResult(typeToken);

            let nameResult = this.visit(ctx.value, typeId + '_NAME');
            result.merge(nameResult);

            let attributesResult = this.visit(ctx.attributes);
            result.merge(attributesResult);

            let name = nameResult ? nameResult.data : undefined;
            let attributes = attributesResult ? attributesResult.data : {};
            let alias = attributes.alias || name;

            result.data = {
                type: typeId,
                name,
                id: attributes.id,
                alias,
                attributes,
                token: typeToken
            };

            if(alias) {
                let aliasInfo = {
                    type:result.data.type,
                    id:attributes.id,
                    name
                };

                if(aliases[alias]) {
                    aliases[alias].push(aliasInfo);
                    result.errors.push(this.createError('Duplicate alias or name "'+alias+'"', typeToken));
                }
                else aliases[alias] = [aliasInfo];
            }

            return result;
        }

        connection(ctx, aliases){
            let self = this;
            let isLinkConnection = ctx.connectionTo && !!ctx.connectionTo[0];
            let resourceAliases = [];
            let resourceIds = [];

            function visitOverrideType(typeId, subRule, prevToken){
                let result = self.visit(subRule, typeId);
                if(!result) return;

                let valueToken = result.token;

                resourceAliases.push(result.data);
                
                let aliasInfo = (aliases[result.data] || [])[0];
                if(aliasInfo && aliasInfo.type !== typeId && aliasInfo.type !== 'REDUNDANCY') aliasInfo = null;
                resourceIds.push((aliasInfo || {}).id);
                
                if(!aliasInfo) {
                    result.errors.push(self.createError(typeId.toLowerCase() + ' "'+result.data+'" not defined', valueToken, prevToken));
                }

                return result;
            }

            let result = visitOverrideType('NODE', ctx.nodeFrom)
                .merge(this.createTokenResult(ctx.connectionFrom))
                .merge(visitOverrideType(isLinkConnection ? 'LINK' : 'NODE', ctx.nodeOrLink));

            if(isLinkConnection) {
                result.merge(this.createTokenResult(ctx.connectionTo))
                    .merge(visitOverrideType('NODE', ctx.nodeTo, ctx.connectionTo));
            }
            
            let attributesResult = this.visit(ctx.attributes);
            result.merge(attributesResult);

            // merged connection data
            result.data = {
                type: 'CONNECTION',
                resources: resourceAliases,
                resourceIds,
                attributes: attributesResult ? attributesResult.data : {}
            };
            
            let editedResult = {
                type: 'CONNECTION',
            };

            if(isLinkConnection){
                let length = 0;
                if(ctx.connectionFrom && ctx.connectionTo){
                    let connectionFrom = ctx.connectionFrom[0];
                    let connectionTo = ctx.connectionTo[0];
    
                    length = Math.max(
                        (connectionFrom.endOffset - connectionFrom.startOffset + 1),
                        (connectionTo.endOffset - connectionTo.startOffset + 1)
                    );
                }
                editedResult.nodeA = result.data.resources[0];
                editedResult.link = result.data.resources[1];
                editedResult.nodeB = result.data.resources[2];
                editedResult.length = length;
                editedResult.attributes = attributesResult ? attributesResult.data : {};
            } 
            else {
                let length = 0; 
                if(ctx.connectionFrom){
                    let connectionFrom = ctx.connectionFrom[0];
                    length = connectionFrom.endOffset - connectionFrom.startOffset + 1;
                }
                editedResult.nodeA =  result.data.resources[0];
                editedResult.nodeB = result.data.resources[1];
                editedResult.length = length;
                editedResult.attributes = attributesResult ? attributesResult.data : {};
            }

            result.data = editedResult;
            return result;
        }

        attribute(ctx){
            let nameToken = this.getFaultTolerantToken(ctx.name);
            let attrName = nameToken.image;
            let attrValue;

            let result = this.createTokenResult('ATTRIBUTE_NAME', nameToken)
                .merge(this.createTokenResult(ctx.equal));

            let attrValueResult = this.visit(ctx.value);
            if(attrValueResult) {
                result.merge(attrValueResult);
                attrValue = attrValueResult.data;
            }

            // merged attribute data
            result.data = {
                name: attrName,
                value: attrValue
            };
            result.token = nameToken;

            return result;
        }

        attributes(ctx){
            let data = {};
            let result = this.createTokenResult(ctx.bracketLeft);

            if(ctx.attribute) ctx.attribute.forEach((attr, index) => {
                if(index > 0) result.merge(this.createTokenResult(ctx.Comma[0]));
                let attrResult = this.visit(attr);

                if(data.hasOwnProperty(attrResult.data.name)){
                    result.errors.push(this.createError('Duplicate attribute name "'+attrResult.data.name+'"', attrResult.token));
                }
                else data[attrResult.data.name] = attrResult.data.value;

                result.merge(attrResult);
            });

            result.merge(this.createTokenResult(ctx.bracketRight));
            result.data = data;
            return result;
        }

        stringValue(ctx, typeId){
            let valueToken = this.getFaultTolerantToken(ctx.value);
            let result = this.createTokenResult(typeId || 'VALUE', valueToken);

            result.data = this.checkUnquoteText(valueToken.image);
            result.token = valueToken;
            return result;
        }
    }

    // We only need a single interpreter instance because our interpreter has no state.
    return new QueryInterpreter();
}

export { createInterpreter };