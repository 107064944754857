<template>
    <v-dialog :value="value" @input="$emit('input', $event)" width="600">
        <v-card flat>
            <v-card-title>
                {{ "chat_add_chat" | translate }}
            </v-card-title>
            <v-card-text>
                <chat-autocomplete-recipients v-model="select" placeholder="Enter name" autofocus />
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import backend from "obj-fe/app/backend";
import ChatAutocompleteRecipients from "./chat-autocomplete-recipients.vue";

export default {
    props: ["value"],

    components: {
        ChatAutocompleteRecipients,
    },

    data() {
        return {
            select: "",
        };
    },

    watch: {
        value(val) {
            this.reset();
        },

        select(val) {
            if (val) {
                this.findOrCreateChatroom(val);
            }
        },
    },

    methods: {
        querySelections(query) {
            this.loading = true;

            backend.chat.findChatRecipient(
                { nameLike: query },
                (data) => {
                    this.items = data;
                    this.loading = false;
                },
                (error) => {
                    this.loading = false;
                }
            );
        },

        findOrCreateChatroom(recipientId) {
            backend.chat.findDirectChatroomWithRecipient({ recipientId }, (response) => {
                if (response && response.id) {
                    this.goToChatroom(response.id);
                } else {
                    this.$store
                        .dispatch("CHAT/CREATE_DIRECT_CHATROOM_WITH_RECIPIENT", { recipientId: recipientId })
                        .then((response) => {
                            this.goToChatroom(response.id);
                        });
                }
            });
        },

        goToChatroom(roomId) {
            this.$router.push({
                name: "chat-room",
                params: {
                    roomId: roomId,
                },
            });
            // Close the dialog
            this.$emit("input", false);
        },

        reset() {
            this.loading = false;
            this.select = "";
            this.search = "";
            this.items = [];
        },
    },
};
</script>

<style></style>
