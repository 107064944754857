import store from 'obj-fe/app/store';
import backend from 'obj-fe/app/backend';

store.registerModule('DASHBOARD', {
    namespaced: true,
    state:{
        panels:[]
    },
    actions:{
        ROUTE_CHANGE_START:{
            root: true,
            handler(ctx, route){
                if(route.name === 'dashboard') {
                    // no need to override default report backend services
                    // ctx.dispatch('SET_BACKEND_SERVICES');
                }
            }
        }
    },
    mutations:{
        
    }
});