<template>
    <v-dialog :value="value" @input="$emit('input', $event)" width="600">
        <v-card flat>
            <v-card-title>
                {{ "chat_add_channel" | translate }}
            </v-card-title>
            <v-card-text>
                <v-autocomplete
                    v-model="select"
                    :items="items"
                    :loading="loading"
                    :search-input.sync="search"
                    :item-value="null"
                    item-text="name"
                    placeholder="Enter name"
                    @keydown.enter="setCreateTopicChatRoomWithName"
                    autofocus
                    ref="channels"
                >
                    <template #no-data>
                        <v-card flat>
                            <v-card-text v-show="!loading && search" class="m-4">
                                {{ "chat_add_channel" | translate }}: <strong>"{{ search }}"</strong>
                            </v-card-text>
                        </v-card>
                    </template>
                </v-autocomplete>

                <div v-show="createTopicChatRoomWithName">
                    <chat-autocomplete-recipients v-model="recipients" multiple chips ref="recipients" />
                    <v-btn @click="createTopicChatRoom(createTopicChatRoomWithName, recipients)">
                        {{ "chat_add_channel" | translate }}
                    </v-btn>
                </div>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import backend from "obj-fe/app/backend";

import ChatAutocompleteRecipients from "./chat-autocomplete-recipients.vue";

export default {
    props: ["value"],

    components: {
        ChatAutocompleteRecipients,
    },

    data() {
        return {
            loading: false,
            recipients: [],
            select: "",
            search: "",
            items: [],
            createTopicChatRoomWithName: null,
        };
    },

    watch: {
        value(val) {
            this.reset();
        },

        select(val) {
            if (val && val.id) {
                this.goToChatroom(val.id);
            }
        },

        search(val) {
            val && this.querySelections(val);
        },
    },

    methods: {
        setCreateTopicChatRoomWithName() {
            if ((!this.items || this.items.length <= 0) && !this.loading && this.search) {
                this.createTopicChatRoomWithName = this.search;

                this.$nextTick(() => {
                    this.$refs.channels.blur();
                    this.$refs.recipients.focus();
                });
            }
        },

        createTopicChatRoom(name, recipientIds) {
            this.$store.dispatch("CHAT/CREATE_TOPIC_CHAT_ROOM", { name, recipientIds }).then((response) => {
                this.goToChatroom(response.id);
            });
        },

        querySelections(query) {
            this.loading = true;

            backend.chat.findTopicByName(
                { nameLike: query },
                (data) => {
                    this.items = data;
                    this.loading = false;
                },
                (error) => {
                    this.loading = false;
                }
            );
        },

        goToChatroom(roomId) {
            this.$router.push({
                name: "chat-room",
                params: {
                    roomId: roomId,
                },
            });

            // Close the dialog
            this.$emit("input", false);
        },

        reset() {
            this.loading = false;
            this.select = "";
            this.search = "";
            this.items = [];
            this.createTopicChatRoomWithName = null;
            this.recipients = [];
        },
    },
};
</script>

<style></style>
