<template>
    <div>
        <template v-for="(segment,i) in segments">
            <inv-conn-visual-port v-if="segment.visualHint.templateName === 'PORT'" :segment="segment" :expanded="expanded" :key="i"></inv-conn-visual-port>
            <inv-conn-visual-portallocation v-else-if="segment.visualHint.templateName === 'PORT_ALLOCATION'" :segment="segment" :expanded="expanded" :key="i"></inv-conn-visual-portallocation>
            <inv-conn-visual-connection v-else-if="segment.visualHint.templateName === 'CONNECTION'" :segment="segment" :expanded="expanded" :key="i"></inv-conn-visual-connection>
            <inv-conn-visual-router v-else-if="segment.visualHint.templateName === 'ROUTER'" :segment="segment" :expanded="expanded" :key="i"></inv-conn-visual-router>
        </template>
    </div>
</template>

<style scoped>
    >>> .attributes-line {
        border-bottom: 1px solid #808080;
        width:20px;
    }

    >>> .attributes-legend {
        font-size:12px;
        padding:5px 5px 5px 0px;
    }

    >>> .attributes-legend a {
        display:inline;
        font-weight: bold;
        font-size:14px;
        line-height:19px;
        cursor:pointer;
    }

    /* >>> .attributes-legend-edge {
        border-top:1px solid #808080;
        border-left:1px solid #808080;
        display:inline-block;
        position:absolute;
        width:5px;
    } */

    >>> .attributes-legend-value {
        border-left:1px solid #808080;
        padding-left:3px;
        font-size: 16px;
    }

    >>> .cell-full-height-fill {
        height: 1px;
    }

    @-moz-document url-prefix() {
        .cell-full-height-fill {
            height: 100%;
        }
    }

    >>> .cell-full-height-fill > div {
        border:1px;
        width:100%;
        height:100%;
        display:inline-table;
        content: "&nbsp;"
    }
</style>

<script>
    import InvConnVisualPort from './inv-conn-visual-port.vue';
    import InvConnVisualPortallocation from './inv-conn-visual-portallocation.vue';
    import InvConnVisualConnection from './inv-conn-visual-connection.vue';
    import InvConnVisualRouter from './inv-conn-visual-router.vue';

    export default {
        components:{
            InvConnVisualPort,
            InvConnVisualPortallocation,
            InvConnVisualConnection,
            InvConnVisualRouter
        },
        props:{
            segments: {},
            expanded: Boolean
        }
    };
</script>