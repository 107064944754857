<template>
    <v-tooltip top>
        <template v-slot:activator="{on: tooltip}">
        <v-btn
            v-on="{...tooltip}"
            v-if="appSettings.useAsyncExcelExports"
            @click="openModal"
            class="expand-actions-button obj-report-button"
            icon
            large
            color="green"
            :disabled="reports.length === 0"
        >
            <v-icon>
                fal fa-file-spreadsheet
            </v-icon>

            <v-dialog v-model="opened" max-width="600" persistent>
                <v-card>
                    <v-card-title>
                        {{ "send_excel_export_to_email" | translate }}
                    </v-card-title>

                    <v-card-text>
                        <v-form v-model="isFormValid">
                            <v-combobox
                                :value="uniqueSelectedItems"
                                :rules="[requiredRule]"
                                :label="'reports' | translate"
                                multiple
                                clearable
                                :items="items"
                                item-text="name"
                                item-value="id"
                                open-on-clear
                                @click:clear="selectedItems = []"
                            >
                                <template v-slot:prepend-item>
                                    <v-list-item color="accent" ripple @mousedown.prevent @click="toggleSelectAllItems">
                                        <v-list-item-icon>
                                            <v-icon>{{ getIconForSelectAllOrClearAllButton }}</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title>{{ (areAllReportsSelected ? 'clear_all' : 'select_all') | translate }}</v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </template>

                                <template v-slot:item="{ attrs, item }">
                                    <v-list-item :class="getItemClass(item)" v-bind="attrs" color="accent" ripple @click="toggleSelectItem(item)">
                                        <v-list-item-icon :class="getItemIconClass(item)">
                                            <v-icon>{{ getItemIcon(item) }}</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title :class="getItemTitleClass(item)" v-text="item.name"/>
                                        </v-list-item-content>
                                    </v-list-item>
                                </template>

                                <template v-slot:selection="data">
                                    <v-chip
                                        v-if="data.item.parentItemId"
                                        :key="data.item.id"
                                        v-bind="data.attrs"
                                        :input-value="data.selected"
                                        :disabled="data.disabled"
                                        close
                                        @click:close="deselectUniqueReportItem(data.item)"
                                    >
                                        <v-icon style="margin-right: 10px">{{ "fal " + data.item.icon }}</v-icon>
                                        {{ data.item.name }}
                                    </v-chip>
                                </template>
                            </v-combobox>

                            <v-text-field v-model="exportName" :rules="[requiredRule]" :label="'export_name' | translate"></v-text-field>
                        </v-form>
                    </v-card-text>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn text @click="opened = false" color="error">{{ "close" | translate }}</v-btn>
                        <v-btn outlined @click="triggerSend" :disabled="!isFormValid" color="success">{{ "send" | translate }}</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-btn>
        </template>
        <span>
            {{ "export-summary-excel" | translate }}
        </span>
    </v-tooltip>
</template>

<script>
export default {
    props: {
        objectId: String,
        reports: Array,
        tabGroups: Array,
        displayBlock: Boolean,
    },
    data() {
        return {
            selectedItems: [],
            exportName: "",
            opened: false,
            isFormValid: false,
        };
    },
    computed: {
        appSettings(){
            return this.$store.state.settings;
        },
        tabGroupItems() {
            return this.tabGroups.map(tabGroup => ({
                id: tabGroup.id,
                originalId: tabGroup.id,
                name: tabGroup.displayString,
                icon: tabGroup.icon,
            }));
        },
        reportItems() {
            return this.reports
                .filter(report => report.parameters.dataSource)
                .flatMap(report => report.tabGroups
                    .map(tabGroupId => ({
                        id: tabGroupId + '.' + report.parameters.dataSource,
                        originalId: report.parameters.dataSource,
                        name: report.name,
                        icon: report.icon,
                        parentItemId: tabGroupId
                    }))
                );
        },
        items() {
            return this.tabGroupItems
                .filter(tabGroupItem => this.getReportItemsOfTabGroup(tabGroupItem).length > 0)
                .flatMap(tabGroupItem =>
                        [
                            { value: tabGroupItem.id+'.divider', divider: true },
                            tabGroupItem,
                            ...this.getReportItemsOfTabGroup(tabGroupItem)
                        ]);
        },
        areAllReportsSelected() {
            return this.selectedItems.length === this.tabGroupItems.length + this.reportItems.length;
        },
        areAnyReportsSelected() {
            return this.selectedItems.length > 0;
        },
        getIconForSelectAllOrClearAllButton() {
             if (this.areAllReportsSelected) return 'fa-solid fa-square-xmark';
             if (this.areAnyReportsSelected) return 'fa-solid fa-square-minus';
             return 'fa-regular fa-square';
        },
        uniqueSelectedReportIds() {
            let selectedReportIds = this.selectedItems
                .filter(item => item.parentItemId).map(reportItem => reportItem.originalId);
            return [...new Set(selectedReportIds)];
        },
        uniqueSelectedItems() {
            let selectedItemsWithOriginalId = this.selectedItems
                .filter(item => item.parentItemId)
                .map(e => ({
                    ...e,
                    id: e.originalId,
                }));
            return [...new Map(selectedItemsWithOriginalId.map(item => [item.id, item])).values()];
        }
    },
    methods: {
        openModal() {
            this.clearSelection();
            this.exportName = `Export / Summary export / ${new Date().getUTCDate()}.${new Date().getUTCMonth() + 1}.${new Date().getUTCFullYear()}`;
            this.$nextTick(() => {
                this.opened = true;
            }, 100);
        },
        triggerSend() {
            this.$store.dispatch("INVENTORY/EXPORT_REPORTS_SEND", {
                objectId: this.objectId,
                reportIds: this.uniqueSelectedReportIds,
                reportName: this.exportName,
            });

            this.opened = false;
        },
        requiredRule(v) {
            return (Array.isArray(v) ? !!v.length : !!v) || this.$options.filters.translate("required");
        },
        allTabGroupReportItemsSelected(tabGroupItem) {
            return this.getSelectedTabGroupReportItems(tabGroupItem).length === this.getReportItemsOfTabGroup(tabGroupItem).length;
        },
        getSelectedTabGroupReportItems(tabGroupItem) {
            return this.selectedItems.filter(selectedItem => this.getReportItemsOfTabGroup(tabGroupItem).includes(selectedItem));
        },
        getReportItemsOfTabGroup(tabGroupItem) {
            return this.reportItems.filter(reportItem => reportItem.parentItemId === tabGroupItem.id);
        },
        toggleSelectAllItems() {
            this.areAllReportsSelected ? this.clearSelection() : this.selectAllItems();
        },
        toggleSelectItem(item) {
            item.parentItemId ? this.toggleSelectReport(item) : this.toggleSelectTabGroup(item);
        },
        toggleSelectTabGroup(tabGoup) {
            if (this.allTabGroupReportItemsSelected(tabGoup)) {
                let tabGroupReportItems = this.getReportItemsOfTabGroup(tabGoup);
                for (const reportItem of tabGroupReportItems) {
                    this.deselectReportItem(reportItem.originalId);
                }

                return;
            }

            this.selectedItems.push(tabGoup);

            let newSelectedReportItems = this.getReportItemsOfTabGroup(tabGoup)
                .filter(report => !this.selectedItems.includes(report));

            for (const reportItem of newSelectedReportItems) {
                this.selectReportItem(reportItem.originalId);
            }
        },
        toggleSelectReport(report) {
            this.selectedItems.includes(report) ? this.deselectReportItem(report.originalId) : this.selectReportItem(report.originalId);
        },
        deselectReportItem(reportOriginalId) {
            let reportItemInAllGroups = this.reportItems
                .filter(reportItem => reportOriginalId === reportItem.originalId);

            this.selectedItems = this.selectedItems
                .filter(selectedItem => !reportItemInAllGroups.includes(selectedItem));

            for (const reportItem of reportItemInAllGroups) {
                let tabGroupItem = this.tabGroupItems
                    .find(tabGroupItem => tabGroupItem.originalId === reportItem.parentItemId);

                this.selectedItems = this.selectedItems
                    .filter(selectedItem => selectedItem !== tabGroupItem);
            }
        },
        selectReportItem(reportOriginalId) {
            let reportItemInAllGroups = this.reportItems
                .filter(reportItem => reportOriginalId === reportItem.originalId);

            this.selectedItems.push(...reportItemInAllGroups);

            for (const reportItem of reportItemInAllGroups) {
                let tabGroupItem = this.tabGroupItems.find(tabGroupItem => tabGroupItem.originalId === reportItem.parentItemId);
                if (this.allTabGroupReportItemsSelected(tabGroupItem) && !this.selectedItems.includes(tabGroupItem)) {
                    this.selectedItems.push(tabGroupItem);
                }
            }
        },
        deselectUniqueReportItem(item) {
            let itemInAllTabGroups = this.reportItems
                .filter(reportItem => item.id === reportItem.originalId);

            for (const itemInGroup of itemInAllTabGroups) {
                this.deselectReportItem(itemInGroup.originalId);
            }
        },
        getItemIcon(item) {
            if (this.selectedItems.includes(item)) {
                return 'fas ' + item.icon;
            }

            return (item.parentItemId ? 'fal ' : 'fa-regular ') + item.icon;
        },
        getItemClass(item) {
            return this.selectedItems.includes(item) ? 'v-list-item--active' : '';
        },
        getItemIconClass(item) {
            return item.parentItemId ? 'v-list-item-report' : '';
        },
        getItemTitleClass(item) {
            return item.parentItemId ? '' : 'v-list-item-title-group';
        },
        clearSelection() {
            this.selectedItems = [];
        },
        selectAllItems() {
            this.selectedItems = [...this.tabGroupItems, ...this.reportItems];
        },
    },
};
</script>

<style scoped>
>>> .v-select__selections {
    min-height: 30px
}
.v-list-item-report {
    margin-left: 36px;
}
.v-list-item-title-group {
    font-weight: bold;
}
.v-list-item__icon {
    display: flex;
    justify-content: center;
}
</style>
