<template>
    <div class="spreadsheet-wrapper" ref="spreadsheetWrapper" :id="'spreadsheet_' + (id || generatedId)">
        <div class="spreadsheet fill-height" :class="{ 'always-white-cells': alwaysWhiteCells }" ref="spreadsheetContainer">
            <div :style="{ width:(tableWidth+containerRightOffset)+'px' }" style="height:1px;background-color:transparent"></div>
            <table :style="{ width:tableWidth+'px', height:tableHeight+'px' }" ref="table">
                <thead>
                    <tr class="width-helper">
                        <th></th>
                        <th v-for="(col,index) in columns" :key="index" :style="{ width: col.width+'px' }"></th>
                    </tr>
                    <tr v-if="computedColumnGroups" class="row-0 row-col-group">
                        <th @mousedown="$event.preventDefault();selectAll()"></th>
                        <th v-for="(colGroup,index) in computedColumnGroups" :key="index" :colspan="colGroup.cols" :style="{ width: colGroup.width+'px' }">{{colGroup.name | translate}}</th>
                    </tr>
                    <template v-for="(headers, rowType) in headerRowsByType">
                        <tr v-if="!headers.hidden" :key="rowType" :data-header-row-type="rowType" :class="[ 'row-'+(headerRowIndexesByType[rowType]+(computedColumnGroups ? 1 : 0)) ].concat(highlightPosClasses.rowTypes[rowType] || [])">
                            <th @mousedown="$event.preventDefault();selectAll()">&nbsp;</th>
                            <th v-for="(header, colIndex) in headers" 
                                :key="colIndex" 
                                data-cell-type="column" 
                                :data-col-index="colIndex" 
                                :style="{ width: columns[colIndex].width+'px' }" 
                                @mousedown="handleColSelectionStart(colIndex)"
                                @contextmenu="showColContextMenu($event, header, colIndex)"
                                :class="highlightPosClasses.cols[colIndex]">

                                <v-icon v-if="!!header.filterable" x-small @click="showColFilterMenu($event, header, colIndex)" :class="{ 'accent--text':Array.isArray(header.filter) ? header.filter.length > 0 : header.filter !== undefined && header.filter !== null }">fa-filter</v-icon>
                                <v-icon v-if="!!header.sortable" x-small @click="showColSortMenu($event, header, colIndex)" :class="{ 'accent--text':!!header.sort }">
                                    {{ header.sort === 1 ? 'fa-sort-asc' : ( header.sort === -1 ? 'fa-sort-desc' : 'fa-sort') }}
                                </v-icon>
                                {{(header ? header.name : '') | translate}}
                                <div class="col-width-resizer" :data-resizer-col-index="colIndex" @mousedown="$event => handleColResizeStart($event, colIndex)"></div>
                            </th>
                        </tr>
                    </template>
                </thead>
                <tbody>
                    <template v-for="(row, visibleRowIndex) in visibleRows">
                        <spreadsheet-row    :key="row._state.id" 
                                            :row="row" 
                                            :row-index="visibleRows.firstRowIndex + visibleRowIndex"
                                            :columns="columns"
                                            :column-data-types="columnDataTypes"
                                            :disable-inline-edit="rowDetails.opened"
                                            :row-selection-classes="highlightPosClasses.rows[visibleRows.firstRowIndex + visibleRowIndex]"
                                            :cell-selection-classes="cellSelectionClasses"
                                            @row-selection-start="rowIndex => handleRowSelectionStart(rowIndex)"
                                            @cell-selection-start="e => handleCellSelectionStart(e.rowIndex, e.colIndex)"
                                            @toggle-edit-cell="e => toggleCellEdit(e.rowIndex, e.colIndex)"
                                            @show-context-menu="v => showRowContextMenu(v.$event, v.rowIndex)"
                                            @cell-renderer-action="e => callCellRendererAction(e.rowIndex, e.colIndex, e.data)"
                                            @cell-editor-action="e => callCellEditorAction(e.rowIndex, e.colIndex, e.data)"
                                            @set-cell-value="e => setCellValue(e.rowIndex, e.colIndex, e.value)"
                                            @close-editing-mode="closeEditingMode">
                        </spreadsheet-row>
                    </template>
                </tbody>
            </table>

            <div class="pagination-placeholder"></div>

            <v-menu v-model="rowMenu.show" :position-x="rowMenu.x" :position-y="rowMenu.y" absolute offset-y>
                <v-list>
                    <template v-for="(action, i) in rowMenu.actions">
                        <v-list-item :key="i" @click="callMenuAction(action)" :disabled="action.disabled" @mousedown="$event.stopPropagation()">
                            <v-list-item-title>{{action.title}}</v-list-item-title>
                        </v-list-item>
                        <v-divider :key="'divider_'+i" v-if="action.divider && rowMenu.actions.length > i+1"></v-divider>
                    </template>
                </v-list>
            </v-menu>

            <v-menu v-model="colMenu.show" :position-x="colMenu.x" :position-y="colMenu.y" absolute offset-x>
                <v-list>
                    <template v-for="(action, i) in colMenu.actions">
                        <v-list-item :key="i" @click="callMenuAction(action)" :disabled="action.disabled" @mousedown="$event.stopPropagation()">
                            <v-list-item-title>{{action.title}}</v-list-item-title>
                        </v-list-item>
                        <v-divider :key="'divider_'+i" v-if="action.divider && colMenu.actions.length > i+1"></v-divider>
                    </template>
                </v-list>
            </v-menu>

            <v-menu :value="colFilterMenu.show" :position-x="colFilterMenu.x" max-height="300" :position-y="colFilterMenu.y" absolute offset-x>
                <v-list>
                    <template v-for="(action, i) in colFilterMenu.actions">
                        <v-list-item :key="i" @click="callMenuAction(action)" :disabled="action.disabled" @mousedown="$event.stopPropagation()">
                            <v-list-item-title>{{action.title}}</v-list-item-title>
                        </v-list-item>
                        <v-divider :key="'divider_'+i" v-if="action.divider && colFilterMenu.actions.length > i+1"></v-divider>
                    </template>

                    <v-divider></v-divider>
                    <v-list-item    v-if="colFilterMenu.component"
                                    @mousedown="$event.stopPropagation()"
                                    @click="$event.stopPropagation()"
                                    @mouseup="$event.stopPropagation()"
                                    @keydown="$event.stopPropagation()">
                        <v-list-item-title @mousedown="$event.stopPropagation()">
                            <v-component :key="colFilterMenu.colIndex"
                                         :is="colFilterMenu.component"
                                         :value="colFilterMenu.value"
                                         :multi="colFilterMenu.multi"
                                         :is-loading="colFilterMenu.isLoading"
                                         :data-type="colFilterMenu.dataType"
                                         :can-choose-text="!colFilterMenu.onlyEnums"
                                         @suggestions="suggs => colFilterMenu.suggestions = suggs" 
                                         @input-text="val => colFilterMenu.suggest(val)" 
                                         @input="val => colFilterMenu.setValue(val)">
                            </v-component>
                        </v-list-item-title>
                    </v-list-item>

                    <v-divider v-if="colFilterMenu.suggestions && colFilterMenu.suggestions.length"></v-divider>

                    <v-list-item v-if="colFilterMenu.isLoading && !colFilterMenu.suggestions.length">
                        <v-list-item-title>
                            {{'loading' | translate}}
                        </v-list-item-title>
                    </v-list-item>

                    <template v-for="(sugg, i) in colFilterMenu.suggestions">
                        <v-list-item :key="'sugg_' + i" @click="$event.stopPropagation();colFilterMenu.setValue(sugg, true)" @mousedown="$event.stopPropagation()" :input-value="colFilterMenu.value === sugg">
                            <v-list-item-title>
                                <span v-if="colFilterMenu.multi">
                                    <v-icon v-if="colFilterMenu.isSelected(sugg)" color="accent">fa-check-square</v-icon>
                                    <v-icon v-else>fa-regular fa-square</v-icon>
                                </span>
                                {{(sugg && sugg.text ? sugg.text : sugg)+'' | translate}}
                            </v-list-item-title>
                        </v-list-item>
                    </template>
                    
                </v-list>
            </v-menu>

            <v-menu v-model="colSortMenu.show" :position-x="colSortMenu.x" :position-y="colSortMenu.y" absolute offset-x>
                <v-list>
                    <template v-for="(action, i) in colSortMenu.actions">
                        <v-list-item :key="i" @click="callMenuAction(action)" :disabled="action.disabled" @mousedown="$event.stopPropagation()">
                            <v-list-item-title>{{action.title}}</v-list-item-title>
                        </v-list-item>
                        <v-divider :key="'divider_'+i" v-if="action.divider && colSortMenu.actions.length > i+1"></v-divider>
                    </template>
                </v-list>
            </v-menu>

            <div ref="colWidthMark" class="col-width-mark"></div>
        </div>

        <div class="pagination" v-if="pagination" :class="{ sticky:stickyPagination }">
            <div class="pagination-content">
                <v-row no-gutters align="baseline">
                    <v-col class="flex-grow-0">
                        <v-btn small icon :disabled="!internalPagination.prev" @click="$emit('page', internalPagination.page-1, resetPagination)"><v-icon>fa-chevron-left</v-icon></v-btn>
                    </v-col>
                    <v-spacer></v-spacer>
                    <!-- <v-col class="flex-grow-0">
                        <v-btn small icon depressed>{{'load_more_documents' | translate}}</v-btn>
                    </v-col> -->
                    <v-col class="flex-grow-0 text-no-wrap">
                        {{'page_size' | translate}}
                    </v-col>
                    <v-col class="flex-grow-0">
                        <v-combobox :items="[5,10,20,50,100]" v-model="internalPagination.limit" @change="limit => $emit('limit', limit, resetPagination)" hide-details style="width:100px;padding:0" class="ma-0"></v-combobox>
                    </v-col>
                    <v-col class="flex-grow-0 text-no-wrap">
                        ,&nbsp;{{'page' | translate}}
                    </v-col>
                    <v-col class="flex-grow-0">
                        <v-text-field type="number" v-model="internalPagination.page" @change="page => $emit('page', parseInt(page,10), resetPagination)" hide-details style="width:100px;padding:0" class="ma-0"></v-text-field>
                    </v-col>
                    <v-col v-if="internalPagination.pages > 0" class="flex-grow-0 text-no-wrap">
                        {{'of' | translate}} {{internalPagination.pages}} ({{internalPagination.count}})
                    </v-col>
                    <v-col class="flex-grow-0">
                        <v-btn small icon @click="$emit('page', internalPagination.page, resetPagination)"><v-icon>fa-refresh</v-icon></v-btn>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col class="flex-grow-0">
                        <v-btn small icon :disabled="!internalPagination.next" @click="$emit('page', internalPagination.page+1, resetPagination)"><v-icon>fa-chevron-right</v-icon></v-btn>
                    </v-col>
                </v-row>
            </div>
        </div>
        <div v-html="stickyHeadersStyle"></div>

        <v-navigation-drawer :value="rowDetails.opened" app fixed right clipped width="800" stateless>
            <v-card flat class="fill-height">
                <v-toolbar ref="toolbar" dense flat>
                    <v-tooltip bottom>
                        <template v-slot:activator="{on}">
                            <v-btn icon @click="rowDetails.opened = false" v-on="on"><v-icon>mdi-close</v-icon></v-btn>
                        </template>
                        <span>{{'close' | translate}}</span>
                    </v-tooltip>

                    <v-spacer></v-spacer>

                    <!-- <slot name="detail-actions"></slot> -->
                    <v-tooltip bottom>
                        <template v-slot:activator="{on}">
                            <v-btn @click="rowDetails.opened = false" v-on="on">{{'done' | translate}}</v-btn>
                        </template>
                        <span>{{'done' | translate}}</span>
                    </v-tooltip>
                </v-toolbar>

                <v-card-text style="height:calc(100% - 48px);overflow:auto">
                    <!-- <slot name="detail"></slot> -->
                    <spreadsheet-row-form v-if="rowDetails.opened" :row-index="rowDetails.rowIndex"></spreadsheet-row-form>
                </v-card-text>
            </v-card>
        </v-navigation-drawer>
    </div>
</template>

<script>
    import localStore from './local-store';

    import SpreadsheetRow from './spreadsheet-row.vue';
    import SpreadsheetRowForm from './spreadsheet-row-form.vue';
    import defaultDataTypes from './spreadsheet-data-types.js';
    import defaultWatchers from './spreadsheet-watchers.js';
    import defaultFilters from './spreadsheet-filters';

    // functionality mixins
    import copypasteMixin from './spreadsheet-mixin-copypaste.js';
    import dataMixin from './spreadsheet-mixin-data.js';
    import errorsMixin from './spreadsheet-mixin-errors.js';
    import keyboardMixin from './spreadsheet-mixin-keyboard.js';
    import selectionMixin from './spreadsheet-mixin-selection.js';

    import Row from './row.js';
    import objectAsArray from './object-as-array.js';

    // TODO: url state

    const NUM_COLUMN_WIDTH = 50; // if changed - check css
    const CONTAINER_RIGHT_OFFSET = 50; // because if container width was same as table width, last column width will be unpossible to change by dragging
    const MIN_HEIGHT = 300;

    export default {
        mixins:[
            copypasteMixin,
            dataMixin,
            errorsMixin,
            keyboardMixin,
            selectionMixin
        ],
        components:{
            SpreadsheetRow,
            SpreadsheetRowForm
        },
        props:{
            id:{},
            columnGroups: Array,
            headers: {},
            fixColIndex: Number,
            data: {},
            mergeRowsById: Boolean,
            watchers: {},
            dataTypes: {},
            filters:{},
            pagination: {},
            onRowMenu: Function,
            ignoreRowProps: Array,
            disableInlineEdit: Boolean,
            rowHeight: Number,
            fitWidth: Boolean,
            fitHeight: Boolean,
            alwaysWhiteCells: Boolean,
            virtualScrollOn: Boolean
        },
        data(){
            return {
                generatedId: new Date().getTime(), // for styles, if id prop is not defined

                containerRightOffset: 0, //CONTAINER_RIGHT_OFFSET,

                containerScrollTop: 0,
                containerHeight: 0,
                defaultRowHeight: 26,
                virtualScrollBuffer: 2,

                editingCell:{
                    active: false,
                    rowIndex: -1,
                    colIndex: -1,
                    origValue: undefined
                },

                rowMenu:{
                    show: false,
                    x: null,
                    y: null,
                    actions: []
                },

                colMenu:{
                    show: false,
                    x: null,
                    y: null,
                    actions: []
                },

                colFilterMenu:{
                    show: false,
                    isLoading: true,
                    x: null,
                    y: null,
                    actions:[],
                    suggestions:[],
                    component:'',
                    value: undefined
                },

                colSortMenu:{
                    show: false,
                    x: null,
                    y: null,
                    actions:[]
                },

                stickyPagination: false,
                internalPagination: {},

                // // spreadsheet state cache - no need to be reactive
                // stateCache:{
                //     headerWidths:{}
                // },

                rowDetails:{
                    opened: false,
                    rowIndex: null
                },

                columns: null,

                columnWatchers: null,
                columnDataTypes: null,
            };
        },
        watch:{
            id:{
                immediate: true,
                handler(id){
                    if(!id) return;

                    let state = localStore.get('spreadsheet:' + id);
                    this.stateCache = state || {};
                }
            },
            data:{
                immediate: true,
                handler(){
                    this.resetChangeHistory();

                    let rows = (this.data || []).map(rowData => new Row(rowData, this.ignoreRowProps));
                    if(rows.length === 0 && !this.addRowDisabled) {
                        let newRowType = Object.keys(this.headerRowIndexesByType)[0];
                        rows.push(new Row({ rowType:newRowType }, this.ignoreRowProps)); // append one empty row at the end of spreadsheet data
                    }

                    // if columns are not defined, recalc it
                    if(!this.columns) this.calcColumnsFromHeaderRowsByType(this.headerRowsByType);
                    this.calcColumnWatchers();
                    this.calcColumnDataTypes();
                    this.calcColumnFilters();


                    if(this.mergeRowsById && this.rows) {
                        let rowsById = {};
                        for(let index in this.rows) {
                            let row = this.rows[index];
                            if(row.id) rowsById[row.id] = row;
                        }
                        rows = rows.map(row => rowsById[row.id] ? rowsById[row.id] : row);
                    }

                    this.setRows(rows);

                    // force trigger all validations on load data
                    this.triggerAllChangeWatchers();
                }
            },
            headers:{
                immediate:true,
                handler(headers, prevHeaders){
                    // on headers change, recalc columns
                    if(prevHeaders) {
                        this.calcColumnsFromHeaderRowsByType(this.headerRowsByType);
                    }

                    if(this.fitWidth) this.$nextTick(() => this.recalcTableToWidthIfNeeded());
                }
            },
            watchers:{
                immediate: true,
                handler(){
                    this.calcColumnWatchers();
                }
            },
            dataTypes:{
                immediate: true,
                handler(){
                    this.calcColumnDataTypes();
                }
            },
            pagination:{
                immediate: true,
                handler(value){
                    this.internalPagination = { ...value || {} };
                }
            },
            headerRowsByType(headers, prevHeaders){
                // force trigger all validations on load data
                if(!prevHeaders) return this.triggerAllChangeWatchers();

                // track col changes if there are colKeys used in column definitions
                let colsChanged = {};
                let colKeysUsed = false;
                for(let rowType in headers) {
                    colsChanged[rowType] = [];
                    headers[rowType].forEach((col, colIndex) => {
                        if(col.colKey) {
                            if(col.colKey !== (prevHeaders[rowType][colIndex] || {}).colKey){
                                colsChanged[rowType].push(colIndex);
                            }
                            colKeysUsed = true;
                        }
                    });
                }

                if(colKeysUsed) {
                    this.triggerChangeWatchersForColumns(colsChanged);
                }
                else this.triggerAllChangeWatchers();
            },
            selectedCells(selectedCells){
                if(!this.editingCell.active) return;

                if(selectedCells.count !== 1) this.closeEditingMode();
                else if(selectedCells.fromRowIndex !== this.editingCell.rowIndex || selectedCells.fromColIndex !== this.editingCell.colIndex) this.closeEditingMode();
            }
        },
        computed:{
            tableHeight(){
                let defaultRowHeight = this.rowHeight || this.defaultRowHeight;
                return this.rows.length * defaultRowHeight;
            },
            tableWidth(){
                return NUM_COLUMN_WIDTH + this.columns.reduce((sum, column) => sum + column.width, 0);
            },
            visibleRowsCount(){
                if(!this.virtualScrollOn) return this.rows.length;

                let defaultRowHeight = this.rowHeight || this.defaultRowHeight;
                return Math.ceil(this.containerHeight / defaultRowHeight);
            },
            firstVisibleRowIndex(){
                if(!this.virtualScrollOn) return 0;

                let defaultRowHeight = this.rowHeight || this.defaultRowHeight;
                let firstVisibleRowIndex = Math.max(Math.floor(this.containerScrollTop / defaultRowHeight) - this.virtualScrollBuffer, 0);

                // TODO: keep same visible rows, even if user scrolls to the end of spreadsheet
                // ??? firstVisibleRowIndex = Math.max(this.virtualScrollBuffer + this.visibleRowsCount, firstVisibleRowIndex);

                return firstVisibleRowIndex;
            },
            lastVisibleRowIndex(){
                return this.firstVisibleRowIndex + this.visibleRowsCount + this.virtualScrollBuffer;
            },
            visibleRows(){
                if(this.rows.length === 0) return [];

                let defaultRowHeight = this.rowHeight || this.defaultRowHeight;
                let firstVisibleRowIndex = this.firstVisibleRowIndex;
                let lastVisibleRowIndex = this.lastVisibleRowIndex;
                
                let visibleRows = this.rows.slice(firstVisibleRowIndex, lastVisibleRowIndex + 1);

                visibleRows.forEach(row => row._state.height = this.virtualScrollOn ? defaultRowHeight : null); // reset rows height
                visibleRows.firstRowIndex = 0;
                if(!this.virtualScrollOn) return visibleRows;

                visibleRows[0]._state.height = (firstVisibleRowIndex+1) * defaultRowHeight;
                visibleRows[ visibleRows.length - 1 ]._state.height = Math.max(1, this.rows.length - lastVisibleRowIndex) * defaultRowHeight;
                
                visibleRows.firstRowIndex = firstVisibleRowIndex;
                return visibleRows;
            },
            headerRowsByType(){
                if(!this.headers) return {};

                let headerRowsByType = {};
                if(Array.isArray(this.headers)) headerRowsByType = { default:this.headers };
                else headerRowsByType = { ...this.headers };

                // sync headers length, because header rows may have different length
                let maxCols = 0;
                for(let rowType in headerRowsByType){
                    let colsLength = headerRowsByType[rowType].length;
                    headerRowsByType[rowType] = headerRowsByType[rowType].map(c => c ? c : { readonly:true });
                    if(colsLength > maxCols) maxCols = colsLength;
                }

                for(let rowType in headerRowsByType){
                    let cols = headerRowsByType[rowType];
                    let colsDiff = maxCols - cols.length;
                    for(let i=0;i<colsDiff;i++){
                        cols.push({ readonly: true });
                    }
                    headerRowsByType[rowType] = cols;
                }

                // update col widths from widths cache
                for(let rowType in headerRowsByType){
                    let cols = headerRowsByType[rowType];
                    cols.forEach((col, colIndex) => {
                        let headerWidthsCache = (this.stateCache || {}).headerWidths || {};
                        let storedWidth = headerWidthsCache[rowType] ? headerWidthsCache[rowType][colIndex] : undefined;
                        col.width = (storedWidth ? storedWidth : col.width) || 100;
                    });
                }

                return headerRowsByType;
            },
            headerRowIndexesByType(){
                let indexesByType = {};
                Object.keys(this.headerRowsByType).forEach((rowType, index) => indexesByType[ rowType ] = index);
                return indexesByType;
            },
            defaultRowType(){
                return Object.keys(this.headerRowsByType)[0] || 'default';
            },
            computedColumnGroups(){
                if(!this.columnGroups || !this.columnGroups.length) return null;

                let lastColIndex = 0;
                
                return this.columnGroups.map(cg => {
                    let width = this.columns.reduce((acc, curr, index) => (index >= lastColIndex && index < lastColIndex+cg.cols ? curr : 0) + acc, 0);
                    lastColIndex = lastColIndex + cg.cols;
                    return {
                        name: cg.name,
                        cols: cg.cols || cg.count,
                        width
                    };
                });
            },
            cellSelectionClasses(){
                let cellSelectionClasses = {};

                if(this.selectedCells.fromRowIndex > -1) {
                    for(let rowIndex = this.selectedCells.fromRowIndex; rowIndex <= this.selectedCells.toRowIndex; rowIndex++){
                        let rowBorderClasses = [];

                        if(rowIndex === this.selectedCells.fromRowIndex) rowBorderClasses.push('h-top'); // first selected row
                        if(rowIndex === this.selectedCells.toRowIndex) rowBorderClasses.push('h-bottom'); // last selected row

                        for(let colIndex = this.selectedCells.fromColIndex; colIndex <= this.selectedCells.toColIndex; colIndex++){
                            let colBorderClasses = [];
                            if(colIndex === this.selectedCells.fromColIndex) colBorderClasses.push('h-left'); // first selected col
                            if(colIndex === this.selectedCells.toColIndex) colBorderClasses.push('h-right'); // last selected col
                            if(colIndex === this.selectedCells.firstColIndex && rowIndex === this.selectedCells.firstRowIndex) colBorderClasses.push('first-selected'); // only first selected cell is white other are grayed
                            
                            cellSelectionClasses[ rowIndex +':'+ colIndex ] = rowBorderClasses.concat(colBorderClasses);
                        }
                    }
                }

                return cellSelectionClasses;
            },
            highlightPosClasses(){
                let fromRowIndex = this.selectedCells.fromRowIndex;
                let toRowIndex = this.selectedCells.toRowIndex;
                
                let rowTypes = {};
                for(let i=fromRowIndex;i<=toRowIndex;i++){
                    let row = this.getRow(i);
                    if(row) rowTypes[ row.rowType ] = ['active'];
                }

                let rows = {};
                for(let i=fromRowIndex;i<=toRowIndex;i++){
                    rows[i] = ['active'];
                }

                let fromColIndex = this.selectedCells.fromColIndex;
                let toColIndex = this.selectedCells.toColIndex;

                let cols = {};
                for(let i=fromColIndex;i<=toColIndex;i++){
                    cols[i] = ['active'];
                }

                return {
                    rows,
                    cols,
                    rowTypes
                };
            },
            stickyHeadersStyle(){
                if(isNaN(this.fixColIndex)) return '';
                
                // TODO: try to move styles outside script, maybe use classes - check rendering performance

                let stickyStyle = 'position:sticky;z-index:4;';
                let styleToInject = '<style>';
                let colWidthsSum = NUM_COLUMN_WIDTH; // first num column has 50px width

                let i;
                for(i=0;i<=this.fixColIndex;i++){
                    colWidthsSum += (i > 0 ? this.columns[i-1].width : 0);
                    let borderStyle = i === this.fixColIndex ? 'border-right:1px solid #ccc;' : ''; // last column should have right border
                    styleToInject += '\n #spreadsheet_' + (this.id || this.generatedId) + ' tr > [data-col-index="'+i+'"] { '+stickyStyle+'left:' +colWidthsSum+ 'px;'+borderStyle+' }';
                    styleToInject += '\n #spreadsheet_' + (this.id || this.generatedId) + ' tr > [data-col-index="'+i+'"][data-cell-type="cell"] { z-index:3; }';
                }

                // // remove left border of next not fixed column ?
                // styleToInject += '\n #spreadsheet_' + (this.id || this.generatedId) + ' tr > [data-col-index="'+i+'"] { border-left:none; }';

                return styleToInject + '</style>';
            },
        },
        methods:{
            resetPagination(){
                this.$nextTick(() => this.internalPagination = { ...this.pagination || {} });
            },
            openRowDetails(rowIndex){
                this.rowDetails.rowIndex = rowIndex;
                this.rowDetails.opened = true;
            },
            closeRowDetails(){
                this.rowDetails.opened = false;
            },
            extendStateCache(obj){
                this.stateCache = { ...this.stateCache, ...obj };
                if(this.id) localStore.set('spreadsheet:' + this.id, this.stateCache);
            },
            calcColumnWatchers(){
                this.columnWatchers = { ...defaultWatchers, ...this.watchers || {} };
            },
            calcColumnDataTypes(){
                this.columnDataTypes = { ...defaultDataTypes, ...this.dataTypes || {} };
            },
            calcColumnFilters(){
                this.columnFilters = { ...defaultFilters, ...this.filters || {} };
            },
            calcColumnsFromHeaderRowsByType(headerRowsByType){
                let columns = [];
                let columnsByRowType = {};

                // merge header rows into columns
                for(let rowType in headerRowsByType){
                    headerRowsByType[rowType].forEach((header, index) => {
                        if(!header) return;
                        if(columns[index]) columns[index][ 'header'+index ] = { ...header };
                        else columns[index] = { ...header };
                        columns[index].width = Math.max(columns[index].width || 100, header.width || 100);

                        columnsByRowType[rowType] = columnsByRowType[rowType] || [];
                        columnsByRowType[rowType][index] = header;
                        columnsByRowType[rowType][index].width = columnsByRowType[rowType][index].width || 100;
                    });
                }

                columns = objectAsArray(columns);

                for(let rowType in columnsByRowType){
                    Object.defineProperty(columns, rowType, { enumerable: false, writable: true, value: objectAsArray(columnsByRowType[rowType]) });
                }

                this.columns = columns;
            },
            callMenuAction(action){
                if(!action.action(this)) this.clearCellsSelection();
            },
            showRowContextMenu(e, rowIndex){
                e.preventDefault();
                this.rowMenu.show = false;
                this.rowMenu.x = e.clientX;
                this.rowMenu.y = e.clientY;

                // change selection into rows if not already selected row
                if(!this.isRowSelected(rowIndex)) this.selectRow(rowIndex);

                let defaultActions = [
                    {
                        id:'insert-above',
                        title: this.$translate('insert') + ' ' + (this.selectedCells.rowsCount || 1) + ' ' + this.$translate('above'),
                        action(spreadsheet){
                            spreadsheet.addRows(spreadsheet.selectedCells.fromRowIndex-1, new Array(spreadsheet.selectedCells.rowsCount || 1).fill({}));
                        },
                        disabled: false
                    },
                    {
                        id:'insert-below',
                        title: this.$translate('insert') + ' ' + (this.selectedCells.rowsCount || 1) + ' ' + this.$translate('below'),
                        action(spreadsheet){
                            spreadsheet.addRows(spreadsheet.selectedCells.toRowIndex, new Array(spreadsheet.selectedCells.rowsCount || 1).fill({}));
                        },
                        disabled: false,
                        divider: true
                    },
                    // {
                    //     id:'paste-rows-below',
                    //     title: this.$translate('paste_rows_below'),
                    //     action(spreadsheet){
                    //         spreadsheet.insertRowsFromClipboard();
                    //     },
                    //     disabled: false,
                    //     divider: true
                    // },
                    {
                        id:'remove',
                        title: this.selectedCells.rowsCount === 1 ? this.$translate('delete_row') + ' ' + (this.selectedCells.fromRowIndex + 1) : this.$translate('delete_rows') + ' ' + (this.selectedCells.fromRowIndex + 1) + ' - ' + (this.selectedCells.toRowIndex + 1),
                        action(spreadsheet){
                            let fromRowIndex = spreadsheet.selectedCells.fromRowIndex;
                            let toRowIndex = spreadsheet.selectedCells.toRowIndex;
                            spreadsheet.removeRows(fromRowIndex, toRowIndex);
                        },
                        disabled: false,
                        divider: true
                    }
                ];

                function disableDefaultAction(actionId){
                    if(!actionId) return defaultActions.forEach(a => a.disabled = true);

                    let targetAction = defaultActions.filter(a => a.id === actionId)[0];
                    if(targetAction) targetAction.disabled = true;
                }

                function replaceDefaultAction(actionDef){
                    let actionId = actionDef.id;
                    let actionIndex = defaultActions.findIndex(a => a.id === actionId);
                    if(actionIndex > -1) defaultActions[ actionIndex ] = actionDef;
                }

                let customActions = [];
                if(this.onRowMenu) {
                    customActions = this.onRowMenu({ row: this.getRow(rowIndex), disableDefaultAction, replaceDefaultAction, rowIndex, selectedCells: this.selectedCells });
                }

                if(!Array.isArray(customActions)) return;
                this.rowMenu.actions = defaultActions.concat(customActions);

                setTimeout(() => {
                    this.$nextTick(() => {
                        this.rowMenu.show = true;
                    });
                });
            },

            showColContextMenu(e, header, colIndex){
                e.preventDefault();
                this.colMenu.show = false;
                this.colMenu.x = e.clientX;
                this.colMenu.y = e.clientY;

                // TODO: add menu items and show column menu if needed
                return;

                this.colMenu.actions = [];

                setTimeout(() => {
                    this.$nextTick(() => {
                        this.colMenu.show = true;
                    });
                });
            },

            showColFilterMenu(e, header, colIndex){
                e.preventDefault();
                this.colFilterMenu.show = false;
                this.colFilterMenu.x = e.clientX;
                this.colFilterMenu.y = e.clientY + 13;
                this.colFilterMenu.colIndex = colIndex;

                const colFilterMenu = this.colFilterMenu;
                this.colFilterMenu.actions = [
                    {
                        id:'filter-values-clear',
                        title: this.$translate('filter_clear'),
                        action(spreadsheet){
                            colFilterMenu.setValue(undefined, true);
                        },
                        disabled: false
                    }
                ];

                let filter = this.columnFilters[ header.dataType || 'default' ] || this.columnFilters.default;

                let suggGetter;

                if(typeof header.filterSuggestions === 'function'){
                    suggGetter = header.filterSuggestions;
                }
                else if(typeof filter.suggestions === 'function') {
                    suggGetter = filter.suggestions;
                }

                this.colFilterMenu.onlyEnums = header.filterIsEnum || filter.isEnum;
                this.colFilterMenu.value = header.filter;
                this.colFilterMenu.component = filter.component;
                this.colFilterMenu.multi = header.filterMultiple;
                this.colFilterMenu.isSelected = (value) => !!findValueInSuggestions(unifyFilterValue(header.filter), value);

                this.colFilterMenu.suggest = (value) => {};
                this.colFilterMenu.isLoading = false;

                function unifyFilterValue(value){
                    if(header.filterMultiple) return (Array.isArray(value) ? value.slice() : (value ? [value] : [])).filter(v => v !== null && v !== undefined);
                    else return value;
                }

                function findValueInSuggestions(suggestions, value){
                    if(value === null || value === undefined) return;
                    return suggestions.find(v => v === value || v?.value === value || v?.value === value?.value || v === value?.value);
                }

                function mergeSelectedValues(suggestions = []){
                    if(!header.filterMultiple) return suggestions;
                    let selectedValues = unifyFilterValue(header.filter);

                    selectedValues.forEach(value => {
                        let exists = !!findValueInSuggestions(suggestions, value);
                        if(!exists) suggestions.unshift(typeof value === 'string' ? { text:value, value } : value);
                    });
                    return suggestions;
                }

                if(suggGetter) {
                    this.colFilterMenu.suggestions = [];
                    this.colFilterMenu.suggest = (value) => suggGetter.call(this, { value, prefix:value, header, colIndex, dataKey:header.dataKey }, (suggs) => {
                        if(colIndex !== this.colFilterMenu.colIndex) return; // user is filtering another column
                        this.colFilterMenu.suggestions = mergeSelectedValues(suggs);
                        this.colFilterMenu.isLoading = false;
                    });
                    
                    // call immediately to fill proposed values
                    this.colFilterMenu.isLoading = true;
                    this.colFilterMenu.suggest();
                }
                else this.colFilterMenu.suggestions = mergeSelectedValues(header.filterSuggestions || filter.suggestions);

                this.colFilterMenu.dataType = this.columnDataTypes[ header.dataType || header.filterDataType || 'string'];

                this.colFilterMenu.setValue = (value, isTrustedValue) => {
                    if(!isTrustedValue) {
                        if(filter.validate) {
                            let dataType = this.colFilterMenu.dataType;
                            let isValid = filter.validate.call(this, { value, colIndex, column: this.columns[colIndex], dataType });
                            if(!isValid) return; // do nothing, just ignore value
                            else if(filter.parse) value = filter.parse.call(this, { value, colIndex, column: this.columns[colIndex], dataType });
                        }
                    }

                    if(filter.valueModifier) {
                        value = filter.valueModifier(value);
                    }
                    
                    let oldValue = Array.isArray(header.filter) ? header.filter.slice() : header.filter;
                    // this.colFilterMenu.value = value;

                    if(value !== null && value !== undefined && this.colFilterMenu.multi) {
                        header.filter = unifyFilterValue(header.filter);
                        let indexOfValue = header.filter.indexOf(findValueInSuggestions(header.filter, value));
                        if(indexOfValue === -1) header.filter.push(value);
                        else header.filter.splice(indexOfValue, 1);
                    }
                    else {
                        header.filter = value;
                        this.colFilterMenu.show = false;
                    }
                    
                    this.$emit('filter', { header, colIndex, dataKey:header.dataKey, value:header.filter, revert(){ header.filter = oldValue; } });
                };

                setTimeout(() => {
                    this.$nextTick(() => {
                        this.colFilterMenu.show = true;
                    });
                });
            },

            showColSortMenu(e, header, colIndex){
                e.preventDefault();
                this.colSortMenu.show = false;
                this.colSortMenu.x = e.clientX;
                this.colSortMenu.y = e.clientY;

                this.colSortMenu.actions = [
                    {
                        id:'sort-values-clear',
                        title: this.$translate('sort_clear'),
                        action(spreadsheet){
                            let oldValue = header.sort;
                            header.sort = 0;
                            spreadsheet.$emit('sort', { header, colIndex, dataKey:header.dataKey, direction:0, revert(){ header.sort = oldValue; }  });
                        },
                        divider: true,
                        disabled: false
                    },
                    {
                        id:'sort-values-asc',
                        title: this.$translate('sort_ascending') + ' (A-Z ' + this.$translate('or') + ' 0-9)',
                        action(spreadsheet){
                            let oldValue = header.sort;
                            header.sort = 1;
                            spreadsheet.$emit('sort', { header, colIndex, dataKey:header.dataKey, direction:1, revert(){ header.sort = oldValue; }  });
                        },
                        disabled: false
                    },
                    {
                        id:'sort-values-desc',
                        title: this.$translate('sort_descending') + ' (Z-A ' + this.$translate('or') + ' 9-0)',
                        action(spreadsheet){
                            let oldValue = header.sort;
                            header.sort = -1;
                            spreadsheet.$emit('sort', { header, colIndex, dataKey:header.dataKey, direction:-1, revert(){ header.sort = oldValue; }  });
                        },
                        disabled: false
                    }
                ];

                setTimeout(() => {
                    this.$nextTick(() => {
                        this.colSortMenu.show = true;
                    });
                });
            },

            closeEditingMode(){
                let wasEditing = !!this.editingCell.active;
                if(this.editingCell.rowIndex > -1) {
                    // ensure editor will close and catch all events before DOM destroy
                    let lastEditingRow = this.getRow(this.editingCell.rowIndex);
                    setTimeout(() => lastEditingRow._state.editingColIndex = -1);
                }
                this.editingCell = { active:false, rowIndex:-1, colIndex:-1, origValue:undefined };
                return wasEditing;
            },
            editCell(rowIndex, colIndex, origValue = this.getCellValue(rowIndex, colIndex)){
                // TODO: listen to click outsice of edited cell and close editing mode
                this.editingCell = { active:true, rowIndex, colIndex, origValue };
                this.getRow(rowIndex)._state.editingColIndex = colIndex;
                this.getRow(rowIndex)._state.editingCellOrigValue = origValue;
            },
            isCellReadonly(rowIndex, colIndex){
                let row = this.getRow(rowIndex);
                return row._state.readonlyCells[colIndex];
            },
            isCellEditable(rowIndex, colIndex){
                let row = this.getRow(rowIndex);
                return row._state.editableCells[colIndex];
            },
            isCellDisabled(rowIndex, colIndex){
                let row = this.getRow(rowIndex);
                return row._state.disabledCells[colIndex];
            },

            setRowActive(rowIndexOrCB, isActive){
                this.rows.forEach((row, index) => {
                    if(typeof rowIndexOrCB === 'function') {
                        isActive = rowIndexOrCB(row, index);

                        if(row._state.active !== !!isActive) {
                            row._state.active = !!isActive;
                        }
                    }
                    else {
                        let rowIndex = rowIndexOrCB;
                        if(index === rowIndex) {
                            row._state.active = !!isActive;
                        }
                        else if(row._state.active !== !isActive) {
                            row._state.active = !isActive;
                        }
                    }
                });
            },

            callCellEditorAction(rowIndex, colIndex, data){
                let row = this.getRow(rowIndex);
                let editorAction = (this.columnDataTypes[ this.columns[row.rowType][colIndex].dataType ] || {}).editorAction || this.columns[row.rowType][colIndex].editorAction;
                if(editorAction) editorAction.call(this, { rowIndex, colIndex, data });
            },

            callCellRendererAction(rowIndex, colIndex, data){
                let row = this.getRow(rowIndex);
                let rendererAction = (this.columnDataTypes[ this.columns[row.rowType][colIndex].dataType ] || {}).rendererAction || this.columns[row.rowType][colIndex].rendererAction;
                if(rendererAction) rendererAction.call(this, { rowIndex, colIndex, data });
            },

            callCellToggleEdit(rowIndex, colIndex, initValue){
                let spreadsheet = this;
                let row = this.getRow(rowIndex);
                let toggleEdit = (this.columnDataTypes[ this.columns[row.rowType][colIndex].dataType ] || {}).toggleEdit || this.columns[row.rowType][colIndex].toggleEdit;
                if(typeof toggleEdit === 'boolean') return toggleEdit;
                else if(typeof toggleEdit === 'function') {
                    toggleEdit.call(this, { rowIndex, colIndex, rowContext:this.getRowContext(rowIndex), toggle(){ spreadsheet.editCell(rowIndex, colIndex, initValue); } });
                    return false;
                }
                else return true;
            },

            callCellToggleDetails(rowIndex, colIndex, initValue){
                let spreadsheet = this;
                let row = this.getRow(rowIndex);
                let toggleDetails = (this.columnDataTypes[ this.columns[row.rowType][colIndex].dataType ] || {}).toggleDetails || this.columns[row.rowType][colIndex].toggleDetails;
                
                if(typeof toggleDetails === 'function') {
                    toggleDetails.call(this, { rowIndex, colIndex, rowContext:this.getRowContext(rowIndex), toggle(){ spreadsheet.editCell(rowIndex, colIndex, initValue); } });
                }
            },

            toggleCellEdit(rowIndex, colIndex, initValue = this.getCellValue(rowIndex, colIndex)){
                if(this.isCellReadonly(rowIndex, colIndex) || !this.isCellEditable(rowIndex, colIndex)) {
                    this.callCellToggleDetails(rowIndex, colIndex, initValue);
                }
                else if(this.callCellToggleEdit(rowIndex, colIndex, initValue)) this.editCell(rowIndex, colIndex, initValue);
            },

            handleColResizeStart(event, colIndex){
                let comp = this;
                event.stopPropagation();
                event.preventDefault();

                const minColWidth = 30;

                let resizerElm = event.target;
                let resizerRect = resizerElm.getBoundingClientRect();
                let x = resizerRect.x;

                // find nearest parent with fixed position
                let colWidthMarkElm = this.$refs.colWidthMark;
                let containerLeft = 0;
                let parent = colWidthMarkElm.parentElement;
                while(parent) {
                    if(parent === window.document.body) {
                        containerLeft = window.document.body.getBoundingClientRect().left;
                        break;
                    }
                    else if(!parent.offsetParent) {
                        containerLeft = parent.getBoundingClientRect().left;
                        break;
                    }
                    parent = parent.parentElement;
                }
                
                let tableRect = this.$refs.table.getBoundingClientRect();
                colWidthMarkElm.classList.add('dragged');
                colWidthMarkElm.style.top = (tableRect.top + 2) + 'px';
                colWidthMarkElm.style.height = (tableRect.height - 2) + 'px';
                colWidthMarkElm.style.left = (containerLeft + resizerRect.right) + 'px';

                let headerElms = this.$refs.table.querySelectorAll('[data-cell-type="column"][data-col-index="'+colIndex+'"]');
                let resizerElms = this.$refs.table.querySelectorAll('[data-resizer-col-index="'+colIndex+'"]');
                let width = headerElms[0].getBoundingClientRect().width;
                
                resizerElms.forEach(elm => elm.classList.add('dragged'));

                let newWidth;

                function move(event){
                    let delta = event.x - x;
                    newWidth = width + delta;
                    if(newWidth >= minColWidth) colWidthMarkElm.style.left = (event.x - containerLeft) + 'px';
                }

                function done(event){
                    newWidth = Math.max(newWidth, minColWidth);
                    headerElms.forEach(elm => elm.style.width = newWidth + 'px');
                    resizerElms.forEach(elm => elm.classList.remove('dragged'));
                    colWidthMarkElm.classList.remove('dragged');

                    let headerWidthsCache = (comp.stateCache || {}).headerWidths || {};

                    // update cached widths
                    for(let rowType in comp.headerRowsByType) {
                        headerWidthsCache[rowType] = headerWidthsCache[rowType] || {};
                        headerWidthsCache[rowType][colIndex] = newWidth;
                    }

                    // update columns width, to recalc whole table width
                    comp.columns[colIndex].width = newWidth;

                    comp.extendStateCache({ headerWidths:headerWidthsCache });

                    window.removeEventListener('mousemove', move);
                    window.removeEventListener('mouseup', done);
                }

                window.addEventListener('mousemove', move);
                window.addEventListener('mouseup', done);
            },
            scrollListener(){
                window.requestAnimationFrame(() => {
                    let container = this.$refs.spreadsheetContainer;
                    let scrollTop = container.scrollTop;
                    if(this.containerScrollTop !== scrollTop) this.containerScrollTop = scrollTop;
                });
            },
            resizeListener(){
                window.requestAnimationFrame(() => {
                    let rect = this.$refs.spreadsheetWrapper.getBoundingClientRect();

                    if(this.fitHeight) {
                        // adjust wrapper height to fit into screen if needed
                        let newHeight = Math.max(MIN_HEIGHT, window.innerHeight - rect.top);
                        this.$refs.spreadsheetWrapper.style.height = newHeight + 'px';
                        this.containerHeight = newHeight;
                    }
                    else this.containerHeight = rect.height;
                });
            },
            recalcTableToWidthIfNeeded(iteration = 0){
                window.requestAnimationFrame(() => {
                    let currContainerWidth = this.$refs.spreadsheetWrapper?.getBoundingClientRect()?.width;
                    this.desiredContainerWidth = currContainerWidth || this.desiredContainerWidth;

                    if(!this.desiredContainerWidth) {
                        if(iteration < 3) setTimeout(() => this.recalcTableToWidthIfNeeded(iteration+1), 50);
                        return;
                    }

                    let desiredColsWidth = this.desiredContainerWidth - NUM_COLUMN_WIDTH - CONTAINER_RIGHT_OFFSET;

                    let staticColsWidth = this.columns.filter(col => col.autoResize === false).reduce((sum, column) => sum + column.width, 0);
                    desiredColsWidth -= staticColsWidth || 0;

                    // table width should be width, so we need to recalc column widths
                    let resizableColumns = this.columns.filter(col => col.autoResize !== false);
                    let resizableColsWidth = resizableColumns.reduce((sum, column) => sum + column.width, 0);
                    let ratio = desiredColsWidth / resizableColsWidth;

                    // only expand width is allowed
                    if(ratio <= 1) return;

                    resizableColumns.forEach(col => col.width = col.width * ratio);
                });
            }
        },
        mounted(){
            this.$refs.spreadsheetContainer.addEventListener('scroll', this.scrollListener);
            window.addEventListener('resize', this.resizeListener);
            this.resizeListener();

            if(this.fitHeight) {
                this.heightObserver = new ResizeObserver(this.resizeListener);
                this.heightObserver.observe(this.$refs.spreadsheetWrapper.parentElement);
            }
        },
        beforeDestroy(){
            this.$refs.spreadsheetContainer.removeEventListener('scroll', this.scrollListener);
            window.removeEventListener('resize', this.resizeListener);
            if(this.heightObserver) this.heightObserver.disconnect();
        }
    }
</script>

<style scoped>
    .spreadsheet-wrapper {
        position: relative;
    }

    .spreadsheet {
        overflow:auto;
        position: relative;
    }

    .spreadsheet table {
        /* min-width:100%; */
        border-spacing: 0;
        border-collapse: separate;
        table-layout: fixed;
        white-space: nowrap;
        empty-cells: show;
        border: 0px;
        background-color: #fff;
        border-top: 1px solid transparent;
        border-left: 1px solid transparent;
        border-right: 1px solid #ccc;
        border-bottom: 1px solid #ccc;
        
    }

    .spreadsheet thead th {
        position: -webkit-sticky;
        position: sticky;
        top: 0px;
        z-index: 2;
        height:22px;
    }

    /* first letter allways uppercase in header text */
    /* .spreadsheet thead th { text-transform: lowercase; }
    .spreadsheet thead th::first-letter { text-transform: uppercase; } */

    .spreadsheet thead tr.row-0 th {
        top: 0px;
        height: 24px;
    }

    .spreadsheet thead tr.row-1 th {
        top: 24px;
        height: 24px;
    }

    .spreadsheet thead tr.row-2 th {
        top: 48px;
        height: 24px;
    }

    .spreadsheet >>> tr > td,
    .spreadsheet >>> tr > th {
        border-top: 1px solid #ccc;
        border-left: 1px solid #ccc;
        /* border-right: 1px solid transparent; */
        border-bottom: 1px solid transparent;
        padding: 4px;
        white-space: nowrap;
        box-sizing: border-box;
        line-height: 1em;
        background-color: #fff;
        overflow: hidden;
    }

    .spreadsheet >>> tr > th,
    .spreadsheet >>> tr > th > i,
    .spreadsheet >>> tr > th > button {
        color: #b1b1b1;
        background-color: #fff; /* #f5f5f5; */
        font-weight: normal;
    }

    .spreadsheet >>> tr > th {
        color: #000000DE;
        font-weight: bold;
    }

    .spreadsheet >>> tr > th > i,
    .spreadsheet >>> tr > th > button {
        background-color: transparent !important;
    }

    .spreadsheet >>> tr > th > i.active,
    .spreadsheet >>> tr > th > button.active {
        color: white !important;
        border: 1px solid grey;
        border-radius: 2px;
        background-color: grey !important;
    }

    .spreadsheet >>> tr.active > th {
        /* color: inherit;
        font-weight: 500; */
        color: #000000DE;
        font-weight: bold;
    }

    .spreadsheet >>> tr.active > th.active,
    .spreadsheet >>> tr.active > th.active > i,
    .spreadsheet >>> tr.active > th.active > button,
    .spreadsheet >>> tr > td.active {
        background-color: #e7e7e7; /* #dddddd; */
    }

    .spreadsheet >>> tr > td {
        position: relative;
        border-right: 1px solid transparent;
    }

    .spreadsheet >>> tr > td[data-cell-editing="true"] {
        overflow: initial;
        z-index:4 !important;
    }

    .spreadsheet >>> tr > :first-child {
        position: -webkit-sticky;
        position: sticky;

        width: 50px;
        min-width: 50px;
        max-width: 50px;
        left: 0px;

        background-color: #fff; /*  #f3f3f3; */
        text-align: center;
        border-right: 1px solid #ccc;
        z-index:3;
    }

    .spreadsheet >>> tr > th:first-child {
        z-index:4;
    }

    .spreadsheet >>> tr:last-child > th {
        border-bottom: 1px solid #ccc;
    }

    .spreadsheet >>> thead tr:nth-child(n + 3) > th:first-child {
        border-top: 1px solid transparent;
    }

    .spreadsheet >>> tr > td.h-bottom {
        border-bottom: 1px solid #000;
    }

    .spreadsheet >>> tr > td.h-top {
        border-top: 1px solid #000;
    }

    .spreadsheet >>> tr > td.h-left {
        border-left: 1px solid #000;
    }

    .spreadsheet >>> tr > td.h-right {
        border-right: 1px solid #000;
    }

    .spreadsheet >>> tr > td.h-left,
    .spreadsheet >>> tr > td.h-left ~ td {
        background-color: #f4f4f4; /* rgba(0,0,0,0.05); */
    }

    .spreadsheet >>> tr > td.h-right ~ td {
        background-color: #fff;
    }

    .spreadsheet >>> tr > td.first-selected {
        background-color: #fff !important;
    }

    .spreadsheet >>> tr.row-delete > td:not(:first-child) {
        text-decoration: line-through;
        text-decoration-color: red;
        /* text-decoration-style: wavy; */
        color: grey;
    }

    .spreadsheet >>> tr > td[data-cell-error]::before,
    .spreadsheet >>> tr > td[data-cell-info]::before {
        position: absolute;
        content: "";
        display: block;
        width: 0px;
        height: 100%;
        border-style: solid;
        border-width: 8px 8px 8px 0;
        border-color: transparent;
        bottom: 0px;
        left: 0px;
    }

    .spreadsheet >>> tr > td[data-cell-info]::before {
        border-bottom-color: blue;
    }

    .spreadsheet >>> tr > td[data-cell-error]::before {
        border-top-color: red;
    }

    .spreadsheet >>> tr > td[data-cell-info]:hover::before,
    .spreadsheet >>> tr > td[data-cell-error]:hover::before {
        border-width: 10px 10px 10px 0;
    }

    .spreadsheet >>> tr > td[data-cell-error]:hover,
    .spreadsheet >>> tr > td[data-cell-info]:hover {
        overflow: initial;
    }

    /* .spreadsheet >>> tr > td > * {
        max-height: 26px !important;
    } */

    .spreadsheet >>> tr > td[data-cell-error]:not([data-cell-editing]) {
        background-color: #fff1f1 !important;
    }

    .spreadsheet:not(.always-white-cells) >>> tr > td[data-cell-readonly],
    .spreadsheet:not(.always-white-cells) >>> tr > td[data-cell-readonly][data-cell-error] {
        background-color: #f7f7f7 !important;
    }

    .spreadsheet >>> .cell-infos {
        color: blue;
        font-size: 11px;
        position: absolute;
        top: calc(2px + 100%);
        left:0px;
        background-color: #ddddff;
        border: 1px solid blue;
        border-radius: 4px;
        padding: 4px;
        z-index:2;
    }

    .spreadsheet >>> tr > td[data-cell-info]:not([data-cell-editing]) .cell-infos:hover {
        display:none;
    }

    .spreadsheet >>> .cell-errors {
        color: red;
        font-size: 11px;
        position: absolute;
        bottom: calc(2px + 100%);
        left:0px;
        background-color: #fff1f1;
        border: 1px solid red;
        border-radius: 4px;
        padding: 4px;
        z-index:2;
    }

    .spreadsheet >>> tr > td[data-cell-error]:not([data-cell-editing]) .cell-errors:hover,
    .spreadsheet >>> tr > td[data-cell-info]:not([data-cell-editing]) .cell-infos:hover {
        display:none;
    }

    .spreadsheet >>> .row-errors {
        position:absolute;
        color: #fff;
        background-color: red;
        border-radius: 50%;
        width: 14px;
        height: 14px;
        margin-left: -2px;
        font-size: 10px;
    }

    .spreadsheet >>> .cell-label + .cell-errors {
        margin-bottom: 20px;
    }

    .spreadsheet >>> .cell-label {
        display: block;
        font-size: 12px;
        line-height: inherit;
        color: #858585; /* #5e5e5e; */
        margin: -6px -4px 0px -2px;
    }

    .spreadsheet >>> .cell-href {
        display: inline-block;
        color: var(--v-anchor-base);
        text-decoration: underline;
        cursor: pointer;
    }

    /* .spreadsheet >>> .cell-label::after {
        content:'';
        display:block;
        border-color: grey transparent transparent transparent;
        border-width: 40px 40px 0 0;
    } */

    .spreadsheet >>> [data-cell-editing="true"] .cell-label {
        position: absolute;
        font-size: 14px;
        line-height: 14px;
        color: #000;
        border-color: #000 !important;
        top: -20px;
        left: -1px;
        right: -1px;
        bottom: auto;
        border: 1px solid;
        border-top-right-radius: 4px;
        border-top-left-radius: 4px;
        background-color: #ddd;
        max-width: none;
        margin: 0px;
        padding: 2px;
        overflow: hidden;
    }

    /* .spreadsheet >>> td:hover > .cell-label {
        color: inherit;
        font-weight: bold;
    } */

    .spreadsheet .col-width-resizer {
        position: absolute;
        width: 5px;
        background-color: transparent;
        cursor: ew-resize;
        top: 0px;
        bottom: 0px;
        right: 0px;
    }

    .spreadsheet .col-width-resizer:hover,
    .spreadsheet .col-width-resizer.dragged {
        background-color: #ccc;
    }

    .spreadsheet .col-width-mark {
        position: fixed;
        width: 2px;
        background-color: #8d8d8d;
        /* top: 10px;
        bottom: 10px; */
        left: 0px;
        display: none;
        z-index: 9999;
        cursor: ew-resize;
    }

    .spreadsheet .col-width-mark.dragged {
        display: block;
    }

    .spreadsheet >>> tr.row-col-group > th:nth-child(n+2) {
        background-color: #fff;
    }

    .spreadsheet >>> tr.width-helper > th {
        padding: 0px;
        height: 0px;
        border: none;
    }

    .spreadhseet >>> tr.width-helper {
        padding:0px;
        height:0px;
        background-color:red;
    }

    .spreadhseet thead tr.width-helper > th {
        padding:0px;
        background-color:red;
    }

    .pagination {
        height:0px;
    }

    .pagination-placeholder {
        height:48px;
    }

    .pagination-content {
        z-index:3;
        /* font-size:80%; */
        width: 520px;
        position: absolute;
        bottom: 20px;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        background-color: #fff; /* #f5f5f5; */
        border: 1px solid #f5f5f5; /* #ccc */
        padding: 0px 6px 2px 6px;
        box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    }

    .pagination-content .col {
        margin:0px 2px;
    }
</style>