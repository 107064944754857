<template>
    <table style="border-spacing:0px;" :style="{'margin-left':(30-segment.visualHint.lineWidth)/2+'px'}">
        <tr>
            <td>
                <div style="min-height:70px;"></div>
            </td>
            <td class="cell-full-height-fill">
                <div :style="{'border-width':'0px 0px 0px '+segment.visualHint.lineWidth+'px','border-color':segment.visualHint.color,'border-style':segment.visualHint.border}">
                    &nbsp;
                </div>
            </td>
            <td v-if="segment.displayVals.length || segment.resources.length">
                <div class="attributes-line"></div>
            </td>
            <td>
                <inv-conn-visual-legend :segment="segment" :expanded="expanded"></inv-conn-visual-legend>
            </td>
        </tr>
    </table>
</template>

<script>
    import InvConnVisualLegend from './inv-conn-visual-legend.vue';

    export default {
        components:{
            InvConnVisualLegend
        },
        props:{
            segment: {},
            expanded: Boolean
        }
    };
</script>