<template>
  <v-card flat tile>
    <v-toolbar flat elevation="0">
      <v-toolbar-title>{{ "summary_info" | translate }}</v-toolbar-title>

      <v-spacer></v-spacer>

      <v-toolbar-items>
        <template v-if="refresh">
          <v-btn text @click="fetch">
            <v-icon left>fal fa-sync</v-icon>
            {{ "refresh" | translate }}
          </v-btn>

          <v-btn text @click="toggleAutoRefresh">
            <v-icon left v-if="autoRefreshInterval" color="accent">fal fa-toggle-on</v-icon>
            <v-icon left v-else>fal fa-toggle-off</v-icon>
            {{ "auto_refresh" | translate }}
          </v-btn>
        </template>

        <v-btn icon @click="close">
          <v-icon>fal fa-times</v-icon>
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>

    <v-card-text class="obj-table">
      <v-simple-table class="summary-info-table">
        <template v-slot:default>
          <colgroup>
            <col span="1" style="height: 24px; width: 200px" />
            <col span="1" style="height: 24px; width: 200px" />
            <col span="1" style="height: 24px; width: 100px" />
            <col span="1" style="height: 24px; width: auto" />
          </colgroup>

          <tbody>
            <tr v-for="(item, idx) of summaryInfo" :key="idx" :data-status="item.status">
              <td :title="item.message" style="white-space: pre">
                {{ item.message }}
              </td>
              <td>{{ item.status }}</td>
              <td>{{ item.duration }}</td>
              <td :title="item.detail">{{ item.detail }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>
  </v-card>
</template>

<style lang="scss" scoped>
.summary-info-table {
  &.v-data-table td {
    font-size: 14px;
    height: 16px !important;
    border-bottom: none !important;
    line-height: 0.5;
    padding: 0;
  }

  min-width: 920px;
  overflow-x: visible;
}

[data-status="Ready to execute"] {
  color: rgba(0, 0, 0, 0.26);
}
[data-status="Waiting for dependency"] {
  color: rgba(0, 0, 0, 0.26);
}
[data-status="Waiting"] {
  color: rgba(0, 0, 0, 0.26);
}
[data-status="Executed"] {
  color: rgba(0, 0, 0, 0.54);
}
[data-status="Finished"] {
  color: #4caf50;
}
[data-status="Failed"] {
  color: var(--v-error-base);
}
[data-status="Killed"] {
  color: var(--v-error-base);
}
</style>

<script>
import backend from "obj-fe/app/backend";

export default {
  props: ["id", "refresh"],

  data() {
    return {
      autoRefreshInterval: null,
      summaryInfo: [],
    };
  },

  mounted() {
    this.fetch();
    if (this.refresh) {
      this.toggleAutoRefresh();
    }
  },

  destroyed() {
    this.clearAutoRefreshInterval();
  },

  methods: {
    fetch() {
      if (this.$props.id) {
        backend.jobs.summaryInfo({ id: this.$props.id }, (data) => {
          this.summaryInfo = data;
        });
      }
    },
    close() {
      this.clearAutoRefreshInterval();
      this.$emit("close");
    },
    toggleAutoRefresh() {
      if (this.autoRefreshInterval) this.clearAutoRefreshInterval();
      else this.setUpAutoRefreshInterval();
    },
    clearAutoRefreshInterval() {
      if (this.autoRefreshInterval) {
        clearTimeout(this.autoRefreshInterval);
        this.autoRefreshInterval = null;
      }
    },
    setUpAutoRefreshInterval() {
      this.clearAutoRefreshInterval();
      this.autoRefreshInterval = setTimeout(() => {
        this.fetch();
        this.setUpAutoRefreshInterval();
      }, 5000);
    },
  },
};
</script>
