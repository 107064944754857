
// spreadsheet generic filters setup by dataType

import SpreadsheetFilterDefault from './spreadsheet-filter-default.vue';

export default {
    default: {
        component: SpreadsheetFilterDefault,
        validate({ value, colIndex, column, dataType }){
            if(dataType?.validate) return dataType.validate.call(this, { value, colIndex, column });
            return true;
        },
        parse({ value, colIndex, column, dataType }){
            if(dataType?.parse) return dataType.parse.call(this, { value, colIndex, column });
            return value;
        }
    },
    boolean:{
        suggestions:[ { text:'true', value:true }, { text:'false', value:false } ]
    }
};