<template>
    <inv-object-tab-item 
        :tab="tab" 
        :tabIsLoading="tabIsLoading"
        data-test="tab-COPY_TO_CLIPBOARD"
    >
        <template v-if="expanded && tab" slot="header">
            <div class="inv-object-button-group">
                
                <v-tooltip top>
                    <template v-slot:activator="{on}">
                        <v-btn 
                            v-on="on"
                            @click="copy" 
                            icon
                            data-test="tab-copy-button"
                        >
                            <v-icon>fal fa-paste</v-icon>
                        </v-btn>
                    </template>
                    <span>
                        {{'copy'|translate}}
                    </span>
                </v-tooltip>

                <v-tooltip top>
                    <template v-slot:activator="{on}">

                    <v-btn 
                        @click="send" 
                        icon
                        v-on="on"
                        data-test="tab-send-button"
                    >
                        <v-icon>fal fa-envelope</v-icon>
                    </v-btn>
                    </template>
                    <span>
                        {{'send' | translate}}
                    </span>
                </v-tooltip>

            </div>
        </template>

        <template slot="body">
            <v-card flat :class="{ 'tab-body-disabled' : tabIsLoading }">
                <v-card-text>
                    <v-textarea
                        class="inv-object-tab-copy-to-clipboard-text-area" 
                        ref="textArea" 
                        :value="textToCopy" 
                        hide-details 
                        readonly 
                        flat
                        rows="10"
                        auto-grow 
                        style="padding-top:0px"
                    />
                </v-card-text>
            </v-card>
        </template>
    </inv-object-tab-item>
</template>

<style >
.inv-object-tab-copy-to-clipboard-text-area{
    height: 100%; 
    overflow: auto;
}
.inv-object-tab-copy-to-clipboard-text-area .v-input__slot::before,
.inv-object-tab-copy-to-clipboard-text-area .v-input__slot::after{
    display: none !important;
}
</style>
<script>
    import backend from 'obj-fe/app/backend';
    import notify from 'obj-fe/services/notifications';

    import tabBehaviour from './inv-object-tab-behaviour';
    import InvObjectTabItem from './inv-object-tab-item.vue';
    

    export default {
        mixins:[ tabBehaviour ],
        components:{
            InvObjectTabItem
        },
        data(){
            return {
                textToCopy: ''
            };
        },
        methods:{
            onFirstTimeExpandLoad(successCb, errorCb){
                backend.objects.plainText(this.objectId, data => {
                    this.textToCopy = data;
                    successCb();
                }, errorCb);
            },
            copy(){
                this.$refs.textArea.$el.querySelector('textarea').select();
                window.document.execCommand('copy');
                notify.success('text_copied_to_clipboard');
            },
            send(){
                backend.objects.emailPlainText(this.objectId, this.textToCopy);
            }
        }
    };
</script>