<template>
    <inv-object-tab-item :tab="tab">
        <template slot="header">
            NOT IMPLEMENTED
        </template>    
        <template slot="body">
            NOT IMPLEMENTED
        </template>        
    </inv-object-tab-item>
</template>

<script>
    import tabBehaviour from './inv-object-tab-behaviour';
    import InvObjectTabItem from './inv-object-tab-item.vue';
    
    export default {
        mixins:[ tabBehaviour ],
        components:{
            InvObjectTabItem
        }
    };
</script>