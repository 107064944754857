<template>
    <div>
        <span class="cell-buttons-container">
            <v-btn text @click="onClick()" @mousedown="$event.stopPropagation()">
                <v-icon v-if="column.buttonIcon">{{column.buttonIcon}}</v-icon>
                {{viewValue}}
            </v-btn>
        </span>
    </div>
</template>

<style scoped>
    .cell-buttons-container {
        display: inline-block;
        margin: -8px 0px -8px -6px;
    }
</style>

<script>
    import rendererMixin from '../../components/spreadsheet/spreadsheet-renderer-mixin';

    export default {
        mixins:[ rendererMixin ],
        computed:{
            field(){
                return this.row._origFields[ this.column.dataKey ];
            }
        },
        methods:{
            onClick(){
                if(this.column.buttonClick) {
                    this.column.buttonClick({ colIndex:this.colIndex, rowIndex:this.rowIndex, row:this.row, column:this.column, field:this.field });
                }
            }
        }
    }
</script>