<template>
    <inv-object-tab-item :tab="tab" :tabIsLoading="tabIsLoading">
        
        <template v-if="expanded && tab" slot="header">
            <div class="inv-object-button-group">
                <v-tooltip top>
                    <template v-slot:activator="{on}">
                        <v-btn 
                            v-on="on"
                            icon
                            @click="loadRestrictions" 
                            color="info"
                            data-test="tab-reload-button"
                        >
                            <v-icon>fal fa-sync</v-icon>
                        </v-btn>
                    </template>
                    <span>
                        {{'reload'|translate}}
                    </span>
                </v-tooltip>
            </div>
        </template>

        <template slot="body">
            <obj-table fillParentHeight :items="restrictions" :class="{ 'tab-body-disabled' : tabIsLoading }">
                <template slot="headers">
                    <th>{{'inherited_from' | translate}}</th>
                    <th>{{'domain_type' | translate}}</th>
                    <th>{{'name' | translate}}</th>
                    <th>{{'allow_read' | translate}}</th>
                    <th>{{'deny_read' | translate}}</th>
                    <th>{{'allow_write' | translate}}</th>
                    <th>{{'deny_write' | translate}}</th>
                </template>

                <template slot="row" slot-scope="row">
                    <td><span class="nowrap">{{row.value.inheritedFrom}}</span></td>
                    <td><span class="nowrap">{{row.value.domainType}}</span></td>
                    <td><span class="nowrap">{{row.value.displayString}}</span></td>
                    <td>
                        <v-checkbox disabled 
                                    v-model="row.value.allow"
                                    style="margin-top:0px"
                                    hide-details>
                        </v-checkbox>
                    </td>
                    <td>
                        <v-checkbox disabled
                                    v-model="row.value.deny" 
                                    style="margin-top:0px"
                                    hide-details>
                        </v-checkbox>
                    </td>
                    <td>
                        <v-checkbox v-if=isAllowable(row.value.domainType)
                                    disabled
                                    v-model="row.value.allowWrite" 
                                    style="margin-top:0px"
                                    hide-details>
                        </v-checkbox>
                    </td>
                    <td>
                        <v-checkbox v-if=isAllowable(row.value.domainType)
                                    disabled
                                    v-model="row.value.denyWrite" 
                                    style="margin-top:0px"
                                    hide-details>
                        </v-checkbox>
                    </td>
                </template>
            </obj-table>
        </template>
    </inv-object-tab-item>
</template>

<style scoped>
    >>> .obj-table thead > tr:first-child th {
        border-top-left-radius: 0px !important;
        border-top-right-radius: 0px !important;
        background-color: #fff;
        color: inherit;
        font-weight: normal;
        border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    }

    >>> .obj-table thead > tr:first-child th .v-icon {
        color: inherit;
    }
    .inner-table-header {
        font-size: 15px !important;
        text-align: left;
        padding: 40px 0px 10px 6px !important;
    }
</style>

<script>
    import backend from 'obj-fe/app/backend';

    import tabBehaviour from './inv-object-tab-behaviour';
    import InvObjectTabItem from './inv-object-tab-item.vue';
    
    export default {
        mixins:[ tabBehaviour ],
        components:{
            InvObjectTabItem
        },
        data(){
            return {
                restrictions: [],
                unallowableDomainTypes: ['FUNCTION', 'CELINE'],
            };
        },
        methods:{
            onFirstTimeExpandLoad(successCb, errorCb){
                this.loadRestrictions(successCb, errorCb);  
            },
            loadRestrictions(successCb, errorCb){
                backend.orgEntities.inheritedRestrictions(
                    this.objectId, 
                    // onSuccess
                    data => {
                        this.fillRestrictions(data)
                        if(successCb && typeof successCb  === 'function') successCb();
                    }, 
                    // onError
                    errorCb
                );
            },
            fillRestrictions(data){
                let restrictions = [];

                data.forEach(group => {

                    group.restrictions.forEach(item => {

                        item.allow = item.allowDeny === 'ALLOW';
                        item.deny = item.allowDeny === 'DENY';
                        item.allowWrite = item.allowDenyWrite === 'ALLOW';
                        item.denyWrite = item.allowDenyWrite === 'DENY';
                        item.inheritedFrom = group.name;

                        restrictions.push(item);
                    });
                });

                this.restrictions = restrictions;
            },
            isAllowable(domainType) {
                return !this.unallowableDomainTypes.includes(domainType.toUpperCase());
            },
        }
    };
</script>