import { CstParser } from "chevrotain";

function createInterpreter(BaseCstVisitor){

    // All our semantics go into the visitor, completly separated from the grammar.
    class QueryInterpreter extends BaseCstVisitor {
        constructor() {
            super();
            
            // This helper will detect any missing or redundant methods on this visitor
            this.validateVisitor();
        }

        // this is calculated only for syntax highlighting purposes
        createTokenResult(typeId, token){
            if(arguments.length === 1) {
                token = arguments[0];
                typeId = null;
            }

            token = this.getFaultTolerantToken(token);
            typeId = typeId ? typeId : (token.tokenType || {}).typeId;

            // { lineNo:{ colNo:type, colNo:type, colNo:type, ... } }
            let tokenBoundaries = {};

            if(!isNaN(token.startOffset)) {
                let startLine = token.startLine - 1;
                tokenBoundaries[ startLine ] = tokenBoundaries[ startLine ] || {};
                tokenBoundaries[ startLine ][ token.startColumn - 1 ] = typeId;

                let endLine = token.endLine - 1;
                tokenBoundaries[ endLine ] = tokenBoundaries[ endLine ] || {};
                tokenBoundaries[ endLine ][ token.endColumn ] = null;
            }

            return {
                tokenBoundaries,
                errors:[],
                merge(subResult){
                    if(!subResult) return this;

                    let subBoundaries = subResult.tokenBoundaries;
                    let boundaries = this.tokenBoundaries;
        
                    for(let lineNo in subBoundaries){
                        if(boundaries[ lineNo ]) {
                            for(let colNo in subBoundaries[ lineNo ]) boundaries[ lineNo ][ colNo ] = subBoundaries[ lineNo ][ colNo ];
                        }
                        else boundaries[ lineNo ] = subBoundaries[ lineNo ];
                    }
        
                    if(subResult.errors) this.errors = this.errors.concat(subResult.errors);
        
                    return this;
                },
                toJSON(){
                    let result = this.data;
                    // if(typeof result !== 'string') result.lineIndex = this.data.token ? this.data.token.startLine : -1;
                    if(result && result.token) delete result.token;

                    return result;
                }
            };
        }

        createError(msg, token, prevToken){
            let err = new Error(msg);
            err.token = token;
            err.previousToken = prevToken;
            return err;
        }

        checkUnquoteText(str = ''){
            let quoteChar = str[0];
            let lastChar = str[ str.length-1 ];
            if(quoteChar === '"' || quoteChar === '\'') return str.slice(1, lastChar === quoteChar ? str.length-1 : undefined);
            else return str;
        }

        getFaultTolerantToken(token){
            if(Array.isArray(token)) token = token[0];
            return token || {};
        }
    
        rootExpression(ctx) {
            let result = this.createTokenResult();
            let data = [];
            let aliases = {
                '$CONTEXT_OBJECT': [
                    {
                        type: 'ENTITY',
                        name: '$CONTEXT_OBJECT',
                        columns: ['global_id', 'object_type_id', 'domain_id' ]
                    }
                ]
            }; // { alias:[ { token, type, id, name } ] };

            if(ctx.entitiesDefinition) ctx.entitiesDefinition.forEach(entityDefinition => {
                let subResult = this.visit(entityDefinition, aliases);
                result.merge(subResult);
                data.push(subResult);
            });
            
            if(ctx.expressionsDefinition) ctx.expressionsDefinition.forEach(expressionDefinition => {
                let subResult = this.visit(expressionDefinition, aliases);
                result.merge(subResult);
                data.push(subResult);
            });

            result.data = data;
            result.aliases = aliases;

            return result;
        }
    
        expression(ctx, input) {
            return this.visit(ctx.subExpression, input);
        }

        entityDefinition(ctx, aliases){

            let result = this.resourceDefinition('ENTITY', ctx, aliases);
            let columns = [];
            let entityColumnAliases = [];
            if(ctx.column) ctx.column.forEach(e =>{
                let subResult = this.visit(e, entityColumnAliases);
                columns.push(subResult.toJSON());
                result.merge(subResult);
            });
            if(aliases[result.data.name]) aliases[result.data.name][0].columns = Object.keys(entityColumnAliases); 

            if(ctx.expressionsDefinition) {
                let subResult = this.visit(ctx.expressionsDefinition, aliases);
                if(subResult.data.type !== 'JOIN' ){
                    result.errors.push(this.createError('Only join is allowed within an entity definition', subResult.token));
                }
                else {
                    result.merge(subResult);
                    result.data.join = subResult.data;
                    if(result.data.join.token) delete result.data.join.token; 
                }
            }
                
            result.data.columns = columns;
            return result;
        }
        entityColumn(ctx, aliases){
            return this.resourceDefinition('COLUMN', ctx, aliases);
        }


        expressionsDefinition(ctx, aliases){
            let typeToken = this.getFaultTolerantToken(ctx.type);
            let result = this.resourceDefinition(typeToken.image.toUpperCase(), ctx, []);
            
            let conditionVisitResult = ctx.andOrExp ? this.visit(ctx.andOrExp, aliases) : {};

            if(conditionVisitResult){
                result.merge(conditionVisitResult); 
                result.data.condition = conditionVisitResult.data;
            }
            result.token = typeToken;
            return result;
        }
        andOrExp(ctx, aliases){
            if(!ctx.comparator) {
                return this.visit(ctx.lhs, aliases);
            }

            let token = this.getFaultTolerantToken(ctx.comparator[0]);
            let finalResult = this.createTokenResult(token);
            let leftHandSide = this.visit(ctx.lhs, aliases);
            finalResult.merge(leftHandSide);
            
            let rightHandSide; 
            rightHandSide = ctx.rhs ? ctx.rhs.map(e=>{
                let visitResult = this.visit(e, aliases);
                if(visitResult){
                    finalResult.merge(visitResult);
                    return visitResult.data;
                }
            }) : [];
            rightHandSide.unshift(leftHandSide.data);
            
            if(rightHandSide.length === 1) return rightHandSide.pop(); 
            
            let comparators = ctx.comparator.sort((a,b) => a.startOffset - b.startOffset);
            comparators.forEach(comp => {
                let comparatorToken = this.getFaultTolerantToken(comp);
                finalResult.merge(this.createTokenResult(comparatorToken));
            });
            function recursiveAppendTree(comparators = comparators, rightHandSide = rightHandSide){
                let comparator = comparators.shift();
                let lhs = rightHandSide.shift();
                if(!comparator) return lhs;
                return {
                    type: comparator.tokenType.typeId,
                    parameters: [lhs, recursiveAppendTree(comparators,rightHandSide)]
                };
            }
            let comparator = comparators.shift();
            let result = {
                type: comparator.tokenType.typeId,
                parameters: [rightHandSide.shift(), recursiveAppendTree(comparators, rightHandSide)]
            };
            finalResult.data = result;
            return finalResult;            
        }
        composedExpression(ctx, aliases){
            return this.visit(ctx.compareRule || ctx.parentAndOrExp, aliases);
        }

        compareRule(ctx, aliases){

            let referencies =[];
            if(aliases) Object.entries(aliases)
                .forEach(alias=>{
                    let reference = alias[0];
                    let columns = alias[1][0].columns;
                    
                    columns.forEach(column => referencies.push(reference + '.' + column));
                });

            let result = this.createTokenResult(this.getFaultTolerantToken());
            let leftValue, comparator, comparatorSymbol, rightValue;
            let parameters = [];
            
            if(ctx.leftValue){
                leftValue = this.visit(ctx.leftValue);
                if(!!leftValue.data.entity && !!leftValue.data.attribute){
                    if(referencies.indexOf(leftValue.data.entity + '.' + leftValue.data.attribute) === -1 && leftValue.data.entity !== '$CONTEXT_OBJECT'){
                        result.errors.push(this.createError('Entity ' + leftValue.data.entity + ' has no ' + leftValue.data.attribute + ' column' , leftValue.token));
                    }
                    parameters.push(
                        !!leftValue.data.type && leftValue.data.type === 'REFERENCE' ? 
                            leftValue.toJSON() : 
                            {
                                type: 'CONSTANT',
                                value: leftValue.data 
                            }
                    );
                }

                result.merge(leftValue);
            } 
            if(ctx.comparator){
                comparator = this.createTokenResult('COMPARATOR', this.getFaultTolerantToken(ctx.comparator)); 
                comparatorSymbol = ctx.comparator.pop().tokenType.typeId;
                result.merge(comparator);
            } 
            if(ctx.rightValue){
                rightValue = this.visit(ctx.rightValue);
                if(
                    rightValue.data.entity && 
                    rightValue.data.attribute && 
                    referencies.indexOf(rightValue.data.entity + '.' + rightValue.data.attribute) === -1 && 
                    rightValue.data.entity !== '$CONTEXT_OBJECT'
                ){
                    result.errors.push(this.createError('Entity ' + rightValue.data.entity + ' has no ' + rightValue.data.attribute + ' column' , rightValue.token));
                }
                parameters.push(
                    !!rightValue.data.type && rightValue.data.type === 'REFERENCE' ? 
                        rightValue.toJSON() : 
                        {
                            type: 'CONSTANT',
                            value: rightValue.data 
                        } 
                );

                result.merge(rightValue);
            } 

            result.data = {
                type: comparatorSymbol,
                parameters: parameters
            };

            return result;
        }
        
        parentAndOrExp(ctx, aliases){
            let result = this.createTokenResult(this.getFaultTolerantToken(ctx.LeftParenthesis));
            if(ctx.RightParenthesis) result.merge(this.createTokenResult(this.getFaultTolerantToken(ctx.RightParenthesis)));
            if(ctx.andOrExp) {
                let andOrExpResult = this.visit(ctx.andOrExp, aliases); 
                result.merge(andOrExpResult);
                result.data = andOrExpResult.data;
            }
            return result;
        }
        
        resourceDefinition(typeId, ctx, aliases){
            let typeToken = this.getFaultTolerantToken(ctx.type);
            let result = this.createTokenResult(typeToken);
            let nameToken, nameTokenResult;

            if(ctx.value){
                nameToken = this.getFaultTolerantToken(ctx.value);
                nameTokenResult = this.createTokenResult(nameToken);
                result.merge(nameTokenResult);
            }
            
            let attributesResult = this.visit(ctx.attributes);
            result.merge(attributesResult);
            
            
            let name = nameToken ? nameToken.image : undefined;
            let attributes = attributesResult ? attributesResult.data : [];
            let alias = attributes.alias || name;

            result.data = {
                type: typeId,
                name,
                alias,
                attributes: attributes ? attributes : null,
                token: typeToken
            };

            if(alias) {
                let aliasInfo = {
                    type:result.data.type,
                    name,
                    attributes:attributes
                };

                if(aliases[alias]) {
                    aliases[alias].push(aliasInfo);
                    result.errors.push(this.createError(`Duplicate alias or name in ${typeToken.tokenType.name} "${alias}"`, typeToken));
                }
                else aliases[alias] = [aliasInfo];
            }

            return result;
        }

        attribute(ctx){
            let nameToken = this.getFaultTolerantToken(ctx.name);
            let attrName = nameToken.image;
            let attrValue;

            let result = this.createTokenResult('ATTRIBUTE_NAME', nameToken)
                .merge(this.createTokenResult(ctx.equal));

            let attrValueResult = this.visit(ctx.value, 'ATTRIBUTE_VALUE'); 
            if(attrValueResult) {
                result.merge(attrValueResult);
                attrValue = attrValueResult.data;
            }

            let attrValueSetResult = this.visit(ctx.valueSet);
            if(attrValueSetResult) {
                result.merge(attrValueSetResult);
                attrValue = attrValueSetResult.data;
            }

            // merged attribute data
            result.data = {
                type: ctx.valueSet ? 'LIST' : 'SIMPLE',
                attribute: attrName,
                value: attrValue
            };
            result.token = nameToken;

            return result;
        }

        attributes(ctx){
            let data = [];
            let result = this.createTokenResult(ctx.bracketLeft);

            if(ctx.attribute) ctx.attribute.forEach((attr, index) => {
                if(index > 0) result.merge(this.createTokenResult(ctx.Comma[0]));
                let attrResult = this.visit(attr);
                
                if(data.map(e=>e.attribute).indexOf(attrResult.data.attribute) > -1){
                    result.errors.push(this.createError('Duplicate attribute name "' + attrResult.data.attribute + '"', attrResult.token));
                }
                else data.push(attrResult.data);

                result.merge(attrResult);
            });

            result.merge(this.createTokenResult(ctx.bracketRight));
            result.data = data;
            return result;
        }

        stringValue(ctx, typeId){
            let valueToken = this.getFaultTolerantToken(ctx.value);
            let result = this.createTokenResult(typeId || 'VALUE', valueToken);

            result.data = this.checkUnquoteText(valueToken.image);
            result.token = valueToken;
            return result;
        }
        
        stringValueSet(ctx){
            let data = [];
            let result = this.createTokenResult(ctx.CurlyBracketLeft);
            
            if(ctx.value) ctx.value.forEach((val,index)=>{
                if(index > 0) result.merge(this.createTokenResult(ctx.Comma[0]));
                let valueResult = this.visit(val, 'ATTRIBUTE_VALUE');
                
                if(data.indexOf(valueResult.data) > -1 ){
                    result.errors.push(this.createError('Duplicate set item "' + valueResult.data + '"', valueResult.token));
                }
                else data.push(valueResult.data);

                result.merge(valueResult);
            });

            result.merge(this.createTokenResult(ctx.CurlyBracketRight));
            
            result.data = data;
            return result;
        }
        
        reference(ctx, typeId){
            let entityToken = this.getFaultTolerantToken(ctx.entity);
            let result = this.createTokenResult(typeId || 'REFERENCE_ENTITY', entityToken);
            
            if(!ctx.separator || !ctx.attribute) result.errors.push(this.createError('Reference has no attribute', entityToken));  
            
            
            result.merge(this.createTokenResult(this.getFaultTolerantToken(ctx.separator)));
            let attributeToken = this.getFaultTolerantToken(ctx.attribute);
            result.merge(this.createTokenResult(typeId || 'REFERENCE_ATTRIBUTE', attributeToken));
            
            result.data = {
                type: 'REFERENCE',
                entity: this.checkUnquoteText(entityToken.image),
                attribute: this.checkUnquoteText(attributeToken.image)
            };

            result.token = entityToken;

            return result;
        }
        constant(ctx){
            let valueToken = this.getFaultTolerantToken(ctx.value);
            let result = this.createTokenResult('CONSTANT_VALUE', valueToken);
            
            result.data = this.checkUnquoteText(valueToken.image);
            result.token = valueToken;
            
            return result;
        }
    }

    // We only need a single interpreter instance because our interpreter has no state.
    return new QueryInterpreter();
}

export { createInterpreter };