<template>
    <div class="uid">{{viewValue}}</div>
</template>

<style scoped>
    .uid {
        color: grey;
        font-size: 11px;
    }
</style>

<script>
    export default {
        props:{
            value:{}
        },
        computed:{
            viewValue(){
                if(Array.isArray(this.value)) return this.value.join(', ');
                else return this.value;
            }
        }
    }
</script>