<template>
    <div>{{viewValue}}</div>
</template>

<script>
    import spreadsheetRendererMixin from './spreadsheet-renderer-mixin.js';
    export default {
        mixins:[
            spreadsheetRendererMixin
        ]
    }
</script>