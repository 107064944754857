<template>
    <div flat class="inv-object-tabs">
        <v-navigation-drawer
            left
            mini-variant
            class="tabs-drawer"
            permanent
        >
            <v-list 
                shaped
                class="tabs-list"
            >
                <v-list-item-group
                    v-model="currentGroup"
                    mandatory
                >
                    <v-list-item 
                        v-for="(group, index) in availableGroups"
                        :key="index"
                    >
                        <v-tooltip right>
                            <template v-slot:activator="{on}">
                                <v-icon 
                                    v-on="on"
                                    :color="index === currentGroup ? 'accent' : (isGroupDirty(group.id) ? 'error' : '') "
                                >
                                    {{index === currentGroup ? 'fas' : 'fal'}} {{group.icon}}
                                </v-icon>
                            </template>
                            <span>
                                {{group.displayString}} {{ (isGroupDirty(group.id) ? 'changed' : '') | translate}}
                            </span>
                        </v-tooltip>
                    </v-list-item>
                </v-list-item-group>
            </v-list>
        </v-navigation-drawer>

        <div class="tabs-content">
            <v-tabs 
                v-model="currentExpansion" 
                grow 
                show-arrows
            >
                <v-tab
                    :ripple="false"
                    v-for="(tab, index) in filteredTabs"
                    :key="tab.parameters.dataSource"
                    @click="changeActiveTab(tab.parameters.dataSource, index)"
                >
                    <v-icon style="margin-right: 10px">{{ (currentExpansion === index ? 'fas ' : 'fal ') + tab.icon }}</v-icon>
                    {{ tab.name }}
                    <v-icon 
                        v-show="isSelectionDirty({groupIdentifier: currentSelection.groupIdentifier, tabIdentifier: filteredTabs[index].parameters.dataSource})"
                        class="ml-4 changes-icon"
                    >
                        fas fa-exclamation-circle
                    </v-icon>
                </v-tab>
            </v-tabs>

            <v-tabs-items
                :value="currentExpansion"
                v-for="(grouppedTab, groupIndex) in grouppedTabs"
                :key="groupIndex"
                v-show="availableGroups[currentGroup] && availableGroups[currentGroup].id === groupIndex"
            >
                <v-component
                    v-for="(tab, i) in grouppedTab"
                    :key="tab.parameters.dataSource"
                    :is="tabComponents[tab.type]"
                    :object-id="object.id || object.globalId"
                    :expanded="isTabExpanded(tab.parameters.dataSource)"
                    :tab="tab"
                    :state="tabStates[tab.parameters.dataSource] || {}"
                    :readonly="readonly"
                    @state-change="(state) => updateTabState(tab.parameters.dataSource, state)"
                    @resource-reload-needed="$emit('resource-reload-needed')"
                    @is-dirty="isDirty => { markGroupTabDirty(groupIndex, tab.parameters.dataSource, isDirty)}"
                    :shouldReload="shouldReloadTabItem"
                    @data-change="onTabDataChange(groupIndex, i, $event)"
                    :disableCRUD="disableCRUD"
                    :height="height"
                />
            </v-tabs-items>
        </div>
        <obj-leave-confirm 
            :is-dirty="isSelectionDirty(previousSelection)" 
            :watch="currentValidSelection" 
            @cancel="$event(true); atCancel()"
            @ignore="shouldReloadTabItem = true"
        />
    </div>
</template>

<script>
import object from 'obj-fe/utils/object';
import InvTab from './object-tabs/inv-object-tab-item.vue';

// tabs
import InvObjectTabGraphvizEditor from './object-tabs/inv-object-tab-graphviz-editor.vue';
import InvObjectTabIhiEditor from './object-tabs/inv-object-tab-ihi-editor.vue';
import InvObjectTabEmdslEditor from './object-tabs/inv-object-tab-emdsl-editor.vue';
import InvObjectTabClipboard from './object-tabs/inv-object-tab-clipboard.vue';
import InvObjectTabIntegration from './object-tabs/inv-object-tab-integration.vue';
import InvObjectTabConnections from './object-tabs/inv-object-tab-connections.vue';
import InvObjectTabMap from './object-tabs/inv-object-tab-map.vue';
import InvObjectTabAccessRestrictions from './object-tabs/inv-object-tab-access-restrictions.vue';
import InvObjectTabInheritedRestrictions from './object-tabs/inv-object-tab-inherited-restrictions.vue';
import InvObjectTabReport from './object-tabs/inv-object-tab-report.vue';
import InvObjectTabLayeredMap from './object-tabs/inv-object-tab-layered-map.vue';
import InvObjectTabSvgDiagram from './object-tabs/inv-object-tab-svg-diagram.vue';
import InvObjectTabBigPictureLazyLoading from './object-tabs/inv-object-tab-big-picture-lazy-loading.vue';
import InvObjectTabRichtextEditor from './object-tabs/inv-object-tab-richtext-editor.vue';
import InvObjectTabAttributesPanel from './object-tabs/inv-object-tab-attributes-panel.vue';
import InvObjectTabGisMap from './object-tabs/inv-object-tab-gis-map.vue';


import InvObjectTabEmpty from './object-tabs/inv-object-tab-empty.vue';

export default {
    components: {
        InvTab,
        InvObjectTabClipboard,
        InvObjectTabIntegration,
        InvObjectTabConnections,
        InvObjectTabEmpty,
        InvObjectTabMap,
        InvObjectTabAccessRestrictions,
        InvObjectTabInheritedRestrictions,
        InvObjectTabReport,
        InvObjectTabLayeredMap,
        InvObjectTabGraphvizEditor,
        InvObjectTabIhiEditor,
        InvObjectTabSvgDiagram,
        InvObjectTabBigPictureLazyLoading,
        InvObjectTabRichtextEditor,
        InvObjectTabEmdslEditor,
        InvObjectTabAttributesPanel,
        InvObjectTabGisMap
    },
    props: {
        object: Object,
        tabStates: Object,
        readonly: Boolean,
        disableCRUD: Boolean,
        height: {}
    },
    data() {
        return {
            defaultGridGroups:[
                {
                    id: 'attributes',
                    displayString: 'General information',
                    icon: 'fa-address-card',
                    tabTypes: ['ATTRIBUTES_PANEL'] 
                },
                {
                    id: 'diagrams',
                    displayString: 'Diagrams',
                    icon: 'fa-images',
                    tabTypes: ['SVG_DIAGRAM', 'CONNECTIONS', 'BIG_PICTURE_LAZY_LOADING']
                },
                {
                    id: 'reports',
                    displayString: 'Reports',
                    icon: 'fa-file-spreadsheet',
                    tabTypes: ['GENERIC_PANEL', 'ACCESS_RESTRICTIONS', 'INHERITED_RESTRICTIONS'] 
                },
                {
                    id: 'editors',
                    displayString: 'Editors',
                    icon: 'fa-tools',
                    tabTypes: ['GRAPHVIZ_EDITOR', 'IHI_EDITOR', 'EMDSL_EDITOR', 'IHI_CUSTOM_DSL_GRID_EDITOR', 'RICHTEXT_EDITOR'] 
                },
                {
                    id: 'maps',
                    displayString: 'Maps',
                    icon: 'fa-map-marked',
                    tabTypes: ['LAYERED_MAP', 'MAP'] 
                },
                {
                    id: 'others',
                    displayString: 'Others',
                    icon: 'fa-bars',
                    tabTypes: ['COPY_TO_CLIPBOARD', 'INTEGRATION', 'DMS'] 
                }
            ],
            currentExpansion: null,
            tabStatesHistory: {}, // keep prev tab states locally, if user collapse and immediately expand tab, his state will be erased
            tabComponents: {
                INTEGRATION: 'InvObjectTabIntegration',
                CONNECTIONS: 'InvObjectTabConnections',
                COPY_TO_CLIPBOARD: 'InvObjectTabClipboard',
                DMS: 'InvObjectTabEmpty',
                MAP: 'InvObjectTabMap',
                GIS_MAP: 'InvObjectTabGisMap',
                ACCESS_RESTRICTIONS: 'InvObjectTabAccessRestrictions',
                INHERITED_RESTRICTIONS: 'InvObjectTabInheritedRestrictions',
                GENERIC_PANEL: 'InvObjectTabReport',
                LAYERED_MAP: 'InvObjectTabLayeredMap',
                GRAPHVIZ_EDITOR: 'InvObjectTabGraphvizEditor',
                IHI_EDITOR: 'InvObjectTabIhiEditor',
                EMDSL_EDITOR: 'InvObjectTabEmdslEditor',
                IHI_CUSTOM_DSL_GRID_EDITOR: 'InvObjectTabIhiEditor',
                SVG_DIAGRAM: 'InvObjectTabSvgDiagram',
                BIG_PICTURE_LAZY_LOADING: 'InvObjectTabBigPictureLazyLoading',
                RICHTEXT_EDITOR: 'InvObjectTabRichtextEditor' ,
                ATTRIBUTES_PANEL: 'InvObjectTabAttributesPanel'
            },
            // group stuff
            availableGroups: [],
            filteredTabs: [],
            currentGroup: null,
            prevGroup: 0,
            grouppedTabs: {},
            groupDirtyByIndex:{},

            dirtyTabsMatrix: {},
            previousSelection: null,
            currentValidSelection: null,

            isDirty: false,
            changes:{},

            shouldReloadTabItem: false,
        };
    },
    computed:{
        currentSelection(){
            let group = this.availableGroups[this.currentGroup] || {};
            let groupIdentifier = group?.id;
            
            let tab = this.filteredTabs[this.currentExpansion];
            let tabIdentifier = tab?.parameters?.dataSource;

            return {groupIdentifier, tabIdentifier};
        },
        isCurrentGroupDirty(){
            let group = this.availableGroups[this.currentGroup] || {};
            let groupIdentifier = group?.id;
            
            let tab = this.filteredTabs[this.currentExpansion];
            let tabIdentifier = tab?.parameters?.dataSource;

            if(Object.keys(this.dirtyTabsMatrix).indexOf(groupIdentifier) === -1) return false;
            if(Object.keys(this.dirtyTabsMatrix[groupIdentifier]).indexOf(tabIdentifier) === -1) return false;
            
            return this.dirtyTabsMatrix[groupIdentifier][tabIdentifier]
        },
        objectTabGroupsAvailable(){
            return this.object.tabGroups && this.object.tabGroups.length > 0; 
        },
    },
    watch: {
        currentSelection(newSelection, oldSelection){
            if(
                !!newSelection && 
                !!newSelection.groupIdentifier &&
                !!newSelection.tabIdentifier &&
                !!oldSelection && 
                !!oldSelection.groupIdentifier &&
                !!oldSelection.tabIdentifier
            ){
                
                let isNewSelectionValid = this.grouppedTabs[newSelection.groupIdentifier]
                    .map(e => e.parameters.dataSource)
                    .indexOf(newSelection.tabIdentifier) > -1;

                let isOldSelectionValid = this.grouppedTabs[oldSelection.groupIdentifier]
                    .map(e => e.parameters.dataSource)
                    .indexOf(oldSelection.tabIdentifier) > -1;


                if(isOldSelectionValid){
                    if(Object.keys(this.dirtyTabsMatrix[oldSelection. groupIdentifier]).length > 0) this.previousSelection = oldSelection;
                } 
                if(isNewSelectionValid) this.currentValidSelection = newSelection;

            }
        },
        isCurrentGroupDirty(val){
            this.$emit('is-dirty', val);
        },
        object: {
            immediate: true,
            handler(object, oldObject) {

                // calculate panels only if object id or globalId changed
                if(oldObject && (oldObject.globalId === object.globalId)) return;

                // compose tab groups and select the available ones
                if(this.objectTabGroupsAvailable) this.availableGroups = object.tabGroups;
                else {
                    let availableGroups = new Set();
                    this.defaultGridGroups
                        .forEach(tabGroup => {
                            this.object.panels.forEach(tab => {
                                if(tabGroup.tabTypes.indexOf(tab.type) > - 1) availableGroups.add(tabGroup)
                            })
                        })
                    this.availableGroups = [...availableGroups];
                } 

                // now, for these available groups,
                // compute all tabs per group
                
                this.grouppedTabs = {};
                if(this.objectTabGroupsAvailable){
                    this.availableGroups
                        .forEach((group, groupIndex) => {
                            let tabs = this.object.panels
                                .filter(e => !!e.tabGroups)
                                .filter(e => e.tabGroups.indexOf(group.id) > -1);
                            
                            if(tabs.length > 0) this.availableGroups[groupIndex].lastVisited = tabs[0].parameters.dataSource;
                            this.grouppedTabs[group.id] = tabs;
                        })
                        
                    
                } 
                else this.availableGroups
                    .forEach((group, groupIndex) => {
                        let tabs = this.object.panels
                            .filter(panel => group.tabTypes.indexOf(panel.type) > -1)
                            .sort((a, b) => a.importance.localeCompare(b.importance));

                        if(tabs.length > 0) this.availableGroups[groupIndex].lastVisited = tabs[0].parameters.dataSource;
                        this.grouppedTabs[group.id] = tabs;
                    })

                // necessary for dirty flags
                Object.keys(this.grouppedTabs)
                    .forEach(e => this.dirtyTabsMatrix[e] = {});
                
                // if a tab is present in the object's query, 
                // select its group and the tab within it
                let tabStates = Object.keys(this.tabStates);
                if(tabStates.length > 0){
                    
                    // get the tab id
                    let selectedTabIdentifier = tabStates[0];
                    
                    // get the group id
                    let selectedGroupIdentifier = Object.entries(this.grouppedTabs)
                        .filter(e => e[1].map(e=>e.parameters.dataSource).indexOf(selectedTabIdentifier) > -1)
                        [0][0]

                    let selectedGroupIndex = this.availableGroups
                        .map(e => e.id)
                        .indexOf(selectedGroupIdentifier)
                    
                    if(selectedGroupIndex > -1 && selectedTabIdentifier) {
                        this.availableGroups[selectedGroupIndex].lastVisited = selectedTabIdentifier;
                        this.currentGroup = selectedGroupIndex;
                    }
                }
            },
        },
        currentGroup: {
            immediate: true,
            handler(currentGroup, oldGroup) {
                this.$nextTick(()=>{
                    let currentExpansion = this.currentExpansion;
                    this.prevGroup = oldGroup;
                    if(!this.availableGroups[currentGroup]) return;

                    
                    // try to persist last visited by group
                    if(!!oldGroup || oldGroup === 0) {
                        let oldTab = this.filteredTabs[currentExpansion];
                        if(!!oldTab) this.availableGroups[oldGroup].lastVisited = oldTab.parameters.dataSource;
                    }
    

                    let tabGroup = this.availableGroups[currentGroup];
                    let filteredTabs = this.grouppedTabs[tabGroup.id];
                    
                    this.filteredTabs = filteredTabs;
                    
                    let selectedTabIndex = 0;
                    if(tabGroup.lastVisited) selectedTabIndex = filteredTabs
                            .map(e => e.parameters.dataSource)
                            .indexOf(tabGroup.lastVisited)

                    this.changeActiveTab(tabGroup.lastVisited, selectedTabIndex)
                })
            }
        }
    },
    methods: {
        markGroupTabDirty(groupIdentifier, tabIndetifier, isDirty){
            this.$set(this.dirtyTabsMatrix[groupIdentifier], tabIndetifier, isDirty);
            this.dirtyTabsMatrix[groupIdentifier][tabIndetifier] = isDirty;
            this.dirtyTabsMatrix = {...this.dirtyTabsMatrix};
        },
        isSelectionDirty(selection){
            if(!selection) return false;
            if(!selection.groupIdentifier || !selection.tabIdentifier) return false;

            if(Object.keys(this.dirtyTabsMatrix).indexOf(selection.groupIdentifier) === -1) return false;
            if(Object.keys(this.dirtyTabsMatrix[selection.groupIdentifier]).indexOf(selection.tabIdentifier) === -1) return false;
            
            return this.dirtyTabsMatrix[selection.groupIdentifier][selection.tabIdentifier];
        },
        updateTabState(tabReportId, state) {
            this.tabStatesHistory = object.extend('data', this.tabStatesHistory, object.deepClone(this.tabStates));

            let tabStates = {};
            this.filteredTabs.forEach((tab) => {
                if (tabReportId === tab.parameters.dataSource) {
                    tabStates[tab.parameters.dataSource] = (this.tabStates || {})[tab.parameters.dataSource] || this.tabStatesHistory[tab.parameters.dataSource] || {};
                    if (state) {
                        tabStates[tabReportId] = state;
                    }
                }
            });

            this.$emit('tab-states-changed', tabStates);
        },
        changeActiveTab(tabReportId, tabIndex) {
            if(tabIndex || tabIndex === 0) setTimeout(() => this.currentExpansion = tabIndex, 50) // rendering problems, that's why
            
            let 
                newActiveTab = {},
                route = this.$route;

            newActiveTab[tabReportId] = {};

            if(route.query && route.query.panels) {
                
                if(Object.keys(this.tabStates).indexOf(tabReportId) > -1) {
                    newActiveTab[tabReportId] = this.tabStates[tabReportId]
                }
                else {
                    let tabState = JSON.parse(route.query.panels)[tabReportId] || {}
                    newActiveTab[tabReportId] = tabState;
                }
            }
            
            this.$emit('tab-states-changed', newActiveTab);
            setTimeout(()=>{ window.dispatchEvent(new Event('resize'))}, 100) // report tabs wont render too small, hence this resize
        },
        atCancel(){
            let groupIndex = this.availableGroups.map(e=>e.id).indexOf(this.previousSelection.groupIdentifier);
            this.currentGroup = groupIndex;
            
            let tabIdentifier = Object.entries(this.dirtyTabsMatrix[this.previousSelection.groupIdentifier])
                .filter(e => e[1])
                [0][0]; // im sorry. i had to do this
                 
            let tabIndex = this.filteredTabs.map(e=>e.parameters.dataSource).indexOf(tabIdentifier);
            this.currentExpansion = tabIndex;
        },
        isGroupDirty(groupId){
            let retval = false;
            if(Object.keys(this.dirtyTabsMatrix).indexOf(groupId) === -1) return false;
            
            return Object.values(this.dirtyTabsMatrix[groupId])
                .filter(e => !!e)
                .length > 0
        },
        isTabExpanded(tabIdentifier){
            // works for domain objects
            if(!!this.$route.query.panels){
                let panels =  Object.keys(JSON.parse(this.$route.query.panels))
                if(!!panels && panels.length > 0){
                    return tabIdentifier === panels[0] 
                } 
            }
            // works generally 
            else if(this.currentSelection.tabIndetifier){
                return this.currentSelection.tabIndetifier === tabIdentifier
            }
            // fallback 
            else {
                let index = this.filteredTabs.map(e=>e.parameters.dataSource).indexOf(tabIdentifier)
                return index === this.currentExpansion;
            }
            return false;
        },
        onTabDataChange(tabGroupIndex, tabIndex, tabChange){
            let key = tabGroupIndex + '/' + tabIndex;
            this.changes[key] = tabChange;
            this.$emit('data-changes', this.changes);
        }
    },
};
</script>

<style>
.inv-object-tabs {
    padding-top: 20px;
    height: 100%;
    width: 100%;

    display: flex;
    flex-direction: row;
}
.inv-object-tabs .tabs-drawer{
    /* margin-top: 36px;  */
    padding-left: 4px;
    box-shadow: none;

    z-index: 4;
}
.inv-object-tabs .tabs-list{
    padding: 0px;
}
.inv-object-tabs .tabs-drawer .v-list .v-list-item{
    justify-content: center;
    min-height: 36px;
}
.inv-object-tabs .tabs-drawer .v-list .v-list-item.v-list-item--active{
    border-right: 2px solid var(--v-accent-base); 
    border-bottom-right-radius: 0!important;
    border-top-right-radius: 0!important;
}
.inv-object-tabs .tabs-drawer .v-list.v-list--shaped .v-list-item::before{
    border-bottom-left-radius: 18px;
    border-top-left-radius: 18px;
    border-bottom-right-radius: 0!important;
    border-top-right-radius: 0!important;
    background-color: var(--v-accent-base);
}
.inv-object-tabs .tabs-content{
    max-height: 100%;
    height: 100%;
    width: calc(100% - 56px);

    display: flex;
    flex-direction: column;
}
.inv-object-tabs .tabs-content .v-window__container,
.inv-object-tabs .tabs-content .v-window.v-item-group{
    height: 100%;
}
.inv-object-tabs .tabs-content .v-tabs{
    flex: 0 1 auto;
}
.inv-object-tabs .v-tabs .v-tab,
.v-slide-group__next,
.v-slide-group__prev {
    border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.inv-object-tabs .v-tabs .v-tab::before{
    display: none;
}
.inv-object-tabs .tabs-content .v-tabs-slider-wrapper{
    bottom: unset;
    top: 0;
}
    
.v-slide-group__next--disabled,
.v-slide-group__prev--disabled {
    display: none;
}

.inv-object-tabs .v-tabs .v-tab,
.inv-object-tabs .v-tabs .v-tab .v-icon {
    color: #000000de !important;
    font-size: 1rem;
}
.inv-object-tabs .v-tabs .v-tab.v-tab--active,
.inv-object-tabs .v-tabs .v-tab.v-tab--active .v-icon {
    color: var(--v-accent-base) !important;
}
.inv-object-tabs .v-tabs-slider{
    background-color: var(--v-accent-base);
}
.inv-object-tabs .v-tabs .v-tab .v-icon.changes-icon{
    color: var(--v-error-base) !important;
}
</style>
