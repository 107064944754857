<template>
    <td @mousedown="$emit('cell-selection-start', { rowIndex, colIndex });"
        @dblclick="toggleEditCell"
        @click="e => toggleEditOnClick ? toggleEditCell(e) : null"
        v-click-outside="closeEditingIfNeeded"
        data-cell-type="cell" 
        :data-row-index="rowIndex"
        :data-col-index="colIndex"
        :data-cell-editing="!disableInlineEdit && row._state.editingColIndex > -1 && row._state.editingColIndex === colIndex"
        :data-cell-error="!!row._state.cellErrors && !!row._state.cellErrors[ colIndex ]"
        :data-cell-info="!!row._state.cellInfos && !!row._state.cellInfos[ colIndex ]"
        :data-cell-readonly="row._state.readonlyCells[colIndex]"
        :data-cell-required="row._state.requiredCells[colIndex]"
        :class="cellSelectionClasses"
        :style="{ overflow: errorsVisible ? 'initial' : null, ...row._state.cellStyles[ colIndex ] }">

        <div :style="{ maxHeight: row._state.height ? (row._state.height-4)+'px' : null }">

            <div v-if="row._state.cellLabels[ colIndex ]" class="cell-label">
                {{row._state.cellLabels[ colIndex ]}}
            </div>

            <!-- v-if="!hideErrors && errorsVisible" -->
            <div v-if="!hideErrors && row._state.cellErrors && row._state.cellErrors[ colIndex ]" class="cell-errors">
                <div v-for="(err,errIndex) in row._state.cellErrors[ colIndex ]" :key="errIndex">
                    - {{err}}
                </div>
            </div>

            <div v-if="!hideErrors && row._state.cellInfos && row._state.cellInfos[ colIndex ]" class="cell-infos">
                <div v-for="(info,infoIndex) in row._state.cellInfos[ colIndex ]" :key="infoIndex">
                    - {{info}}
                </div>
            </div>

            <template v-if="!disableInlineEdit && row._state.editingColIndex > -1 && row._state.editingColIndex === colIndex">
                <component  :is="row._state.cellEditors[colIndex] || 'SpreadsheetEditorDefault'"
                            :value="row._state.editingCellOrigValue" 
                            :cell-value="row[ columns[row.rowType][colIndex].dataKey ]" 
                            :value-changed="row._state.editingCellOrigValue !== row[ columns[row.rowType][colIndex].dataKey ]" 
                            @close="closeEditingMode" 
                            @change="value => $emit('set-cell-value', { rowIndex, colIndex, value })"
                            @reset="closeEditingMode" 
                            :get-spreadsheet-component="getSpreadsheetComponent"
                            :min-width="columns[row.rowType][colIndex].width"
                            :data-types="columnDataTypes"
                            :data-type-id="columns[row.rowType][colIndex].dataType"
                            :row="row"
                            :column="columns[row.rowType][colIndex]"
                            :row-index="rowIndex"
                            :col-index="colIndex"
                            @action="data => $emit('cell-editor-action', { rowIndex, colIndex, data })">
                </component>
            </template>
            <template v-else>
                <div :class="{ 'cell-href':!!row._state.cellHrefs[colIndex] }" @click="e => onHrefClick(e, row._state.cellHrefs[colIndex])">
                    <component  :is="row._state.cellRenderers[colIndex] || 'SpreadsheetRendererDefault'" 
                                :value="row[ columns[row.rowType][colIndex].dataKey ]" 
                                @input="value => $emit('set-cell-value', { rowIndex, colIndex, value })"
                                :data-types="columnDataTypes"
                                :data-type-id="columns[row.rowType][colIndex].dataType"
                                :row="row"
                                :column="columns[row.rowType][colIndex]"
                                :row-index="rowIndex"
                                :col-index="colIndex"
                                :get-spreadsheet-component="getSpreadsheetComponent"
                                @action="data => $emit('cell-renderer-action', { rowIndex, colIndex, data })">
                    </component>
                </div>
            </template>

        </div>
    </td>

</template>

<script>
    import SpreadsheetEditorDefault from './spreadsheet-editor-default.vue';
    import SpreadsheetRendererDefault from './spreadsheet-renderer-default.vue';

    export default {
        components:{
            SpreadsheetEditorDefault,
            SpreadsheetRendererDefault
        },
        props:{
            rowIndexString: {},
            colIndexString: {},
            row: Object,
            columns: {},
            cellSelectionClasses: Array,
            columnDataTypes: Object,
            hideErrors: Boolean,
            toggleEditOnClick: Boolean,
            disableInlineEdit: Boolean
        },
        data(){
            return {
                showErrors: false
            };
        },
        computed:{
            rowIndex(){
                return parseInt(this.rowIndexString, 10);
            },
            colIndex(){
                return parseInt(this.colIndexString, 10);
            },
            errorsVisible(){
                return  (this.showErrors || 
                        (this.row._state.editingColIndex > -1 && 
                        this.row._state.editingColIndex === this.colIndex)) && 
                        !!this.row._state.cellErrors && 
                        !!this.row._state.cellErrors[ this.colIndex ];
            }
        },
        methods:{
            closeEditingMode(){
                // TODO: check if this is needed
                // if(!dontForceEditorToEnd && this.editingCell.active && this.$refs.cellEditor) this.$refs.cellEditor.editingDone();
                this.$emit('close-editing-mode');
            },
            getSpreadsheetComponent(){
                // TODO: not good, refactor
                return this.$parent.$parent;
            },
            clearSelection() {
                if(document.selection && document.selection.empty) {
                    document.selection.empty();
                } else if(window.getSelection) {
                    var sel = window.getSelection();
                    sel.removeAllRanges();
                }
            },
            toggleEditCell(event){
                if(event) event.preventDefault();
                if(this.row._state.editingColIndex === -1) this.clearSelection();
                this.$emit('toggle-edit-cell', { rowIndex: this.rowIndex, colIndex: this.colIndex });
            },
            closeEditingIfNeeded(){
                if(!this.toggleEditOnClick) return;
                else if(this.row._state.editingColIndex > -1 && this.row._state.editingColIndex === this.colIndex) this.closeEditingMode();
            },
            onHrefClick(event, cellHref){
                if(!cellHref) return;
                if(event.ctrlKey || event.metaKey) {
                    window.open(cellHref.url, cellHref.target || '_blank');
                }
                else window.open(cellHref.url, cellHref.target || '_top');
            }
        }
    };
</script>