var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { on: { wheel: _vm.zoomMain } },
    [
      _c(
        "SPZ",
        {
          staticClass: "thumbnail",
          attrs: {
            zoomEnabled: false,
            panEnabled: false,
            controlIconsEnabled: false,
            dblClickZoomEnabled: false,
            preventMouseEventsDefault: true
          },
          on: { svgpanzoom: _vm.thumbnailSPZcreated }
        },
        [_vm._t("default")],
        2
      ),
      _vm._v(" "),
      _c("Scope", {
        attrs: {
          bus: _vm.bus,
          mainSPZ: _vm.mainSPZ,
          thumbnailSPZ: _vm.thumbnailSPZ
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }