import CodeMirror from 'codemirror';
import 'codemirror/lib/codemirror.css';

import 'codemirror/addon/lint/lint';
import 'codemirror/addon/lint/lint.css';

import 'codemirror/addon/hint/show-hint';
import 'codemirror/addon/hint/show-hint.css';
import './custom-cm-css.css';

import 'codemirror/addon/fold/foldcode';
import 'codemirror/addon/fold/brace-fold';
import 'codemirror/addon/fold/foldgutter';
import 'codemirror/addon/fold/foldgutter.css';

import object from 'obj-fe/utils/object';
import NwdslParser from './nwdsl-parser/nwdsl-parser.js';

import locals from 'obj-fe/services/localisation';

import suggestionsTestSuite from './nwdsl-parser/test/suggestions_test_resources';

const modeName = 'inv-nwdsl-mode';

CodeMirror.defineMode(modeName, function(cmConfig, modeConfig) {

    // // line:{ col:type, col:type, col:type, ... }
    // function createStreamPosTypes(parsedResultValue = []){
    //     if(parsedResultValue.streamPosTypes) return parsedResultValue.streamPosTypes;

    //     let streamPosTypes = {};
        
    //     parsedResultValue.forEach(part => {
    //         if(!part || isNaN(part.startOffset)) return;

    //         let startLine = part.startLine - 1;
    //         streamPosTypes[ startLine ] = streamPosTypes[ startLine ] || {};
    //         streamPosTypes[ startLine ][ part.startColumn - 1 ] = part.typeId;

    //         let endLine = part.endLine - 1;
    //         streamPosTypes[ endLine ] = streamPosTypes[ endLine ] || {};
    //         streamPosTypes[ endLine ][ part.endColumn ] = null;
    //     });

    //     return parsedResultValue.streamPosTypes = streamPosTypes;
    // }

    return {
        token(stream, state) {

            let streamPosTypes = modeConfig.getParsedResultValue().tokenBoundaries; // createStreamPosTypes(modeConfig.getParsedResultValue());

            // switch type when stream is on the edge of types
            let linePosTypes = streamPosTypes[ stream.lineOracle.line ];
            let posType = linePosTypes ? linePosTypes[ stream.pos ] : undefined;

            state.type = posType === undefined ? state.type : posType;
            stream.next();

            // codemirror is ignoring new line chars
            // if stream standing on end of line and type is ending here, we need to reset state.type for next line
            if(stream.eol()){
                linePosTypes = streamPosTypes[ stream.lineOracle.line ];
                let nextPosType = linePosTypes ? linePosTypes[ stream.pos ] : undefined;
                if(nextPosType === null) {
                    let prevType = state.type;
                    state.type = null;
                    return prevType;
                }
            }

            return state.type;
        },
        startState() {
            return {
                type: null
            };
        }
    };
});

CodeMirror.registerHelper('lint', modeName, function(text, cb, opts, cm) {
    cm.parsedResult.onValidated(() => {
        if(!cm.parsedResult) return cb([]);

        if(cm.parsedResult.isValid) {
            let parseWarnings = cm.parsedResult.parseErrors
                .map((err)=>prepareError(err, text));
            return cb(parseWarnings);
        } 

        let lexErrors = cm.parsedResult.lexErrors.map(err => {
            return {
                severity: 'error',
                from: CodeMirror.Pos(err.line - 1, err.column - 1),
                to: CodeMirror.Pos(err.line - 1, err.length + err.column - 1),
                message: locals.translate(err.message)
            };
        });
    
        let parseErrors = cm.parsedResult.parseErrors.map(err => prepareError(err, text));
    
        cb(lexErrors.concat(parseErrors));
    });
});



export default {
    init,
    test
};

function init(cm, parserOpts){

    cm.nwdslParser = new NwdslParser(parserOpts);

    // TODO: trigger first time change callback sync, then async debounced
    cm.on('change', () => {
        let value = cm.getValue();
        cm.parsedResult = cm.nwdslParser.parse(value);
    });

    // immediatelly parse query value
    cm.parsedResult = cm.nwdslParser.parse(cm.getValue());

    cm.setOption('mode', { // will re-initialize the mode
        name: modeName,
        getParsedResultValue() {
            return cm.parsedResult ? cm.parsedResult.value : [];
        }
    });

    cm.setOption('lint', {
        delay: 150,
        async: true
    });

    // hint
    cm.setOption('hintOptions', {
        hint: autocompleteWrapper,
        completeSingle: false
    });

    cm.setOption('extraKeys', {
        'Ctrl-Space': 'autocomplete',
        '.': 'autocomplete',
        'Ctrl-/': comment
    });

    cm.setOption('foldGutter', {
        rangeFinder: CodeMirror.fold.brace
    });

    cm.setOption('gutters', ['CodeMirror-linenumbers', 'CodeMirror-foldgutter']);

    let showAutocompletions = object.debounce(function(cm) {
        let cursor = cm.getCursor();
        let lineText = cm.getLine(cursor.line);
        let cursorIsAtLineStart = !!lineText.slice(0, cursor.ch).match(/^\s*$/);

        if(!cursorIsAtLineStart) cm.showHint(cm);
    }, 150);

    let hintHandlersRegistered = false;
    function registerHintEventHandlers(){
        if(hintHandlersRegistered) return;
        hintHandlersRegistered = true;
        setTimeout(() => {
            // cm.on('cursorActivity', showAutocompletions);
            // cm.on('focus', showAutocompletions);
            // if(cm.hasFocus()) showAutocompletions(cm);
        });
    }
    
    // cm.on('focus', registerHintEventHandlers);
}
async function autocompleteWrapper(cm){
    let result = await autocomplete(cm);
    if(!result) return;
    CodeMirror.on(result, 'pick', function(completion){
        if(completion.type !== 'ALIAS-BE' ) return;

        let codeValue = cm.getDoc().getValue();
        let splitText = codeValue.split('\n');
        let definitionsLineIndex = 0;
        
        
        // find 'definitions'
        while(definitionsLineIndex < splitText.length){
            if(splitText[definitionsLineIndex].includes('definitions')) break;
            else definitionsLineIndex++;
        }
        // find '{' after 'definitions'
        while(definitionsLineIndex < splitText.length){
            if(splitText[definitionsLineIndex].includes('{')) break;
            else definitionsLineIndex++;
        }

        // let newCodeValue = (codeValue.splice(definitionsLineIndex, 0, completion.text)).join('\n');
        cm.getDoc().replaceRange(completion.definitionText, {
            line: definitionsLineIndex + 1,
            ch: 0
        });
    });
    return result;
}

function autocomplete(cm) {
    let cursor = cm.getCursor();

    return new Promise((resolve, reject) => {
        cm.nwdslParser.getNextTokenSuggestions(cursor.line, cursor.ch, (suggestions, startPos, endPos, replaceOldText) => {
            let result = {
                list: suggestions
                    .map(s => {
                        return {
                            text: s.text + ' ',
                            displayText: s.displayText || s.text,
                            className: s.className || 'undefined-suggestion custom-suggestion',
                            type: s.type,
                            definitionText: s.definitionText ? s.definitionText : 'undefined'
                        };}
                    )
                    // .sort((a,b)=>{
                    //     return a.className.localeCompare(b.className);
                    // })
            };
            if(result.list.length > 0){
                result.from = CodeMirror.Pos(startPos.line, startPos.column);
                result.to = CodeMirror.Pos(endPos.line, replaceOldText ? endPos.column + 1 : startPos.column);
                resolve(result);
            }
            else resolve();
        });
    });
}
function comment(cm){
    let selection = cm.getDoc().getSelection();

    // is there anything selected ? 
    // no, there isnt - we only need to process one line
    if(selection.length < 1){
        let cursor = cm.getCursor();
        let line = cm.getDoc().getLine(cursor.line);
    
        // either comment a line 
        if(isLineCommented(line)) {
            cm.getDoc().replaceRange(
                uncommentLine(line), {
                    line: cursor.line,
                    ch: 0
                },{
                    line: cursor.line,
                    ch: line.length
                });
        }
        // or uncomment it
        else {
            cm.getDoc().replaceRange(
                ('//' + line), {
                    line: cursor.line,
                    ch: 0
                },{
                    line: cursor.line,
                    ch: line.length
                });
        }
    } 
    // yes, there is - we need to process all the lines
    else {
        let selectionStartLineIndex = cm.getCursor('from').line;
        let selectionEndLineIndex = cm.getCursor('to').line;
        let shouldUncommentSelection = true;

        // let selection = selection.split('\n');
        for (let lineIndex = selectionStartLineIndex; lineIndex < selectionEndLineIndex; lineIndex++) {
            let line = cm.getDoc().getLine(lineIndex);
            if(!isLineCommented(line)) shouldUncommentSelection = false;
        }

        if(shouldUncommentSelection){
            for (let lineIndex = selectionStartLineIndex; lineIndex <= selectionEndLineIndex; lineIndex++) {
                let line = cm.getDoc().getLine(lineIndex);

                cm.getDoc().replaceRange(
                    uncommentLine(line), {
                        line: lineIndex,
                        ch: 0
                    },{
                        line: lineIndex,
                        ch: line.length
                    });
            }   
        }
        else{
            for (let lineIndex = selectionStartLineIndex; lineIndex <= selectionEndLineIndex; lineIndex++) {
                let line = cm.getDoc().getLine(lineIndex);

                cm.getDoc().replaceRange(
                    ('//' + line), {
                        line: lineIndex,
                        ch: 0
                    },{
                        line: lineIndex,
                        ch: line.length
                    });
            }   
        }
    }

}

function isLineCommented(line){
    let cleanLine = line.trim();
    if(cleanLine.length < 2) return false;
    if(cleanLine[0] === '/' && cleanLine[1] === '/') return true;
    return false;
}
function uncommentLine(line){
    let retval = line;
    let firstForwardSlashIndex = findForwardSlashInString(line);
    retval = retval.substr(0, firstForwardSlashIndex) + retval.substr(firstForwardSlashIndex + 1);
    let secondForwardSlashIndex = findForwardSlashInString(retval);
    retval = retval.substr(0, secondForwardSlashIndex) + retval.substr(secondForwardSlashIndex + 1);

    return retval;
}
function findForwardSlashInString(line){
    let index = 0;
    while(index < line.length){
        if(line[index] === '/') {
            return index;
        }
        index++;
    }
    return -1;
}
function prepareError(err, text){
    let errToken = err.token || {};
    let startColumn = errToken.startColumn;
    let endColumn = errToken.endColumn;
    let startLine = errToken.startLine;
    let endLine = errToken.endLine;

    if(isNaN(startColumn) && err.previousToken) {
        startColumn = err.previousToken.startColumn;
        endColumn = err.previousToken.endColumn;
        startLine = err.previousToken.startLine;
        endLine = err.previousToken.endLine;
    }
    
    if(isNaN(startColumn)) {
        startColumn = 0;
        endColumn = text.length - 1;
    }

    return {
        severity: err.isWarning ? 'warning' : 'error',
        from: CodeMirror.Pos((startLine || 1) - 1, startColumn - 1),
        to: CodeMirror.Pos((endLine || 1) - 1, endColumn),
        message: locals.translate(err.message)
    };
}

async function test(cm, parserOpts){
    cm.nwdslParser = new NwdslParser(parserOpts);
    // TODO: trigger first time change callback sync, then async debounced
    cm.on('change', () => {
        let value = cm.getValue();
        cm.parsedResult = cm.nwdslParser.parse(value);
    });
    // immediatelly parse query value
    cm.parsedResult = cm.nwdslParser.parse(cm.getValue());
    cm.setOption('mode', { // will re-initialize the mode
        name: modeName,
        getParsedResultValue() {
            return cm.parsedResult ? cm.parsedResult.value : [];
        }
    });
    cm.setOption('lint', {
        delay: 150,
        async: true
    });
    // hint
    cm.setOption('hintOptions', {
        hint: autocompleteWrapper,
        completeSingle: true
    });
    cm.setOption('extraKeys', {
        'Ctrl-Space': 'autocomplete',
        'Ctrl-/': comment
    });
    cm.setOption('foldGutter', {
        rangeFinder: CodeMirror.fold.brace
    });
    cm.setOption('gutters', ['CodeMirror-linenumbers', 'CodeMirror-foldgutter']);

    let showAutocompletions = object.debounce(function(cm) {
        let cursor = cm.getCursor();
        let lineText = cm.getLine(cursor.line);
        let cursorIsAtLineStart = !!lineText.slice(0, cursor.ch).match(/^\s*$/);

        if(!cursorIsAtLineStart) cm.showHint(cm);
    }, 150);
    let hintHandlersRegistered = false;
    function registerHintEventHandlers(){
        if(hintHandlersRegistered) return;
        hintHandlersRegistered = true;
        setTimeout(() => {
            // cm.on('cursorActivity', showAutocompletions);
            // cm.on('focus', showAutocompletions);
            // if(cm.hasFocus()) showAutocompletions(cm);
        });
    }
    // tests
    basicSuggestionTest(cm, suggestionsTestSuite);
    backendSuggestionsTest(cm, suggestionsTestSuite);
    setTimeout(()=>codeModifyingSuggestionTest(cm, suggestionsTestSuite), 1000);
    
}
async function codeModifyingSuggestionTest(cm, testSuite){
    let codeModifyingSuggestions = testSuite.codeModifiers;
    console.log('Testing code modifying suggestions (' + codeModifyingSuggestions.length + ' tests):');
    let testingResult = {
        ok: [],
        wrong: []
    };
    for (let i = 0; i < codeModifyingSuggestions.length; i++) {
        let sugg = codeModifyingSuggestions[i];
        let line = sugg.input.split('\n').length - 1;
        let ch = sugg.input.split('\n')[line].split('').length;
        
        cm.setValue(sugg.input);
        cm.setCursor({line, ch});
        
        let suggestions = await autocompleteWrapper(cm);
        let pick = suggestions._handlers.pick[0];
        pick(suggestions.list[0]);
        let actual = cm.getValue();
        if(actual === sugg.expected){
            testingResult.ok.push(createTestResultObject({ type: 'OK', message: 'ok', context: sugg }));
            console.log(`
                ok: ${testingResult.ok.length}/${codeModifyingSuggestions.length} 
                wrong: ${testingResult.wrong.length}/${codeModifyingSuggestions.length}`
            );    
        }
        else{
            testingResult.wrong.push(createTestResultObject({ type: 'WRONG', message: 'ok', context: sugg }));
            console.error(
                'Wrong: ' + sugg.description + '\n\n',
                'expected:\n', sugg.expected + '\n\n',
                'actual:\n', actual + '\n\n',
                'diff:\n', findDiff(sugg.expected, actual)
            );
            
            console.log(`
                ok: ${testingResult.ok.length}/${codeModifyingSuggestions.length} 
                wrong: ${testingResult.wrong.length}/${codeModifyingSuggestions.length}`
            );
        }

    }
}
function backendSuggestionsTest(cm, testSuite) {
    let backendSuggestions = testSuite.suggestions.filter(e => !!e.type && e.type === 'BE-suggest');
    console.log('Testing backend suggestions (' + backendSuggestions.length + ' tests):');
    let testingResult = {
        ok: [],
        wrong: []
    };
    for (let i = 0; i < backendSuggestions.length; i++) {
        let sugg = backendSuggestions[i];
        cm.setValue(sugg.input);

        let line = sugg.input.split('\n').length - 1;
        let ch = sugg.input.split('\n')[line].split('').length;

        cm.nwdslParser.getNextTokenSuggestions(line, ch, async (suggestions) => {
            console.log(sugg.description);
            let result = { list: suggestions.map(s => s.displayText || s.text) };
            if (result.list.length === 0 && sugg.expected.length !== 0) {
                testingResult.wrong.push(createTestResultObject({ type: 'WRONG', message: '0 suggs', context: sugg }));
                console.log(`
                    ok: ${testingResult.ok.length}/${backendSuggestions.length} 
                    wrong: ${testingResult.wrong.length}/${backendSuggestions.length}`
                );
            }
            else {
                let expected = sugg.expected.sort();
                let actual = result.list.sort();

                if (JSON.stringify(expected) === JSON.stringify(actual)) {
                    testingResult.ok.push(createTestResultObject({ type: 'OK', message: 'ok', context: sugg }));
                    console.log(`
                        ok: ${testingResult.ok.length}/${backendSuggestions.length} 
                        wrong: ${testingResult.wrong.length}/${backendSuggestions.length}`
                    );
                }
                else {
                    let inputLines = sugg.input.split('\n');
                    let lastLine = inputLines.pop();
                    let newCursorLine = [lastLine.slice(0, ch), '[•]', lastLine.slice(ch)].join('');
                    inputLines.push(newCursorLine);

                    console.error(
                        '\tWrong: ' + sugg.description + '\n\t\t',
                        'expected:', JSON.stringify(expected), '\n\t\t',
                        'actual:', JSON.stringify(actual), ',\n\t\t',
                        'input with cursor:', inputLines.join('\n')
                    );

                    testingResult.wrong.push(createTestResultObject({
                        type: 'Wrong',
                        message: 'incorrect suggestions',
                        context: {
                            ...sugg,
                            expected: expected,
                            actual: actual,
                            cursor: {
                                ch: ch,
                                line: line
                            }
                        }
                    }));
                    console.log(`
                        ok: ${testingResult.ok.length}/${backendSuggestions.length} 
                        wrong: ${testingResult.wrong.length}/${backendSuggestions.length}`
                    );
                }
            }
        });

    }
}

function basicSuggestionTest(cm, testSuite) {
    let syntacticSuggestions = testSuite.suggestions.filter(e => !e.type);

    console.log('Testing basic suggestions (' + syntacticSuggestions.length + ' tests):');
    let testingResult = {
        ok: [],
        wrong: []
    };
    for (let i = 0; i < syntacticSuggestions.length; i++) {
        let sugg = syntacticSuggestions[i];
        cm.setValue(sugg.input);

        let line = sugg.input.split('\n').length - 1;
        let ch = sugg.input.split('\n')[line].split('').length;

        cm.nwdslParser.getNextTokenSuggestions(line, ch, async (suggestions) => {
            console.log(sugg.description);
            let result = { list: suggestions.map(s => s.displayText || s.text) };
            if (result.list.length === 0 && sugg.expected.length !== 0) {
                testingResult.wrong.push(createTestResultObject({ type: 'WRONG', message: '0 suggs', context: sugg }));
                console.error(`
                    ok: ${testingResult.ok.length}/${syntacticSuggestions.length} 
                    wrong: ${testingResult.wrong.length}/${syntacticSuggestions.length}`
                );
            }
            else {
                let expected = sugg.expected.sort();
                let actual = result.list.sort();

                if (JSON.stringify(expected) === JSON.stringify(actual)) {
                    testingResult.ok.push(createTestResultObject({ type: 'OK', message: 'ok', context: sugg }));
                    console.log(`
                        ok: ${testingResult.ok.length}/${syntacticSuggestions.length} 
                        wrong: ${testingResult.wrong.length}/${syntacticSuggestions.length}`
                    );
                }
                else {
                    let inputLines = sugg.input.split('\n');
                    let lastLine = inputLines.pop();
                    let newCursorLine = [lastLine.slice(0, ch), '[•]', lastLine.slice(ch)].join('');
                    inputLines.push(newCursorLine);

                    console.error(
                        '\tWrong: ' + sugg.description + '\n\t',
                        'expected:', JSON.stringify(expected), '\n\t\t',
                        'actual:', JSON.stringify(actual), '\n\t\t',
                        'input with cursor:', inputLines.join('\n')
                    );

                    testingResult.wrong.push(createTestResultObject({
                        type: 'Wrong',
                        message: 'incorrect suggestions',
                        context: {
                            ...sugg,
                            expected: expected,
                            actual: actual,
                            cursor: {
                                ch: ch,
                                line: line
                            }
                        }
                    }));
                    console.log(`
                        ok: ${testingResult.ok.length}/${syntacticSuggestions.length} 
                        wrong: ${testingResult.wrong.length}/${syntacticSuggestions.length}`
                    );
                }
            }
        });

    }
}
function findDiff(str1, str2){ 
    let diff= '';
    str2.split('').forEach(function(val, i){
        if (val !== str1.charAt(i))
            diff += val ;         
    });
    return diff;
}
function createTestResultObject(eObject){
    return {
        type: eObject.type,
        message: eObject.message,
        context: eObject.context
    };
}

