<template>
    <span v-if="name">
        <!-- TODO: [queryParams]="snapshotQueryParam" -->
        <v-icon v-if="object && object.userModified" color="warning">update</v-icon>

        <router-link class="object-link" v-if="!disabled && object" :to="{ name:'inventory-object', params:{ id: (object.id || object.globalId)+'' } }" :target="openNewTab ? '_blank' : ''">
            <slot :object-name="name">{{ name }}</slot>
        </router-link>
        
        <template v-if="disabled && object">{{ name }}</template>
    </span>
</template>

<script>
    export default {
        props:{
            object: Object,
            disabled: Boolean,
            customName: String,
            openNewTab: Boolean
        },
        data(){
            return {};
        },
        computed:{
            name(){
                if(this.customName) return this.customName;
                
                let objectName = '';
                if(this.object) {
                    let objectTypeName = this.object.typeDisplayString;
                    if(!objectTypeName) objectTypeName = Array.isArray(this.object.objectType) ? this.object.objectType[ this.object.objectType.length-1 ] : this.object.objectType || '';
                    objectName = (this.object.name || this.object.displayString || '') 
                    if(!objectName) return objectTypeName || '';
                    if(objectTypeName) objectName += ' (' + objectTypeName + ')';
                }
                
                return objectName;
            }
        }
    };
</script>

<style scoped>
.object-link{
    font-size: unset !important;
}

</style>