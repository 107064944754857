var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"fullscreen":"","persistent":"","hide-overlay":"","transition":"dialog-bottom-transition","content-class":"editor-dialog"},model:{value:(_vm.open),callback:function ($$v) {_vm.open=$$v},expression:"open"}},[_c('v-toolbar',{attrs:{"dark":"","color":"primary","flat":""}},[_c('v-btn',{attrs:{"icon":"","outlined":"","color":"error"},on:{"click":_vm.finishEditing}},[_c('v-icon',[_vm._v("fal fa-times")])],1),_vm._v(" "),_c('v-toolbar-title',[_vm._v(_vm._s(_vm._f("translate")('grid_dsl_editor')))]),_vm._v(" "),_c('v-spacer'),_vm._v(" "),_c('v-toolbar-items',{staticClass:"editor-dialog-toolbar-items"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"x-large":"","icon":"","color":"error"},on:{"click":_vm.revertChanges}},on),[_c('v-icon',[_vm._v("fal fa-undo-alt")])],1)]}}])},[_vm._v(" "),_c('span',[_vm._v("\n                        "+_vm._s(_vm._f("translate")('revert_to_origin'))+"\n                    ")])]),_vm._v(" "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"x-large":"","icon":"","color":"success","disabled":!_vm.editorContentIsValid || _vm.changesSaved},on:{"click":_vm.save}},on),[_c('v-icon',[_vm._v("fal fa-save")])],1)]}}])},[_vm._v(" "),_c('span',[_vm._v("\n                        "+_vm._s(_vm._f("translate")('save'))+"\n                    ")])])],1)],1),_vm._v(" "),_c('v-card',[_c('div',{staticClass:"dsl-editor-container"},[_c('div',{ref:"editor",staticClass:"grid-dsl-dsl-editor",class:{ 'equal-size': _vm.modalContentEqualSize }},[_c('inv-ihi-dsl-editor',{attrs:{"revertCodeFlag":_vm.revertCodeFlag,"originValue":_vm.oldIhiDslCode,"placeholder":_vm._f("translate")('enter_graph_code_here'),"auto-height":""},on:{"reverted":_vm.processRevertChanges,"valid":function (isValid) { return _vm.editorContentIsValid = isValid; },"parser-result":function (result) { return _vm.ihiParserResult = result; }},model:{value:(_vm.ihiDslCode),callback:function ($$v) {_vm.ihiDslCode=$$v},expression:"ihiDslCode"}})],1),_vm._v(" "),(_vm.previewReportId)?_c('div',{staticClass:"grid-dsl-dsl-table",class:{ 'equal-size': _vm.modalContentEqualSize }},[_c('v-toolbar',{attrs:{"dark":"","color":"primary","dense":""}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm._f("translate")('table_preview')))]),_vm._v(" "),_c('v-spacer'),_vm._v(" "),_c('v-toolbar-items',{staticClass:"editor-dialog-toolbar-items"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"x-large":"","icon":"","color":"white"},on:{"click":_vm.attemptReload}},on),[_c('v-icon',[_vm._v("fal fa-sync")])],1)]}}],null,false,2786269479)},[_vm._v(" "),_c('span',[_vm._v("\n                                    "+_vm._s(_vm._f("translate")('reload_table_preview'))+"\n                                ")])]),_vm._v(" "),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","x-large":"","color":"white"},on:{"click":_vm.toggleModalContentResize}},on),[_c('v-icon',[_vm._v(_vm._s(_vm.modalContentEqualSize ? 'fal fa-compress-alt' : 'fal fa-expand-alt'))])],1)]}}],null,false,1880495772)},[_vm._v(" "),_c('span',[_vm._v("\n                                    "+_vm._s(_vm._f("translate")((_vm.modalContentEqualSize ? 'collapse' : 'expand')))+"\n                                ")])])],1)],1),_vm._v(" "),_c('div',{staticClass:"loading-indicator"},[_c('v-progress-linear',{attrs:{"indeterminate":"","color":"var(--v-accent-base)"}})],1),_vm._v(" "),(_vm.reportServicesInited)?_c('obj-report',{attrs:{"full-height":"","load-immediately":"","report-id":_vm.previewReportId,"readonly":false,"state":_vm.reportState,"data-test":'obj-report_' + _vm.previewReportId},on:{"input":function (query) { return _vm.$emit('state-change', query); }}}):_vm._e()],1):_vm._e()])])],1),_vm._v(" "),_c('v-dialog',{attrs:{"content-class":"small-dialog","width":"500px"},model:{value:(_vm.closeDialog),callback:function ($$v) {_vm.closeDialog=$$v},expression:"closeDialog"}},[_c('v-card',[_c('v-card-title',[_c('h2',[_vm._v(_vm._s(_vm._f("translate")('warning')))])]),_vm._v(" "),_c('v-card-text',[_vm._v("\n                "+_vm._s(_vm._f("translate")('discarding_unsaved_changes'))+"\n            ")]),_vm._v(" "),_c('v-card-actions',[_c('v-spacer'),_vm._v(" "),_c('v-btn',{attrs:{"color":"error","text":""},on:{"click":_vm.discard}},[_vm._v("\n                    "+_vm._s(_vm._f("translate")('exit_editor_discard'))+"\n                ")]),_vm._v(" "),_c('v-btn',{attrs:{"outlined":"","color":"green"},on:{"click":function($event){_vm.closeDialog = false}}},[_vm._v("\n                    "+_vm._s(_vm._f("translate")('return_to_code'))+"\n                ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }