import Vue from 'vue';
import backend from 'obj-fe/app/backend';
import store from 'obj-fe/app/store';
import ifc from 'obj-fe/modules/interface';

export default {
    init
};

function init() {

    // dashboard
    store.dispatch('MENU/ADD_ITEMS', {
        'dashboard': {
            text: 'dashboard',
            icon: 'fal fa-home',
            index: 0,
            to: {
                name: 'dashboard'
            },
            requiresPermission: "DASHBOARD",
        },
    });

    // search
    store.dispatch('MENU/ADD_ITEMS', {
        'search': {
            text: 'search_inventory_objects',
            icon: 'fal fa-search',
            index: 1000,
            to: {
                name: 'inventory-search'
            },
            requiresPermission: "SEARCH",
        },
    });

    // project
    store.dispatch('MENU/ADD_ITEMS', {
        project: {
            text: 'project',
            index: 5000,
            icon: 'fal fa-users-crown',
            allowRoles: ['ReportPermission', 'AdminPermission'],
            allowForPermissions: [
                "REPORTS",
                "COLLECTIONS",
            ],
        },
    });
    store.dispatch('MENU/ADD_ITEM_CHILDREN', {
        item: 'project',
        children: {
            reports: {
                text: 'reports',
                icon: 'fal fa-file-chart-line',
                index: 6000,
                to: { name:'reports-list' },
                allowRoles: ['ReportPermission', 'AdminPermission'],
                requiresPermission: "REPORTS",
            },
            collections: {
                text: 'collections',
                icon: 'fal fa-folders',
                index: 6000,
                to: { name:'collections-list' },
                allowRoles: ['ReportPermission', 'AdminPermission'],
                requiresPermission: "COLLECTIONS",
            }
        }
    });

    // administration
    store.dispatch('MENU/ADD_ITEMS', {
        administration: {
            text: 'administration',
            icon: 'fal fa-users-crown',
            index: 6000,
            allowRoles: ['AdminPermission'],
            allowForPermissions: [
                "ORGANISATION_STRUCTURE",
                "ACTIVE_USERS",
                "LIST_VALUES",
            ],
        },
    });
    store.dispatch('MENU/ADD_ITEM_CHILDREN', {
        item: 'administration',
        children: {
            'org-structure': {
                icon: 'fal fa-sitemap',
                text: 'org_structure',
                to: {
                    path: '/maintenance/org-structure'
                },
                allowRoles: ['AdminPermission'],
                requiresPermission: "ORGANISATION_STRUCTURE",
            },
            'active-users': {
                text: 'active_users',
                icon: 'fal fa-users',
                to: { name:'active-users' },
                allowRoles: ['AdminPermission'],
                requiresPermission: "ACTIVE_USERS",
            },
            'list-values': {
                icon: 'fal fa-list-ul',
                text: 'list_values',
                to: {
                    path: '/maintenance/list-values'
                },
                allowRoles: ['AdminPermission'],
                requiresPermission: "LIST_VALUES",
            }
        }
    });

    // maintanance
    store.dispatch('MENU/REMOVE_ITEM', 'maintanance');
    store.dispatch('MENU/ADD_ITEMS', {
        'maintanance': {
            text: 'maintanance',
            icon: 'settings',
            index: 9000,
            allowRoles: ['AdminPermission'],
            allowForPermissions: [
                "BATCH_JOBS",
                "IMPORT_JOBS",
                "JOBS_DASHBOARD",
                "META_MODEL",
                "APPLICATION_CONFIGURATION",
                "APPLICATION_LOGS",
                "SWAGGER",
            ],
        },
    });
    store.dispatch('MENU/ADD_ITEM_CHILDREN', {
        item: 'maintanance',
        children: {
            'batch-jobs': {
                icon: 'fal fa-cogs',
                text: 'batch_jobs',
                to: {
                    name: 'batch-jobs'
                },
                allowRoles: ['AdminPermission'],
                requiresPermission: "BATCH_JOBS",
            },
            'import-jobs': {
                icon: 'fal fa-inbox-in',
                text: 'import_jobs',
                to: {
                    name: 'import-jobs'
                },
                allowRoles: ['AdminPermission'],
                requiresPermission: "IMPORT_JOBS",
            },
            'jobs-dashboard': {
                icon: 'fal fa-money-check',
                text: 'jobs_dashboard',
                to: {
                    name: 'jobs-dashboard'
                },
                allowRoles: ['AdminPermission'],
                requiresPermission: "JOBS_DASHBOARD",
            },
            'meta-model': {
                icon: 'fal fa-folder-tree',
                text: 'meta_model',
                onClick(item) {
                    window.open('rest/admin/metaModel', '_blank');
                },
                allowRoles: ['AdminPermission'],
                requiresPermission: "META_MODEL",
            },
            'app-config': {
                text: 'configuration',
                icon: 'fal fa-tools',
                to: { name:'app-config' },
                allowRoles: ['AdminPermission'],
                requiresPermission: "APPLICATION_CONFIGURATION",
            },
            'app-logs':{
                icon:'fal fa-poll-h',
                text: 'app_logs',
                to: { name:'app-logs' },
                allowRoles: ['AdminPermission'],
                requiresPermission: "APPLICATION_LOGS",
            },
            'swagger': {
                text: 'swagger',
                icon: 'fal fa-link',
                onClick(item) {
                    // get swagger url from app store
                    window.open(ifc.store.state.swaggerUrl,'_blank');
                },
                allowRoles: ['SwaggerPermission', 'AdminPermission'],
                requiresPermission: "SWAGGER",
            }
        }
    });
    
    // reports
    store.dispatch('MENU/REMOVE_ITEM', 'reporting');
    
    // 'map': {
    //     text: 'Map',
    //     icon: 'map',
    //     index: 1000,
    //     to: { name:'inventory-map' }
    // },

    // 'data-model': {
    //     text:'data_model',
    //     // icon:'perm_data_setting',
    //     opened:true,
    //     index: 6000,
    //     allowRoles: ['TagsAdminPermission', 'AdminPermission'],
    //     children: {
    //         'admin-document-types': {
    //             text: 'types',
    //             icon: 'fa-sitemap',
    //             to: { name:'admin-document-types-list' },
    //             allowRoles: ['TagsAdminPermission', 'AdminPermission']
    //         },
    //         'admin-field-list': {
    //             text: 'fields',
    //             icon: 'input',
    //             to: '/admin/field-list',
    //             allowRoles: ['TagsAdminPermission', 'AdminPermission']
    //         },
    //         'admin-field-value-list': {
    //             text: 'field_values',
    //             icon: 'label',
    //             to: { name:'admin-field-value-list' },
    //             allowRoles: ['TagsAdminPermission', 'AdminPermission']
    //         },
    //         // 'admin-decision-tree-templates': {
    //         //     text: 'decision_tree_templates',
    //         //     icon: 'fa-project-diagram',
    //         //     to: { name:'admin-decision-tree-templates' },
    //         //     allowRoles: ['CreateDecisionTreePermission', 'AdminPermission'],
    //         // },
    //     }
    // },
    // admin:{
    //     text:'admin_menu',
    //     // icon: 'fa-cogs',
    //     opened: true,
    //     index: 7000,
    //     children: {
    //         'admin-user-defined-queries': {
    //             text: 'user_defined_queries',
    //             icon: 'fa-filter',
    //             to: '/admin/user-defined-queries'
    //         },
    //         'admin-tag-rules': {
    //             text: 'tag_rules', 
    //             icon: 'fa-not-equal', 
    //             to: '/admin/tag-rules', 
    //             allowRoles: ['TagsAdminPermission', 'AdminPermission'] 
    //         },
    //         'notifications': {
    //             text: 'notifications', 
    //             icon: 'fa-bell', 
    //             to: '/notifications', 
    //             allowRoles: ['NotificationAdminPermission', 'AdminPermission'] 
    //         },
    //         'admin-uploadtrees': {
    //             text: 'upload_trees', 
    //             icon: 'call_split', 
    //             to: '/admin/uploadtrees',
    //             allowRoles: ['UploadTreesAdminPermission', 'AdminPermission']
    //         },
    //         'admin-loader': {
    //             text: 'loader_configurations', 
    //             icon: 'fa-file-import', 
    //             to: '/admin/loader-configurations', 
    //             allowRoles: ['LoaderPermission', 'AdminPermission'] 
    //         }
    //     }
    // },
    // 'persons-teams-accounts': {
    //     text: 'person_teams_and_accounts',
    //     // icon: 'fa-user-cog', 
    //     allowRoles: ['AdminPermission'],
    //     opened: true,
    //     index: 8000,
    //     children: {
    //         'admin-orgstructure': { 
    //             text: 'org_structure', 
    //             icon: 'share',
    //             to: { name:'admin-orgstructure' }, 
    //             allowRoles: ['OrgStructureAdminPermission', 'AdminPermission'] 
    //         }
    //     }
    // },
    // 'maintanance': {
    //     text: 'maintenance',
    //     // icon: 'settings', 
    //     allowRoles: ['AdminPermission'],
    //     opened: true,
    //     index: 9000,
    //     children: {
    //         'admin-stats': {
    //             text:'stats',
    //             icon:'fa-chart-line', 
    //             to: '/stats'
    //         },
    //         'admin-active-users': { 
    //             text: 'active_users', 
    //             icon: 'fa-address-card',
    //             to: '/admin/active-users', 
    //             allowRoles: ['AdminPermission'] 
    //         },
    //         'document-jobs': { 
    //             text: 'document_jobs', 
    //             icon: 'settings_applications', 
    //             to: '/admin/document-jobs', 
    //             allowRoles: ['AdminPermission'] 
    //         },
    //         'admin-reports': { 
    //             text: 'reports_admin',
    //             icon: 'fa-chart-bar',
    //             to: { name:'admin-reports' },
    //             allowRoles: ['AdminPermission']
    //         },
    //         'admin-logs': { 
    //             text: 'logs', 
    //             icon: 'fa-file-alt', 
    //             to: '/admin/logs', 
    //             allowRoles: ['AdminPermission']
    //         },
    //         'admin-config': { 
    //             text: 'config', 
    //             icon: 'fa-receipt',
    //             to: { name:'admin-config' }, 
    //             allowRoles: ['AdminPermission'] 
    //         },
    //         'admin-swagger': {
    //             text: 'swagger',
    //             icon: 'link',
    //             allowRoles: ['SwaggerPermission', 'AdminPermission'],
    //             onClick(item) {
    //                 var url = 'api-docs/index.html?url=' + window.location.origin + window.location.pathname.replace('/index.html','') + '/rest/swagger.json';
    //                 url = url.replace(/([^:])\/\//,'$1/');
    //                 window.open(url,'_blank');
    //             }
    //         }
    //     }
    // }
}