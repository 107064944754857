<template>
    <div>
        <v-tooltip top>
            <template v-slot:activator="{ on }">
                <v-btn v-on="on" x-small text @click="openRowDetails" @mousedown="$event.stopPropagation()">
                    <v-icon small>mdi-clipboard-text-multiple</v-icon>
                </v-btn>
            </template>
            {{'form' | translate}}
        </v-tooltip>
    </div>
</template>

<script>
    import rendererMixin from './spreadsheet-renderer-mixin';

    export default {
        mixins:[ rendererMixin ],
        methods:{
            openRowDetails(){
                this.getSpreadsheetComponent().openRowDetails(this.rowIndex);
            }
        }
    }
</script>

<style scoped>
    div {
        margin:-2px 0px -4px 0px;
    }
</style>