var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"container",staticClass:"editor-container",class:{ editable: _vm.editable }},[_c('editor-menu-bar',{directives:[{name:"show",rawName:"v-show",value:(_vm.editable),expression:"editable"}],attrs:{"editor":_vm.editor},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var commands = ref.commands;
var isActive = ref.isActive;
var getMarkAttrs = ref.getMarkAttrs;
return _c('div',{staticClass:"menubar"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({class:{ active: isActive.bold() },attrs:{"text":"","icon":""},on:{"click":commands.bold}},on),[_c('v-icon',[_vm._v("format_bold")])],1)]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm._f("translate")("bold")))])]),_vm._v(" "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({class:{ active: isActive.italic() },attrs:{"text":"","icon":""},on:{"click":commands.italic}},on),[_c('v-icon',[_vm._v("format_italic")])],1)]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm._f("translate")("italic")))])]),_vm._v(" "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({class:{ active: isActive.underline() },attrs:{"text":"","icon":""},on:{"click":commands.underline}},on),[_c('v-icon',[_vm._v("format_underlined")])],1)]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm._f("translate")("underline")))])]),_vm._v(" "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({class:{ active: isActive.strike() },attrs:{"text":"","icon":""},on:{"click":commands.strike}},on),[_c('v-icon',[_vm._v("format_strikethrough")])],1)]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm._f("translate")("strikethrough")))])]),_vm._v(" "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({class:{ active: isActive.paragraph() },attrs:{"text":"","icon":""},on:{"click":commands.paragraph}},on),[_c('v-icon',[_vm._v("format_textdirection_l_to_r")])],1)]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm._f("translate")("paragraph")))])]),_vm._v(" "),_vm._l((6),function(n){return _c('v-tooltip',{key:n,attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({class:{ active: isActive.heading({ level: n }) },attrs:{"text":"","icon":""},on:{"click":function($event){return commands.heading({ level: n })}}},on),[_vm._v("\n                        H"+_vm._s(n)+"\n                    ")])]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm._f("translate")(("heading_" + n))))])])}),_vm._v(" "),_c('v-menu',{attrs:{"content-class":"button-menu","offset-y":"","open-on-click":false,"nudge-width":200},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('template',_vm._g({slot:"activator"},on),[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({class:{ active: isActive.link() },attrs:{"text":"","icon":""},on:{"click":function($event){return _vm.openLinkMenu(getMarkAttrs)}}},on),[_c('v-icon',[_vm._v("link")])],1)]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm._f("translate")((isActive.link() ? "update_link" : "add_link"))))])])],1)]}}],null,true),model:{value:(_vm.linkMenu.opened),callback:function ($$v) {_vm.$set(_vm.linkMenu, "opened", $$v)},expression:"linkMenu.opened"}},[_vm._v(" "),_c('v-tabs',{class:{ 'hidden-tabs-bar': _vm.predefinedLinks.length === 0 },attrs:{"fixed-tabs":""}},[_c('v-tab',[_vm._v(_vm._s(_vm._f("translate")("custom")))]),_vm._v(" "),_c('v-tab-item',[_c('v-card',[_c('v-card-text',[(!_vm.isSelectedRange())?_c('v-text-field',{attrs:{"label":_vm._f("translate")('link_text')},model:{value:(_vm.linkMenu.name),callback:function ($$v) {_vm.$set(_vm.linkMenu, "name", $$v)},expression:"linkMenu.name"}}):_vm._e(),_vm._v(" "),_c('v-text-field',{attrs:{"label":_vm._f("translate")('link_url'),"placeholder":"https://..."},model:{value:(_vm.linkMenu.url),callback:function ($$v) {_vm.$set(_vm.linkMenu, "url", $$v)},expression:"linkMenu.url"}})],1),_vm._v(" "),_c('v-card-actions',[(isActive.link())?_c('v-btn',{attrs:{"text":""},on:{"click":function($event){commands.link({ href: '' });
                                        _vm.linkMenu.opened = false;}}},[_vm._v(_vm._s(_vm._f("translate")("unlink")))]):_vm._e(),_vm._v(" "),_c('v-spacer'),_vm._v(" "),_c('v-btn',{attrs:{"color":"primary","text":"","disabled":!_vm.linkMenu.url || !(_vm.linkMenu.name || _vm.isSelectedRange())},on:{"click":function($event){_vm.addLink();
                                        _vm.linkMenu.opened = false;}}},[_vm._v(_vm._s(_vm._f("translate")((isActive.link() ? "update_link" : "add_link"))))])],1)],1)],1),_vm._v(" "),(_vm.predefinedLinks.length > 0)?_c('v-tab',[_vm._v(_vm._s(_vm._f("translate")(_vm.predefinedLabel)))]):_vm._e(),_vm._v(" "),_c('v-tab-item',[_c('v-list',_vm._l((_vm.predefinedLinks),function(link,i){return _c('v-list-tile',{key:i,on:{"click":function($event){_vm.addLink(link);
                                    _vm.linkMenu.opened = false;}}},[_vm._v("\n                                "+_vm._s(link.name)+"\n                            ")])}),1)],1)],1)],1),_vm._v(" "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                    var on = ref.on;
return [_c('v-btn',_vm._g({class:{ active: isActive.bullet_list() },attrs:{"text":"","icon":""},on:{"click":commands.bullet_list}},on),[_c('v-icon',[_vm._v("format_list_bulleted")])],1)]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm._f("translate")("list_bulleted")))])]),_vm._v(" "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                    var on = ref.on;
return [_c('v-btn',_vm._g({class:{ active: isActive.ordered_list() },attrs:{"text":"","icon":""},on:{"click":commands.ordered_list}},on),[_c('v-icon',[_vm._v("format_list_numbered")])],1)]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm._f("translate")("list_numbered")))])]),_vm._v(" "),(!_vm.basic)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                    var on = ref.on;
return [_c('v-btn',_vm._g({class:{ active: isActive.blockquote() },attrs:{"text":"","icon":""},on:{"click":commands.blockquote}},on),[_c('v-icon',[_vm._v("format_quote")])],1)]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm._f("translate")("quote")))])]):_vm._e(),_vm._v(" "),(!_vm.basic)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                    var on = ref.on;
return [_c('v-btn',_vm._g({class:{ active: isActive.code() },attrs:{"text":"","icon":""},on:{"click":commands.code}},on),[_c('v-icon',[_vm._v("code")])],1)]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm._f("translate")("code")))])]):_vm._e(),_vm._v(" "),(!_vm.basic)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                    var on = ref.on;
return [_c('v-btn',_vm._g({class:{ active: isActive.code_block() },attrs:{"text":"","icon":""},on:{"click":commands.code_block}},on),[_c('v-icon',[_vm._v("notes")])],1)]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm._f("translate")("code_block")))])]):_vm._e(),_vm._v(" "),(!_vm.basic)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                    var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","icon":""},on:{"click":commands.horizontal_rule}},on),[_vm._v("\n                        –\n                    ")])]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm._f("translate")("horizontal_line")))])]):_vm._e(),_vm._v(" "),(!_vm.basic)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                    var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","icon":""},on:{"click":commands.undo}},on),[_c('v-icon',[_vm._v("undo")])],1)]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm._f("translate")("undo")))])]):_vm._e(),_vm._v(" "),(!_vm.basic)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                    var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","icon":""},on:{"click":commands.redo}},on),[_c('v-icon',[_vm._v("redo")])],1)]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm._f("translate")("redo")))])]):_vm._e(),_vm._v(" "),(!_vm.basic)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                    var on = ref.on;
return [_c('v-btn',_vm._g({class:{
                            active: isActive.alignment() && _vm.editor.activeMarkAttrs.alignment.textAlign === 'left'
                        },attrs:{"text":"","icon":""},on:{"click":function($event){return commands.alignment({ textAlign: 'left' })}}},on),[_c('v-icon',[_vm._v("format_align_left")])],1)]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm._f("translate")("align_left")))])]):_vm._e(),_vm._v(" "),(!_vm.basic)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
return [_c('v-btn',_vm._g({class:{
                            active: isActive.alignment() && _vm.editor.activeMarkAttrs.alignment.textAlign === 'center'
                        },attrs:{"text":"","icon":""},on:{"click":function($event){return commands.alignment({ textAlign: 'center' })}}},on),[_c('v-icon',[_vm._v("format_align_center")])],1)]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm._f("translate")("align_center")))])]):_vm._e(),_vm._v(" "),(!_vm.basic)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
return [_c('v-btn',_vm._g({class:{
                            active: isActive.alignment() && _vm.editor.activeMarkAttrs.alignment.textAlign === 'right'
                        },attrs:{"text":"","icon":""},on:{"click":function($event){return commands.alignment({ textAlign: 'right' })}}},on),[_c('v-icon',[_vm._v("format_align_right")])],1)]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm._f("translate")("align_right")))])]):_vm._e(),_vm._v(" "),(!_vm.basic)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","icon":""},on:{"click":function($event){return commands.createTable({ rowsCount: 3, colsCount: 3, withHeaderRow: false })}}},on),[_c('div',{staticStyle:{"padding":"8px"}},[_c('svg',{attrs:{"viewBox":"0 0 24 24","width":"100%","height":"100%"}},[_c('path',{attrs:{"fill-rule":"evenodd","d":"M17 17v5h2a3 3 0 0 0 3-3v-2h-5zm-2 0H9v5h6v-5zm2-2h5V9h-5v6zm-2 0V9H9v6h6zm2-8h5V5a3 3 0 0 0-3-3h-2v5zm-2 0V2H9v5h6zm9 9.177V19a5 5 0 0 1-5 5H5a5 5 0 0 1-5-5V5a5 5 0 0 1 5-5h14a5 5 0 0 1 5 5v2.823a.843.843 0 0 1 0 .354v7.646a.843.843 0 0 1 0 .354zM7 2H5a3 3 0 0 0-3 3v2h5V2zM2 9v6h5V9H2zm0 8v2a3 3 0 0 0 3 3h2v-5H2z"}})])])])]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm._f("translate")("add_table")))])]):_vm._e(),_vm._v(" "),(!_vm.basic && isActive.table())?[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","icon":""},on:{"click":commands.deleteTable}},on),[_c('div',{staticStyle:{"padding":"8px"}},[_c('svg',{attrs:{"viewBox":"0 0 24 24","id":"icon--delete_table","width":"100%","height":"100%"}},[_c('path',{attrs:{"d":"M19 14a5 5 0 1 1 0 10 5 5 0 0 1 0-10zm-2.5 5.938h5a.937.937 0 1 0 0-1.875h-5a.937.937 0 1 0 0 1.875zM12.29 17H9v5h3.674c.356.75.841 1.426 1.427 2H5a5 5 0 0 1-5-5V5a5 5 0 0 1 5-5h14a5 5 0 0 1 5 5v2.823a.843.843 0 0 1 0 .354V14.1a7.018 7.018 0 0 0-2-1.427V9h-5v3.29a6.972 6.972 0 0 0-2 .965V9H9v6h4.255a6.972 6.972 0 0 0-.965 2zM17 7h5V5a3 3 0 0 0-3-3h-2v5zm-2 0V2H9v5h6zM7 2H5a3 3 0 0 0-3 3v2h5V2zM2 9v6h5V9H2zm0 8v2a3 3 0 0 0 3 3h2v-5H2z"}})])])])]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm._f("translate")("delete_table")))])]),_vm._v(" "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","icon":""},on:{"click":commands.addColumnBefore}},on),[_c('div',{staticStyle:{"padding":"8px"}},[_c('svg',{attrs:{"viewBox":"0 0 24 24","id":"icon--add_col_before","width":"100%","height":"100%"}},[_c('path',{attrs:{"d":"M19 14a5 5 0 1 1 0 10 5 5 0 0 1 0-10zm2.5 5.938a.937.937 0 1 0 0-1.875h-1.25a.312.312 0 0 1-.313-.313V16.5a.937.937 0 1 0-1.875 0v1.25c0 .173-.14.313-.312.313H16.5a.937.937 0 1 0 0 1.875h1.25c.173 0 .313.14.313.312v1.25a.937.937 0 1 0 1.875 0v-1.25c0-.173.14-.313.312-.313h1.25zM2 19a3 3 0 0 0 6 0V5a3 3 0 1 0-6 0v14zm-2 0V5a5 5 0 1 1 10 0v14a5 5 0 0 1-10 0z"}})])])])]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm._f("translate")("add_col_before")))])]),_vm._v(" "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","icon":""},on:{"click":commands.addColumnAfter}},on),[_c('div',{staticStyle:{"padding":"8px"}},[_c('svg',{attrs:{"viewBox":"0 0 24 24","id":"icon--add_col_after","width":"100%","height":"100%"}},[_c('path',{attrs:{"d":"M5 14a5 5 0 1 1 0 10 5 5 0 0 1 0-10zm2.5 5.938a.937.937 0 1 0 0-1.875H6.25a.312.312 0 0 1-.313-.313V16.5a.937.937 0 1 0-1.875 0v1.25c0 .173-.14.313-.312.313H2.5a.937.937 0 1 0 0 1.875h1.25c.173 0 .313.14.313.312v1.25a.937.937 0 1 0 1.875 0v-1.25c0-.173.14-.313.312-.313H7.5zM16 19a3 3 0 0 0 6 0V5a3 3 0 0 0-6 0v14zm-2 0V5a5 5 0 0 1 10 0v14a5 5 0 0 1-10 0z"}})])])])]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm._f("translate")("add_col_after")))])]),_vm._v(" "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","icon":""},on:{"click":commands.deleteColumn}},on),[_c('div',{staticStyle:{"padding":"8px"}},[_c('svg',{attrs:{"viewBox":"0 0 24 24","id":"icon--delete_col","width":"100%","height":"100%"}},[_c('path',{attrs:{"d":"M12.641 21.931a7.01 7.01 0 0 0 1.146 1.74A5 5 0 0 1 7 19V5a5 5 0 1 1 10 0v7.29a6.972 6.972 0 0 0-2 .965V5a3 3 0 0 0-6 0v14a3 3 0 0 0 3.641 2.931zM19 14a5 5 0 1 1 0 10 5 5 0 0 1 0-10zm-2.5 5.938h5a.937.937 0 1 0 0-1.875h-5a.937.937 0 1 0 0 1.875z"}})])])])]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm._f("translate")("delete_col")))])]),_vm._v(" "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","icon":""},on:{"click":commands.addRowBefore}},on),[_c('div',{staticStyle:{"padding":"8px"}},[_c('svg',{attrs:{"viewBox":"0 0 24 24","id":"icon--add_row_before","width":"100%","height":"100%"}},[_c('path',{attrs:{"d":"M19 14a5 5 0 1 1 0 10 5 5 0 0 1 0-10zm2.5 5.938a.937.937 0 1 0 0-1.875h-1.25a.312.312 0 0 1-.313-.313V16.5a.937.937 0 1 0-1.875 0v1.25c0 .173-.14.313-.312.313H16.5a.937.937 0 1 0 0 1.875h1.25c.173 0 .313.14.313.312v1.25a.937.937 0 1 0 1.875 0v-1.25c0-.173.14-.313.312-.313h1.25zM5 2a3 3 0 1 0 0 6h14a3 3 0 0 0 0-6H5zm0-2h14a5 5 0 0 1 0 10H5A5 5 0 1 1 5 0z"}})])])])]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm._f("translate")("add_row_before")))])]),_vm._v(" "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","icon":""},on:{"click":commands.addRowAfter}},on),[_c('div',{staticStyle:{"padding":"8px"}},[_c('svg',{attrs:{"viewBox":"0 0 24 24","id":"icon--add_row_after","width":"100%","height":"100%"}},[_c('path',{attrs:{"d":"M19 0a5 5 0 1 1 0 10 5 5 0 0 1 0-10zm2.5 5.938a.937.937 0 1 0 0-1.875h-1.25a.312.312 0 0 1-.313-.313V2.5a.937.937 0 1 0-1.875 0v1.25c0 .173-.14.313-.312.313H16.5a.937.937 0 1 0 0 1.875h1.25c.173 0 .313.14.313.312V7.5a.937.937 0 1 0 1.875 0V6.25c0-.173.14-.313.312-.313h1.25zM5 16a3 3 0 0 0 0 6h14a3 3 0 0 0 0-6H5zm0-2h14a5 5 0 0 1 0 10H5a5 5 0 0 1 0-10z"}})])])])]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm._f("translate")("add_row_after")))])]),_vm._v(" "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","icon":""},on:{"click":commands.deleteRow}},on),[_c('div',{staticStyle:{"padding":"8px"}},[_c('svg',{attrs:{"viewBox":"0 0 24 24","id":"icon--delete_row","width":"100%","height":"100%"}},[_c('path',{attrs:{"d":"M13.255 15a6.972 6.972 0 0 0-.965 2H5A5 5 0 0 1 5 7h14a5 5 0 0 1 4.671 6.787 7.01 7.01 0 0 0-1.74-1.146A3 3 0 0 0 19 9H5a3 3 0 0 0 0 6h8.255zM19 14a5 5 0 1 1 0 10 5 5 0 0 1 0-10zm-2.5 5.938h5a.937.937 0 1 0 0-1.875h-5a.937.937 0 1 0 0 1.875z"}})])])])]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm._f("translate")("delete_row")))])]),_vm._v(" "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","icon":""},on:{"click":commands.toggleCellMerge}},on),[_c('div',{staticStyle:{"padding":"8px"}},[_c('svg',{attrs:{"viewBox":"0 0 24 24","id":"icon--combine_cells","width":"100%","height":"100%"}},[_c('path',{attrs:{"d":"M2 19a3 3 0 0 0 3 3h14a3 3 0 0 0 3-3V5a3 3 0 0 0-3-3H5a3 3 0 0 0-3 3v14zm-2 0V5a5 5 0 0 1 5-5h14a5 5 0 0 1 5 5v14a5 5 0 0 1-5 5H5a5 5 0 0 1-5-5zm12-9a1 1 0 0 1 1 1v2a1 1 0 0 1-2 0v-2a1 1 0 0 1 1-1zm0 6a1 1 0 0 1 1 1v3a1 1 0 0 1-2 0v-3a1 1 0 0 1 1-1zm0-13a1 1 0 0 1 1 1v3a1 1 0 0 1-2 0V4a1 1 0 0 1 1-1z"}})])])])]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm._f("translate")("combine_cells")))])])]:_vm._e()],2)}}])}),_vm._v(" "),_c('editor-content',{attrs:{"editor":_vm.editor}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }